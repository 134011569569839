import { ColumnAPI } from 'dataModel';
import { UploadData } from '../../types';
import { DynamicStep } from './NuvoSession';
import { ColumnsAPIMapper } from '@nuvo-importer/common/sdk';
import FeatureWhiteList from '../../settings/FeatureWhiteList';

type ListenStartCallback = (params: {
  data?: UploadData;
  headerIndex?: number;
  step?: DynamicStep;
}) => void;

type GetCompDataCallback = () => {
  isAuth: boolean;
  columns: ColumnAPI[];
  columnAPIMapper: ColumnsAPIMapper;
  licenseKey: string;
  featureWhiteList: FeatureWhiteList;
};

class NuvoSessionListener {
  public listenStartCallbackStack: Record<
    string,
    ListenStartCallback | undefined
  > = {};

  public getConfigCallbackStack: Record<
    string,
    GetCompDataCallback | undefined
  > = {};

  listener = (key: string, listenStartCallback?: ListenStartCallback) => {
    this.listenStartCallbackStack[key] = listenStartCallback;
  };

  getCompDataCallbackListener = (
    key: string,
    getCompDataCallback?: GetCompDataCallback
  ) => {
    this.getConfigCallbackStack[key] = getCompDataCallback;
  };
}

export default NuvoSessionListener;
