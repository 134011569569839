import { CategoryDataModel, DataModel, Sheet } from '@nuvo-importer/common/sdk';
import { cleanSpecialCharAndToLowercase } from './../core/replaceRegex';
import {
  DataModelSheetMatchingAutoMatching,
  DataModelSheetMatchingAutoMatchingSerializer,
  LogAutoOptionMapping,
} from 'core/matching/autoMatching';
import { booleanDropdownOptions } from '../core/constants/boolean';
import AutoMatchingAble from './autoMatching/BaseAutoMatching';
import { encode } from 'core/base64Utils';
import DataModelSheetMatching from './DataModelSheetMatching';
import { from } from 'rxjs';
import { DATATYPE } from 'core/dataType';
import { chunk } from 'lodash';
import { PAYLOAD_CHUNK_SIZE } from 'core/constants/service';

class AutoMatching {
  private autoMatchingLocalStorage: AutoMatchingAble;
  private autoMatchingAPI: AutoMatchingAble;
  private dataModels: DataModel[];

  constructor(
    autoMatchingLocalStorage: AutoMatchingAble,
    autoMatchingAPI: AutoMatchingAble,
    dataModels: DataModel[]
  ) {
    this.autoMatchingAPI = autoMatchingAPI;
    this.autoMatchingLocalStorage = autoMatchingLocalStorage;
    this.dataModels = dataModels;
  }

  private handleGetSaveAutoMatching = (
    sheets: Sheet[],
    serialValue: DataModelSheetMatchingAutoMatching | null
  ) => {
    if (serialValue) {
      try {
        const result =
          DataModelSheetMatchingAutoMatchingSerializer.deSerializeForAutoMatching(
            serialValue,
            sheets,
            this.dataModels
          );

        return {
          ...result,
          autoMatchingChecksum:
            AutoMatching.generateAutoMatchingChecksum(serialValue),
          matchingByAutoMatch: serialValue.dataModelSheetMatch ?? [],
        };
      } catch (error) {
        throw new Error('not found matching in localStorage');
      }
    } else {
      throw new Error('not found matching in localStorage');
    }
  };

  private generateOrderSerializeDataModels = (dataModels: DataModel[]) => {
    const sortedDataModels = [...dataModels].sort((a, b) => {
      if (
        cleanSpecialCharAndToLowercase(a.getKey()) <
        cleanSpecialCharAndToLowercase(b.getKey())
      ) {
        return -1;
      }
      if (
        cleanSpecialCharAndToLowercase(a.getKey()) >
        cleanSpecialCharAndToLowercase(b.getKey())
      ) {
        return 1;
      }
      return 0;
    });

    return sortedDataModels;
  };

  private generateIdentifier = (sheets: Sheet[], dataModels: DataModel[]) => {
    const dataModelsSorted = this.generateOrderSerializeDataModels(dataModels);

    const checksumDataModels = dataModelsSorted.map((dataModel) => {
      const options = (() => {
        if (dataModel.isCategoryType()) {
          return (dataModel as CategoryDataModel).getOptions();
        } else if (dataModel.getType() === DATATYPE.BOOLEAN) {
          return booleanDropdownOptions();
        } else {
          return null;
        }
      })()?.map((option) => option.value);

      return {
        key: cleanSpecialCharAndToLowercase(dataModel.getKey()),
        options,
        isMultiSelect: dataModel.getIsMultiSelection(),
      };
    });

    const checksumSheetColumns: string[] = [];

    sheets.forEach((sheet) => {
      sheet.getSortedColumns().forEach((sheetColumn) => {
        checksumSheetColumns.push(
          cleanSpecialCharAndToLowercase(sheetColumn.getColumnKey())
        );
      });
    });

    return encode(
      `${JSON.stringify(checksumDataModels)}${checksumSheetColumns}`
    );
  };

  private saveAutoMatchingSerializeValue = (
    sheets: Sheet[],
    licenseKey: string,
    serializeValue: DataModelSheetMatchingAutoMatching
  ) => {
    const identifier = this.generateIdentifier(sheets, this.dataModels);

    return this.autoMatchingLocalStorage
      .save(identifier, serializeValue, licenseKey)
      .finally(() => {
        return this.autoMatchingAPI.save(
          identifier,
          serializeValue,
          licenseKey
        );
      })
      .catch(() => {});
  };

  private saveAutoOptionChunk = async (
    licenseKey: string,
    items: LogAutoOptionMapping[]
  ) => {
    return new Promise((resolve, reject) => {
      const chunks = chunk(items, PAYLOAD_CHUNK_SIZE);
      for (let i = 0; i < chunks.length; i++) {
        const data = chunks[i];
        this.autoMatchingAPI.saveOption(licenseKey, data).catch((err) => {
          reject(err);
        });
      }
      resolve(true);
    });
  };

  getAndSaveAutoMatching = (sheets: Sheet[], licenseKey: string) => {
    const identifier = this.generateIdentifier(sheets, this.dataModels);
    return from(
      this.autoMatchingLocalStorage
        .getAutoMatch(identifier, licenseKey)
        .then((serialValue) => {
          return this.handleGetSaveAutoMatching(sheets, serialValue);
        })
        .catch(() => {
          return this.autoMatchingAPI
            .getAutoMatch(identifier, licenseKey)
            .then((serialValue) => {
              if (serialValue) {
                return this.autoMatchingLocalStorage
                  .save(identifier, serialValue, licenseKey)
                  .then(() => serialValue)
                  .catch(() => serialValue);
              }
              return serialValue;
            })
            .then((serialValue) => {
              return this.handleGetSaveAutoMatching(sheets, serialValue);
            })
            .catch(() => {});
        })
    );
  };

  saveAutoMatching = (
    serializeValue: DataModelSheetMatchingAutoMatching,
    dataModelSheetMatching: DataModelSheetMatching,
    licenseKey: string
  ) => {
    return this.saveAutoMatchingSerializeValue(
      dataModelSheetMatching.getSheets(),
      licenseKey,
      serializeValue
    );
  };

  saveAutoOptionMapping = (
    licenseKey: string,
    dataModelSheetMatching: DataModelSheetMatching
  ) => {
    const items: LogAutoOptionMapping[] = [];
    for (let i = 0; i < dataModelSheetMatching?.getMatching()?.length; i++) {
      const col = dataModelSheetMatching?.getMatching()[i];
      const dataModel = col?.matchedDataModel;
      if (
        dataModel?.dataModel?.getType() === DATATYPE.BOOLEAN ||
        dataModel?.dataModel?.getType() === DATATYPE.SINGLE_SELECT ||
        dataModel?.dataModel?.getType() === DATATYPE.MULTIPLE_SELECT
      ) {
        for (let j = 0; j < (dataModel?.matchedOptions?.length ?? 0); j++) {
          const sheetOption = dataModel?.matchedOptions?.[j]?.sheetOption || '';
          if (dataModel?.dataModel?.getIsMultiSelection()) {
            const currentMatched =
              dataModel?.matchedOptions?.[j].dataModelOptions;

            const item: LogAutoOptionMapping = {
              ref: `${sheetOption}`,
              target: currentMatched ?? [],
            };

            items.push(item);
          } else {
            const value = dataModel?.matchedOptions?.[j]?.dataModelOption;
            if (value !== undefined) {
              items.push({
                ref: `${sheetOption}`,
                target: `${value}` ?? '',
              });
            }
          }
        }
      }
    }

    return this.saveAutoOptionChunk(licenseKey, items).catch(() => {});
  };

  static generateAutoMatchingChecksum = (
    serialValue: DataModelSheetMatchingAutoMatching
  ) => {
    return JSON.stringify(serialValue.dataModelSheetMatch);
  };
}

export default AutoMatching;
