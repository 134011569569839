import { RefObject, useCallback } from 'react';
import {
  OnEntryChange,
  OnEntryChangeResult,
  Row,
  RowInfo,
} from '../../../hooks/hooksAPI';
import ValueBeforeHookParser from '../../valueResultParser/ValueBeforeHookParser';
import DataModelRegistry from './../DataModelRegistry';
import AllColumnSetting from './../columns/AllColumnSetting';
import { Validator } from '../../../reviewEntries/validator';
import {
  DeleteRowChange,
  EditRowChange,
  NewRowChange,
  OnColumnsLogs,
  RowChange,
} from '../type';
import { ColumnAPI } from '../../../dataModel/columnsAPI';
import isPromise from 'is-promise';
import { HotTableClass } from '@handsontable/react';
import useHandleChange from './handleChange';
import {
  getFinalChangesByChangeFnResult,
  getFinalChangesWithEditRows,
} from './finalChange';
import { HandleChangeInfoFn } from '../../../reviewEntries/type';
import ModeViewTable from '../ModeViewTable/ModeViewTable';

type IEntryChange = {
  dataModelRegistry: DataModelRegistry;
  updateTotalError: () => void;
  allColumnSetting: AllColumnSetting;
  handleChangeInfo: HandleChangeInfoFn;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  dataSet: Record<string, any>[];
  validator: Validator;
  onEntryChange?: OnEntryChange;
  hotInstance?: RefObject<HotTableClass>;
  modeViewTable: ModeViewTable;
  onColumnsLogs: OnColumnsLogs;
};

const getRowsParamEntryChange = (
  rowChanges: RowChange[],
  dataModelRegistry: DataModelRegistry,
  modeViewTable: ModeViewTable
) => {
  const dataModelsWithHidden = dataModelRegistry.getDataModelsWithHidden();
  const columnsWithHidden = dataModelRegistry.getColumnsWithHidden();
  const rows = [];

  for (let j = 0; j < rowChanges.length; ++j) {
    const rowChange = rowChanges[j];
    const rowDataObj: Row = {};
    const rowInfoObj: RowInfo = {};
    const rawRowInfo = modeViewTable.getDataInfo(rowChange.rowIndex);

    for (let i = 0; i < rowChange.currentRowData.length; ++i) {
      const key = columnsWithHidden[i].key;
      const dataModel = dataModelsWithHidden[i];
      const infos = [];
      rowDataObj[key] = dataModel
        ? ValueBeforeHookParser.parse(dataModel, rowChange.currentRowData[i])
        : rowChange.currentRowData[i];

      for (let j = 0; j < rawRowInfo?.length; j++) {
        const info = rawRowInfo[j];
        if (info?.colIndex === i) {
          infos.push({
            level: info.popover.level,
            message: info.popover.message,
          });
        }
      }

      if (infos.length > 0) {
        rowInfoObj[key] = infos;
      }
    }

    rows.push({
      actionType: rowChange.actionType,
      changeLog: rowChange.actionType === 'create' ? null : rowChange.changeLog,
      data: rowDataObj,
      rowIndex: rowChange.rowIndex,
      info: rowInfoObj,
    });
  }

  return rows;
};

const useEntryChange = ({
  dataModelRegistry,
  updateTotalError,
  allColumnSetting,
  handleChangeInfo,
  dataSet,
  validator,
  onEntryChange,
  hotInstance,
  modeViewTable,
  onColumnsLogs,
}: IEntryChange) => {
  const getColumnsLog = useCallback(() => {
    const dataModels = dataModelRegistry.getDataModelsWithHidden();
    const columnLogs = onColumnsLogs(dataModels);
    return {
      columns: columnLogs,
    };
  }, [onColumnsLogs, dataModelRegistry]);

  const { handleChanges } = useHandleChange({
    dataModelRegistry,
    updateTotalError,
    allColumnSetting,
    handleChangeInfo,
    dataSet,
    validator,
    hotInstance,
  });

  const getCurrentRowData = useCallback(
    (rowIndex: number, columns: ColumnAPI[]) => {
      const rowData: any[] = [];
      const sourceRowData = dataSet[rowIndex];
      columns.forEach((column, i) => {
        rowData[i] = sourceRowData?.[column.key];
      });

      return rowData;
    },
    [dataSet]
  );

  const handleOnEntryChanges = useCallback(
    (
      editRows: EditRowChange[],
      changeFnResult: OnEntryChangeResult | undefined,
      dataSetLength: number
    ) => {
      if (changeFnResult) {
        if (isPromise(changeFnResult)) {
          return changeFnResult
            .then((changeFnResultValues) => {
              if (changeFnResultValues) {
                const finalChanges = getFinalChangesByChangeFnResult(
                  changeFnResultValues,
                  editRows,
                  dataModelRegistry,
                  dataSetLength,
                  getCurrentRowData
                );
                handleChanges(finalChanges);
              } else {
                const finalChanges = getFinalChangesWithEditRows(
                  editRows,
                  dataModelRegistry
                );
                handleChanges(finalChanges);
              }

              return;
            })
            .catch(() => {});
        } else {
          const finalChanges = getFinalChangesByChangeFnResult(
            changeFnResult,
            editRows,
            dataModelRegistry,
            dataSetLength,
            getCurrentRowData
          );
          handleChanges(finalChanges);
          return;
        }
      } else {
        const finalChanges = getFinalChangesWithEditRows(
          editRows,
          dataModelRegistry
        );
        handleChanges(finalChanges);
        return;
      }
    },
    [handleChanges, dataModelRegistry, getCurrentRowData]
  );

  const handleAfterChange = useCallback(
    (
      editRows: EditRowChange[],
      _isChangesInPhysical: boolean,
      isCreateNewSpareRow: boolean
    ) => {
      let changeFnResult: OnEntryChangeResult | undefined;
      const dataSetLength = isCreateNewSpareRow
        ? dataSet.length + 1
        : dataSet.length;
      if (onEntryChange) {
        const rowsParams = getRowsParamEntryChange(
          editRows,
          dataModelRegistry,
          modeViewTable
        );
        changeFnResult = onEntryChange(rowsParams, getColumnsLog());
      }
      return handleOnEntryChanges(editRows, changeFnResult, dataSetLength);
    },
    [
      dataModelRegistry,
      handleOnEntryChanges,
      dataSet,
      onEntryChange,
      modeViewTable,
      getColumnsLog,
    ]
  );

  const handleAfterRemove = useCallback(
    (deleteRows: DeleteRowChange[]) => {
      if (onEntryChange) {
        const rowsParams = getRowsParamEntryChange(
          deleteRows,
          dataModelRegistry,
          modeViewTable
        );
        const changeFnResult = onEntryChange(rowsParams, getColumnsLog());
        return handleOnEntryChanges([], changeFnResult, dataSet.length);
      }

      return;
    },
    [
      dataModelRegistry,
      handleOnEntryChanges,
      dataSet,
      onEntryChange,
      modeViewTable,
      getColumnsLog,
    ]
  );

  const handleAfterNewRow = (newRows: NewRowChange[]) => {
    if (onEntryChange) {
      const rowsParams = getRowsParamEntryChange(
        newRows,
        dataModelRegistry,
        modeViewTable
      );

      const changeFnResult = onEntryChange(rowsParams, getColumnsLog());
      return handleOnEntryChanges([], changeFnResult, dataSet.length);
    }

    return;
  };

  return {
    handleAfterChange,
    handleAfterRemove,
    handleAfterNewRow,
  };
};

export default useEntryChange;
