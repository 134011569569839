import {
  ColumnNumberFormat,
  DetectedNumberFormats,
  NumberFormat,
} from '../dataModel/columnsAPI';
import { DATATYPE } from '../dataType';
import { DataModelSheetMatch } from '../matching/DataModelSheetMatching';
import { Value } from '../sheet/Sheet';
import { NumberParser } from './NumberParser';

export class SheetRegionDetector {
  private PROBE_ROWS_COUNT = 25;
  private MAX_ITERATIONS = 6;

  private totalEuFormatsCount = 0;
  private totalUsFormatsCount = 0;

  private matching: DataModelSheetMatch[] = [];
  private probeTypes = [
    DATATYPE.FLOAT,
    DATATYPE.CURRENCY_EUR,
    DATATYPE.CURRENCY_USD,
    DATATYPE.INTEGER,
    DATATYPE.PERCENTAGE,
  ];

  constructor(matching: DataModelSheetMatch[]) {
    this.matching = matching;
  }

  public detect(): DetectedNumberFormats {
    const columnNumberFormat = this.getColumnsNumberFormat();
    let globalNumberFormat: NumberFormat = NumberFormat.EU;

    if (this.totalUsFormatsCount > this.totalEuFormatsCount) {
      globalNumberFormat = NumberFormat.US;
    } else {
      globalNumberFormat = NumberFormat.EU;
    }

    return {
      global: globalNumberFormat,
      columns: columnNumberFormat,
    };
  }

  private getColumnsNumberFormat(): ColumnNumberFormat[] {
    const columnNumberFormats: ColumnNumberFormat[] = [];

    for (let i = 0; i < this.matching.length; i++) {
      const type: DATATYPE | undefined =
        this.matching[i].matchedDataModel?.dataModel?.getType();

      if (!type || !this.probeTypes.includes(type)) {
        continue;
      }

      let detectedNumberFormat: NumberFormat | undefined = undefined;
      let skipRows = 0;
      let iteration = 1;

      const column = this.matching[i].sheetColumn.getColumnKey();
      const rows: Value[] = this.matching[i].sheetColumn.getRows();

      while (!detectedNumberFormat && iteration <= this.MAX_ITERATIONS) {
        const probe: Value[] = rows.slice(
          skipRows,
          skipRows + this.PROBE_ROWS_COUNT
        );

        if (probe.length === 0) {
          break;
        }

        const format = this.detectNumberFormat(probe);
        if (format !== undefined) {
          detectedNumberFormat = format;
          break;
        }

        skipRows += this.PROBE_ROWS_COUNT;
        iteration += 1;
      }

      columnNumberFormats.push({
        column,
        numberFormat: detectedNumberFormat,
      });
    }

    return columnNumberFormats;
  }

  private detectNumberFormat(values: Value[]): NumberFormat | undefined {
    let euFormatsCount = 0;
    let usFormatsCount = 0;

    for (let i = 0; i < values.length; i++) {
      const value: Value = values[i];
      if (typeof value !== 'string') {
        continue;
      }
      const format: NumberFormat | null | undefined =
        NumberParser.detectNumberFormat(value, { resolveUncertainty: false });

      if (format === NumberFormat.EU) {
        euFormatsCount++;
        this.totalEuFormatsCount++;
      }

      if (format === NumberFormat.US) {
        usFormatsCount++;
        this.totalUsFormatsCount++;
      }
    }

    if (usFormatsCount === 0 && euFormatsCount === 0) {
      return undefined;
    }

    if (usFormatsCount > euFormatsCount) {
      return NumberFormat.US;
    }

    return NumberFormat.EU;
  }
}
