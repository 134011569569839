import { useTranslation } from 'react-i18next';
import DataModelSheetMatcherForm from './DataModelSheetMatcherForm';
import useViewModel from './viewModel';
import DropdownBoundaryProvider from './DropdownBoundaryContext';
import { cx } from '@nuvo-importer/common';
import { useMainView } from 'settings';
import { css } from 'core/emotion';
import ProgressProcessing from 'baseUI/Processing';
import CreateNewColumnModal from './CreateCustomModal/CreateNewColumnModal';
import CreateNewColumnModalContextProvider from './CreateCustomModal/CreateNewColumnModal/CreateNewColumnModalContext';
import CreateNewOptionModalContextProvider from './CreateCustomModal/CreateNewOptionModal/CreateNewOptionModalContext';
import CreateNewOptionModal from './CreateCustomModal/CreateNewOptionModal';

const MatchColumns = () => {
  const { t } = useTranslation();
  const {
    dataModelSheetMatching,
    isLoadingModel,
    dataModelSheetMatcher,
    executing,
    timer,
    hasDataModel,
    isShowFieldRequired,
    progressProcessingRef,
    setShowFieldRequired,
    setDataModelSheetMatcher,
    setDataModelSheetMatching,
    autoMatchingChecksum,
    matchingByAutoMatch,
  } = useViewModel();

  const { modal } = useMainView();
  const loading = isLoadingModel || executing;

  return (
    <CreateNewColumnModalContextProvider>
      <CreateNewOptionModalContextProvider>
        <DropdownBoundaryProvider>
          <div
            role="region"
            className={cx(
              'min-h-500 mt-3 flex w-full flex-col text-left',
              modal && loading ? '!mt-0 h-full' : '',
              loading ? '!min-h-auto' : ''
            )}
          >
            {dataModelSheetMatching &&
              dataModelSheetMatcher &&
              !isLoadingModel &&
              !executing && (
                <DataModelSheetMatcherForm
                  dataModelSheetMatching={dataModelSheetMatching}
                  dataModelSheetMatcher={dataModelSheetMatcher}
                  isShowFieldRequired={isShowFieldRequired}
                  setShowFieldRequired={setShowFieldRequired}
                  setDataModelSheetMatcher={setDataModelSheetMatcher}
                  autoMatchingChecksum={autoMatchingChecksum}
                  matchingByAutoMatch={matchingByAutoMatch}
                />
              )}
            {loading && (
              <div
                className={cx(
                  'mt-2 flex justify-center',
                  modal
                    ? '!-mt-0 ml-1.5 h-full items-center pb-32'
                    : 'min-h-500'
                )}
              >
                <ProgressProcessing
                  ref={progressProcessingRef}
                  timer={timer}
                  initialProgress={hasDataModel ? 100 : undefined}
                  text={t('txt_data_processing')}
                  className={
                    !modal
                      ? cx(
                          'absolute left-1/2',
                          css`
                            top: 19.6rem;
                            transform: translateX(-50%);
                          `
                        )
                      : ''
                  }
                />
              </div>
            )}
            {dataModelSheetMatcher ? (
              <>
                <CreateNewColumnModal
                  dataModelSheetMatcher={dataModelSheetMatcher}
                  setDataModelSheetMatching={setDataModelSheetMatching}
                  setDataModelSheetMatcher={setDataModelSheetMatcher}
                />
                <CreateNewOptionModal
                  dataModelSheetMatcher={dataModelSheetMatcher}
                  setDataModelSheetMatching={setDataModelSheetMatching}
                  setDataModelSheetMatcher={setDataModelSheetMatcher}
                />
              </>
            ) : null}
          </div>
        </DropdownBoundaryProvider>
      </CreateNewOptionModalContextProvider>
    </CreateNewColumnModalContextProvider>
  );
};

export default MatchColumns;
