import IconButton, { IIconButton } from 'baseUI/IconButton';
import { Button, cx, css, useTheme } from '@nuvo-importer/common';
import { FieldArray } from 'react-final-form-arrays';
import SimpleBar from 'simplebar-react';
import { FormSpy } from 'react-final-form';
import { Sheet } from '@nuvo-importer/common/sdk';
import ColumnDropdownField from 'baseUI/ColumnDropdownField';
import Row from '../../components/Row';
import AddColumnButton from '../AddColumnButton';
import Label from '../../components/Label';
import useViewModel from './viewModel';
import MatchStatus from '../../components/MatchStatus';
import { useTranslation } from 'react-i18next';

type FormProps = {
  handleSubmit: () => void;
  joinTargetSheet: Sheet;
  sourceSheet: Sheet;
  loading: boolean;
};

const Form = ({
  handleSubmit,
  joinTargetSheet,
  sourceSheet,
  loading,
}: FormProps) => {
  const theme = useTheme();
  const {
    modalElement,
    scrollbarRef,
    getTargetOptions,
    getSourceOptions,
    sheetName,
    joinMaxLength,
    values,
    matchPercent,
    onValuesChange,
    countMatching,
  } = useViewModel({
    joinTargetSheet,
    sourceSheet,
  });
  const { t } = useTranslation();

  const matching = values.columns.filter((col) => {
    return col.source && col.target;
  });

  return (
    <>
      {matching.length > 0 ? (
        <MatchStatus
          isProcessing={countMatching}
          percent={matchPercent}
          configTheme={theme.getJoinSheetTheme().joinColumnsDialog?.matchStatus}
        />
      ) : null}
      <FormSpy
        subscription={{
          values: true,
        }}
        onChange={onValuesChange}
      />
      <form
        onSubmit={handleSubmit}
        className={cx(
          'nuvo-join-sheet-list-modal-scroll flex min-h-0 flex-grow flex-col',
          css`
            .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
              background-color: ${theme.getJoinSheetTheme().joinColumnsDialog
                ?.scrollbar?.navigatorColor};
            }
            .simplebar-track {
              background-color: ${theme.getJoinSheetTheme().joinColumnsDialog
                ?.scrollbar?.backgroundColor};
            }
          `
        )}
      >
        <SimpleBar
          ref={scrollbarRef}
          forceVisible={false}
          autoHide={false}
          className="min-h-0 flex-shrink flex-grow p-3 pb-0"
        >
          <FieldArray name="columns">
            {({ fields }) => {
              const showDeleteButton = (fields.length ?? 0) > 1;
              return (
                <div className="flex min-h-0 flex-shrink flex-grow flex-col">
                  <div className="flex min-h-0 flex-col">
                    <Row showDeleteButton={showDeleteButton} className="mb-2">
                      <Label
                        className={css({
                          '&&': theme.getJoinSheetTheme().joinColumnsDialog
                            ?.label,
                        })}
                        asteriskClassName={css({
                          '&&': theme.getJoinSheetTheme().joinColumnsDialog
                            ?.asterisk,
                        })}
                      >
                        {sheetName}
                      </Label>
                      <Label
                        className={css({
                          '&&': theme.getJoinSheetTheme().joinColumnsDialog
                            ?.label,
                        })}
                        asteriskClassName={css({
                          '&&': theme.getJoinSheetTheme().joinColumnsDialog
                            ?.asterisk,
                        })}
                      >
                        {t('txt_joined_dataset')}
                      </Label>
                      {showDeleteButton ? <div className="w-8" /> : null}
                    </Row>
                    {fields.map((name, index) => {
                      return (
                        <Row
                          className={
                            index < (fields.length ?? 0) - 1 ? 'mb-2' : ''
                          }
                          showDeleteButton={showDeleteButton}
                        >
                          <ColumnDropdownField
                            name={`${name}.target`}
                            rootDropdownPortal={modalElement ?? undefined}
                            options={getTargetOptions(index)}
                            menuTitle={sheetName}
                            placeholder={t('txt_select_column')}
                            isShowSheetName={false}
                            disabled={countMatching}
                            isFieldShowError
                            theme={
                              theme.getJoinSheetTheme().joinColumnsDialog
                                ?.dropdown
                            }
                            errorMessageClassName={css({
                              '&&': theme.getJoinSheetTheme().joinColumnsDialog
                                ?.errorMessage,
                            })}
                          />
                          <ColumnDropdownField
                            name={`${name}.source`}
                            rootDropdownPortal={modalElement ?? undefined}
                            options={getSourceOptions(index)}
                            menuTitle={t('txt_joined_dataset')}
                            placeholder={t('txt_select_output_col')}
                            disabled={countMatching}
                            isFieldShowError
                            joinPopoverTheme={
                              theme.getJoinSheetTheme().joinPopover
                            }
                            theme={
                              theme.getJoinSheetTheme().joinColumnsDialog
                                ?.dropdown
                            }
                            errorMessageClassName={css({
                              '&&': theme.getJoinSheetTheme().joinColumnsDialog
                                ?.errorMessage,
                            })}
                          />
                          {showDeleteButton ? (
                            <div className="h-8.5 flex items-center">
                              <IconButton
                                className={cx(
                                  'h-8 w-8',
                                  css`
                                    color: #f87171;
                                  `,
                                  css({
                                    '&&': theme.getJoinSheetTheme()
                                      .joinColumnsDialog?.deleteButton,
                                  })
                                )}
                                icon={IIconButton.DELETE}
                                onClick={() => {
                                  fields.remove(index);
                                }}
                              />
                            </div>
                          ) : null}
                        </Row>
                      );
                    })}

                    <div className="pt-2">
                      <AddColumnButton
                        disabled={
                          values.columns.length >= joinMaxLength ||
                          countMatching
                        }
                        onClick={() => {
                          fields.push({
                            joinColumn: null,
                            baseColumn: null,
                          });
                          const scrollElement =
                            scrollbarRef.current?.getScrollElement();
                          setTimeout(() => {
                            scrollElement?.scrollTo({
                              behavior: 'smooth',
                              left: 0,
                              top: scrollElement?.scrollHeight,
                            });
                          }, 0);
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            }}
          </FieldArray>
        </SimpleBar>
        <div className="flex justify-end pt-4">
          <Button type="submit" disabled={countMatching} loading={loading}>
            {t('txt_join')}
          </Button>
        </div>
      </form>
    </>
  );
};

export default Form;
