import { useMemo, useRef } from 'react';
import type {
  MultipleViewModel,
  MultipleViewModelProps,
  Option,
} from './../Dropdown/types';
import lodashIsEmpty from 'lodash/isEmpty';
import {
  useMultipleSelection,
  UseMultipleSelectionStateChange,
  useSelect,
} from 'downshift';
import {
  useViewModel as useBaseViewModel,
  itemToString,
} from '../Dropdown/viewModel';
import { useClickAway } from 'react-use';

const useViewModel = ({
  options,
  onChange,
  value,
  selectRef,
  hasScrollbar,
  type,
  allowCustomOptions,
}: MultipleViewModelProps): MultipleViewModel => {
  const handleChange = (changes: UseMultipleSelectionStateChange<Option>) => {
    const values = changes.selectedItems?.map((item) => item.value) ?? [];
    onChange(values);
  };
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const defaultSelectedItems: Option[] | undefined = useMemo((): Option[] => {
    if (!value || !Array.isArray(value)) {
      return [];
    }

    const nextOptions: Option[] = [];
    const optionMap: Record<string, Option> = {};

    for (let i = 0; i < options.length; i++) {
      optionMap[options[i].value] = options[i];
    }

    value.forEach((valueItem: string) => {
      const option: Option = optionMap[valueItem];
      if (valueItem === option.value) {
        nextOptions.push(option);
      }
    });

    return nextOptions;
  }, [options, value]);

  const {
    selectedItems,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    reset,
  } = useMultipleSelection<Option>({
    selectedItems: defaultSelectedItems,
    onSelectedItemsChange: handleChange,
  });

  const {
    isOpen: isOpenDropdown,
    getItemProps,
    getToggleButtonProps,
    highlightedIndex,
    getMenuProps,
    openMenu,
    closeMenu,
    setHighlightedIndex,
  } = useSelect({
    items: options,
    itemToString,
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.ItemClick:
        case useSelect.stateChangeTypes.MenuBlur:
          return {
            ...changes,
            isOpen: true,
          };
        case useSelect.stateChangeTypes.MenuKeyDownCharacter:
          return { ...changes, highlightedIndex: state.highlightedIndex };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem) {
            if (selectedItems.includes(selectedItem)) {
              removeSelectedItem(selectedItem);
            } else {
              addSelectedItem(selectedItem);
            }
          }
          break;
        default:
          break;
      }
    },
  });

  const shownValue = useMemo(() => {
    return selectedItems.map((item) => item.label).join(', ');
  }, [selectedItems]);

  const {
    isEmpty,
    isShowCustomOptions,
    isShowSearch,
    onClear,
    onMenuButtonKeyDown,
    onMenuKeyDown,
    onSearchOption,
    popper,
    searchOptions,
    menuRef,
    searchValue,
    toggleDropdown,
  } = useBaseViewModel({
    closeMenu,
    hasScrollbar,
    isOpenDropdown,
    openMenu,
    options,
    reset,
    type,
    value,
    selectRef,
    allowCustomOptions,
  });

  useClickAway({ current: menuRef.current }, (event) => {
    if (!menuButtonRef.current?.contains(event.target as HTMLElement)) {
      // Handle for react 18
      setTimeout(() => {
        closeMenu();
      }, 0);
    }
  });

  return {
    isOpenDropdown,
    popper,
    shownValue,
    isEmpty,
    getToggleButtonProps,
    getItemProps,
    highlightedIndex,
    getMenuProps,
    menuRef,
    selectedItem: selectedItems,
    onClear,
    onMenuButtonKeyDown,
    onMenuKeyDown,
    searchOptions,
    isSearching: !lodashIsEmpty(searchValue),
    onSearchOption,
    searchValue,
    isShowCustomOptions,
    isShowSearch,
    setHighlightedIndex,
    toggleDropdown,
    getDropdownProps,
    menuButtonRef,
  };
};

export default useViewModel;
