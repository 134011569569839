import { RefObject, useMemo, useRef } from 'react';
import { Validator } from '../../reviewEntries/validator';
import DataModelRegistry from './DataModelRegistry';
import { HotTableClass } from '@handsontable/react';
import { RecordInfo } from './type';
import { DATATYPE } from '../../dataType';
import ColumnsAPIMapper from '../../dataModel/ColumnsAPIMapper';
import { Subject } from 'rxjs';
import { ConfirmModalProps } from './confirmModal';
import { useTranslation } from 'react-i18next';
import ContextMenuController from './ContextMenu/controller/ContextMenuController';
import { DataModel } from '../../dataModel/model/DataModel';

type UseCustomColumnProps = {
  dataModelRegistry: DataModelRegistry;
  validator: Validator;
  hotInstance: RefObject<HotTableClass>;
  dataInfos: Record<string, RecordInfo[]>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSet: Record<string, any>[];
  updateTotalError: () => void;
  showConfirmModal: (props: ConfirmModalProps) => void;
  contextMenuController: ContextMenuController;
};

export type CustomColumnUIObservable =
  | {
      isOpen: boolean;
      type: 'add';
    }
  | {
      isOpen: boolean;
      type: 'edit';
      dataModel: DataModel;
    };

const useCustomColumn = ({
  dataModelRegistry,
  validator,
  dataInfos,
  dataSet,
  updateTotalError,
  showConfirmModal,
  contextMenuController,
  hotInstance,
}: UseCustomColumnProps) => {
  const { t } = useTranslation();
  const waitingConfirmDeleteColumn = useRef(false);
  const customColumnUIObservable = useMemo(() => {
    return new Subject<CustomColumnUIObservable>();
  }, []);

  const removeDataSet = (columnKey: string) => {
    for (let i = 0; i < dataSet.length; ++i) {
      dataSet[i][columnKey] = undefined;
    }
  };

  const removeDataInfos = (colIndex: number) => {
    const dataLength = dataSet.length - 1;
    const errors = validator.getError();
    let error = 0;
    for (let i = 0; i < dataLength; ++i) {
      const rowDataInfo = dataInfos[i];
      if (rowDataInfo) {
        for (let j = rowDataInfo.length - 1; j >= 0; --j) {
          if (rowDataInfo[j].colIndex > colIndex) {
            rowDataInfo[j].colIndex -= 1;
          } else if (rowDataInfo[j].colIndex === colIndex) {
            if (rowDataInfo[j].popover.level === 'error' && !errors[i][j]) {
              error++;
            }
            rowDataInfo.splice(j, 1);
          }
        }
      }
    }

    return error;
  };

  const updateDataSetKey = (oldKey: string, newKey: string) => {
    for (let i = 0; i < dataSet.length; ++i) {
      dataSet[i][newKey] = dataSet[i][oldKey];
      dataSet[i][oldKey] = undefined;
    }
  };

  const deleteColumn = (columnKey: string) => {
    waitingConfirmDeleteColumn.current = true;
    showConfirmModal({
      title: t('txt_confirm_title'),
      description: t('txt_confirm_delete_custom_column'),
      onClickNegativeButton: () => {
        contextMenuController.closeMenu();
        const colIndex = dataModelRegistry
          .getDataModels()
          .findIndex((model) => model.getKey() === columnKey);
        if (colIndex === -1) {
          return;
        }
        const selectedCell = hotInstance.current?.hotInstance?.getSelected();
        const hot = hotInstance.current?.hotInstance;
        if (hot && selectedCell && selectedCell[0][1] === hot.countCols() - 2) {
          let nextCol = selectedCell[0][1] - 1;
          if (nextCol === -1) {
            nextCol = selectedCell[0][1] + 1;
          }
          if (dataModelRegistry.getColumns().length > 1) {
            hot.selectCell(selectedCell[0][0], nextCol);
          } else {
            hot.deselectCell();
          }
        }
        removeDataInfos(colIndex);
        validator.removeColumn(
          colIndex,
          columnKey,
          dataModelRegistry.getColumns().length
        );
        dataModelRegistry.deleteColumn(columnKey);
        updateTotalError();
        removeDataSet(columnKey);
        waitingConfirmDeleteColumn.current = false;
      },
      onClickPositiveButton: () => {
        waitingConfirmDeleteColumn.current = false;
      },
      isShowIcon: true,
      isShowCloseIcon: false,
      disabledClickOutside: true,
      textNegativeButton: t('txt_delete_button'),
      textPositiveButton: t('txt_cancel'),
    });
  };

  const addColumn = (
    columnName: string,
    columnType: DATATYPE,
    dateFormat?: string
  ) => {
    dataModelRegistry.addColumn({
      key: columnName,
      label: columnName,
      columnType: ColumnsAPIMapper.mapColumnType({ dataType: columnType }),
      outputFormat: dateFormat,
    });
  };

  const editColumn = (editDataModel: DataModel, columnName: string) => {
    const data = dataModelRegistry.editColumn(editDataModel, columnName);
    if (data) {
      updateDataSetKey(editDataModel.getKey(), data.dataModel.getKey());
      validator.editColumn(editDataModel.getKey(), data.dataModel.getKey());
      dataModelRegistry.dataModelObservable().next({
        action: 'edit_column',
        dataModel: data.dataModel,
        column: data.column,
        oldKey: editDataModel.getKey(),
      });
    }
  };

  return {
    deleteColumn,
    addColumn,
    customColumnUIObservable,
    waitingConfirmDeleteColumn,
    editColumn,
  };
};

export default useCustomColumn;
