import { isArray, isNil, isObject } from 'lodash';
import { DataModel } from '../../../dataModel/model/DataModel';
import { findDataModel } from '../../../dataModel/utils';
import {
  EntryChangeResult,
  HookedRecordInfo,
  HookedRecordResult,
  RowResult,
} from '../../../hooks/hooksAPI';
import { ColumnAPI } from '../../../dataModel/columnsAPI';
import { FinalChange, EditRowChange } from '../type';
import ValueParser from '../../valueParser/ValueParser';
import DataModelRegistry from '../DataModelRegistry';
import { DISABLED_LEVEL } from '../../../level';

export const getCleanFnChanges = (
  data: RowResult,
  dataModels: DataModel[],
  cleanFnChanges: Record<string, HookedRecordResult>
) => {
  const dataKeys = Object.keys(data ?? {});

  for (let i = 0; i < dataKeys.length; ++i) {
    const key = dataKeys[i];
    const dataModel = findDataModel(dataModels, key);
    if (!dataModel) {
      continue;
    }
    const cell = data[key];
    let value;
    let info: HookedRecordInfo[] | undefined;

    if (isObject(cell) && !isArray(cell)) {
      value = cell?.value;
      info = cell?.info;
    } else {
      value = cell;
    }

    if (!isNil(value)) {
      if (!cleanFnChanges[key]) {
        cleanFnChanges[key] = {};
      }
      cleanFnChanges[key].value = ValueParser.parse(value, {
        dataModel,
      });
    }

    if (cleanFnChanges[key]?.info) {
      cleanFnChanges[key].info = [
        ...(cleanFnChanges[key]?.info ?? []),
        ...(info ?? []),
      ];
    } else if (!isNil(info)) {
      if (!cleanFnChanges[key]) {
        cleanFnChanges[key] = {};
      }
      cleanFnChanges[key].info = info;
    }

    if (!dataModel?.isDisabled() && cleanFnChanges[key].info) {
      const cleanFnChangesInfos: HookedRecordInfo[] | undefined = [];
      for (let i = 0; i < (cleanFnChanges[key]?.info?.length ?? 0); i++) {
        const target = cleanFnChanges[key]?.info?.[i];
        if (target && target?.level !== DISABLED_LEVEL) {
          cleanFnChangesInfos.push(target);
        }
      }

      cleanFnChanges[key].info = cleanFnChangesInfos;
    }
  }

  return cleanFnChanges;
};

export const getFinalChangesByChangeFnResult = (
  changeFnResults: EntryChangeResult[],
  editRows: EditRowChange[],
  dataModelRegistry: DataModelRegistry,
  dataSetLength: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCurrentRowData: (rowIndex: number, column: ColumnAPI[]) => any[]
) => {
  const dataModelsWithHidden = dataModelRegistry.getDataModelsWithHidden();
  const columns = dataModelRegistry.getColumns();
  const finalChangesObj: Record<number, FinalChange> = {};

  for (let i = 0; i < editRows.length; ++i) {
    const editRow = editRows[i];
    finalChangesObj[editRow.rowIndex] = getFinalChangeWithEditRow(
      editRow,
      dataModelRegistry
    );
  }

  if (isArray(changeFnResults)) {
    for (let i = 0; i < changeFnResults.length; ++i) {
      const changeFnResult = changeFnResults[i];

      if (
        isNil(changeFnResult) ||
        isNil(changeFnResult.rowIndex) ||
        changeFnResult.rowIndex >= dataSetLength - 1 ||
        changeFnResult.rowIndex < 0
      ) {
        continue;
      }

      if (!finalChangesObj[changeFnResult.rowIndex]) {
        const currentRowData = getCurrentRowData(
          changeFnResult.rowIndex,
          columns
        );
        const cleanFnChanges = getCleanFnChanges(
          changeFnResult.data ?? {},
          dataModelsWithHidden,
          {}
        );
        finalChangesObj[changeFnResult.rowIndex] = {
          currentRowData: currentRowData,
          rowIndex: changeFnResult.rowIndex,
          cleanFnChanges,
          sourceCols: [],
        };
      } else {
        finalChangesObj[changeFnResult.rowIndex].cleanFnChanges =
          getCleanFnChanges(
            changeFnResult.data ?? {},
            dataModelsWithHidden,
            finalChangesObj[changeFnResult.rowIndex].cleanFnChanges ?? {}
          );
      }
    }
  }

  const finalChanges: FinalChange[] = [];
  const finalChangesObjKeys = Object.keys(finalChangesObj);

  for (let i = 0; i < finalChangesObjKeys.length; ++i) {
    const finalChange = finalChangesObj[Number(finalChangesObjKeys[i])];
    finalChanges.push(finalChange);
  }

  return finalChanges;
};

export const getFinalChangesWithEditRows = (
  editRows: EditRowChange[],
  dataModelRegistry: DataModelRegistry
) => {
  return editRows.map((editRow) =>
    getFinalChangeWithEditRow(editRow, dataModelRegistry)
  );
};

const getFinalChangeWithEditRow = (
  editRow: EditRowChange,
  dataModelRegistry: DataModelRegistry
) => {
  const currentRowData = editRow.currentRowData;
  const columnsWithHidden = dataModelRegistry.getColumnsWithHidden();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateRowData: any[] = [];
  columnsWithHidden.forEach((column, index) => {
    if (!column.hidden) {
      updateRowData.push(currentRowData[index]);
    }
  });
  return {
    currentRowData: updateRowData,
    rowIndex: editRow.rowIndex,
    changeLog: editRow.changeLog,
    sourceCols: editRow.sourceCols,
  };
};
