import { useFormState } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import DataModelSheetMatching from './../../../matching/DataModelSheetMatching';
import DataModelSheetMatcherModel from './../../../matching/DataModelSheetMatcher';
import DataModelSheetMatcher from '../DataModelSheetMatcher';
import { FormValues } from '../viewModel';
import { useTheme } from 'theme';
import { Fragment, useMemo } from 'react';

type DataModelSheetMatcherListProps = {
  dataModelSheetMatching: DataModelSheetMatching;
  dataModelSheetMatcherModel: DataModelSheetMatcherModel;
  setDataModelSheetMatcher: (
    dataModelSheetMatcher: DataModelSheetMatcherModel
  ) => void;
};

const DataModelSheetMatcherList = ({
  dataModelSheetMatching,
  dataModelSheetMatcherModel,
  setDataModelSheetMatcher,
}: DataModelSheetMatcherListProps) => {
  const { fields } = useFieldArray('matching', {
    subscription: {
      value: true,
    },
  });
  const { values } = useFormState<FormValues>({
    subscription: {
      values: true,
    },
  });
  const theme = useTheme();
  const configTheme = useMemo(() => {
    return theme.getColumnMatchTheme();
  }, [theme]);

  const configThemePopper = useMemo(() => {
    return theme.getPopoverInfo();
  }, [theme]);

  return (
    <>
      {fields.map((name, index) => {
        const match = values?.matching[index];
        if (!match) {
          return null;
        }
        const matchedDataModel = dataModelSheetMatching
          .getDataModels()
          .find(
            (entry) =>
              entry.getKey() === match.matchedDataModel?.dataModel?.getKey()
          );

        return (
          <Fragment key={name}>
            <DataModelSheetMatcher
              prefixName={name}
              matchedDataModel={matchedDataModel}
              sheetColumn={match.sheetColumn}
              dataModelSheetMatcherModel={dataModelSheetMatcherModel}
              dataModelSheetMatching={dataModelSheetMatching}
              configTheme={configTheme}
              configThemePopper={configThemePopper}
              index={index}
              setDataModelSheetMatcher={setDataModelSheetMatcher}
            />
          </Fragment>
        );
      })}
    </>
  );
};

export default DataModelSheetMatcherList;
