import { useEffect, useMemo, useState } from 'react';
import { useSettings } from 'settings';
import { useConfigure } from 'configure';
import { useNuvoSession } from 'main/SessionContext';
import { useTranslation } from 'react-i18next';
import { ColumnAPI, SheetData } from '../types';
import useInAppNavigate from 'core/navigate';
import SpreadSheetNavigate from '../uploadData/SelectHeaderPage/SpreadSheetNavigate';
import { DataModel } from '../dataModel/model/DataModel';
import {
  HEADER_SELECTION_PATH,
  MATCH_COLUMN_PATH,
  REVIEW_ENTRIES_PATH,
} from 'core/constants/route';
import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { ReactComponent as ContextualEngineLogo } from '../assets/logo/contextual-engine.svg';
import { css, CSSObject, cx } from 'core/emotion';
import { useLocalStorage } from 'react-use';
import useNavigatePolicy from '../navigation/navigatePolicy';
import { ContextualEngineRepository } from './services/ContextualEngine.repository';
import ContextualEngineService from './services/ContextualEngine.service';
import { firstValueFrom } from 'rxjs';
import { useTheme } from 'theme';
import { validateBackgroundImageInCss } from '@nuvo-importer/common/sdk';

export const useContextualEngine = () => {
  const setting = useSettings();
  const { sessionId } = useNuvoSession();
  const { licenseKey } = useConfigure();
  const { t } = useTranslation();
  const navigate = useInAppNavigate();
  const { showConfirmModal } = useContextConfirmModalManager();
  const [fileName, setFileName] = useLocalStorage('ce-file-name', '');
  const { getHasJoinColumnPage } = useNavigatePolicy();
  const theme = useTheme();
  const abortController = useMemo(() => new AbortController(), []);
  const [hasProcessing, setHasProcessing] = useState(false);

  const contextualEngineRepository = useMemo(
    () => new ContextualEngineRepository(new ContextualEngineService()),
    []
  );

  const promptContextualEngine = ({
    sheetData,
    columns,
    dataModels,
    spreadSheetNavigate,
    onStartProcess,
    onFinishProcess,
    onFailProcess,
    onCancel,
  }: {
    sheetData: SheetData;
    columns: ColumnAPI[];
    dataModels: DataModel[];
    spreadSheetNavigate: SpreadSheetNavigate;
    onStartProcess?: () => void;
    onFinishProcess?: () => void;
    onFailProcess?: () => void;
    onCancel?: () => void;
  }) => {
    showConfirmModal({
      title: t('txt_use_contextual_engine'),
      description: `<div>
  <p className="font-bold">
 ${t('txt_use_contextual_engine_desc')}
  </p>
</div>`,
      isShowIcon: true,
      isShowPositiveButton: true,
      onClickNegativeButton: () => {
        onStartProcess?.();
        processContextualEngine({
          sheetData,
          columns,
          dataModels,
          spreadSheetNavigate,
        })
          .catch(() => onFailProcess?.())
          .finally(() => onFinishProcess?.());
      },
      onClickPositiveButton() {
        if (onCancel) {
          onCancel?.();
        } else {
          navigate(
            {
              pathname: setting.automaticHeaderDetection
                ? MATCH_COLUMN_PATH
                : HEADER_SELECTION_PATH,
            },
            {
              state: {
                spreadSheetNavigate: spreadSheetNavigate,
              },
            }
          );
        }
      },
      textNegativeButton: t('txt_use_contextual_engine'),
      textPositiveButton: t('txt_skip_for_now'),
      negativeButtonClassName: cx(
        'w-1/2 ml-2',
        css({ '&&': theme.getContextualEngineTheme().prompt?.confirmButton })
      ),
      positiveButtonClassName: cx(
        'w-full !mr-0',
        css({ '&&': theme.getContextualEngineTheme().prompt?.cancelButton })
      ),
      positiveButtonWrapperClassName: 'w-1/2 mr-2',
      customIcon: (className) =>
        validateBackgroundImageInCss(
          theme?.getContextualEngineTheme().prompt?.icon as CSSObject
        ) ? (
          <svg
            className={cx(
              'w-auto bg-contain bg-center bg-no-repeat',
              css({
                '&&': theme?.getContextualEngineTheme().prompt?.icon,
              })
            )}
          />
        ) : (
          <ContextualEngineLogo
            className={cx(
              'w-auto',
              className,
              css({ '&&': theme.getContextualEngineTheme().prompt?.icon })
            )}
          />
        ),
      baseModalClassName: cx(
        css({ '&&': theme.getContextualEngineTheme().prompt?.root })
      ),
      disabledClickOutside: true,
      titleClassName: '!text-neutral-gray',
      descriptionClassName: '!text-neutral-600',
    });
  };

  const processContextualEngine = ({
    sheetData,
    columns,
    dataModels,
    spreadSheetNavigate,
  }: {
    sheetData: SheetData;
    columns: ColumnAPI[];
    dataModels: DataModel[];
    spreadSheetNavigate: SpreadSheetNavigate;
  }) => {
    setHasProcessing(true);
    return firstValueFrom(
      contextualEngineRepository.processContextualEngine({
        data: sheetData,
        licenseKey,
        columns,
        sessionId,
        abortController,
      })
    )
      .then(({ data, fileName }) => {
        setFileName(fileName);
        navigate(
          {
            pathname: REVIEW_ENTRIES_PATH,
          },
          {
            state: {
              spreadSheetNavigate: spreadSheetNavigate,
              columns: columns,
              dataModels: dataModels,
              isAutoMapping: true,
              contextualEngineValues: data,
              isContextualEngineProcessed: true,
            },
          }
        );
      })
      .catch((error) => {
        if (error.message !== 'canceled') {
          onFailedContextualEngine(spreadSheetNavigate);
        }
      });
  };

  const onFailedContextualEngine = (
    spreadSheetNavigate: SpreadSheetNavigate
  ) => {
    if (setting.automaticHeaderDetection) {
      navigate(
        {
          pathname: MATCH_COLUMN_PATH,
        },
        {
          state: {
            spreadSheetNavigate: spreadSheetNavigate,
            isContextualEngineProcessed: true,
          },
        }
      );
    } else {
      displayFailedContextualEngineDialog(spreadSheetNavigate);
    }
  };

  const completeImportContextualEngine = () => {
    if (fileName) {
      return firstValueFrom(
        contextualEngineRepository.deleteFile(licenseKey, fileName)
      );
    }
    return Promise.resolve(null);
  };

  const getContextualEngineType = () => {
    return setting.contextualEngine;
  };

  const getContextualEngineFileName = () => {
    return fileName;
  };

  const displayFailedContextualEngineDialog = (
    spreadSheetNavigate: SpreadSheetNavigate
  ) => {
    const isShowJoinColumn = getHasJoinColumnPage(
      spreadSheetNavigate.getSpreadSheetList()
    );
    showConfirmModal({
      title: t('txt_process_contextual_failed_title'),
      description: t('txt_process_contextual_failed_desc'),
      onClickNegativeButton: () => {
        !setting.automaticHeaderDetection &&
          navigate(
            {
              pathname: isShowJoinColumn
                ? MATCH_COLUMN_PATH
                : HEADER_SELECTION_PATH,
            },
            {
              state: {
                spreadSheetNavigate: spreadSheetNavigate,
              },
            }
          );
      },
      isShowIcon: true,
      isShowPositiveButton: false,
      roundedIcon: true,
      textNegativeButton: t('txt_continue_with_manual_input'),
      baseModalClassName: 'sm:!max-w-480',
    });
  };

  useEffect(() => {
    return () => {
      if (hasProcessing) {
        abortController.abort();
      }
    };
  }, [abortController, hasProcessing]);

  return {
    processContextualEngine,
    promptContextualEngine,
    getContextualEngineType,
    completeImportContextualEngine,
    getContextualEngineFileName,
    displayFailedContextualEngineDialog,
  };
};
