import { useContext } from 'react';
import { ICleaningAssistantContext } from '../../index.types';
import { CleaningAssistantContext } from '../../context';
import styles from './index.style';
import { Info } from 'lucide-react';

type IProps = {
  text: string;
};

export const Message = ({ text }: IProps) => {
  const context = useContext<ICleaningAssistantContext>(
    CleaningAssistantContext
  );
  const s = styles(context);

  return (
    <div className={s.rootClass}>
      <Info size={16} className={s.iconClass} />
      <span className={s.textClass}>{text}</span>
    </div>
  );
};
