import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeftThin } from '../../assets/icon/arrow-left-thin.svg';

export const BackManualImportButton = ({
  onClick,
}: {
  onClick?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-end justify-between gap-1">
      <p className="leading-5.5 text-gray-560 text-xs">
        {t('txt_did_you_expect_different_result')}
      </p>
      <button
        type="button"
        className="text-blue-light-500 leading-5.5 flex cursor-pointer gap-1 text-xs font-medium"
        onClick={onClick}
        aria-label={t('txt_switch_manual_import_title')}
      >
        <ArrowLeftThin />
        {t('txt_switch_manual_import_title')}
      </button>
    </div>
  );
};
