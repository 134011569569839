import { HookedRecordInfoLevel } from '../../hooks/hooksAPI';
import { POPOVER_TYPE_CLASS } from '../../dataModelSheet/Popover/StackedMessage/stackedMessagePopper';
import { CELL_TYPE_CLASS } from '../type';

export const popperRootClassName = (
  cellInfo: {
    level: HookedRecordInfoLevel;
    message: string;
  }[]
) => {
  if (cellInfo?.length > 1) {
    return POPOVER_TYPE_CLASS.MULTIPLE;
  } else {
    switch (cellInfo?.[0]?.level) {
      case 'error':
        return POPOVER_TYPE_CLASS.ERROR;
      case 'warning':
        return POPOVER_TYPE_CLASS.WARNING;
      case 'disabled':
        return POPOVER_TYPE_CLASS.DISABLED;
      case 'info':
      default:
        return POPOVER_TYPE_CLASS.INFO;
    }
  }
};

export const cellClassName = (level: HookedRecordInfoLevel) => {
  if (!level) return CELL_TYPE_CLASS.DEFAULT;
  switch (level) {
    case 'error':
      return `${CELL_TYPE_CLASS.ERROR} bg-salmon-100 text-salmon-500`;
    case 'warning':
      return `${CELL_TYPE_CLASS.WARNING} bg-yellow-50 text-yellow-500`;
    case 'info':
      return `${CELL_TYPE_CLASS.INFO} bg-info-cell`;
    case 'disabled':
      return `${CELL_TYPE_CLASS.DISABLED} bg-gray-50 !text-gray-260`;
    default:
      return CELL_TYPE_CLASS.DEFAULT;
  }
};
