import Button from 'baseUI/Button';
import BackButton from 'baseUI/BackButton';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'settings';
import { useScroll } from 'core/scroll';
import { useTheme } from 'theme';
import { css, CSSInterpolation, CSSObject, cx } from 'core/emotion';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import Popover from 'baseUI/Popover';
import Message from 'baseUI/Popover/Message';
import { isMobile, isTablet } from 'react-device-detect';
import { Watermark } from '@nuvo-importer/common';
import { NUVO_ELEMENT_ID } from 'core/constants/element';
import { useDomMutationSpy } from '../../hooks/useDomMutationSpy';
import { useWatermark } from '../../hooks/useWatermark';
import { createPortal } from 'react-dom';

type ActionFooterProps = {
  onPositiveClick?: () => void;
  onNegativeClick?: () => void;
  positiveButtonText?: string;
  disabledPositive?: boolean;
  disabledNegative?: boolean;
  className?: string;
  footerStyle?: string;
  positiveButtonClassName?: string;
  enablePopper?: boolean;
  popperMessage?: string;
  popoverInfoTheme?: CSSInterpolation;
  backButton?: (disabled: boolean, className: string) => ReactNode;
  wrapperClassName?: string;
};

const ActionFooter = ({
  onPositiveClick,
  onNegativeClick,
  positiveButtonText,
  disabledPositive = false,
  disabledNegative = false,
  className,
  footerStyle,
  positiveButtonClassName,
  enablePopper,
  popperMessage,
  popoverInfoTheme,
  backButton,
  wrapperClassName,
}: ActionFooterProps) => {
  const { t } = useTranslation();
  const { scrollToTop } = useScroll();
  const theme = useTheme();
  const { modal, watermark, identifier } = useSettings();
  const [isHoverPopoverShow, setIsHoverPopoverShow] = useState(false);
  const baseTargetEleId = `${NUVO_ELEMENT_ID.WATERMARK}-footer-root`;
  const { ensureWatermarkVisibility } = useWatermark();
  const { isVisible, setVisible, forceListener, forceTargetListener } =
    useDomMutationSpy({
      rootViewId: '',
      rootTargetEleId: NUVO_ELEMENT_ID.FOOTER_ROOT,
      targetEleId: baseTargetEleId,
      identifier,
      isShowElement: watermark,
      predicateRootTarget: (mutations: MutationRecord[]) => {
        let hasDisplay = true;
        const events = mutations.filter(
          (item) => item.type === 'childList' && item?.removedNodes.length > 0
        );
        for (let i = 0; i < events.length; i++) {
          const event = events[i];
          for (let j = 0; j < event.removedNodes.length; j++) {
            const node = event.removedNodes[j];
            const id = (node as HTMLElement)?.id;
            if (
              id.includes(NUVO_ELEMENT_ID.WATERMARK) &&
              id.includes(identifier)
            ) {
              hasDisplay = false;
            }
          }
        }
        return hasDisplay;
      },
    });

  ensureWatermarkVisibility(
    {
      rootEleId: NUVO_ELEMENT_ID.FOOTER_ROOT,
      isVisible,
      viewMode: 'footer',
      identifier,
      forceListener,
      forceTargetListener,
    },
    () => {
      setVisible(true);
    }
  );

  const isShowPopover = useMemo(
    () => !!(enablePopper && isHoverPopoverShow),
    [isHoverPopoverShow, enablePopper]
  );

  const rootElementRef = useRef<HTMLDivElement | null>(null);
  const nuvoAppRootElementRef = useRef<HTMLDivElement | null | undefined>(null);
  useEffect(() => {
    nuvoAppRootElementRef.current = rootElementRef.current?.closest(
      `#${NUVO_ELEMENT_ID.APP_ROOT}`
    );
  }, []);

  const footerElement = useMemo(
    () => (
      <div
        className={cx(
          className,
          modal ? 'rounded-b-lg' : '',
          css`
            overflow-x: clip;
          `
        )}
      >
        <div
          id={`${NUVO_ELEMENT_ID.FOOTER_ROOT}-${identifier}`}
          className={cx(
            'relative',
            css`
              filter: drop-shadow(0px -13px 50px rgba(17, 48, 68, 0.08));
            `
          )}
        >
          <div
            className={cx(
              'flex items-center justify-end space-x-4 py-6',
              footerStyle ? footerStyle : '',
              modal ? 'rounded-b-lg' : '',
              css({ '&&': theme.getFooterTheme().root }),
              css({
                backgroundColor: theme.getGlobalTheme().getBackgroundColor(),
              })
            )}
          >
            {backButton ? (
              backButton(disabledNegative, 'xsmd:w-auto w-full')
            ) : (
              <BackButton
                onClick={onNegativeClick}
                disabled={disabledNegative}
                className="xsmd:w-auto w-full"
              />
            )}
            <Popover
              isShow={isShowPopover}
              message={
                <Message
                  className={cx(
                    css({ '&&': popoverInfoTheme }),
                    css`
                      max-width: 230px;
                    `
                  )}
                >
                  {popperMessage}
                </Message>
              }
              isShowArrow
              arrowClassName={
                (popoverInfoTheme as CSSObject)?.['backgroundColor']
              }
              offset={[-3, 10]}
              direction="top"
              render={({ referenceElement }) => {
                return (
                  <span
                    ref={referenceElement}
                    onMouseEnter={() => setIsHoverPopoverShow(true)}
                    onMouseOut={() => setIsHoverPopoverShow(false)}
                  >
                    <Button
                      disabled={disabledPositive}
                      onClick={() => {
                        scrollToTop();
                        onPositiveClick?.();
                        if (isMobile || isTablet) {
                          setIsHoverPopoverShow((isShow) => !isShow);
                        }
                      }}
                      className={cx(
                        `xsmd:w-auto w-full disabled:opacity-70`,
                        positiveButtonClassName
                      )}
                      textClassName="pointer-events-none"
                    >
                      {positiveButtonText || t('txt_continue')}
                    </Button>
                  </span>
                );
              }}
            />
          </div>
          <Watermark
            id={`${baseTargetEleId}-${identifier}`}
            viewMode="footer"
            allowRender={watermark}
            identifier={identifier}
          />
        </div>
      </div>
    ),
    [
      backButton,
      baseTargetEleId,
      className,
      disabledNegative,
      disabledPositive,
      footerStyle,
      identifier,
      isShowPopover,
      modal,
      onNegativeClick,
      onPositiveClick,
      popoverInfoTheme,
      popperMessage,
      positiveButtonClassName,
      positiveButtonText,
      scrollToTop,
      t,
      theme,
      watermark,
    ]
  );

  return (
    <div
      ref={rootElementRef}
      className={cx(modal ? 'pt-22.5' : '', wrapperClassName)}
    >
      {!modal && nuvoAppRootElementRef.current
        ? createPortal(footerElement, nuvoAppRootElementRef.current)
        : footerElement}
    </div>
  );
};

export default ActionFooter;
