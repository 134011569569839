import BooleanValueParser from './BooleanValueParser';
import IValueParser, {
  IValueParserOptions,
  PossibleValue,
} from './IValueParser';
import { DATATYPE } from '../../../core/dataType';
import CategoryParser from './CategoryParser';
import StringValueParser from './StringValueParser';
import IntNumberValueParser from './IntNumberValueParser';
import FloatNumberValueParser from './FloatNumberValueParser';
import PercentValueParser from './PercentValueParser';
import CurrencyValueParser from './CurrencyValueParser';
import DateValueParser from './DateValueParser';
import { IColumnInformation } from '../DataModelSheetForm/TopAction/SearchAndReplace';
import { NumberParser } from '../../utils/NumberParser';
import { NumberFormat } from '../../dataModel/columnsAPI';
import { booleanDropdownOptions } from '../../constants/boolean';
import { isArray } from 'lodash';
import { DataModel } from '../../dataModel/model/DataModel';

const intNumberValueParser = new IntNumberValueParser();
const floatValueParser = new FloatNumberValueParser();
const currencyValueParser = new CurrencyValueParser();
const percentValueParser = new PercentValueParser();
const booleanNumberValueParser = new BooleanValueParser();
const categoryParser = new CategoryParser();
const stringSelectParser = new StringValueParser();
const dateValueParser = new DateValueParser();

class ValueParser {
  static parse = (value: PossibleValue, options?: IValueParserOptions) => {
    let parser: IValueParser;

    const type = options?.dataModel?.getType();

    switch (type) {
      case DATATYPE.INTEGER:
        parser = intNumberValueParser;
        break;
      case DATATYPE.FLOAT:
        parser = floatValueParser;
        break;
      case DATATYPE.BOOLEAN:
        parser = booleanNumberValueParser;
        break;
      case DATATYPE.SINGLE_SELECT:
      case DATATYPE.CURRENCY_CODE:
      case DATATYPE.COUNTRY_CODE_ALPHA_2:
      case DATATYPE.COUNTRY_CODE_ALPHA_3:
        parser = categoryParser;
        break;
      case DATATYPE.PERCENTAGE:
        parser = percentValueParser;
        break;
      case DATATYPE.CURRENCY_EUR:
      case DATATYPE.CURRENCY_USD:
        parser = currencyValueParser;
        break;
      case DATATYPE.DATE:
      case DATATYPE.DATE_DMY:
      case DATATYPE.DATE_MDY:
      case DATATYPE.DATE_ISO:
      case DATATYPE.DATETIME:
      case DATATYPE.TIME_HMS:
      case DATATYPE.TIME_HMS_24:
      case DATATYPE.TIME_HM:
      case DATATYPE.TIME_HM_24:
        parser = dateValueParser;
        break;
      case DATATYPE.EMAIL:
      case DATATYPE.URL_WWW:
      case DATATYPE.URL_HTTPS:
      case DATATYPE.URL:
      case DATATYPE.PHONE:
      case DATATYPE.ZIP_CODE_DE:
      case DATATYPE.BIC:
      case DATATYPE.VAT_EU:
      case DATATYPE.GTIN:
      case DATATYPE.IBAN:
      default:
        parser = stringSelectParser;
        break;
    }

    return parser.parse(value, options);
  };

  static parseRawValueToDisplayValue = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rawValue: any,
    column: IColumnInformation
  ) => {
    let parsedValue = rawValue;
    if (column.isNumeric) {
      parsedValue = NumberParser.convertToFormat(rawValue, {
        targetFormat: column.numberFormat || NumberFormat.EU,
      });

      if (
        [DATATYPE.CURRENCY_EUR, DATATYPE.CURRENCY_USD].includes(column.type)
      ) {
        if (column.type === DATATYPE.CURRENCY_USD) {
          parsedValue = `$${parsedValue}`;
        } else {
          parsedValue = `${parsedValue} €`;
        }
      } else if (column.type === DATATYPE.PERCENTAGE) {
        parsedValue = `${parsedValue} %`;
      }
    } else if (column.type === DATATYPE.BOOLEAN) {
      const booleanLabels = booleanDropdownOptions();
      const item = booleanLabels.find((item) =>
        `${item.value}`.includes(`${rawValue}`)
      );
      if (item) {
        parsedValue = `${item.label}`;
      }
    } else if (DataModel.isTypeCategory(column.type)) {
      if (!column.isMultiSelection) {
        const option = column.options?.find((item) => item.value === rawValue);
        if (option) {
          parsedValue = `${option?.label}`;
        }
      } else {
        if (isArray(rawValue)) {
          const options = column.options?.filter((item) =>
            rawValue.includes(item.value)
          );
          if (options) {
            parsedValue = options.map((item) => item.label).join(', ');
          }
        }
      }
    }

    return `${parsedValue}`;
  };

  static parseDisplayValueToRawValue = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    displayValue: any,
    column: IColumnInformation
  ) => {
    if (column.isNumeric) {
      const universalDecimal = NumberParser.convertToUsWithDecimal(
        `${displayValue}`,
        column.numberFormat ?? NumberFormat.EU
      );
      if (universalDecimal === null) {
        return displayValue;
      }
      const convertedUS = NumberParser.convertStringToNumber(universalDecimal, {
        format: NumberFormat.US,
      });
      if (convertedUS !== null) {
        return Number(convertedUS);
      } else {
        return displayValue;
      }
    } else {
      return displayValue;
    }
  };
}

export default ValueParser;
