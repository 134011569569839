export type StackedMessageLevel = 'error' | 'warning' | 'info' | 'disabled';

export enum LEVEL {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  DISABLED = 'disabled',
}

export const DEFAULT_LEVEL = 'info';

export const DISABLED_LEVEL = 'disabled';

export const CELL_COLOR = {
  error: 'FCEAEA',
  warning: 'FFFBF0',
  info: 'F5F8FE',
  disabled: 'FBFBFB',
};
