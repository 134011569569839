import ThemeAPIMapper from '../../../../theme/ThemeAPIMapper';
import { COLOR } from '../../../constants/colors';
import { css, CSSObject, cx } from '../../../emotion';
import { LEVEL } from '../../../level';
import { PopoverThemeProps } from '../../DataModelSheetForm/type';

const baseClassname = 'rounded-full w-2 h-2 min-w-2 min-h-2 mb-px mt-1';

const defaultStyle = COLOR.DARK_BLUE;

const infoClassname = (
  theme: ThemeAPIMapper,
  configTheme?: PopoverThemeProps['info']
) => {
  return cx(
    css({
      backgroundColor: theme.getGlobalTheme().getLight400Color(),
    }),
    baseClassname,
    css(
      {
        '&&': {
          backgroundColor: (configTheme?.circle as CSSObject)?.backgroundColor,
        },
      },
      { '&&': configTheme?.circle }
    )
  );
};

const warningClassname = (configTheme?: PopoverThemeProps['warning']) => {
  return cx(
    'bg-yellow-500',
    baseClassname,
    css(
      {
        '&&': {
          backgroundColor: (configTheme?.circle as CSSObject)?.backgroundColor,
        },
      },
      { '&&': configTheme?.circle }
    )
  );
};

const errorClassname = (configTheme?: PopoverThemeProps['error']) => {
  return cx(
    'bg-salmon-500',
    baseClassname,
    css(
      {
        '&&': {
          backgroundColor: (configTheme?.circle as CSSObject)?.backgroundColor,
        },
      },
      { '&&': configTheme?.circle }
    )
  );
};

const disabledClassname = (configTheme?: PopoverThemeProps['disabled']) => {
  return cx(
    'bg-gray-560',
    baseClassname,
    css(
      {
        '&&': {
          backgroundColor: (configTheme?.circle as CSSObject)?.backgroundColor,
        },
      },
      { '&&': configTheme?.circle }
    )
  );
};

export const levelInfoClassname = ({
  level,
  configTheme,
  theme,
}: {
  level: 'error' | 'warning' | 'info' | 'disabled';
  configTheme?: PopoverThemeProps;
  theme: ThemeAPIMapper;
}) => {
  switch (level) {
    case LEVEL.INFO:
      return infoClassname(theme, configTheme?.info);
    case LEVEL.WARNING:
      return warningClassname(configTheme?.warning);
    case LEVEL.ERROR:
      return errorClassname(configTheme?.error);
    case LEVEL.DISABLED:
      return disabledClassname(configTheme?.disabled);
    default: {
      return '';
    }
  }
};

export const wrapperClassname = ({
  level,
  configTheme,
  hasMultipleMessage,
  theme,
}: {
  level?: 'error' | 'warning' | 'info' | 'disabled';
  configTheme?: PopoverThemeProps;
  hasMultipleMessage: boolean;
  theme: ThemeAPIMapper;
}) => {
  const defaultStyle = cx(
    'bg-blue-dark-400 border-blue-dark-800',
    css`
      .multiple-popper {
        ${configTheme?.root}
      }
    `
  );

  switch (level) {
    case LEVEL.ERROR:
      return !hasMultipleMessage
        ? cx(
            'bg-salmon-500 border-salmon-500',
            css`
              .error-popper {
                ${configTheme?.error?.root}
              }
            `
          )
        : defaultStyle;
    case LEVEL.WARNING:
      return !hasMultipleMessage
        ? cx(
            'bg-yellow-500 border-yellow-500',
            css`
              .warning-popper {
                ${configTheme?.warning?.root}
              }
            `
          )
        : defaultStyle;
    case LEVEL.INFO:
      return !hasMultipleMessage
        ? cx(
            css({
              backgroundColor: theme.getGlobalTheme().getLight400Color(),
              borderColor: theme.getGlobalTheme().getLight400Color(),
            }),
            css`
              .info-popper {
                background-color: ${theme.getGlobalTheme().getLight400Color()};
                border-color: ${theme.getGlobalTheme().getLight400Color()};
              }
            `,
            css`
              .info-popper.info-popper {
                ${configTheme?.info?.root}
              }
            `
          )
        : defaultStyle;
    case LEVEL.DISABLED:
      return cx(
        'bg-gray-660 border-gray-660',
        css`
          .disabled-popper {
            ${configTheme?.disabled?.root}
          }
        `
      );
    default:
      return cx(
        'bg-salmon-500 border-salmon-500',
        css`
          .error-popper {
            ${configTheme?.error?.root}
          }
        `
      );
  }
};

export const arrowStyled = ({
  level,
  configTheme,
  hasMultipleMessage,
  theme,
}: {
  level?: 'error' | 'warning' | 'info' | 'disabled';
  configTheme?: PopoverThemeProps;
  hasMultipleMessage: boolean;
  theme: ThemeAPIMapper;
}) => {
  switch (level) {
    case LEVEL.ERROR:
      return !hasMultipleMessage
        ? (configTheme?.error?.root as CSSObject)?.backgroundColor
          ? (configTheme?.error?.root as CSSObject)?.backgroundColor
          : COLOR.ERROR
        : defaultStyle;
    case LEVEL.WARNING:
      return !hasMultipleMessage
        ? (configTheme?.warning?.root as CSSObject)?.backgroundColor
          ? (configTheme?.warning?.root as CSSObject)?.backgroundColor
          : COLOR.WARNING
        : defaultStyle;
    case LEVEL.INFO:
      return !hasMultipleMessage
        ? (configTheme?.info?.root as CSSObject)?.backgroundColor
          ? (configTheme?.info?.root as CSSObject)?.backgroundColor
          : theme.getGlobalTheme().getLight400Color()
        : defaultStyle;
    case LEVEL.DISABLED:
      return !hasMultipleMessage
        ? (configTheme?.disabled?.root as CSSObject)?.backgroundColor
          ? (configTheme?.disabled?.root as CSSObject)?.backgroundColor
          : COLOR.DISABLED
        : defaultStyle;

    default:
      return COLOR.ERROR;
  }
};
