import { BaseValueParser } from './BaseNumberParser';
import { PossibleValue } from './IValueParser';

class IntNumberValueParser extends BaseValueParser {
  parseStrToValue = (value: PossibleValue) => {
    return value;
  };
}

export default IntNumberValueParser;
