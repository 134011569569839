export function isIntersecting(
  element1: HTMLElement | null,
  element2: HTMLElement | null
): boolean {
  if (element1 === null || element2 === null) {
    return false;
  }

  const rect1: DOMRect = element1.getBoundingClientRect();
  const rect2: DOMRect = element2.getBoundingClientRect();

  return !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );
}
