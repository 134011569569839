import { isArray, isBoolean, isEmpty, isNil, isNumber, isObject } from 'lodash';
import { DataModel } from '../../dataModel/model/DataModel';
import { PossibleValue } from './IValueParser';

export abstract class BaseValueParser {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract parseStrToValue: (value: PossibleValue) => any;

  parse = (_dataModel: DataModel, value: PossibleValue) => {
    if (isNil(value)) {
      return '';
    }

    if (isBoolean(value)) {
      return value ? 1 : 0;
    }

    if (isNumber(value)) {
      return value;
    }

    if (isObject(value) || isArray(value) || isEmpty(value)) {
      return '';
    }

    const result = this.parseStrToValue(value);

    if (isNaN(result)) {
      return value;
    }

    return result;
  };
}
