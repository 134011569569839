import { forwardRef } from 'react';
import { CSSInterpolation, css, cx } from '@nuvo-importer/common';
import { SheetColumn } from '@nuvo-importer/common/sdk';
import { CSSProperties } from 'react';

type IMessageProps = {
  theme: CSSInterpolation;
  sheetColumns: SheetColumn[];
  className?: string;
  style?: CSSProperties;
  popperAttrs?: { [key: string]: string };
  title: string;
  hideColumnName?: boolean;
};

const getSheetName = (sheetColumn: SheetColumn) => {
  switch (sheetColumn.getSheet().getSpreadSheet().getType()) {
    case 'tsv':
    case 'csv':
    case 'json':
      return '';
    default:
      return sheetColumn.getSheet().getName();
  }
};

const Message = forwardRef<HTMLDivElement, IMessageProps>(
  (
    {
      theme,
      sheetColumns,
      style,
      popperAttrs,
      className,
      title,
      hideColumnName,
    },
    ref
  ) => {
    const joinedSheetNames = sheetColumns.map((sheetColumn) => {
      const sheetName = getSheetName(sheetColumn);
      const fileName = sheetColumn.getSheet().getSheetFileName();
      return [
        hideColumnName ? null : sheetColumn.getColumnKey(),
        sheetName,
        fileName,
      ]
        .filter((item) => !!item)
        .join(' - ');
    });

    return (
      <div
        ref={ref}
        style={style}
        className={cx(
          'bg-blue-dark-500 rounded-medium z-170 break-words px-3 py-2 text-xs text-white',
          className,
          css`
            max-width: 280px;
          `,
          css({
            '&&': theme,
          })
        )}
        {...(popperAttrs ? popperAttrs : {})}
      >
        {title}{' '}
        <ul className="mt-px list-disc">
          {joinedSheetNames.map((sheet) => {
            return (
              <li key={sheet} className="ml-3">
                <span className="relative -left-0.5 break-all">{sheet}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
);

export default Message;
