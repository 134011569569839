import { parse, format, isMatch } from 'date-fns';
import moment from 'moment';
import { DATATYPE } from './dataType';
import { DATE_GENERAL_FORMAT } from './constants/date';

export const DATE_FNS_FORMAT_DATE: Record<string, string> = {
  [DATATYPE.DATE_DMY]: 'dd.MM.yyyy',
  [DATATYPE.DATE_MDY]: 'MM.dd.yyyy',
  [DATATYPE.DATE_ISO]: 'yyyy-MM-dd',
  [DATATYPE.DATETIME]: 'yyyy-MM-dd HH:mm:ss',
  [DATATYPE.TIME_HM]: 'hh:mm aaa',
  [DATATYPE.TIME_HMS]: 'hh:mm:ss aaa',
  [DATATYPE.TIME_HM_24]: 'HH:mm',
  [DATATYPE.TIME_HMS_24]: 'HH:mm:ss',
};

export const MOMENT_FORMAT_TIME: Record<string, string> = {
  [DATATYPE.TIME_HM]: 'hh:mm a',
  [DATATYPE.TIME_HMS]: 'hh:mm:ss a',
  [DATATYPE.TIME_HM_24]: 'HH:mm',
  [DATATYPE.TIME_HMS_24]: 'HH:mm:ss',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const displayDateText = (text: any) => {
  try {
    return format(parse(text, DATE_GENERAL_FORMAT, new Date()), 'dd.MM.yyyy');
  } catch (err) {
    return text;
  }
};

export const parseDateString = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateValue: any,
  formatTo?: string,
  customDateSupports?: string[],
  hasDateType?: boolean
) => {
  try {
    if (typeof dateValue === 'string') {
      if (
        hasDateType === false ||
        (isNaN(Date.parse(dateValue)) && !isDateFormat(dateValue))
      ) {
        return dateValue;
      }

      const dateSupports = customDateSupports
        ? customDateSupports
        : [
            'dd.MM.yyyy HH:mm:ss',
            'dd.MM.yyyy HH:mm',
            'dd.MM.yyyy',
            'dd/MM/yyyy HH:mm:ss',
            'dd/MM/yyyy HH:mm',
            'dd/MM/yyyy',
            'dd-MM-yyyy HH:mm:ss',
            'dd-MM-yyyy HH:mm',
            "yyyy-MM-dd'T'HH:mm:ssZ",
            "yyyy-MM-dd'T'HH:mm:ss",
            'yyyy-MM-dd',
            'yyyy-MM-dd HH:mm:ss',
            'dd-MM-yyyy',
          ];
      let dateFormat = undefined;
      for (let i = 0; i < dateSupports.length; ++i) {
        if (isMatch(dateValue, dateSupports[i])) {
          dateFormat = dateSupports[i];
          break;
        }
      }

      if (dateFormat) {
        return format(
          parse(dateValue, dateFormat, new Date()),
          formatTo ? formatTo : DATE_GENERAL_FORMAT
        );
      } else {
        throw new Error('not found format');
      }
    } else {
      throw new Error('not date string');
    }
  } catch (err) {
    return dateValue;
  }
};

const STRICT_FORMATS = ['YYYY-MM-DDTHH:mm:ss.SSSZ', 'X', 'x'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatTime = (value: any, timeFormat: string) => {
  if (value === null) {
    value = '';
  }

  value = /^\d{3,}$/.test(value) ? parseInt(value, 10) : value;

  const twoDigitValue = /^\d{1,2}$/.test(value);

  if (twoDigitValue) {
    value += ':00';
  }

  const date = moment(value, STRICT_FORMATS, true).isValid()
    ? moment(value)
    : moment(value, timeFormat);
  let isValidTime = date.isValid();

  let isValidFormat =
    moment(value, timeFormat, true).isValid() && !twoDigitValue;

  if (value === '') {
    isValidTime = true;
    isValidFormat = true;
  }

  if (isValidTime && !isValidFormat) {
    const correctedValue = date.format(timeFormat);
    return correctedValue;
  } else {
    return value;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatDateStringISO = (dateValue: any, formatTo: string) => {
  try {
    if (typeof dateValue === 'string') {
      if (moment(dateValue, formatTo, true).isValid()) {
        return moment.utc(dateValue, formatTo).format(formatTo);
      } else if (moment(dateValue).isValid()) {
        return moment.utc(dateValue).format(formatTo);
      } else {
        throw new Error('not found format');
      }
    } else {
      throw new Error('not date string');
    }
  } catch (err) {
    return dateValue;
  }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatDateStringByDateType = (value: any, type: DATATYPE) => {
  switch (type) {
    case DATATYPE.DATE_DMY:
    case DATATYPE.DATE_MDY:
    case DATATYPE.DATE_ISO:
    case DATATYPE.DATETIME: {
      return parseDateString(value, DATE_FNS_FORMAT_DATE[type], [
        DATE_FNS_FORMAT_DATE[type],
      ]);
    }

    default:
      return value;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDateFormat = (str: any) => {
  const [d, M, y] = str?.split(' ')?.[0]?.split(/[./-]/);
  return Number(y) && Number(M) <= 12 && Number(d) <= 31 ? true : false;
};
