import { SettingsAPI } from '../settings/settingsAPI';
import { supportedTypes } from './../core/constants/file';
import { IDataFile } from '../modules/nuvo.parser.worker';
import { IReviewEntriesLog } from '../completeImportLog/Types';

export interface IEventStack {
  eventType: string;
  timestamp: string;
}

interface IEventRecord {
  step: string;
  action: 'start' | 'end';
}

export type TrackSettings = Required<
  Omit<
    SettingsAPI,
    | 'developerMode'
    | 'style'
    | 'preloadData'
    | 'columns'
    | 'i18nOverrides'
    | 'dataHandler'
  > & {
    i18nOverrides: boolean;
    preloadData: boolean;
    style: boolean;
    onEntryInit: boolean;
    onEntryChange: boolean;
    columnHooks: boolean;
    dataHandlerUsage: {
      headerStep: boolean;
      reviewStep: boolean;
    };
  }
>;

export enum FRAMEWORK {
  REACT = 'react',
  VANILLA = 'vanilla',
  ANGULAR = 'angular',
  VUE = 'vue',
}

export interface IUploadedFile {
  fileSize: number;
  fileType: string;
}

class Tracking {
  private static instance: Tracking;
  private framework: FRAMEWORK = FRAMEWORK.REACT;
  private origin?: string;
  private versionNumber: string = process.env.NX_SDK_VERSION_NUMBER || '';
  private uploadedFiles: Record<string, IUploadedFile[]> = {};
  private frameworkVersion = '1.0.0';
  private eventStack: Record<string, IEventStack[]> = {};
  private static eventMapping: Record<string, string> = {
    '/': 'upload',
    '/select-sheet': 'sheet_selection',
    '/select-header': 'header_selection',
    '/match-column': 'match_columns',
    '/review-entries': 'review_entries',
    '/join-column': 'join_sheets',
  };
  private reviewEntriesLog: Record<string, IReviewEntriesLog> = {};
  private parseSession = false;
  private startSession: Record<string, boolean | undefined> = {};

  private constructor() {}

  static getInstance() {
    if (!Tracking.instance) {
      Tracking.instance = new Tracking();
    }
    return Tracking.instance;
  }

  setFrameWork(framework: FRAMEWORK) {
    this.framework = framework;
  }

  getFrameWork() {
    return this.framework;
  }

  setOrigin(origin: string) {
    this.origin = origin;
  }

  getOrigin() {
    return this.origin ?? '';
  }

  setVersionNumber(versionNumber: string) {
    this.versionNumber = versionNumber;
  }

  getVersionNumber() {
    return this.versionNumber;
  }

  setFrameworkVersion(frameworkVersion: string) {
    this.frameworkVersion = frameworkVersion;
  }

  getFrameworkVersion() {
    return this.frameworkVersion;
  }

  pushEventStack(identifier: string, event: IEventRecord) {
    if (!this.eventStack[identifier]) {
      this.eventStack[identifier] = [];
    }

    const lastEvent =
      this.eventStack[identifier][this.eventStack[identifier].length - 1];

    if (
      event.action === 'end' &&
      (lastEvent?.eventType ===
        `${Tracking.eventMapping[event.step] || event.step}_end` ||
        lastEvent?.eventType !==
          `${Tracking.eventMapping[event.step] || event.step}_start`)
    ) {
      return;
    }

    if (
      this.eventStack[identifier].length > 0 &&
      event.action === 'start' &&
      lastEvent?.eventType.includes('_start') &&
      lastEvent?.eventType !==
        `${Tracking.eventMapping[event.step] || event.step}_start`
    ) {
      return;
    }

    this.eventStack[identifier].push({
      eventType: `${Tracking.eventMapping[event.step] || event.step}_${
        event.action
      }`,
      timestamp: new Date().toISOString(),
    });

    if (
      this.eventStack[identifier].length > 1 &&
      this.eventStack[identifier][this.eventStack[identifier].length - 1]
        .eventType ===
        this.eventStack[identifier][this.eventStack[identifier].length - 2]
          .eventType
    ) {
      this.eventStack[identifier].splice(
        this.eventStack[identifier].length - 2,
        1
      );
    }
  }

  removeEventStack(identifier: string, eventName: string) {
    this.eventStack[identifier] = this.eventStack[identifier].filter(
      (event) => event.eventType !== eventName
    );
  }

  getEventStack(identifier: string) {
    return this.eventStack[identifier];
  }

  clearEventStack(identifier: string) {
    this.eventStack[identifier] = [];
  }

  setUploadedFiles(identifier: string, files: IDataFile[]) {
    if (!this.uploadedFiles[identifier])
      this.clearUploadedFilesStack(identifier);

    files.forEach((file) =>
      this.uploadedFiles[identifier].push({
        fileSize: file.fileSize,
        fileType: this.mapSupportedType(file.type),
      })
    );
  }

  getUploadFiles(identifier: string) {
    return this.uploadedFiles?.[identifier] || [];
  }

  clearUploadedFilesStack(identifier: string) {
    this.uploadedFiles[identifier] = [];
  }

  setReviewEntriesLog(identifier: string, log: IReviewEntriesLog) {
    this.reviewEntriesLog[identifier] = log;
  }

  getReviewEntriesLog(identifier: string) {
    return this.reviewEntriesLog?.[identifier] || {};
  }

  setTotalCleanings(identifier: string, total_cleanings: number): void {
    this.reviewEntriesLog[identifier].total_cleanings = total_cleanings;
  }

  clearReviewEntriesLog(identifier: string) {
    this.reviewEntriesLog[identifier] = {
      total_rows: 0,
      total_error_rows: 0,
      total_error_cells: 0,
      total_columns: 0,
      total_cleanings: 0,
    };
  }

  setParseSession = () => {
    this.parseSession = true;
  };

  getParseSession = () => {
    return this.parseSession;
  };

  clearParseSession = () => {
    this.parseSession = false;
  };

  setStartSession = (identifier: string) => {
    this.startSession[identifier] = true;
  };

  getStartSession = (identifier: string) => {
    return this.startSession[identifier];
  };

  clearStartSession = (identifier: string) => {
    this.startSession[identifier] = undefined;
  };

  private mapSupportedType(type: string): string {
    for (const [fileType, mimeType] of Object.entries(supportedTypes)) {
      if (type === mimeType) {
        return fileType;
      }
    }
    return type;
  }
}

export default Tracking;
