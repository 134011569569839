import { ErrorMessage } from '@nuvo-importer/common/sdk';
import ColumnDropdown, { IColumnDropdownProps } from '../ColumnDropdown';
import useViewModel from './viewModel';

export type IColumnDropdownFieldProps = Omit<
  IColumnDropdownProps,
  'onChange' | 'value'
> & {
  name: string;
  isFieldShowError?: boolean;
  errorMessageClassName?: string;
};

const ColumnDropdownField = ({
  name,
  isFieldShowError = false,
  errorMessageClassName,
  ...props
}: IColumnDropdownFieldProps) => {
  const { input, isShowError, errorMessage } = useViewModel({
    name,
    isFieldShowError,
  });
  return (
    <div>
      <ColumnDropdown {...input} {...props} />
      {isShowError ? (
        <ErrorMessage className={errorMessageClassName}>
          {errorMessage}
        </ErrorMessage>
      ) : null}
    </div>
  );
};

export default ColumnDropdownField;
