import { HotTableClass } from '@handsontable/react';
import Button from '../../../../../components/Button';
import { ReactComponent as SearchIcon } from '../../../../../assets/icon/search-icon.svg';
import { css, CSSObject, cx } from '../../../../emotion';
import { MutableRefObject, RefObject, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import useViewModel from './viewModel';
import Menu from './Menu';
import {
  DataModel,
  DataModelType,
} from '../../../../dataModel/model/DataModel';
import { NumberFormat } from '../../../../dataModel/columnsAPI';
import { Option } from '../../../../dataModel/model/CategoryDataModel';
import { useTranslation } from 'react-i18next';
import ContextMenuController from '../../ContextMenu/controller/ContextMenuController';
import { ReviewEntriesThemeAPI } from '../../../../../theme/themeAPI';
import { ConfirmModalProps } from '../../confirmModal';
import DataModelRegistry from '../../DataModelRegistry';
import Popover from '../../../../../components/Popover';
import Message from '../../../../../components/Popover/Message';
import { useTheme } from '../../../../../theme/theme';
import { validateBackgroundImageInCss } from '../../../../style';

export type ISearchParams = {
  value: string;
  isExact: boolean;
  columns: IColumnInformation[];
  isReplaceAll?: boolean;
  wordToReplace?: string;
};

export type IColumnInformation = {
  key: string;
  type: DataModelType;
  isNumeric: boolean;
  numberFormat?: NumberFormat;
  options?: Option[];
  isMultiSelection?: boolean;
  disabled?: boolean;
};

type SearchAndReplaceProps = {
  className: string;
  dataModels: DataModel[];
  onFindSearchMatch: (
    searchParams: ISearchParams,
    switchFocus?: boolean
  ) => void;
  onGetAllSearchMatchCount: (searchParams: ISearchParams) => Promise<
    | {
        counter: number;
        hidden: number;
        skip: number;
        matchedCategoryType: boolean;
        matchedDisabledColumn: boolean;
      }
    | undefined
  >;
  onReplaceWordSearchMatch: (searchParams: ISearchParams) => Promise<void>;
  contextMenuController: ContextMenuController;
  configTheme?: ReviewEntriesThemeAPI;
  readOnly?: boolean;
  lastSelectedBySearchCell: MutableRefObject<{
    row: number;
    col: number;
  } | null>;
  showConfirmModal: (props: ConfirmModalProps) => void;
  hotInstance: RefObject<HotTableClass>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSet: Record<string, any>[];
  dataModelRegistry: DataModelRegistry;
  mediaSize: boolean;
};

const SearchAndReplace = ({
  className,
  dataModels,
  onFindSearchMatch,
  onGetAllSearchMatchCount,
  onReplaceWordSearchMatch,
  contextMenuController,
  configTheme,
  readOnly,
  lastSelectedBySearchCell,
  showConfirmModal,
  hotInstance,
  dataSet,
  dataModelRegistry,
  mediaSize,
}: SearchAndReplaceProps) => {
  const { popper, isOpenMenu, setIsOpenMenu, setWaitingConfirmReplace } =
    useViewModel({
      contextMenuController,
    });
  const { t } = useTranslation();
  const [isShowInfo, setIsShowInfo] = useState(false);
  const theme = useTheme();

  const getOnHover = () => {
    if (!isMobile && !isTablet) {
      return {
        onMouseEnter: () => setIsShowInfo(true),
        onMouseLeave: () => setIsShowInfo(false),
      };
    } else {
      return {
        onClick: () => setIsShowInfo((isShow) => !isShow),
        onMouseLeave: () => setIsShowInfo(false),
      };
    }
  };

  return (
    <div className={cx(className, 'relative')}>
      <span ref={popper.referenceElement as RefObject<HTMLSpanElement>}>
        <Popover
          isShow={isShowInfo && mediaSize}
          message={
            <Message className="max-w-355 h-full w-full" variant="info">
              {readOnly ? t('txt_find') : t('txt_find_and_replace')}
            </Message>
          }
          flip
          direction="top"
          offset={[0, 17]}
          render={({ referenceElement }) => {
            return (
              <span ref={referenceElement} {...getOnHover()}>
                <Button
                  className={cx(
                    css({
                      '&&': configTheme?.smartTable?.findAndReplaceButton,
                      '@media (min-width: 901px) and (max-width: 970px)': {
                        padding: '8px 16px !important',
                      },
                    }),
                    css({
                      '&&': {
                        paddingLeft: mediaSize ? 11 : 16,
                        paddingRight: mediaSize ? 11 : 16,
                        height: 40,
                      },
                    })
                  )}
                  variant="findReplace"
                  onClick={() => {
                    setIsOpenMenu(!isOpenMenu);
                  }}
                >
                  <span className="flex items-center">
                    {validateBackgroundImageInCss(
                      theme?.getButtonTheme().findReplace?.icon as CSSObject
                    ) ? (
                      <svg
                        className={cx(
                          'mb-px h-4 w-4 bg-no-repeat',
                          mediaSize ? '' : 'mr-2',
                          css({
                            '&&': theme.getButtonTheme().findReplace?.icon,
                          })
                        )}
                      />
                    ) : (
                      <SearchIcon
                        className={cx(
                          mediaSize ? '' : 'mr-2',
                          css({
                            '&&': theme.getButtonTheme().findReplace?.icon,
                          })
                        )}
                      />
                    )}{' '}
                    {mediaSize
                      ? ''
                      : readOnly
                      ? t('txt_find')
                      : t('txt_find_and_replace')}
                  </span>
                </Button>
              </span>
            );
          }}
        />
      </span>
      <Menu
        isOpenMenu={isOpenMenu}
        setIsOpenMenu={setIsOpenMenu}
        popper={popper}
        dataModels={dataModels}
        onFindSearchMatch={onFindSearchMatch}
        onGetAllSearchMatchCount={onGetAllSearchMatchCount}
        onReplaceWordSearchMatch={onReplaceWordSearchMatch}
        configTheme={configTheme}
        readOnly={readOnly}
        lastSelectedBySearchCell={lastSelectedBySearchCell}
        showConfirmModal={showConfirmModal}
        setWaitingConfirmReplace={setWaitingConfirmReplace}
        hotInstance={hotInstance}
        dataSet={dataSet}
        dataModelRegistry={dataModelRegistry}
      />
    </div>
  );
};

export default SearchAndReplace;
