import { Fragment, RefObject, useMemo, useRef } from 'react';
import { CSSInterpolation, css } from '@emotion/css';
import { cx, useTheme } from '@nuvo-importer/common';
import SimpleBar from 'simplebar-react';
import TruncatePopover from 'baseUI/TruncatePopover';
import SearchInput from 'baseUI/SearchInput';
import Container from './Container';
import { IPopper } from '../popper';
import SheetName from '../SheetName';
import SearchHeader from './SearchHeader';
import { IOption } from '../viewModel';
import { UseSelectPropGetters } from 'downshift';
import {
  ColumnDropdownThemeAPI,
  JoinSheetColumn,
  SHEET_COLUMN_TYPE,
} from '@nuvo-importer/common/sdk';
import JoinSheetNamePopover from 'baseUI/JoinSheetNamePopover';

type MenuItemsProps = {
  searchValue?: string;
  options: IOption[];
  searchOptions: IOption[];
  highlightedIndex: number;
  selectedItem: IOption | null;
  getItemProps: UseSelectPropGetters<IOption>['getItemProps'];
  onSearchValue: (value: string) => void;
  isSearching: boolean;
  isShowSearch: boolean;
  menuItemsClassName?: string;
  searchRef: RefObject<HTMLInputElement>;
  popper: IPopper;
  menuTitle: string;
  isShowSheetName: boolean;
  joinPopoverTheme?: CSSInterpolation;
  customTheme?: ColumnDropdownThemeAPI;
  configThemePopoverInfo?: {
    root?: string;
    arrowIcon?: string;
  };
};

const MenuItems = ({
  searchValue,
  highlightedIndex,
  selectedItem,
  getItemProps,
  onSearchValue,
  isSearching,
  isShowSearch = false,
  searchOptions,
  options,
  menuItemsClassName,
  searchRef,
  popper,
  menuTitle,
  isShowSheetName,
  joinPopoverTheme,
  customTheme,
  configThemePopoverInfo,
}: MenuItemsProps) => {
  const scrollableRef = useRef<HTMLDivElement>();
  const theme = useTheme();
  const popperBorderColor = useMemo(() => {
    return theme.getGlobalTheme().getDark50Color();
  }, [theme]);
  return (
    <Container popper={popper}>
      <div
        className={cx(
          `rounded-medium dropdown-scroll border-1 last:rounded-b-medium bg-white`,
          css({
            borderColor: popperBorderColor,
          }),
          css({
            '&&': customTheme?.root,
          }),
          css({
            '&&& .simplebar-scrollbar:before': {
              backgroundColor: `${customTheme?.scrollbar?.navigatorColor}`,
            },
            '&&& .simplebar-track': {
              backgroundColor: `${customTheme?.scrollbar?.backgroundColor}`,
            },
          })
        )}
      >
        <SearchInput
          value={searchValue}
          onChange={(value) => onSearchValue(value)}
          ref={searchRef}
          theme={{
            icon: css({ '&&': customTheme?.search?.icon }),
            placeholder: css`
              &&&::placeholder {
                ${customTheme?.search?.placeholder}
              }
            `,
            root: css({ '&&': customTheme?.search?.root }),
          }}
          headerTheme={css({ '&&': customTheme?.header })}
        />
        <SimpleBar
          forceVisible={false}
          autoHide={true}
          className="max-h-210 4xl:max-h-72"
          scrollableNodeProps={{
            ref: scrollableRef,
          }}
        >
          {isShowSearch ? (
            <SearchHeader
              isSearching={isSearching}
              searchOptionsLength={searchOptions.length}
              optionsLength={options.length}
              menuTitle={menuTitle}
              configThemeHeader={css({ '&&': customTheme?.header })}
            />
          ) : null}
          {options.map((option, index) => {
            const isIncludedInSearchOptions = !searchOptions.some(
              (searchOption) => searchOption.value === option.value
            );

            if (isIncludedInSearchOptions) {
              return null;
            }

            return (
              <Fragment key={`${option.label}${index}`}>
                <div
                  className={cx(
                    'flex items-center justify-between',
                    highlightedIndex === index && 'bg-gray-50',
                    selectedItem?.value === option.value && 'bg-gray-50'
                  )}
                >
                  <TruncatePopover
                    boundary={scrollableRef.current}
                    key={option.label}
                    flip
                    render={({ textRef, getHover, referenceElement }) => (
                      <div
                        className={cx(
                          'text-gray-310 flex w-full cursor-pointer items-center justify-between px-4 py-2.5 text-sm',
                          selectedItem?.value === option.value
                            ? css({ '&&': customTheme?.selectedOption })
                            : css({ '&&': customTheme?.option })
                        )}
                        {...getItemProps({
                          ref: referenceElement,
                          item: option,
                          index,
                        })}
                      >
                        <span
                          ref={textRef}
                          className={cx(
                            'flex-nowrap truncate',
                            menuItemsClassName
                          )}
                          {...getHover()}
                        >
                          {option.label}
                        </span>
                        {isShowSheetName ? (
                          option.value.getType() === SHEET_COLUMN_TYPE.JOIN ? (
                            <JoinSheetNamePopover
                              sheetColumn={option.value as JoinSheetColumn}
                              boundary={scrollableRef.current}
                              joinPopoverTheme={joinPopoverTheme}
                              customTheme={customTheme?.joinBadge}
                              overflowCheckOffset={{
                                bottom: -25,
                                right: 300,
                              }}
                              padding={{
                                right: -100,
                              }}
                            />
                          ) : (
                            <SheetName
                              sheetColumn={option.value}
                              boundary={scrollableRef.current}
                              customTheme={customTheme?.badge}
                              configThemePopoverInfo={configThemePopoverInfo}
                            />
                          )
                        ) : null}
                      </div>
                    )}
                  />
                </div>
              </Fragment>
            );
          })}
        </SimpleBar>
      </div>
    </Container>
  );
};

export default MenuItems;
