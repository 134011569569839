import { useTranslation } from 'react-i18next';
import { ActionFooter, Header } from 'navigation';
import { SpreadDataSheet } from '../../spreadDataSheet';
import { css, cx } from 'core/emotion';
import { useTheme } from 'theme';
import { useMainView } from 'settings';
import useViewModel from './viewModel';
import { ContextualEngineLoading } from '../../contextualEngine/ContextualEngineLoading';

const SelectHeaderPage = () => {
  const { t } = useTranslation();
  const {
    onBackClick,
    sheet,
    onContinueClick,
    headerRowIndexRef,
    onHeaderRowIndexChange,
    currentProgress,
    allSheets,
    wrapperSheetNameRef,
    goToSheet,
    currentSheetSelected,
    textSubmitButton,
    isHideSheetName,
    isLoadingContextualEngine,
  } = useViewModel();
  const theme = useTheme();
  const { modal } = useMainView();
  const wrapperClassName = modal ? 'absolute' : 'sticky';
  const wrapperFooterClassName = !modal ? 'sticky bottom-0 pt-0' : '';
  const widthWrapperClassName = modal ? 'w-full' : 'px-0';
  const pageHeaderTheme = theme.getHeaderMatchTheme().pageHeader;

  return (
    <div className={cx('flex flex-col', modal ? 'h-full' : '')}>
      <Header
        title={t('txt_select_header')}
        description={t('txt_select_header_desc')}
        className="flex-shrink-0"
        headerTheme={pageHeaderTheme}
      />
      <div
        className={cx(
          'header-selection-page relative flex flex-col',
          modal ? 'mb-4 min-h-0 flex-shrink flex-grow' : 'mb-7',
          css`
            min-height: 320px;
          `
        )}
      >
        <SpreadDataSheet
          configThemeSheetName={theme.getHeaderMatchTheme().sheetName}
          wrapperStyled={theme.getHeaderMatchTheme().root}
          configTheme={{
            root: theme.getHeaderMatchTheme().root,
            content: theme.getHeaderMatchTheme().content,
            selectRowColor: theme.getHeaderMatchTheme().selectRowColor,
            hoverRowColor: theme.getHeaderMatchTheme().hoverRowColor,
            scrollbarNavigatorColor:
              theme.getHeaderMatchTheme().scrollbarNavigatorColor,
            scrollbarBackgroundColor:
              theme.getHeaderMatchTheme().scrollbarBackgroundColor,
            th: theme.getHeaderMatchTheme().th,
            td: theme.getHeaderMatchTheme().td,
          }}
          sheet={sheet}
          headerRowIndexRef={headerRowIndexRef}
          onHeaderRowIndexChange={onHeaderRowIndexChange}
          currentProgress={currentProgress}
          currentSheetSelected={currentSheetSelected}
          allSheets={allSheets}
          configThemeSheetNameSelected={
            theme.getHeaderMatchTheme().sheetNameSelected
          }
          configThemeProgressBar={theme.getHeaderMatchTheme().progressBar}
          wrapperSheetNameRef={wrapperSheetNameRef}
          goToSheet={goToSheet}
          isHideSheetName={isHideSheetName}
          isLoadingContextualEngine={isLoadingContextualEngine}
        />
      </div>
      <ActionFooter
        footerStyle="px-8"
        className={cx(
          'bottom-0 left-0 right-0 flex flex-col bg-white',
          wrapperClassName,
          widthWrapperClassName,
          isLoadingContextualEngine ? 'z-40' : 'z-20'
        )}
        wrapperClassName={wrapperFooterClassName}
        positiveButtonText={textSubmitButton}
        onPositiveClick={onContinueClick}
        onNegativeClick={onBackClick}
      />
      {isLoadingContextualEngine && <ContextualEngineLoading />}
    </div>
  );
};

export default SelectHeaderPage;
