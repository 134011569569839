import { SerializedStyles } from '@emotion/utils';
import {
  ButtonThemeVariantAPI,
  CleaningAssistantThemeAPI,
  ContextualEngineThemeAPI,
  FooterThemeAPI,
  HeaderThemeAPI,
  PopoverInfoThemeAPI,
  PopoverThemeAPI,
  ProgressBarThemeAPI,
  ReviewEntriesThemeAPI,
  ThemeAPI,
} from './themeAPI';
import { GlobalTheme } from './GlobalTheme';
import {
  ArrayCSSInterpolation,
  ComponentSelector,
  CSSObject,
} from '../core/emotion';
import chroma from 'chroma-js';
import PrimaryButtonTheme from './ButtonTheme/PrimaryButtonTheme';
import SecondaryButtonTheme from './ButtonTheme/SecondaryButtonTheme';
import TertiaryButtonTheme from './ButtonTheme/TertiaryButtonTheme';
import ImportButtonTheme from './ButtonTheme/ImportButtonTheme';

type CommonTheme = {
  root:
    | string
    | number
    | boolean
    | ComponentSelector
    | SerializedStyles
    | CSSObject
    | ArrayCSSInterpolation;
  content?:
    | string
    | number
    | boolean
    | ComponentSelector
    | SerializedStyles
    | CSSObject
    | ArrayCSSInterpolation;
  loadAnimationColor?: string;
};

class ThemeAPIMapper {
  private themeAPI: ThemeAPI;
  private reviewEntriesTheme!: ReviewEntriesThemeAPI;
  private footerTheme!: FooterThemeAPI;
  private headerTheme!: HeaderThemeAPI;
  private progressBarTheme!: ProgressBarThemeAPI;
  private buttonTheme!: ButtonThemeVariantAPI;
  private popoverInfoTheme: PopoverInfoThemeAPI;
  private popoverTheme!: PopoverThemeAPI;
  private globalTheme!: GlobalTheme;

  constructor(themeAPI: ThemeAPI) {
    this.themeAPI = themeAPI;
    this.initGlobalTheme();
    this.initReviewEntriesTheme();
    this.initFooterTheme();
    this.initHeaderTheme();
    this.initProgressBarTheme();
    this.initButtonTheme();
    this.initPopoverInfoTheme();
    this.initPopoverTheme();
  }

  getRootTheme = () => {
    return this.themeAPI.root ?? {};
  };

  getGlobalTheme = () => {
    return this.globalTheme;
  };

  getPopoverInfo = () => {
    return this.popoverInfoTheme;
  };

  getButtonTheme = () => {
    return this.buttonTheme;
  };

  getDialogTheme = () => {
    const rootDialog = this.themeAPI.dialog?.root ?? {};
    const overlayStyled = this.themeAPI.dialog?.overlay ?? {};
    const closeIconStyled = this.themeAPI.dialog?.closeIcon ?? {};
    const scrollbarStyled = this.themeAPI.dialog?.scrollbar ?? {};

    return {
      root: rootDialog,
      overlay: overlayStyled,
      closeIcon: closeIconStyled,
      scrollbar: scrollbarStyled,
    };
  };

  getDialogConfirmTheme = () => {
    const rootDialog = this.themeAPI.messagePopup?.root ?? {};
    const overlayStyled = this.themeAPI.messagePopup?.overlay ?? {};
    const titleStyled = this.themeAPI.messagePopup?.title ?? {};
    const descriptionStyled = this.themeAPI.messagePopup?.description ?? {};
    const iconStyled = this.themeAPI.messagePopup?.warnIcon ?? {};
    const closeIconStyled = this.themeAPI.messagePopup?.closeIcon ?? {};

    return {
      root: rootDialog,
      overlay: overlayStyled,
      title: titleStyled,
      description: descriptionStyled,
      icon: iconStyled,
      closeIcon: closeIconStyled,
    };
  };

  getLoaderTheme = (): CommonTheme => {
    const rootLoaderDialog = this.themeAPI.loader?.root ?? {};

    return {
      root: rootLoaderDialog,
      loadAnimationColor: this.themeAPI.loader?.loadAnimationColor ?? '',
      content: this.themeAPI.loader?.content ?? {},
    };
  };

  getHeaderMatchTheme = () => {
    const rootStyled = this.themeAPI.headerSelect?.root ?? {};
    const pageHeaderStyled = this.themeAPI.headerSelect?.pageHeader ?? {};
    const contentStyled = this.themeAPI.headerSelect?.content ?? {};
    const tableThStyled = this.themeAPI.headerSelect?.table?.th ?? {};
    const tableTdStyled = this.themeAPI.headerSelect?.table?.td ?? {};
    const tableSelectRowStyled =
      this.themeAPI.headerSelect?.table?.selectRowColor ?? '';
    const tableHoverRowStyled =
      this.themeAPI.headerSelect?.table?.hoverRowColor ?? '';
    const fullScreenStyled = this.themeAPI.headerSelect?.fullScreen ?? {};
    const scrollbarNavigatorColor =
      this.themeAPI.headerSelect?.table?.scrollbar?.navigatorColor ?? '';
    const scrollbarBackgroundColor =
      this.themeAPI.headerSelect?.table?.scrollbar?.backgroundColor ?? '';

    return {
      root: rootStyled,
      pageHeader: pageHeaderStyled,
      content: contentStyled,
      sheetName: this.themeAPI.headerSelect?.sheetName ?? {},
      th: tableThStyled,
      td: tableTdStyled,
      selectRowColor: tableSelectRowStyled,
      hoverRowColor: tableHoverRowStyled,
      fullScreen: fullScreenStyled,
      scrollbarNavigatorColor,
      scrollbarBackgroundColor,
      sheetNameSelected: this.themeAPI.headerSelect?.sheetNameSelected ?? {},
      progressBar: this.themeAPI.headerSelect?.progressBar ?? {},
    };
  };

  getJoinSheetTheme = () => {
    return this.themeAPI.joinSheet ?? {};
  };

  getDropzoneTheme = () => {
    return {
      root: this.themeAPI.dropzone?.root ?? {},
      icon: this.themeAPI.dropzone?.icon ?? {
        box: {},
      },
      content: this.themeAPI.dropzone?.content ?? {},
      container: this.themeAPI.dropzone?.container ?? {},
    };
  };

  getProgressBarTheme = () => {
    return this.progressBarTheme;
  };

  getFooterTheme = () => {
    return this.footerTheme;
  };

  getHeaderTheme = () => {
    return this.headerTheme;
  };

  getColumnMatchTheme = () => {
    return {
      root: this.themeAPI.columnMatch?.root ?? {},
      pageHeader: this.themeAPI.columnMatch?.pageHeader ?? {},
      icon: this.themeAPI.columnMatch?.icon ?? {},
      borderColor: this.themeAPI.columnMatch?.borderColor ?? {},
      matchingTitle: this.themeAPI.columnMatch?.matchingTitle ?? {},
      matchingPercent: this.themeAPI.columnMatch?.matchingPercent ?? {},
      columnMatchHeader: this.themeAPI.columnMatch?.columnMatchHeader ?? {},
      columnMatchValue: this.themeAPI.columnMatch?.columnMatchValue ?? {},
      notMatchingValue: this.themeAPI.columnMatch?.notMatchingValue ?? {},
      joinBadge: this.themeAPI.columnMatch?.joinBadge ?? {},
      dialogCreateCustomColumn:
        this.themeAPI.columnMatch?.dialogCreateCustomColumn ?? {},
      dialogCreateCustomOption:
        this.themeAPI.columnMatch?.dialogCreateCustomOption ?? {},
      columnPopover: this.themeAPI.columnMatch?.columnPopover ?? {},
      sheetInfo: this.themeAPI.columnMatch?.sheetInfo ?? {},
    };
  };

  getRequiredColumnsTheme = () => {
    const requiredColumns = this.themeAPI.columnMatch?.requiredColumns ?? {};

    return {
      title: requiredColumns.title,
      hasMatch: requiredColumns.hasMatch,
      hasMatchIcon: requiredColumns.hasMatchIcon,
      notMatch: requiredColumns.notMatch,
      notMatchIcon: requiredColumns.notMatchIcon,
      notMatchError: requiredColumns.notMatchError,
      notMatchErrorIcon: requiredColumns.notMatchErrorIcon,
      showMore: {
        root: requiredColumns.showMore?.root,
        text: requiredColumns.showMore?.text,
        icon: requiredColumns.showMore?.icon,
        badge: requiredColumns.showMore?.badge,
      },
    };
  };

  getReviewEntriesTheme = () => {
    return this.reviewEntriesTheme;
  };

  getSheetSelectTheme = () => {
    return {
      root: this.themeAPI.sheetSelect?.root ?? {},
      pageHeader: this.themeAPI.sheetSelect?.pageHeader ?? {},
      header: this.themeAPI.sheetSelect?.header ?? {},
      sheetName: this.themeAPI.sheetSelect?.sheetName ?? {},
      grid: this.themeAPI.sheetSelect?.grid ?? {},
      list: this.themeAPI.sheetSelect?.list ?? {},
      icon: this.themeAPI.sheetSelect?.icon ?? {},
      pagination: this.themeAPI.sheetSelect?.pagination,
      scrollbar: this.themeAPI.sheetSelect?.scrollbar,
      unSelectSheetName: this.themeAPI.sheetSelect?.unSelectSheetName,
      sheetList: this.themeAPI.sheetSelect?.sheetLists,
      addButton: this.themeAPI.sheetSelect?.addFileButton,
      sheetPreview: this.themeAPI.sheetSelect?.sheetPreview ?? {},
    };
  };

  getPopoverTheme = () => {
    return this.popoverTheme;
  };

  private initReviewEntriesTheme = () => {
    this.reviewEntriesTheme = this.themeAPI.reviewEntries ?? {};
  };

  private initFooterTheme = () => {
    this.footerTheme = {
      root: this.themeAPI.footer?.root ?? {},
    };
  };

  private initHeaderTheme = () => {
    this.headerTheme = {
      root: this.themeAPI.header?.root ?? {},
      title: this.themeAPI.header?.title ?? {},
      description: this.themeAPI.header?.description ?? {},
    };
  };

  private initProgressBarTheme = () => {
    this.progressBarTheme = {
      root: this.themeAPI.progressBar?.root ?? {},
      icon: this.themeAPI.progressBar?.icon ?? {},
      current: this.themeAPI.progressBar?.current ?? {},
      complete: this.themeAPI.progressBar?.complete ?? {},
      incomplete: this.themeAPI.progressBar?.incomplete ?? {},
    };
  };

  private initButtonTheme = () => {
    const primaryButton = new PrimaryButtonTheme(this.themeAPI).generate();
    const secondaryButton = new SecondaryButtonTheme(this.themeAPI).generate();
    const tertiaryButton = new TertiaryButtonTheme(this.themeAPI).generate();
    const importButton = this.themeAPI.buttons?.import
      ? new ImportButtonTheme(this.themeAPI).generate()
      : new PrimaryButtonTheme(this.themeAPI).generate();
    const findErrorButton = this.themeAPI?.buttons?.findError;
    const findReplaceButton = this.themeAPI?.buttons?.findReplace;
    const exportExcelButton = this.themeAPI?.buttons?.exportExcel;
    const cleanDataButton = this.themeAPI?.buttons?.cleanData;

    this.buttonTheme = {
      primary: primaryButton ?? undefined,
      secondary: tertiaryButton, // NOTE: this is intention. Our internal, we use secondary for outline button, but in theme props we called it tertiary
      tertiary: secondaryButton ?? undefined,
      import: importButton ?? undefined,
      findError: findErrorButton,
      findReplace: findReplaceButton,
      exportExcel: exportExcelButton,
      cleanData: cleanDataButton,
    };
  };

  private initPopoverInfoTheme = () => {
    this.popoverInfoTheme = this.themeAPI.popoverInfo ?? {};
  };

  private initPopoverTheme = () => {
    this.popoverTheme = {
      root: this.themeAPI.popover?.root ?? {},
      info: this.themeAPI.popover?.info ?? {},
      warning: this.themeAPI.popover?.warning ?? {},
      error: this.themeAPI.popover?.error ?? {},
    };
  };

  private initGlobalTheme = () => {
    const textColor = this.themeAPI.globals?.textColor;
    const primaryText = this.themeAPI.globals?.primaryTextColor;
    const secondaryText = this.themeAPI.globals?.secondaryTextColor;

    this.globalTheme = new GlobalTheme({
      fontFamily: this.themeAPI.globals?.fontFamily ?? 'Inter',
      primaryTextColor: primaryText
        ? primaryText
        : textColor
        ? textColor
        : '#162338',
      secondaryTextColor: secondaryText
        ? secondaryText
        : textColor
        ? textColor
        : '#8D8D8D',
      backgroundColor: this.themeAPI.globals?.backgroundColor,
      primaryColor: this.themeAPI.globals?.primaryColor,
      secondaryColor: this.themeAPI.globals?.secondaryColor,
      borderRadius: this.themeAPI.globals?.borderRadius,
    });
  };

  private getSecondaryButton = () => {
    if (this.themeAPI.buttons?.secondary) {
      return this.themeAPI.buttons?.secondary;
    }

    const secondaryGlobalColor = this.themeAPI.globals?.secondaryColor;

    if (secondaryGlobalColor) {
      const secondaryColorChroma = chroma(secondaryGlobalColor);
      const secondaryHoverColor = chroma
        .hsl(
          chroma(secondaryColorChroma).get('hsl.h') + 0.3325,
          chroma(secondaryColorChroma).get('hsl.s') - 0.0303,
          chroma(secondaryColorChroma).get('hsl.l') - 0.0647
        )
        .hex();

      return {
        backgroundColor: secondaryGlobalColor,
        borderColor: secondaryGlobalColor,
        ':hover': {
          backgroundColor: secondaryHoverColor,
          borderColor: secondaryHoverColor,
        },
      };
    }

    return;
  };

  getCleaningAssistantTheme = (): CleaningAssistantThemeAPI => {
    /* istanbul ignore next */
    return {
      root: this.themeAPI.cleaningAssistant?.root ?? {},
      toggle: {
        root:
          this.themeAPI.buttons?.cleanData ??
          this.themeAPI.cleaningAssistant?.toggle?.root ??
          {},
        sparkleA: this.themeAPI.cleaningAssistant?.toggle?.sparkleA ?? {},
        sparkleB: this.themeAPI.cleaningAssistant?.toggle?.sparkleB ?? {},
        icon:
          this.themeAPI.buttons?.cleanData?.icon ??
          this.themeAPI.cleaningAssistant?.toggle?.icon ??
          {},
        badge: this.themeAPI.cleaningAssistant?.toggle?.badge ?? {},
      },
      suggestionList: {
        root: this.themeAPI.cleaningAssistant?.suggestionList?.root ?? {},
        header: this.themeAPI.cleaningAssistant?.suggestionList?.header ?? {},
        headerCheckbox:
          this.themeAPI.cleaningAssistant?.suggestionList?.headerCheckbox ?? {},
        headerCheckboxLabel:
          this.themeAPI.cleaningAssistant?.suggestionList
            ?.headerCheckboxLabel ?? {},
      },
      suggestion: {
        root: this.themeAPI.cleaningAssistant?.suggestion?.root ?? {},
        newValue: this.themeAPI.cleaningAssistant?.suggestion?.newValue ?? {},
        oldValue: this.themeAPI.cleaningAssistant?.suggestion?.oldValue ?? {},
        oldValueEmpty:
          this.themeAPI.cleaningAssistant?.suggestion?.oldValueEmpty ?? {},
        checkbox: this.themeAPI.cleaningAssistant?.suggestion?.checkbox ?? {},
        details: this.themeAPI.cleaningAssistant?.suggestion?.details ?? {},
        actions: this.themeAPI.cleaningAssistant?.suggestion?.actions ?? {},
        findButton:
          this.themeAPI.cleaningAssistant?.suggestion?.findButton ?? {},
        dismissButton:
          this.themeAPI.cleaningAssistant?.suggestion?.dismissButton ?? {},
        applyButton:
          this.themeAPI.cleaningAssistant?.suggestion?.applyButton ?? {},
      },
      loadingState: {
        root: this.themeAPI.cleaningAssistant?.loadingState?.root ?? {},
        skeleton: this.themeAPI.cleaningAssistant?.loadingState?.skeleton ?? {},
      },
      emptyState: {
        root: this.themeAPI.cleaningAssistant?.emptyState?.root ?? {},
        icon: this.themeAPI.cleaningAssistant?.emptyState?.icon ?? {},
        title: this.themeAPI.cleaningAssistant?.emptyState?.title ?? {},
        text: this.themeAPI.cleaningAssistant?.emptyState?.text ?? {},
      },
      message: {
        root: this.themeAPI.cleaningAssistant?.message?.root ?? {},
        icon: this.themeAPI.cleaningAssistant?.message?.icon ?? {},
        text: this.themeAPI.cleaningAssistant?.message?.text ?? {},
      },
      dropdown: {
        root: this.themeAPI.cleaningAssistant?.dropdown?.root ?? {},
        messageContainer:
          this.themeAPI.cleaningAssistant?.dropdown?.messageContainer ?? {},
        header: this.themeAPI.cleaningAssistant?.dropdown?.header ?? {},
        headerBack: this.themeAPI.cleaningAssistant?.dropdown?.headerBack ?? {},
        headerText: this.themeAPI.cleaningAssistant?.dropdown?.headerText ?? {},
        headerTag: this.themeAPI.cleaningAssistant?.dropdown?.headerTag ?? {},
        headerActions:
          this.themeAPI.cleaningAssistant?.dropdown?.headerActions ?? {},
        headerActionRepeat:
          this.themeAPI.cleaningAssistant?.dropdown?.headerActionRepeat ?? {},
        headerActionRepeatSpinner:
          this.themeAPI.cleaningAssistant?.dropdown
            ?.headerActionRepeatSpinner ?? {},
        headerActionRepeatSpinnerInner:
          this.themeAPI.cleaningAssistant?.dropdown
            ?.headerActionRepeatSpinnerInner ?? {},
        headerActionClose:
          this.themeAPI.cleaningAssistant?.dropdown?.headerActionClose ?? {},
        body: this.themeAPI.cleaningAssistant?.dropdown?.body ?? {},
        footer: this.themeAPI.cleaningAssistant?.dropdown?.footer ?? {},
        footerSelected:
          this.themeAPI.cleaningAssistant?.dropdown?.footerSelected ?? {},
        footerActions:
          this.themeAPI.cleaningAssistant?.dropdown?.footerActions ?? {},
        footerDismiss:
          this.themeAPI.cleaningAssistant?.dropdown?.footerDismiss ?? {},
        footerApply:
          this.themeAPI.cleaningAssistant?.dropdown?.footerApply ?? {},
        footerApplySpinner:
          this.themeAPI.cleaningAssistant?.dropdown?.footerApplySpinner ?? {},
        footerApplySpinnerInner:
          this.themeAPI.cleaningAssistant?.dropdown?.footerApplySpinnerInner ??
          {},
      },
      cleaningGroupList: {
        root: this.themeAPI.cleaningAssistant?.cleaningGroupList?.root ?? {},
      },
      cleaningGroup: {
        root: this.themeAPI.cleaningAssistant?.cleaningGroup?.root ?? {},
        name: this.themeAPI.cleaningAssistant?.cleaningGroup?.name ?? {},
        divider: this.themeAPI.cleaningAssistant?.cleaningGroup?.divider ?? {},
        count: this.themeAPI.cleaningAssistant?.cleaningGroup?.count ?? {},
        icon: this.themeAPI.cleaningAssistant?.cleaningGroup?.icon ?? {},
      },
    };
  };

  getContextualEngineTheme = (): ContextualEngineThemeAPI => {
    return {
      loading: {
        root: this.themeAPI.contextualEngine?.loading?.root ?? {},
        icon: this.themeAPI.contextualEngine?.loading?.icon ?? {},
      },
      prompt: {
        root: this.themeAPI.contextualEngine?.prompt?.root ?? {},
        icon: this.themeAPI.contextualEngine?.prompt?.icon ?? {},
        cancelButton:
          this.themeAPI.contextualEngine?.prompt?.cancelButton ?? {},
        confirmButton:
          this.themeAPI.contextualEngine?.prompt?.confirmButton ?? {},
      },
    };
  };
}

export default ThemeAPIMapper;
