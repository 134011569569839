import { css, cx } from 'core/emotion';
import useViewModel from './viewModel';
import { FormValues } from '../viewModel';
import { useMainView } from 'settings';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { getScrollbarWidth } from 'core/scroll';
import {
  MatchFooter,
  DataModelFooterImperativeHandle,
} from '@nuvo-importer/common';
import { NUVO_ELEMENT_ID } from 'core/constants/element';

const FormFooter = ({
  onBackClick,
  isShowFieldRequired,
  values,
  isModalClose,
  onIsAvailableCollapseChange,
  onIsShowChange,
  onSubmitClick,
}: {
  onBackClick: () => void;
  isShowFieldRequired: boolean;
  values: FormValues;
  isModalClose: boolean;
  onIsAvailableCollapseChange: (isAvailableCollapse: boolean) => void;
  onIsShowChange: (isShow: boolean) => void;
  onSubmitClick?: (() => void) | undefined;
}) => {
  const { requiredDataModels, matchedDataModels } = useViewModel({ values });
  const { modal } = useMainView();
  const wrapperClassName =
    'match-form-footer absolute bottom-0 left-0 right-0 z-20 absolute';
  const wrapperFooterClassName = !modal ? 'sticky bottom-0' : '';

  const [rightPositionForScrollbar, setRightPositionForScrollbar] = useState(0);
  const matchFooterRef = useRef<DataModelFooterImperativeHandle>(null);

  useLayoutEffect(() => {
    const isAvailableCollapse =
      matchFooterRef.current?.getIsAvailableCollapse();

    if (isShowFieldRequired && !isModalClose && isAvailableCollapse) {
      matchFooterRef.current?.setIsShowBadge(true);
    }
    // eslint-disable-next-line
  }, [isShowFieldRequired, isModalClose]);

  useLayoutEffect(() => {
    const scrollBarWidth = getScrollbarWidth();
    if (modal) {
      return;
    }
    if (isModalClose) {
      setRightPositionForScrollbar(scrollBarWidth);
    } else {
      setRightPositionForScrollbar(0);
    }
  }, [modal, isModalClose]);

  const rootElementRef = useRef<HTMLDivElement | null>(null);
  const nuvoAppRootElementRef = useRef<HTMLDivElement | null | undefined>(null);
  useEffect(() => {
    nuvoAppRootElementRef.current = rootElementRef.current?.closest(
      `#${NUVO_ELEMENT_ID.APP_ROOT}`
    );
  }, []);

  return (
    <MatchFooter
      dataModels={requiredDataModels}
      isShowFieldRequired={isShowFieldRequired}
      matchedDataModels={matchedDataModels}
      onIsAvailableCollapseChange={onIsAvailableCollapseChange}
      buttonWrapperClassName={css`
        padding-right: ${rightPositionForScrollbar}px;
      `}
      className={cx(wrapperFooterClassName, 'pt-22.5')}
      onBackClick={onBackClick}
      wrapperClassName={wrapperClassName}
      onIsShowChange={onIsShowChange}
      ref={matchFooterRef}
      rootElement={nuvoAppRootElementRef.current}
      modal={modal}
      onSubmitClick={onSubmitClick}
    />
  );
};

export default FormFooter;
