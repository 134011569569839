import { isNumber } from 'lodash';
import { DataHandlerResultValues } from '../types';
import {
  DataHandlerReviewStep,
  ModifierAddColumn,
  ModifierRemoveColumn,
  ModifierRemoveRow,
  ModifierStack,
  MODIFIER_TYPE,
  MappingStepModifierAddColumn,
  ModifierAddRow,
  Modifier,
} from './DataHandlerAPI';

type AddColumnModifier = ModifierAddColumn | MappingStepModifierAddColumn;

export abstract class DataHandlerMapper<
  PARAMETERS = unknown,
  RESULT = DataHandlerResultValues,
  BASE_MODIFIER = DataHandlerReviewStep
> {
  private removeRowIndexes: number[] = [];
  protected dataModifier?: BASE_MODIFIER;
  protected modifierData: DataHandlerResultValues = [];
  protected modifierStack: ModifierStack<DataHandlerResultValues>[] = [];
  constructor(dataModifier?: BASE_MODIFIER) {
    this.dataModifier = dataModifier;
  }

  protected abstract mappedHandler: (parameters: PARAMETERS) => Promise<RESULT>;
  protected abstract executorAddColumn: AddColumnModifier;
  protected abstract executorRemoveColumn: ModifierRemoveColumn;
  protected abstract dataModifierAddColumn: AddColumnModifier;
  protected abstract dataModifierRemoveColumn: ModifierRemoveColumn;
  abstract getDataModifier: () => (data: PARAMETERS) => Promise<RESULT>;
  abstract cleanUp: () => void;

  protected dataModifierAddRow: ModifierAddRow = ({ data, index }) => {
    this.modifierStack.push({
      modifierType: MODIFIER_TYPE.ADD_ROW,
      rowIndex: index,
      rowData: data,
    });
  };

  protected dataModifierRemoveRow: ModifierRemoveRow = (index) => {
    this.modifierStack.push({
      modifierType: MODIFIER_TYPE.REMOVE_ROW,
      rowIndex: index,
    });
  };

  protected executorAddRow: ModifierAddRow = ({ data, index }) => {
    if (isNumber(index)) {
      this.modifierData.splice(index, 0, ...data);
    } else {
      this.modifierData.push(...data);
    }
  };

  protected executorRemoveRow: ModifierRemoveRow = (index) => {
    this.removeRowIndexes.push(index);
  };

  protected executeModifier = (
    modifier: ModifierStack<DataHandlerResultValues>
  ) => {
    switch (modifier.modifierType) {
      case MODIFIER_TYPE.ADD_ROW:
        return this.executorAddRow({
          data: modifier.rowData ?? [],
          index: modifier.rowIndex,
        });
      case MODIFIER_TYPE.ADD_COLUMN:
        return this.executorAddColumn({
          columnType: modifier.columnType ?? 'string',
          key: modifier.columnKey ?? '',
          label: modifier.columnLabel ?? '',
          validations: modifier.columnValidations,
          dropdownOptions: modifier.columnDropdownOptions,
          outputFormat: modifier.outputFormat,
          isMultiSelect: modifier.isMultiSelect,
          hidden: modifier.hidden,
          disabled: modifier.disabled,
        });
      case MODIFIER_TYPE.REMOVE_ROW:
        return this.executorRemoveRow(modifier.rowIndex ?? 0);
      case MODIFIER_TYPE.REMOVE_COLUMN:
        return this.executorRemoveColumn(modifier.columnKey ?? '');
    }
  };

  protected getModifier(): Modifier {
    return {
      addRow: this.dataModifierAddRow,
      removeRow: this.dataModifierRemoveRow,
      addColumn: this.dataModifierAddColumn,
      removeColumn: this.dataModifierRemoveColumn,
    };
  }

  protected removeRow() {
    const newData = [];
    for (let i = 0; i < this.modifierData.length; i++) {
      if (!this.removeRowIndexes.includes(i)) {
        newData.push(this.modifierData[i]);
      }
    }
    this.modifierData = newData;
  }

  cleanDataModifier = () => {
    this.dataModifier = undefined;
  };
}
