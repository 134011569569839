import { CSSProperties, Ref, RefObject, forwardRef } from 'react';
import { UseSelectPropGetters } from 'downshift';
import { ReactComponent as CheckedIcon } from '../../assets/icon/checked.svg';
import { DropdownOptionProps } from './type';
import { cx } from '../../core/emotion';
import SearchHeader from './SearchHeader';
import TruncatePopover from '../TruncatePopover';
import PopoverInfo from '../form/Label/PopoverInfo';

export type OptionItemData = {
  items: {
    option: DropdownOptionProps;
    index: number;
  }[];
  onSelect?: (value: string) => void;
  onMouseOver?: (index: number) => void;
  currentSelector: number;
  hoveredItemStyle?: string;
  isSelecting: (option: DropdownOptionProps) => boolean;
  hoveredAndSelectedItemStyle?: string;
  selectedItemStyle?: string;
  itemStyle?: string;
  checkedIconStyle: string;
  isShowHeader?: boolean;
  configThemeHeader?: string;
  isMultiSelect?: boolean;
  searchHeaderTextRef: RefObject<HTMLParagraphElement>;
  getItemProps?: UseSelectPropGetters<DropdownOptionProps>['getItemProps'];
  onMouseLeave?: () => void;
  isSearching: boolean;
  isShowSimilarity?: boolean;
};

type OptionItemProps = {
  index: number;
  style: CSSProperties;
  data: OptionItemData;
  className?: string;
};

const DropdownOptionItem = forwardRef<HTMLSpanElement, OptionItemProps>(
  ({ data, index: baseIndex, style, className }, ref) => {
    if (data.isShowHeader && baseIndex === 0) {
      return (
        <SearchHeader
          ref={ref as unknown as Ref<HTMLDivElement>}
          style={style}
          configThemeHeader={data.configThemeHeader}
          isSearching={data.isSearching}
          isMultiSelect={data.isMultiSelect}
          searchHeaderTextRef={data.searchHeaderTextRef}
        />
      );
    }

    const index = data.isShowHeader ? baseIndex - 1 : baseIndex;

    const optionItem = data.items[index];

    if (!optionItem) {
      return null;
    }

    const option = optionItem.option;
    const itemPropsIndex = optionItem.index;

    const isSelected = data.isSelecting(option);

    return (
      <span
        ref={ref}
        style={style}
        key={option.value}
        id={`${option.value}-${index}`}
        onClick={() => {
          data.onSelect?.(option.value);
        }}
        onMouseOver={() => {
          data.onMouseOver?.(index);
        }}
        onMouseLeave={data.onMouseLeave}
        className={cx(
          'dropdown-item text-blue-dark-900 options block cursor-pointer break-all px-4 py-2.5 text-left text-sm',
          data.currentSelector === index && !isSelected
            ? data.hoveredItemStyle
            : '',
          isSelected && data.currentSelector === index
            ? data.hoveredAndSelectedItemStyle
            : '',
          isSelected ? data.selectedItemStyle : data.itemStyle,
          className
        )}
        {...data.getItemProps?.({ item: option, index: itemPropsIndex, ref })}
      >
        <TruncatePopover
          pageWrapperElement={document.body}
          render={({ referenceElement, textRef, getHover }) => {
            return (
              <div
                className="dropdown-sub-item flex items-center justify-between"
                ref={referenceElement as RefObject<HTMLDivElement>}
                {...getHover()}
              >
                <div className="flex items-center justify-center">
                  {optionItem?.option?.description ? (
                    <span className="pr-4">
                      <PopoverInfo
                        flip={true}
                        description={optionItem?.option?.description}
                      />
                    </span>
                  ) : null}
                  <span
                    className="dropdown-sub-item-label truncate"
                    ref={textRef}
                  >
                    {option.label}{' '}
                  </span>
                </div>
                {data.isShowSimilarity ? (
                  <span className="mr-auto pl-1">
                    ({parseFloat(option.similarity?.toFixed(3) ?? '0')})
                  </span>
                ) : null}
                <span
                  className={cx('dropdown-sub-item', data.checkedIconStyle)}
                >
                  {isSelected ? (
                    <CheckedIcon className="dropdown-sub-item ml-px" />
                  ) : null}
                </span>
              </div>
            );
          }}
        />
      </span>
    );
  }
);

export default DropdownOptionItem;
