/* istanbul ignore file */

import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useState,
  useCallback,
  useEffect,
} from 'react';
import ConfirmModal, { ConfirmModalProps } from './index';

type ConfirmModalManagerProps = {
  children?: ReactNode;
};

type ContextType = {
  isOpen: boolean;
  setOpenModal: (isOpen: boolean) => void;
  showConfirmModal: (options: ConfirmModalProps) => void;
  type?: 'warn' | 'confirm';
  title?: ReactNode;
};

const ModalConfirmManagerContext = createContext({} as ContextType);

const ConfirmModalManager: FC<ConfirmModalManagerProps> = ({ children }) => {
  const [isOpen, setOpenModal] = useState(false);
  const [options, setOptions] = useState<ConfirmModalProps>();

  const showConfirmModal = useCallback((options: ConfirmModalProps) => {
    setOptions(options);
    setOpenModal(true);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setOptions(undefined);
    }
  }, [isOpen]);

  return (
    <ModalConfirmManagerContext.Provider
      value={{
        isOpen,
        setOpenModal,
        showConfirmModal,
        type: options?.type,
        title: options?.title,
      }}
    >
      {children}
      {options && (
        <ConfirmModal
          title={options.title}
          description={options.description}
          textPositiveButton={options.textPositiveButton}
          textNegativeButton={options.textNegativeButton}
          onClickPositiveButton={options.onClickPositiveButton}
          onClickNegativeButton={options.onClickNegativeButton}
          onClickNegativeButtonPromise={options.onClickNegativeButtonPromise}
          isShowPositiveButton={options.isShowPositiveButton}
          isShowIcon={options.isShowIcon}
          disabledClickOutside={options.disabledClickOutside}
          isShowCloseIcon={options.isShowCloseIcon}
          type={options.type}
          disabledPositiveButton={options.disabledPositiveButton}
          isPopper={options.isPopper}
          textPopper={options.textPopper}
          content={options.content}
          descriptionClassName={options.descriptionClassName}
          footerClassName={options.footerClassName}
          negativeButtonClassName={options.negativeButtonClassName}
          positiveButtonClassName={options.positiveButtonClassName}
          positiveButtonWrapperClassName={
            options.positiveButtonWrapperClassName
          }
          roundedIcon={options.roundedIcon}
          baseModalClassName={options.baseModalClassName}
          customIcon={options.customIcon}
          titleClassName={options.titleClassName}
        />
      )}
    </ModalConfirmManagerContext.Provider>
  );
};

export const useContextConfirmModalManager = () => {
  return useContext(ModalConfirmManagerContext);
};

export default ConfirmModalManager;
