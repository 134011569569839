import { useTranslation } from 'react-i18next';
import { HotTableClass } from '@handsontable/react';
import { MutableRefObject, RefObject, useMemo } from 'react';
import useViewModel from './viewModel';
import InfoBox from '../../../../components/InfoBox';
import { css, CSSObject, cx } from '../../../emotion';
import { useTheme } from '../../../../theme/theme';
import Button from '../../../../components/Button';
import FullScreenButton from '../../../../components/FullScreenButton';
import ToggleViewButton from './ToggleViewButton';
import SelectActions from './SelectActions';
import CheckboxController from '../checkbox/CheckboxController';
import { ConfirmModalProps } from '../confirmModal';
import SearchAndReplace, { ISearchParams } from './SearchAndReplace';
import ContextMenuController from '../ContextMenu/controller/ContextMenuController';
import { ReactComponent as ClearFilterIcon } from '../../../../assets/icon/filter-icon.svg';
import AllColumnSetting from '../columns/AllColumnSetting';
import ModeViewTable from '../ModeViewTable/ModeViewTable';
import DataModelRegistry from '../DataModelRegistry';
import UndoRedo from './UndoRedo';
import { Subject } from 'rxjs';
import { EditRowChange, UndoRedoEvent } from '../type';
import ExportButton from './ExportButton';
import { CleaningAssistant } from './CleaningAssistant';
import { Validator } from '../../../reviewEntries/validator';
import { ColumnAPI } from '../../../dataModel/columnsAPI';
import {
  CleaningLogsRecord,
  ICleaningAssistantOnRemoveRow,
} from './CleaningAssistant/api/CleaningAssistant.dto';
import { validateBackgroundImageInCss } from '../../../style';

type TopActionProps = {
  changeModeView: (showOnlyError: boolean) => void;
  hideShowErrorDropdown: boolean;
  exportValuesToXlsx: () => void;
  validating: boolean;
  errorCount: MutableRefObject<number>;
  onSearchError: () => void;
  hasValidated: boolean;
  exporting: boolean;
  disableExportHasMoreDataRef: MutableRefObject<boolean>;
  toggleFullScreen: () => void;
  isFullScreen?: boolean;
  sendLog: (event: string) => Promise<void>;
  isBypass: boolean;
  setBypass: (value: boolean) => void;
  hidedFullScreenButton?: boolean;
  hidedExportButton?: boolean;
  modal?: boolean;
  checkboxController?: CheckboxController;
  onRemove: (rows: number[]) => void;
  onDuplicate: (rows: number[]) => void;
  showConfirmModal: (props: ConfirmModalProps) => void;
  cleaningAssistant: boolean;
  smartTable: boolean;
  onFindSearchMatch: (
    searchParams: ISearchParams,
    switchFocus?: boolean
  ) => void;
  onGetAllSearchMatchCount: (searchParams: ISearchParams) => Promise<
    | {
        counter: number;
        hidden: number;
        skip: number;
        matchedCategoryType: boolean;
        matchedDisabledColumn: boolean;
      }
    | undefined
  >;
  onReplaceWordSearchMatch: (searchParams: ISearchParams) => Promise<void>;
  dataModelRegistry: DataModelRegistry;
  contextMenuController: ContextMenuController;
  onClearAllFilter: () => void;
  allColumnSetting: AllColumnSetting;
  readOnly?: boolean;
  lastSelectedBySearchCell: MutableRefObject<{
    row: number;
    col: number;
  } | null>;
  modeViewTable: ModeViewTable;
  hotInstance: RefObject<HotTableClass>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSet: Record<string, any>[];
  undoRedoObservable: Subject<UndoRedoEvent>;
  mediaSize: boolean;
  validator: Validator;
  baseColumns: ColumnAPI[];
  cleaningAssistantLogsRef: RefObject<CleaningLogsRecord[] | null>;
  cleaningAssistantEntryChangeObserverRef: RefObject<Subject<EditRowChange>>;
  cleaningAssistantRemoveRowObserverRef: RefObject<
    Subject<ICleaningAssistantOnRemoveRow>
  >;
  setTotalCleanings: (total_cleanings: number) => void;
  licenseKey: string;
  origin: string;
  sessionId: string;
};

const buttonStyle = css`
  :disabled {
    background-color: #dfe2e4;
    color: #88959c;
  }

  @media (min-width: 901px) and (max-width: 970px) {
    padding: 6px 16px !important;
  }
`;

const TopAction = ({
  changeModeView,
  exportValuesToXlsx,
  validating,
  errorCount,
  onSearchError,
  hasValidated,
  exporting = false,
  disableExportHasMoreDataRef,
  toggleFullScreen,
  isFullScreen = false,
  sendLog,
  isBypass,
  setBypass,
  hidedFullScreenButton = false,
  hidedExportButton = false,
  modal,
  checkboxController,
  onRemove,
  onDuplicate,
  showConfirmModal,
  onFindSearchMatch,
  onGetAllSearchMatchCount,
  onReplaceWordSearchMatch,
  smartTable,
  dataModelRegistry,
  contextMenuController,
  onClearAllFilter,
  allColumnSetting,
  readOnly,
  lastSelectedBySearchCell,
  modeViewTable,
  hotInstance,
  dataSet,
  undoRedoObservable,
  mediaSize,
  cleaningAssistant,
  cleaningAssistantLogsRef,
  cleaningAssistantEntryChangeObserverRef,
  cleaningAssistantRemoveRowObserverRef,
  validator,
  baseColumns,
  licenseKey,
  origin,
  setTotalCleanings,
  sessionId,
}: TopActionProps) => {
  const {
    isErrorCount,
    errorCountNumber,
    isShowOnlyError,
    disableExportHasMoreData,
    onToggleViewChange,
    selectedCount,
    onDeleteClick,
    onDuplicateClick,
    isLoadingDuplicate,
    filterCount,
    dataModels,
  } = useViewModel({
    errorCount,
    disableExportHasMoreDataRef,
    changeModeView,
    checkboxController,
    onRemove,
    onDuplicate,
    showConfirmModal,
    allColumnSetting,
    modeViewTable,
    dataModelRegistry,
    cleaningAssistantRemoveRowObserverRef,
    hotInstance,
  });
  const { t } = useTranslation();
  const configTheme = useTheme();

  const reviewTheme = useMemo(
    () => configTheme.getReviewEntriesTheme(),
    [configTheme]
  );

  return (
    <div>
      {isBypass ? (
        <InfoBox
          customStyle={cx(
            'pb-4',
            css`
              max-width: 60%;
              min-width: 650px;
            `
          )}
          title={t('txt_title_info_box_automatic_mapping')}
          description={t('txt_description_info_box_automatic_mapping')}
          onClickClose={() => {
            setBypass(false);
            window.dispatchEvent(new Event('resize'));
          }}
        />
      ) : null}
      <div className="mb-4 flex items-end justify-between">
        <div className="flex items-center space-x-2">
          <Button
            type="button"
            disabled={isErrorCount}
            className={buttonStyle}
            onClick={onSearchError}
            variant="findError"
          >
            {validateBackgroundImageInCss(
              configTheme?.getButtonTheme().findError?.icon as CSSObject
            ) && (
              <svg
                className={cx(
                  'mb-px h-4 w-4 bg-no-repeat',
                  mediaSize ? '' : 'mr-2',
                  css`
                    ${configTheme.getButtonTheme().findError?.icon}
                  `
                )}
              />
            )}
            {t('txt_find_error')}
          </Button>
          {hasValidated ? (
            selectedCount > 0 ? (
              <SelectActions
                selectedCount={selectedCount}
                onDeleteClick={onDeleteClick}
                onDuplicateClick={onDuplicateClick}
                isLoadingDuplicate={isLoadingDuplicate}
                customTheme={reviewTheme}
              />
            ) : (
              <div className="flex">
                <ToggleViewButton
                  errorCountNumber={errorCountNumber}
                  isShowOnlyError={isShowOnlyError}
                  onToggleViewChange={onToggleViewChange}
                  customTheme={reviewTheme.toggleViewButton}
                />
                {filterCount > 0 ? (
                  <Button
                    className={cx(
                      'ml-2 !border-0 !px-4',
                      css({ '&&': reviewTheme?.clearFilterButton })
                    )}
                    variant="secondary"
                    onClick={onClearAllFilter}
                  >
                    <span className="flex items-center">
                      <ClearFilterIcon
                        className={cx(
                          (reviewTheme?.clearFilterButton as CSSObject)?.color
                            ? ''
                            : 'text-neutral-gray'
                        )}
                      />{' '}
                      <span className="ml-2 inline-block">
                        {t('txt_filter_clear', { count: filterCount })}
                      </span>
                    </span>
                  </Button>
                ) : null}
              </div>
            )
          ) : null}
        </div>
        <div className="flex items-center">
          <UndoRedo
            hotInstance={hotInstance}
            undoRedoObservable={undoRedoObservable}
          />
          {!hidedFullScreenButton && (
            <FullScreenButton
              className="relative top-px mr-4"
              theme={reviewTheme.fullScreen}
              toggleFullScreen={toggleFullScreen}
              isFullScreen={isFullScreen}
              modal={modal}
            />
          )}
          {cleaningAssistant && (
            <CleaningAssistant
              hotInstance={hotInstance}
              validator={validator}
              dataModelRegistry={dataModelRegistry}
              baseColumns={baseColumns}
              cleaningAssistantLogsRef={cleaningAssistantLogsRef}
              cleaningAssistantEntryChangeObserverRef={
                cleaningAssistantEntryChangeObserverRef
              }
              cleaningAssistantRemoveRowObserverRef={
                cleaningAssistantRemoveRowObserverRef
              }
              licenseKey={licenseKey}
              origin={origin}
              setTotalCleanings={setTotalCleanings}
              sessionId={sessionId}
            />
          )}
          {smartTable ? (
            <SearchAndReplace
              className="mr-2"
              dataModels={dataModels}
              onFindSearchMatch={onFindSearchMatch}
              onGetAllSearchMatchCount={onGetAllSearchMatchCount}
              onReplaceWordSearchMatch={onReplaceWordSearchMatch}
              contextMenuController={contextMenuController}
              configTheme={reviewTheme}
              readOnly={readOnly}
              lastSelectedBySearchCell={lastSelectedBySearchCell}
              showConfirmModal={showConfirmModal}
              hotInstance={hotInstance}
              dataSet={dataSet}
              dataModelRegistry={dataModelRegistry}
              mediaSize={mediaSize}
            />
          ) : null}
          {!hidedExportButton && (
            <ExportButton
              disableExportHasMoreData={disableExportHasMoreData}
              exportValuesToXlsx={exportValuesToXlsx}
              sendLog={sendLog}
              exporting={exporting}
              isExportDisabled={validating}
              mediaSize={mediaSize}
              cleaningAssistant={cleaningAssistant}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopAction;
