/* istanbul ignore file */

import { DataModel } from '../../core/dataModel/model/DataModel';
import { Option } from '../../core/dataModel/model/CategoryDataModel';
import Sheet, { Value } from '../sheet/Sheet';
import SheetColumn from './../sheet/SheetColumn';
import SheetColumnDataModelSimilarityList from './SheetColumnDataModelSimilarityList';
import SheetColumnDataModelOptionSimilarityList from './SheetColumnDataModelOptionSimilarityList';
import { DataModelJSON } from '../dataModel/serializer/type';

export type MatchedOption = {
  dataModelOption?: Option['value'];
  dataModelOptions?: Option['value'][];
  sheetOption: Value;
};

export type MatchedDataModel = {
  dataModel?: DataModel;
  matchedOptions?: MatchedOption[];
};

export type MatchedDataModelJSON = {
  dataModel?: DataModelJSON;
  matchedOptions?: MatchedOption[];
};

export type DataModelSheetMatch = {
  sheetColumn: SheetColumn;
  matchedDataModel?: MatchedDataModel;
};

class DataModelSheetMatching {
  private dataModelSheetMatch: DataModelSheetMatch[] = [];
  private dataModels: DataModel[] = [];
  private sheets: Sheet[] = [];
  private sheetColumnDataModelSimilarityList: SheetColumnDataModelSimilarityList;
  private sheetColumnDataModelOptionSimilarityList: SheetColumnDataModelOptionSimilarityList;

  constructor({
    dataModels,
    dataModelSheetMatch,
    sheets,
    sheetColumnDataModelSimilarityList,
    sheetColumnDataModelOptionSimilarityList,
  }: {
    dataModels: DataModel[];
    sheets: Sheet[];
    dataModelSheetMatch: DataModelSheetMatch[];
    sheetColumnDataModelSimilarityList: SheetColumnDataModelSimilarityList;
    sheetColumnDataModelOptionSimilarityList: SheetColumnDataModelOptionSimilarityList;
  }) {
    this.dataModels = dataModels;
    this.sheets = sheets;
    this.dataModelSheetMatch = dataModelSheetMatch;
    this.sheetColumnDataModelSimilarityList =
      sheetColumnDataModelSimilarityList;
    this.sheetColumnDataModelOptionSimilarityList =
      sheetColumnDataModelOptionSimilarityList;
  }

  getMatching = () => {
    return this.dataModelSheetMatch;
  };

  getMatchingBySheetColumn = (sheetColumn: SheetColumn) => {
    return this.dataModelSheetMatch.find(
      (sheetMatchDataModel) => sheetMatchDataModel.sheetColumn === sheetColumn
    );
  };

  getMatchingByDataModel = (dataModel: DataModel) => {
    return this.dataModelSheetMatch.find(
      (sheetMatchDataModel) =>
        sheetMatchDataModel.matchedDataModel?.dataModel?.getKey() ===
        dataModel.getKey()
    );
  };

  getMatchingByDataModelKey = (key: string) => {
    return this.dataModelSheetMatch.find(
      (sheetMatchDataModel) =>
        sheetMatchDataModel.matchedDataModel?.dataModel?.getKey() === key
    );
  };

  getDataModels = () => {
    return this.dataModels;
  };

  getSheets = () => {
    return this.sheets;
  };

  getSheetColumnDataModelSimilarityList = () => {
    return this.sheetColumnDataModelSimilarityList;
  };

  getSheetColumnDataModelOptionSimilarityList = () => {
    return this.sheetColumnDataModelOptionSimilarityList;
  };

  setDataModelSheetMatch = (dataModelSheetMatch: DataModelSheetMatch[]) => {
    this.dataModelSheetMatch = dataModelSheetMatch;
  };

  getMatchedDataModelKeys = () => {
    const dataModelMatchKeys: string[] = [];
    for (let i = 0; i < this.dataModelSheetMatch.length; i++) {
      const key =
        this.dataModelSheetMatch[i]?.matchedDataModel?.dataModel?.getKey();
      if (key && !dataModelMatchKeys.includes(key)) {
        dataModelMatchKeys.push(key);
      }
    }
    return dataModelMatchKeys;
  };
}

export default DataModelSheetMatching;
