import { HeaderSelection } from '@nuvo-importer/common';
import Sheet from './../../sheetImporter/Sheet';
import useViewModel from './viewModel';
import { CSSInterpolation, cx } from 'core/emotion';
import { useMainView } from 'settings';
import { MutableRefObject, RefObject, useMemo } from 'react';
import { maxHeightOfReviewEntries } from 'core/constants/style';
import TopAction from './TopAction';

type SpreadDataSheetProps = {
  sheet: Sheet;
  headerRowIndexRef: MutableRefObject<number>;
  onHeaderRowIndexChange: (rowIndex: number) => void;
  wrapperStyled?: CSSInterpolation;
  configTheme?: {
    root: CSSInterpolation;
    content: CSSInterpolation;
    selectRowColor: string;
    hoverRowColor: string;
    scrollbarNavigatorColor: string;
    scrollbarBackgroundColor: string;
    th: CSSInterpolation;
    td: CSSInterpolation;
  };
  configThemeSheetName?: {
    root?: CSSInterpolation;
    title?: CSSInterpolation;
    description?: CSSInterpolation;
    border?: CSSInterpolation;
  };
  currentProgress: number;
  currentSheetSelected: number;
  allSheets: Sheet[];
  configThemeSheetNameSelected?: {
    root?: CSSInterpolation;
    title?: CSSInterpolation;
    description?: CSSInterpolation;
    border?: CSSInterpolation;
  };
  configThemeProgressBar: {
    root?: CSSInterpolation;
    progress?: {
      navigatorColor?: string;
      backgroundColor?: string;
    };
  };
  wrapperSheetNameRef: RefObject<HTMLDivElement>;
  goToSheet: (sheet: Sheet) => void;
  isHideSheetName?: boolean;
  isLoadingContextualEngine: boolean;
};

const SpreadDataSheet = ({
  sheet,
  headerRowIndexRef,
  onHeaderRowIndexChange,
  configTheme,
  configThemeSheetName,
  currentProgress,
  currentSheetSelected,
  allSheets,
  configThemeSheetNameSelected,
  configThemeProgressBar,
  wrapperSheetNameRef,
  goToSheet,
  isHideSheetName = false,
  isLoadingContextualEngine,
}: SpreadDataSheetProps) => {
  const { values, isSxlargeScreen, mediaSize, htLicenseKey } = useViewModel({
    configTheme,
    sheet,
    headerRowIndexRef,
    onHeaderRowIndexChange,
  });
  const { modal } = useMainView();

  /* A memoized function that returns an object with the style of the table. */
  const styleTable = useMemo(() => {
    const baseHeightRow = mediaSize ? 23 : 33;

    const maxHeightPage = isSxlargeScreen ? '465px' : '465px';
    const minHeightPage = isSxlargeScreen ? 'calc(100vh - 368px)' : 'unset';

    const maxHeightModal = '100%';
    const minHeightModal = '100%';

    return {
      width: modal ? '100%' : 'calc(100% - 48px)',
      height: Math.min(
        (values.length + 1) * baseHeightRow + 2 + 6,
        maxHeightOfReviewEntries
      ),
      maxHeight: !modal ? maxHeightPage : maxHeightModal,
      minHeight: !modal ? minHeightPage : minHeightModal,
    };
  }, [values.length, isSxlargeScreen, modal, mediaSize]);

  return (
    <div
      className={cx(
        modal ? 'flex min-h-0 flex-shrink flex-grow flex-col' : '',
        isLoadingContextualEngine && !modal ? 'h-0 overflow-hidden' : ''
      )}
    >
      <TopAction
        allSheets={allSheets}
        wrapperSheetNameRef={wrapperSheetNameRef}
        configThemeSheetName={configThemeSheetName}
        configThemeSheetNameSelected={configThemeSheetNameSelected}
        configThemeProgressBar={configThemeProgressBar}
        currentSheetSelected={currentSheetSelected}
        currentProgress={currentProgress}
        goToSheet={goToSheet}
        isHideSheetName={isHideSheetName}
      />
      <HeaderSelection
        configTheme={configTheme}
        sheet={sheet}
        headerRowIndexRef={headerRowIndexRef}
        onHeaderRowIndexChange={onHeaderRowIndexChange}
        wrapperStyledDivClassName={modal ? 'mx-0' : 'mx-6'}
        wrapperStyledClassName={
          modal ? 'h-full min-h-0 flex-shrink flex-grow' : ''
        }
        styleTable={styleTable}
        htLicenseKey={htLicenseKey}
        readOnly={false}
      />
    </div>
  );
};

export default SpreadDataSheet;
