import {
  ColumnMapping,
  IJoinHistory,
  JoinSheet,
  JoinType,
  SHEET_TYPE,
  Sheet,
} from '@nuvo-importer/common/sdk';
import { ICombineSheetsStrategy } from './combineSheetsStrategy';
import { convertColumnMappingToIndex } from './utils';
import { IMatchingValuesWorker } from '../../reviewEntries/worker/type';

export const createJoinSheet = async (
  sourceSheet: Sheet,
  targetSheet: Sheet,
  mappings: ColumnMapping[],
  combineStrategy: ICombineSheetsStrategy
) => {
  const mappingIndexes = convertColumnMappingToIndex(
    mappings,
    sourceSheet,
    targetSheet
  );
  const result = await combineStrategy(
    sourceSheet.toJSON(),
    targetSheet.toJSON(),
    mappingIndexes
  );
  let joinSheet;
  if (sourceSheet.getType() === SHEET_TYPE.JOIN) {
    (sourceSheet as JoinSheet).joinSheet(
      result,
      targetSheet,
      mappings,
      mappingIndexes
    );
    joinSheet = sourceSheet;
  } else {
    joinSheet = new JoinSheet({
      joinedData: result,
      mappings,
      mappingIndexes,
      sheets: [sourceSheet, targetSheet],
    });
  }

  return joinSheet;
};

const getNumberOfRemoveColumn = (
  sourceSheetParam: JoinSheet,
  currentHistoryIndex: number,
  removeSheet: Sheet,
  updatedJoinHistory: (IJoinHistory | undefined)[],
  sourceIndex: number
) => {
  const sourceSheets = sourceSheetParam.getJoinedSheets();
  const joinHistory = sourceSheetParam.getJoinHistory();
  const sourceSheet = findOriginSourceSheet(
    sourceSheetParam,
    currentHistoryIndex,
    sourceIndex
  );
  if (!sourceSheet) {
    return 0;
  }
  const sourceSheetIndex = sourceSheets.findIndex((sheet) =>
    sheet.equal(sourceSheet)
  );
  const sourceJoinHistoryIndex =
    sourceSheetIndex === 0 ? 0 : sourceSheetIndex - 1;
  let numberOfRemoveColumn = removeSheet.equal(sourceSheets[0])
    ? sourceSheets[0].getColumns().length
    : 0;

  for (let i = 0; i < sourceJoinHistoryIndex; ++i) {
    if (updatedJoinHistory[i]) {
      continue;
    }
    numberOfRemoveColumn +=
      sourceSheets[i + 1].getColumns().length -
      joinHistory[i].mappingIndexes.length;
  }

  return numberOfRemoveColumn;
};

const findOriginSourceSheet = (
  sourceSheetParam: JoinSheet,
  joinHistoryIndex: number,
  sourceIndex: number
) => {
  const sourceSheets = sourceSheetParam.getJoinedSheets();
  const joinHistory = sourceSheetParam.getJoinHistory();
  if (sourceIndex < sourceSheets[0].getColumns().length) {
    return sourceSheets[0];
  }
  let numOfColumns = sourceSheets[0].getColumns().length;
  for (let i = 0; i < joinHistoryIndex; ++i) {
    numOfColumns +=
      sourceSheets[i + 1].getColumns().length -
      joinHistory[i].mappingIndexes.length;

    if (sourceIndex < numOfColumns) {
      return sourceSheets[i + 1];
    }
  }

  return null;
};

const updateSourceAfterRemove = (
  joinHistory: IJoinHistory,
  sourceSheetParam: JoinSheet,
  currentHistoryIndex: number,
  removeSheet: Sheet,
  updatedHistory: (IJoinHistory | undefined)[]
) => {
  return joinHistory.mappingIndexes.map((mapping) => {
    const numberOfRemoveColumn = getNumberOfRemoveColumn(
      sourceSheetParam,
      currentHistoryIndex,
      removeSheet,
      updatedHistory,
      mapping.source
    );
    return {
      source: mapping.source - numberOfRemoveColumn,
      target: mapping.target,
    };
  });
};

export const getJoinHistoryAfterRemove = (
  sourceSheetParam: JoinSheet,
  removeSheet: Sheet
) => {
  const joinHistory = sourceSheetParam.getJoinHistory();
  const sourceSheets = sourceSheetParam.getJoinedSheets();
  const removeIndex = sourceSheets.findIndex((sheet) =>
    sheet.equal(removeSheet)
  );
  const startRemoveHistory = removeIndex <= 1 ? 0 : removeIndex - 1;
  const updatedHistory: (IJoinHistory | undefined)[] = [];
  const updatedSheets: Sheet[] = [];
  for (let i = 0; i < joinHistory.length; ++i) {
    if (i < startRemoveHistory) {
      if (i === 0) {
        updatedSheets.push(sourceSheets[0]);
      }
      updatedHistory[i] = joinHistory[i];
      updatedSheets.push(sourceSheets[i + 1]);
      continue;
    }

    if (i === startRemoveHistory) {
      if (startRemoveHistory !== 0) {
        continue;
      }
      if (!removeSheet.equal(sourceSheets[0])) {
        updatedSheets.push(sourceSheets[0]);
        continue;
      }
      if (joinHistory[0].mappingIndexes.length === 0) {
        updatedSheets.push(sourceSheets[1]);
      }
      continue;
    }

    if (joinHistory[i].mappingIndexes.length === 0) {
      if (updatedSheets.length > 0) {
        updatedHistory[i] = joinHistory[i];
      }
      updatedSheets.push(sourceSheets[i + 1]);
      continue;
    }
    const notMappedWithRemovedSheet = joinHistory[i].mappingIndexes.every(
      (mapping) => {
        const sourceSheet = findOriginSourceSheet(
          sourceSheetParam,
          i,
          mapping.source
        );
        if (!sourceSheet) {
          return true;
        }
        return updatedSheets.some((sheet) => sheet.equal(sourceSheet));
      }
    );
    if (notMappedWithRemovedSheet) {
      const history = {
        ...joinHistory[i],
        mappingIndexes: updateSourceAfterRemove(
          joinHistory[i],
          sourceSheetParam,
          i,
          removeSheet,
          updatedHistory
        ),
      };
      updatedSheets.push(sourceSheets[i + 1]);
      updatedHistory[i] = history;
    }
  }

  return {
    joinHistory: updatedHistory.filter(
      (history) => !!history
    ) as IJoinHistory[],
    sheets: updatedSheets,
  };
};

export const removeJoinSheet = async (
  removeSheet: Sheet,
  sourceSheetParam: Sheet,
  combineStrategies: Omit<IMatchingValuesWorker, 'getValues' | 'countMatching'>
) => {
  if (sourceSheetParam.getType() === SHEET_TYPE.NORMAL) {
    return null;
  }

  const sourceSheet = sourceSheetParam as JoinSheet;
  const joinedSheets = sourceSheet.getJoinedSheets();
  const removeIndex = joinedSheets.findIndex((sheet) =>
    sheet.equal(removeSheet)
  );
  if (removeIndex < 0) {
    return sourceSheetParam;
  }

  const { joinHistory: updatedJoinHistory, sheets: updatedJoinedSheets } =
    getJoinHistoryAfterRemove(sourceSheetParam as JoinSheet, removeSheet);

  let updatedSourceSheet: Sheet = updatedJoinedSheets[0];
  for (let i = 0; i < updatedJoinHistory.length; ++i) {
    const updatedJoinSheet = updatedJoinedSheets[i + 1];
    const sourceSheet = updatedSourceSheet;
    const mappingColumns = updatedJoinHistory[i].mappingIndexes.map(
      (mapping) => {
        return {
          source: sourceSheet.getColumns()[mapping.source],
          target: updatedJoinSheet.getColumns()[mapping.target],
        };
      }
    );
    updatedSourceSheet = await createJoinSheet(
      updatedSourceSheet,
      updatedJoinSheet,
      mappingColumns,
      getCombineStrategy(updatedJoinHistory[i].type, combineStrategies)
    );
  }

  return updatedSourceSheet;
};

const getCombineStrategy = (
  joinType: JoinType,
  combineStrategies: Omit<IMatchingValuesWorker, 'getValues' | 'countMatching'>
) => {
  const mapping = {
    [JoinType.ADD_TO_ALL_ROWS]: combineStrategies.addToAllRows,
    [JoinType.APPEND_TO_COLUMNS]: combineStrategies.appendToColumns,
    [JoinType.JOIN_ON_COLUMNS]: combineStrategies.joinOnColumns,
    [JoinType.JOIN_WITHOUT_COLUMNS]: combineStrategies.joinWithoutColumns,
  };

  return mapping[joinType];
};
