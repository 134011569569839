import { createContext, ReactNode, useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

/**
 * This is a React Context for storing sessionId value. It is not related to "nuvoSession".
 */

export type INuvoSessionContext = {
  sessionId: string;
};

export type INuvoSessionContextProps = {
  children: ReactNode;
};

export const NuvoSessionContext = createContext<INuvoSessionContext>({
  sessionId: '',
});

export const NuvoSessionProvider = (props: INuvoSessionContextProps) => {
  const sessionId = useMemo(() => {
    return uuidv4().replace(/-/g, '');
  }, []);
  const context: INuvoSessionContext = {
    sessionId,
  };

  return (
    <NuvoSessionContext.Provider value={context}>
      {props.children}
    </NuvoSessionContext.Provider>
  );
};

export const useNuvoSession = () => {
  return useContext(NuvoSessionContext);
};
