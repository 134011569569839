import JoinSheetColumn from './JoinSheetColumn';
import Sheet, { SheetData } from '../Sheet';
import SheetColumn from '../SheetColumn';
import { ColumnMapping, ColumnMappingIndex } from './type';
import { SHEET_COLUMN_TYPE, SHEET_TYPE } from '../../constants/sheet';

export enum JoinType {
  JOIN_ON_COLUMNS = 'JOIN_ON_COLUMNS',
  JOIN_WITHOUT_COLUMNS = 'JOIN_WITHOUT_COLUMNS',
  APPEND_TO_COLUMNS = 'APPEND_TO_COLUMNS',
  ADD_TO_ALL_ROWS = 'ADD_TO_ALL_ROWS',
}

export type JoinedData = { data: SheetData; type: JoinType };

export type IJoinHistory = {
  type: JoinType;
  mappingIndexes: ColumnMappingIndex[];
  row: number;
  col: number;
};

class JoinSheet extends Sheet {
  private sheets: Sheet[] = [];
  private joinHistory: IJoinHistory[] = [];

  constructor({
    joinedData,
    sheets,
    mappings,
    mappingIndexes,
  }: {
    joinedData: JoinedData;
    sheets: Sheet[];
    mappings: ColumnMapping[];
    mappingIndexes: ColumnMappingIndex[];
  }) {
    super({
      data: joinedData.data,
      name: 'join-sheet',
      isNotSetColumn: true,
    });
    this.headerRowIndex = 0;
    this.joinHistory.push({
      mappingIndexes,
      type: joinedData.type,
      row: joinedData.data.length,
      col: this.data[this.headerRowIndex]?.length ?? 0,
    });
    this.sheets = sheets;
    this.setJoinColumns(mappings, mappingIndexes);
  }

  private findRefJoinSheetColumn = (headerIndex: number) => {
    const sourceSheet = this.sheets.length === 2 ? this.sheets[0] : this;
    const joinSheet = this.sheets[this.sheets.length - 1];
    if (headerIndex < sourceSheet.getColumns().length) {
      return sourceSheet.getColumns()[headerIndex];
    }
    const joinHeaderIndex = headerIndex - sourceSheet.getColumns().length;
    return joinSheet.getColumns().filter((col, index) => {
      return this.joinHistory[this.joinHistory.length - 1].mappingIndexes.every(
        (mapping) => mapping.target !== index
      );
    })[joinHeaderIndex];
  };

  private setJoinColumns = (
    mappings: ColumnMapping[],
    mappingIndexes: ColumnMappingIndex[]
  ) => {
    const headerRows = this.data[this.headerRowIndex];
    if (!headerRows) {
      this.columns = [];
      return;
    }
    const columns: SheetColumn[] = [];

    for (let headerIndex = 0; headerIndex < headerRows.length; ++headerIndex) {
      const header = headerRows[headerIndex];
      const mappingIndex = mappingIndexes.findIndex(
        (col) => col.source === headerIndex
      );

      const isAlreadyJoinColumn =
        this.columns[headerIndex] &&
        this.columns[headerIndex].getType() === SHEET_COLUMN_TYPE.JOIN;

      if (mappingIndex >= 0 || isAlreadyJoinColumn) {
        const mapping = mappings[mappingIndex];

        let sheetColumns;
        if (isAlreadyJoinColumn && mappingIndex >= 0) {
          const joinColumn = this.columns[headerIndex] as JoinSheetColumn;
          sheetColumns = [
            ...joinColumn.getJoinedSheetColumns(),
            mapping.target,
          ];
        } else if (isAlreadyJoinColumn) {
          const joinColumn = this.columns[headerIndex] as JoinSheetColumn;
          sheetColumns = joinColumn.getJoinedSheetColumns();
        } else {
          sheetColumns = [
            mapping.source.getRefJoinSheetColum() ?? mapping.source,
            mapping.target,
          ];
        }

        columns.push(
          new JoinSheetColumn({
            columnKey: `${header}`,
            sheetColumns,
            sheet: this,
            rows: this.getRowsByColumnIndex(headerIndex),
          })
        );
      } else {
        const refJoinSheetColum = this.columns[headerIndex]
          ? this.columns[headerIndex].getRefJoinSheetColum() ??
            this.columns[headerIndex]
          : this.findRefJoinSheetColumn(headerIndex);

        columns.push(
          new SheetColumn({
            columnKey: `${header}`,
            sheet: refJoinSheetColum.getSheet(),
            rows: this.getRowsByColumnIndex(headerIndex),
            refJoinSheetColum: refJoinSheetColum,
          })
        );
      }
    }

    this.columns = columns;
  };

  getJoinedSheets = () => {
    return this.sheets;
  };

  getType = () => {
    return SHEET_TYPE.JOIN;
  };

  joinSheet = (
    joinedData: JoinedData,
    sheet: Sheet,
    mappings: ColumnMapping[],
    mappingIndexes: ColumnMappingIndex[]
  ) => {
    this.setData(joinedData.data);
    this.sheets.push(sheet);
    this.joinHistory.push({
      mappingIndexes,
      type: joinedData.type,
      row: joinedData.data.length,
      col: this.data[this.headerRowIndex]?.length ?? 0,
    });
    this.setJoinColumns(mappings, mappingIndexes);
  };

  getJoinColumns = () => {
    return this.columns.filter(
      (column) => column.getType() === SHEET_COLUMN_TYPE.JOIN
    ) as JoinSheetColumn[];
  };

  getJoinHistory = () => {
    return this.joinHistory;
  };
}

export default JoinSheet;
