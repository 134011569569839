import useViewModel from './viewModel';
import DataModelSheetMatcher from '../../../matching/DataModelSheetMatcher';
import DataModelSheetMatching from '../../../matching/DataModelSheetMatching';
import { CreateNewColumnModal as BaseCreateNewColumnModal } from '@nuvo-importer/common';
import { useContextCreateNewColumnModal } from './CreateNewColumnModalContext';
import { useMemo } from 'react';
import { useTheme } from 'theme';

type CreateNewColumnModalProps = {
  dataModelSheetMatcher: DataModelSheetMatcher;
  setDataModelSheetMatcher: (
    dataModelSheetMatcher: DataModelSheetMatcher
  ) => void;
  setDataModelSheetMatching: (
    dataModelSheetMatching: DataModelSheetMatching
  ) => void;
};

const CreateNewColumnModal = ({
  dataModelSheetMatcher,
  setDataModelSheetMatcher,
  setDataModelSheetMatching,
}: CreateNewColumnModalProps) => {
  const { isOpen, close } = useContextCreateNewColumnModal();
  const { onSubmit, dataModels, initialValues } = useViewModel({
    dataModelSheetMatcher,
    setDataModelSheetMatcher,
    setDataModelSheetMatching,
  });
  const theme = useTheme();
  const configureStyled = useMemo(() => {
    return theme.getColumnMatchTheme().dialogCreateCustomColumn;
  }, [theme]);

  return (
    <BaseCreateNewColumnModal
      isOpen={isOpen}
      close={close}
      onSubmit={onSubmit}
      columnLabels={dataModels.map((item) => item.getLabel())}
      configureStyled={configureStyled}
      initialValues={initialValues}
    />
  );
};

export default CreateNewColumnModal;
