import { CSSInterpolation, css, cx } from '@nuvo-importer/common';
import Popover from 'baseUI/Popover';
import { Padding } from '@popperjs/core';
import { RefObject, useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { JoinSheetColumn } from '@nuvo-importer/common/sdk';
import { useTranslation } from 'react-i18next';
import Message from '../SheetInfoMessage';
import { OverflowCheckOffset } from 'core/constants/overflowCheckOffset';

type IJoinSheetNamePopoverProps = {
  className?: string;
  boundary?: HTMLElement;
  sheetColumn: JoinSheetColumn;
  joinPopoverTheme?: CSSInterpolation;
  customTheme?: CSSInterpolation;
  overflowCheckOffset?: Partial<OverflowCheckOffset>;
  padding?: Padding;
};

const JoinSheetNamePopover = ({
  className,
  boundary,
  sheetColumn,
  joinPopoverTheme,
  customTheme,
  overflowCheckOffset,
  padding,
}: IJoinSheetNamePopoverProps) => {
  const [isShowPopover, setShowIsPopover] = useState(false);
  const { t } = useTranslation();

  const getOnHover = () => {
    if (!isMobile && !isTablet) {
      return {
        onMouseEnter: () => setShowIsPopover(true),
        onMouseLeave: () => setShowIsPopover(false),
      };
    } else {
      return {
        onClick: () => setShowIsPopover((isShow) => !isShow),
        onMouseLeave: () => setShowIsPopover(false),
      };
    }
  };

  useEffect(() => {
    const onScroll = () => {
      setShowIsPopover(false);
    };
    boundary?.addEventListener('scroll', onScroll);

    return () => {
      boundary?.removeEventListener('scroll', onScroll);
    };
  }, [boundary]);

  const joinedSheetColumns = sheetColumn.getJoinedSheetColumns();

  return (
    <Popover
      boundary={boundary}
      direction="bottom"
      isShow={isShowPopover}
      overflowCheckOffset={overflowCheckOffset}
      padding={padding}
      message={
        <Message
          sheetColumns={joinedSheetColumns}
          theme={joinPopoverTheme}
          title={t('txt_joined_data_from')}
        />
      }
      render={({ referenceElement }) => {
        return (
          <div
            {...getOnHover()}
            ref={referenceElement as RefObject<HTMLDivElement>}
            className={cx(
              'text-xss text-blue-light-500 rounded-2lg bg-blue-light-50 ml-1 flex whitespace-nowrap px-2 py-1 leading-3',
              className,
              css({
                '&&': customTheme,
              })
            )}
          >
            {joinedSheetColumns.length} {t('txt_sheets')}
          </div>
        );
      }}
    />
  );
};

export default JoinSheetNamePopover;
