import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CleaningAssistantContext } from '../../context';
import { ICleaningAssistantContext } from '../../index.types';
import Button from '../../../../../../../components/Button';
import styles from './index.style';
import { ReactComponent as Sparkle } from './../../../../../../../assets/icon/sparkle.svg';
import { ReactComponent as ToggleIcon } from './../../../../../../../assets/icon/receipt-sparkles.svg';
import { useTheme } from '../../../../../../../theme';
import { CSSObject, cx } from '@emotion/css';
import { validateBackgroundImageInCss } from '../../../../../../style';

const Toggle = () => {
  const { t } = useTranslation();
  const context = useContext<ICleaningAssistantContext>(
    CleaningAssistantContext
  );
  const { onOpenPopover, count } = context;
  const s = styles(context);
  const theme = useTheme();

  return (
    <Button
      variant="cleanData"
      type="button"
      className={s.rootClass}
      onClick={onOpenPopover}
    >
      <Sparkle className={s.sparkleClassA} />
      <Sparkle className={s.sparkleClassB} />
      {count > 0 && <div className={s.badgeClass}>{count}</div>}
      {validateBackgroundImageInCss(
        theme.getCleaningAssistantTheme().toggle?.icon as CSSObject
      ) ? (
        <svg className={cx('mb-px h-4 w-4 bg-no-repeat', s.iconClass)} />
      ) : (
        <ToggleIcon className={s.iconClass} />
      )}
      {t('txt_cleaning_assistant')}
    </Button>
  );
};

export default Toggle;
