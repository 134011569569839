import { Form } from 'react-final-form';
import { css, cx } from '../../core/emotion';
import { DialogCreateCustomOption } from '../../theme/themeAPI';
import BaseModal from '../CreateNewColumnModal/Modal';
import useViewModel, { FormValues } from './viewModel';
import CategoryDataModel from '../../core/dataModel/model/CategoryDataModel';
import FieldDropdown from '../form/FieldDropdown';
import FieldTextInput from '../form/FieldTextInput';
import Label from '../form/Label';
import { useTranslation } from 'react-i18next';
import FieldNumberInput from '../form/FieldNumberInput';
import Button from '../Button';

type CreateNewOptionModalProps = {
  isOpen: boolean;
  close: () => void;
  configureStyled?: DialogCreateCustomOption;
  dataModel?: CategoryDataModel | null;
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => void;
};

export const createNewOptionModalId = 'nuvo-create-new-option-modal';

const CreateNewOptionModal = ({
  isOpen,
  close,
  configureStyled,
  initialValues,
  onSubmit,
  dataModel,
}: CreateNewOptionModalProps) => {
  const {
    modalElement,
    dropdownOptions,
    validate,
    hasSubmit,
    inputRef,
    handleDropdownChange,
    handleSubmit,
  } = useViewModel({
    dataModel,
    isOpen,
    onSubmit,
    close,
  });
  const { t } = useTranslation();

  return (
    <BaseModal
      gravity="middle"
      className="w-full !p-0 sm:max-w-xl"
      isOpen={isOpen}
      onCloseModal={() => {
        close();
      }}
      wrapperClassName={cx(css`
        z-index: 10000 !important;
      `)}
      isShowCloseIcon={true}
      id={createNewOptionModalId}
      overlayStyled={configureStyled?.overlay}
      bodyStyled={configureStyled?.root}
      closeIconStyled={configureStyled?.closeIcon}
    >
      <Form<FormValues>
        onSubmit={handleSubmit}
        validate={(values) => {
          if (hasSubmit) {
            return true;
          }
          return validate({
            ...values,
            optionName: values.optionName?.trim() ?? '',
          });
        }}
        initialValues={initialValues as FormValues}
        render={({ handleSubmit, values, form }) => {
          return (
            <form className="p-6 pt-0 text-left" onSubmit={handleSubmit}>
              <div>
                <p
                  className={cx(
                    'text-neutral-gray text-2xl font-semibold',
                    css({ '&&': configureStyled?.title })
                  )}
                >
                  {t('txt_create_new_option')}
                </p>
                <p
                  className={cx(
                    'text-gray-970 mt-2 text-sm leading-5',
                    css({ '&&': configureStyled?.description })
                  )}
                >
                  {t('txt_create_new_option_description')}
                </p>
              </div>
              <div className="mt-6">
                <Label
                  description={t(
                    'txt_create_new_option_option_name_field_description'
                  )}
                  className="mb-1"
                  textClassName={css({
                    '&&': configureStyled?.optionName?.label,
                  })}
                  iconClassName={css({
                    '&&': configureStyled?.optionName?.icon,
                  })}
                >
                  {t('txt_option_name')}
                </Label>
                {values.optionType === 'string' ? (
                  <FieldTextInput
                    ref={inputRef}
                    className={css({
                      '&&': configureStyled?.optionName?.input,
                    })}
                    errorMessageClassName={css({
                      '&&': configureStyled?.optionName?.errorMessage,
                    })}
                    name="optionName"
                    placeholder={t(
                      'txt_create_new_option_option_name_field_placeholder'
                    )}
                  />
                ) : (
                  <FieldNumberInput
                    ref={inputRef}
                    withDecimal={values.optionType === 'float'}
                    className={css({
                      '&&': configureStyled?.optionName?.input,
                    })}
                    errorMessageClassName={css({
                      '&&': configureStyled?.optionName?.errorMessage,
                    })}
                    name="optionName"
                    placeholder={t(
                      'txt_create_new_option_option_name_field_placeholder'
                    )}
                    hint={
                      values.optionType === 'float'
                        ? t('txt_message_float_only_numbers')
                        : t('txt_message_int_only_numbers')
                    }
                  />
                )}
              </div>
              <div className="mt-4">
                <Label
                  description={t(
                    'txt_create_new_option_option_type_field_description'
                  )}
                  textClassName={css({
                    '&&': configureStyled?.optionType?.label,
                  })}
                  iconClassName={css({
                    '&&': configureStyled?.optionType?.icon,
                  })}
                  className="mb-1"
                >
                  {t('txt_option_type')}
                </Label>
                <FieldDropdown
                  name="optionType"
                  placeholder={t('txt_select_option_type')}
                  options={dropdownOptions}
                  rootDropdownPortal={modalElement ?? undefined}
                  dropdownStyled={configureStyled?.optionType?.dropdown}
                  onChange={handleDropdownChange(values, form.change)}
                />
              </div>
              <div className="flex justify-end pt-10">
                <Button
                  variant="secondary"
                  className="mr-4"
                  onClick={() => {
                    close();
                  }}
                >
                  {t('txt_cancel')}
                </Button>
                <Button type="submit">{t('txt_create')}</Button>
              </div>
            </form>
          );
        }}
      />
    </BaseModal>
  );
};

export default CreateNewOptionModal;
