import { CSSInterpolation, css, cx } from '@nuvo-importer/common';
import Popover from 'baseUI/Popover';
import { RefObject, useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { JoinSheetColumn } from '@nuvo-importer/common/sdk';
import Message from 'baseUI/SheetInfoMessage';
import { ReactComponent as JoinIcon } from '../../../../assets/icon/join.svg';
import { useTranslation } from 'react-i18next';

type IJoinSheetBadgeProps = {
  boundary?: HTMLElement;
  sheetColumn: JoinSheetColumn;
  joinPopoverTheme?: CSSInterpolation;
  customTheme?: CSSInterpolation;
};

const JoinSheetBadge = ({
  boundary,
  sheetColumn,
  joinPopoverTheme,
  customTheme,
}: IJoinSheetBadgeProps) => {
  const [isShowPopover, setShowIsPopover] = useState(false);
  const { t } = useTranslation();

  const getOnHover = () => {
    if (!isMobile && !isTablet) {
      return {
        onMouseEnter: () => setShowIsPopover(true),
        onMouseLeave: () => setShowIsPopover(false),
      };
    } else {
      return {
        onClick: () => setShowIsPopover((isShow) => !isShow),
        onMouseLeave: () => setShowIsPopover(false),
      };
    }
  };

  useEffect(() => {
    const onScroll = () => {
      setShowIsPopover(false);
    };
    boundary?.addEventListener('scroll', onScroll);

    return () => {
      boundary?.removeEventListener('scroll', onScroll);
    };
  }, [boundary]);

  const joinedSheetColumns = sheetColumn.getJoinedSheetColumns();

  return (
    <Popover
      boundary={boundary}
      direction="bottom"
      isShow={isShowPopover}
      message={
        <Message
          sheetColumns={joinedSheetColumns}
          theme={joinPopoverTheme}
          title={t('txt_joined_data_from')}
        />
      }
      render={({ referenceElement }) => {
        return (
          <div
            {...getOnHover()}
            ref={referenceElement as RefObject<HTMLDivElement>}
            className={cx(
              'border-blue-light-100 rounded-medium text-blue-light-500 hover:bg-blue-light-100 ml-0.5 flex items-center border px-2 py-1 text-xs',
              css({
                '&&': customTheme,
              })
            )}
          >
            <JoinIcon />
            <span className="ml-px">{joinedSheetColumns.length}</span>
          </div>
        );
      }}
    />
  );
};

export default JoinSheetBadge;
