import { useTranslation } from 'react-i18next';
import { useSettings } from 'settings';
import { MutableRefObject, useEffect, useMemo, useState } from 'react';
import { ActionFooter } from 'navigation';
import { CSSInterpolation } from '@nuvo-importer/common';
import { BackManualImportButton } from './BackManualImport';

type ReviewEntriesActionFooterProps = {
  onPositiveClick?: () => void;
  onNegativeClick?: () => void;
  positiveButtonText?: string;
  validating?: boolean;
  className?: string;
  errorCount?: MutableRefObject<number>;
  popoverInfoTheme: CSSInterpolation;
  isDirectToReviewEntryFromContextualEngine?: boolean;
  wrapperClassName?: string;
};

const ReviewEntriesActionFooter = ({
  onPositiveClick,
  onNegativeClick,
  positiveButtonText,
  validating = false,
  className,
  errorCount = { current: 0 },
  popoverInfoTheme,
  isDirectToReviewEntryFromContextualEngine,
  wrapperClassName,
}: ReviewEntriesActionFooterProps) => {
  const { t } = useTranslation();
  const { completeImportAction } = useSettings();
  const [errorNumber, setErrorNumber] = useState(0);

  useEffect(() => {
    if (completeImportAction === 'block') {
      const interval = setInterval(() => {
        setErrorNumber(errorCount.current);
      }, 150);

      return () => {
        clearTimeout(interval);
      };
    }
    return;
  }, [errorCount, completeImportAction]);

  const disabledPositiveButton = useMemo(() => errorNumber > 0, [errorNumber]);

  const disabledSubmitButton = useMemo(
    () => completeImportAction === 'block' && disabledPositiveButton,
    [completeImportAction, disabledPositiveButton]
  );

  return (
    <ActionFooter
      footerStyle="px-8"
      className={className}
      positiveButtonText={positiveButtonText}
      disabledPositive={validating || disabledSubmitButton}
      onPositiveClick={onPositiveClick}
      onNegativeClick={onNegativeClick}
      enablePopper={disabledSubmitButton}
      popperMessage={t(
        errorNumber === 1
          ? 'txt_invalid_data_behavior_block'
          : 'txt_invalid_data_behavior_block_more_error',
        {
          errorCount: errorNumber,
        }
      )}
      popoverInfoTheme={popoverInfoTheme}
      backButton={
        isDirectToReviewEntryFromContextualEngine
          ? () => <BackManualImportButton onClick={onNegativeClick} />
          : undefined
      }
      wrapperClassName={wrapperClassName}
    />
  );
};

export default ReviewEntriesActionFooter;
