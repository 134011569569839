import { useErrorMessage } from '@nuvo-importer/common/core';
import { IValue } from '../ColumnDropdown/viewModel';
import { useField } from 'react-final-form';

type IUseViewModelProps = {
  name: string;
  isFieldShowError: boolean;
};

const useViewModel = ({ name, isFieldShowError }: IUseViewModelProps) => {
  const { input, meta } = useField<IValue>(name);
  const { isShowError, errorMessage } = useErrorMessage<IValue>({
    isFieldShowError,
    meta,
  });

  return { input, isShowError, errorMessage };
};

export default useViewModel;
