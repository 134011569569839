import DataModelSheetMatching, {
  DataModelSheetMatch,
  MatchedOption,
} from './DataModelSheetMatching';
import Sheet, { Value } from './../sheetImporter/Sheet';
import SheetColumn from './../sheetImporter/SheetColumn';
import { DataModel } from 'dataModel';
import SheetColumnDataModelSimilarityList from './SheetColumnDataModelSimilarityList';
import SheetColumnDataModelSimilarity from './SheetColumnDataModelSimilarity';
import SheetColumnDataModelOptionSimilarityList from './SheetColumnDataModelOptionSimilarityList';
import { CalculateSimilarityResult } from './CalculateSimilarityMapper';
import { isSheetColumnOptionEqual } from '../sheetImporter/utils';
import SheetColumnDataModelOptionSimilarity from './SheetColumnDataModelOptionSimilarity';
import { chunk, maxBy, uniq } from 'lodash';
import {
  checkIsMultipleValues,
  separateMultipleValues,
} from '@nuvo-importer/common/core';

class DataModelSheetMatcher {
  private dataModels: DataModel[];
  private dataModelSheetMatch: DataModelSheetMatch[] = [];
  private sheets: Sheet[];
  private sheetColumnDataModelSimilarityList: SheetColumnDataModelSimilarityList;
  private sheetColumnDataModelOptionSimilarityList: SheetColumnDataModelOptionSimilarityList;
  private calculateSimilarityResult: CalculateSimilarityResult;

  constructor({
    dataModels,
    sheets,
    sheetColumnDataModelSimilarityList,
    sheetColumnDataModelOptionSimilarityList,
    calculateSimilarityResult,
  }: {
    dataModels: DataModel[];
    sheets: Sheet[];
    sheetColumnDataModelSimilarityList: SheetColumnDataModelSimilarityList;
    sheetColumnDataModelOptionSimilarityList: SheetColumnDataModelOptionSimilarityList;
    calculateSimilarityResult: CalculateSimilarityResult;
  }) {
    this.dataModels = dataModels;
    this.sheets = sheets;
    this.sheetColumnDataModelSimilarityList =
      sheetColumnDataModelSimilarityList;
    this.sheetColumnDataModelOptionSimilarityList =
      sheetColumnDataModelOptionSimilarityList;
    this.calculateSimilarityResult = calculateSimilarityResult;
  }

  /* Matching the options of the data model with the options of the sheet column. */
  matchingSheetColumnOptionWithDataModelOption = () => {
    for (let i = 0; i < this.dataModelSheetMatch.length; i++) {
      const dataModelSheetMatch = this.dataModelSheetMatch[i];
      const matchedDataModel = dataModelSheetMatch.matchedDataModel;
      if (matchedDataModel && matchedDataModel.dataModel?.isDropdown()) {
        const matchedOptions = this.getMatchedOptions(
          matchedDataModel.dataModel,
          dataModelSheetMatch.sheetColumn
        );
        matchedDataModel.matchedOptions = matchedOptions;
      }
    }
  };

  /* A function that returns the matched options of the sheet column and the data model. */
  getMatchOptions = (sheetColumn: SheetColumn, dataModel: DataModel) => {
    if (dataModel.isDropdown()) {
      return this.getMatchedOptions(dataModel, sheetColumn);
    } else {
      return null;
    }
  };

  /* A function that returns the matched options of the sheet column and the data model. */
  getMatching = () => {
    this.dataModelSheetMatch = [];
    this.matchingSheetColumnWithDataModel();
    this.matchingSheetColumnOptionWithDataModelOption();

    return new DataModelSheetMatching({
      dataModelSheetMatch: this.dataModelSheetMatch,
      dataModels: this.dataModels,
      sheets: this.sheets,
      sheetColumnDataModelSimilarityList:
        this.sheetColumnDataModelSimilarityList,
      sheetColumnDataModelOptionSimilarityList:
        this.sheetColumnDataModelOptionSimilarityList,
    });
  };

  getSheetColumnDataModelOptionSimilarityList = () => {
    return this.sheetColumnDataModelOptionSimilarityList;
  };

  getSheetColumnDataModelSimilarityList = () => {
    return this.sheetColumnDataModelSimilarityList;
  };

  getSheets = () => {
    return this.sheets;
  };

  addSheetColumnDataModelOptionSimilarityList = (
    sheetColumnDataModelOptionSimilarityList: SheetColumnDataModelOptionSimilarityList
  ) => {
    this.sheetColumnDataModelOptionSimilarityList =
      this.sheetColumnDataModelOptionSimilarityList.merge(
        sheetColumnDataModelOptionSimilarityList
      );
  };

  getDataModels = () => {
    return this.dataModels;
  };

  getCalculateSimilarityResult = () => {
    return this.calculateSimilarityResult;
  };

  /* Matching the options of the data model with the options of the sheet column. */
  private getMatchedOptions = (
    dataModel: DataModel,
    sheetColumn: SheetColumn
  ) => {
    const similaritySheetColumnOptions =
      this.sheetColumnDataModelOptionSimilarityList.getSimilaritySheetColumnOptions(
        sheetColumn,
        dataModel
      );

    const predicate = (
      similaritySheetColumnOption: SheetColumnDataModelOptionSimilarity,
      uniqueOption: Value
    ) => {
      const sheetColumnOption =
        similaritySheetColumnOption.getSheetColumnOption().option;
      return isSheetColumnOptionEqual(uniqueOption, sheetColumnOption);
    };

    const matchedOptions: MatchedOption[] = [];
    const uniqueOptions = sheetColumn.getUniqueRows();
    const isMultiSelection = dataModel.getIsMultiSelection();
    const chunkSize = 100_000;
    const uniqueOptionsChunks = chunk(uniqueOptions, chunkSize);
    const lookupSimilarityOptions: Record<
      string,
      SheetColumnDataModelOptionSimilarity[]
    > = {};
    for (let i = 0; i < similaritySheetColumnOptions.length; ++i) {
      const item = similaritySheetColumnOptions[i];
      if (!lookupSimilarityOptions[`${item.getSheetColumnOption().option}`]) {
        lookupSimilarityOptions[`${item.getSheetColumnOption().option}`] = [
          item,
        ];
      } else {
        lookupSimilarityOptions[`${item.getSheetColumnOption().option}`].push(
          item
        );
      }
    }

    for (let i = 0; i < uniqueOptionsChunks.length; i++) {
      const uniqueOptionsChunk = uniqueOptionsChunks[i];
      for (let j = 0; j < uniqueOptionsChunk.length; j++) {
        const uniqueOption = uniqueOptionsChunk[j];
        if (isMultiSelection) {
          const dataModelOptions: string[] = [];
          const optionValuesByTdm: string[] = checkIsMultipleValues(
            uniqueOption
          )
            ? separateMultipleValues(uniqueOption)
            : [`${uniqueOption}`];

          for (let i = 0; i < optionValuesByTdm.length; i++) {
            const option = optionValuesByTdm[i]?.trim();
            const targetMatchedList = [];
            if (lookupSimilarityOptions[option]) {
              targetMatchedList.push(...lookupSimilarityOptions[option]);
            }

            if (targetMatchedList.length === 1) {
              const itemTdmMatched = targetMatchedList?.[0]
                ?.getDataModelOption()
                ?.option?.toString();
              dataModelOptions.push(itemTdmMatched);
            } else {
              // NOTE: The option has multiple matching
              const maxSimilarity = maxBy(targetMatchedList, (item) =>
                item.getSimilarity()?.getSimilarity()
              );
              const maxSimilarityList = targetMatchedList.filter(
                (item) =>
                  item.getSimilarity()?.getSimilarity() ===
                  maxSimilarity?.getSimilarity()?.getSimilarity()
              );

              if (maxSimilarityList?.length === 1) {
                const itemTdmMatched = maxSimilarityList?.[0]
                  ?.getDataModelOption()
                  ?.option?.toString();
                dataModelOptions.push(itemTdmMatched);
              }
            }
          }

          matchedOptions.push({
            sheetOption: uniqueOption,
            dataModelOptions: uniq(dataModelOptions),
          });
        } else {
          const matchedOptionItems = [];
          const optionKey = uniqueOption as string;
          const matchedOptionItemsChunks = chunk(
            lookupSimilarityOptions[optionKey],
            chunkSize
          );
          for (let k = 0; k < matchedOptionItemsChunks.length; k++) {
            const chunk = matchedOptionItemsChunks[k];
            const filteredChunk = chunk.filter((item) =>
              predicate(item, uniqueOption)
            );
            matchedOptionItems?.push(...filteredChunk);
            new Promise((resolve) => setImmediate(resolve)).then();
          }

          const maxSimilarity = maxBy(matchedOptionItems, (item) =>
            item.getSimilarity()?.getSimilarity()
          );
          const maxSimilarityList = matchedOptionItems?.filter(
            (item) =>
              item.getSimilarity()?.getSimilarity() ===
              maxSimilarity?.getSimilarity()?.getSimilarity()
          );

          if (maxSimilarityList.length === 1) {
            const matchedOptionItem = matchedOptionItems[0];
            matchedOptions.push({
              sheetOption: matchedOptionItem.getSheetColumnOption().option,
              dataModelOption: matchedOptionItem.getDataModelOption().option,
            });
          } else {
            matchedOptions.push({
              sheetOption: uniqueOption,
            });
          }
        }
      }

      new Promise((resolve) => setImmediate(resolve)).then();
    }

    return matchedOptions;
  };

  /* Adding a new data model to the dataModelSheetMatch array. */
  private addSheetMatchDataModel = (
    matching: SheetColumnDataModelSimilarity,
    sheetColumn: SheetColumn
  ) => {
    this.dataModelSheetMatch.push({
      sheetColumn,
      matchedDataModel: {
        dataModel: matching.getDataModel(),
      },
    });
  };

  private matchingSheetColumnWithDataModel = () => {
    this.sheetColumnDataModelSimilarityList
      .getSortedSheetColumnDataModelSimilarity()
      .forEach((sheetColumnDataModelSimilarity) => {
        if (sheetColumnDataModelSimilarity.getMarkApplied()) {
          return;
        }

        const sheetColumn = sheetColumnDataModelSimilarity.getSheetColumn();

        const maxSimilarity = sheetColumnDataModelSimilarity?.getSimilarity();
        const maxSimilarities = this.sheetColumnDataModelSimilarityList
          .getSortedSheetColumnDataModelSimilarity()
          .filter((item) => {
            return (
              item.getSimilarity().getSimilarity() ===
                maxSimilarity?.getSimilarity() &&
              item.getSheetColumn() === sheetColumn
            );
          });

        if (maxSimilarities.length > 1) {
          return;
        }

        if (sheetColumn) {
          const mostSimilarityBySheetColumn =
            this.sheetColumnDataModelSimilarityList.getMostSimilarityBySheetColumn(
              sheetColumn
            );

          if (
            mostSimilarityBySheetColumn?.getSimilarity().getSimilarity() !==
            sheetColumnDataModelSimilarity.getSimilarity().getSimilarity()
          ) {
            return;
          }

          this.addSheetMatchDataModel(
            sheetColumnDataModelSimilarity,
            sheetColumn
          );
          this.sheetColumnDataModelSimilarityList.markDataModel(
            sheetColumnDataModelSimilarity.getDataModel()
          );
          this.sheetColumnDataModelSimilarityList.markSheetColumn(sheetColumn);
        }
      });

    this.sheetColumnDataModelSimilarityList.clearMark();
  };
}

export default DataModelSheetMatcher;
