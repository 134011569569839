/* istanbul ignore file */

import { cleanSpecialCharAndToLowercase } from './../replaceRegex';
import { DataModel } from '../dataModel/model/DataModel';
import CategoryDataModel from '../dataModel/model/CategoryDataModel';
import { flatten } from 'lodash';
import Similarity from './Similarity';
import Sheet from '../sheet/Sheet';
import SheetColumnDataModelSimilarity, {
  NullSheetColumnDataModelSimilarity,
} from './SheetColumnDataModelSimilarity';
import SheetColumnDataModelSimilarityList from './SheetColumnDataModelSimilarityList';
import SheetColumnDataModelOptionSimilarity, {
  NullSheetColumnDataModelOptionSimilarity,
} from './SheetColumnDataModelOptionSimilarity';
import SheetColumnDataModelOptionSimilarityList from './SheetColumnDataModelOptionSimilarityList';
import { DATATYPE } from '../dataType';
import { FullResultMatching } from './types/ml';
import SheetColumn from '../sheet/SheetColumn';
import { THRESHOLD } from '../constants/similarity';

export type CalculateSimilarityResult = {
  result: FullResultMatching[];
};

type CalculateSimilarityMapperOptions = {
  isAutoMapping?: boolean;
};

class CalculateSimilarityMapper {
  private calculateSimilarityResult: CalculateSimilarityResult;
  private sheets: Sheet[];
  private dataModels: DataModel[];
  private options: CalculateSimilarityMapperOptions;

  constructor({
    calculateSimilarityResult,
    sheets,
    dataModels,
    options = {},
  }: {
    calculateSimilarityResult: CalculateSimilarityResult;
    sheets: Sheet[];
    dataModels: DataModel[];
    options?: CalculateSimilarityMapperOptions;
  }) {
    this.calculateSimilarityResult = calculateSimilarityResult;
    this.sheets = sheets;
    this.dataModels = dataModels;
    this.options = options;
  }

  private parseSheetOption = (sheetOption: string) => {
    const booleanWhiteList = ['true', 'false'];
    if (booleanWhiteList.includes(sheetOption)) {
      return JSON.parse(sheetOption);
    } else {
      return sheetOption;
    }
  };

  static mergeCalculateSimilarityResultOptions = (
    rawBaseCalculateSimilarityResultOptions: CalculateSimilarityResult,
    addCalculateSimilarityResultOptions: CalculateSimilarityResult
  ) => {
    const baseCalculateSimilarityResultOptions = {
      result: [...rawBaseCalculateSimilarityResultOptions.result],
    };

    for (
      let i = 0;
      i < addCalculateSimilarityResultOptions.result.length;
      ++i
    ) {
      const addItem = addCalculateSimilarityResultOptions.result[i];
      let baseItem: FullResultMatching | undefined;
      for (
        let j = 0;
        j < baseCalculateSimilarityResultOptions.result.length;
        ++j
      ) {
        if (
          addItem.label === baseCalculateSimilarityResultOptions.result[j].label
        ) {
          baseItem = baseCalculateSimilarityResultOptions.result[j];
          break;
        }
      }

      if (baseItem) {
        const baseChoiceKeys = Object.keys(baseItem.choice);
        for (let k = 0; k < baseChoiceKeys.length; ++k) {
          const key = baseChoiceKeys[k];
          baseItem.choice[key] = {
            ...baseItem.choice[key],
            ...(addItem.choice[key] ?? {}),
          };
        }

        const addChoiceKeys = Object.keys(addItem.choice);
        for (let k = 0; k < addChoiceKeys.length; ++k) {
          const key = addChoiceKeys[k];
          baseItem.choice[key] = {
            ...(baseItem.choice[key] ?? {}),
            ...addItem.choice[key],
          };
        }
      }
    }

    return baseCalculateSimilarityResultOptions;
  };

  static cleanCalculateSimilarityResultOptions = (
    rawCalculateSimilarityResults: CalculateSimilarityResult
  ) => {
    const baseCalculateSimilarityResultOptions = {
      result: rawCalculateSimilarityResults.result.map((item) => {
        return {
          ...item,
        };
      }),
    };

    for (
      let i = 0;
      i < baseCalculateSimilarityResultOptions.result.length;
      ++i
    ) {
      const calculateSimilarityResultOptionsItem =
        baseCalculateSimilarityResultOptions.result[i];
      const choiceDataModel = calculateSimilarityResultOptionsItem.choice;

      baseCalculateSimilarityResultOptions.result[i].choice = {};
      const choiceDataModelKeys = Object.keys(choiceDataModel);
      for (let j = 0; j < choiceDataModelKeys.length; ++j) {
        const sheetValues = choiceDataModel[choiceDataModelKeys[j]];
        const sheetValuesKeys = Object.keys(sheetValues);

        baseCalculateSimilarityResultOptions.result[i].choice[
          choiceDataModelKeys[j]
        ] = {};

        for (let k = 0; k < sheetValuesKeys.length; ++k) {
          const similarities = sheetValues[sheetValuesKeys[k]];
          const similaritiesKeys = Object.keys(similarities);

          baseCalculateSimilarityResultOptions.result[i].choice[
            choiceDataModelKeys[j]
          ][sheetValuesKeys[k]] = {};

          for (let l = 0; l < similaritiesKeys.length; ++l) {
            if (similarities[similaritiesKeys[l]] > THRESHOLD) {
              baseCalculateSimilarityResultOptions.result[i].choice[
                choiceDataModelKeys[j]
              ][sheetValuesKeys[k]][similaritiesKeys[l]] =
                similarities[similaritiesKeys[l]];
            }
          }
        }
      }
    }

    return baseCalculateSimilarityResultOptions;
  };

  getSheetColumnDataModelSimilarityList = () => {
    const sheetColumnDataModelSimilarities =
      this.calculateSimilarityResult.result.map((resultMatching) => {
        return resultMatching.suggestions.map((suggestion) => {
          const dataModel = this.dataModels.find((dataModel) => {
            let dataModelKey = dataModel.getKey();
            let suggestionKey = suggestion.key;

            if (this.options?.isAutoMapping === true) {
              dataModelKey = cleanSpecialCharAndToLowercase(dataModel.getKey());
              suggestionKey = cleanSpecialCharAndToLowercase(suggestion.key);
            }

            if (dataModelKey === suggestionKey) {
              return true;
            }

            if (dataModel.getAlternativeMatches().includes(suggestion.key)) {
              return true;
            }

            return false;
          });

          const sheetColumns: SheetColumn[] = [];

          for (let index = 0; index < this.sheets.length; index++) {
            const columns = this.sheets[index].getColumnsByKey(
              resultMatching.label,
              this.options?.isAutoMapping
            );
            columns.forEach((column) => {
              sheetColumns.push(column);
            });
          }

          return sheetColumns.map((sheetColumn) => {
            if (dataModel && sheetColumn) {
              return new SheetColumnDataModelSimilarity({
                dataModel,
                sheetColumn: sheetColumn,
                similarity: new Similarity({ similarity: suggestion.value }),
              });
            } else {
              return new NullSheetColumnDataModelSimilarity();
            }
          });
        });
      });

    const newSheetColumnDataModel: SheetColumnDataModelSimilarity[] = [];

    flatten(flatten(flatten(sheetColumnDataModelSimilarities))).forEach(
      (item) => {
        let hasMatchedDataModel = false;

        newSheetColumnDataModel.forEach((existItem, index) => {
          if (
            !existItem.isNull() &&
            existItem.getDataModel() === item.getDataModel() &&
            existItem.getSheetColumn() === item.getSheetColumn()
          ) {
            hasMatchedDataModel = true;
            if (item.getSimilarity() > existItem.getSimilarity()) {
              newSheetColumnDataModel[index] = item;
            }
          }
        });

        if (!hasMatchedDataModel) {
          newSheetColumnDataModel.push(item);
        }
      }
    );

    return new SheetColumnDataModelSimilarityList({
      sheetColumnDataModelSimilarities: newSheetColumnDataModel,
    });
  };

  generateSheetColumnDataModelOptionSimilarity = ({
    sheetOption,
    dataModel,
    similarities,
    sheetColumn,
    sheetColumnDataModelOptionSimilaritiesHashMap,
  }: {
    sheetOption: string;
    dataModel: DataModel | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    similarities: any;
    sheetColumn?: SheetColumn;
    sheetColumnDataModelOptionSimilaritiesHashMap: Record<
      string,
      SheetColumnDataModelOptionSimilarity
    >;
  }) => {
    const sheetColumnOption = {
      option: this.parseSheetOption(sheetOption),
      sheetColumn: sheetColumn!,
    };

    const similaritiesKeys = Object.keys(similarities);
    for (let m = 0; m < similaritiesKeys.length; ++m) {
      const dataModelValue = similaritiesKeys[m];
      const similarity = similarities[dataModelValue];
      const sheetColumnDataModelOptionSimilarities: SheetColumnDataModelOptionSimilarity[] =
        [];

      if (
        dataModel &&
        sheetColumnOption.sheetColumn &&
        dataModel.isDropdown()
      ) {
        let dataModelOptions = [];
        if (dataModel.getType() === DATATYPE.BOOLEAN) {
          dataModelOptions = [dataModelValue];
        } else {
          const options = (dataModel as CategoryDataModel).getOptions();
          if (dataModelValue === '' && similarity > 0) {
            dataModelOptions.push('');
          }
          for (let i = 0; i < options.length; ++i) {
            const option = options[i];
            if (
              option.value === dataModelValue ||
              option.label === dataModelValue
            ) {
              dataModelOptions.push(option.value);
            }
          }
        }

        for (let i = 0; i < dataModelOptions.length; ++i) {
          const dataModelOption = dataModelOptions[i];
          if (
            dataModelOption?.length > 0 ||
            (dataModelOption.length === 0 && similarity > 0)
          ) {
            sheetColumnDataModelOptionSimilarities.push(
              new SheetColumnDataModelOptionSimilarity({
                dataModelOption: {
                  dataModel,
                  option: dataModelOption,
                },
                similarity: new Similarity({ similarity }),
                sheetColumnOption,
              })
            );
          } else {
            sheetColumnDataModelOptionSimilarities.push(
              new NullSheetColumnDataModelOptionSimilarity()
            );
          }
        }
      } else {
        sheetColumnDataModelOptionSimilarities.push(
          new NullSheetColumnDataModelOptionSimilarity()
        );
      }

      for (let i = 0; i < sheetColumnDataModelOptionSimilarities.length; ++i) {
        let hasMatchedSimilarity = false;
        const sheetColumnDataModelOptionSimilarity =
          sheetColumnDataModelOptionSimilarities[i];

        if (!sheetColumnDataModelOptionSimilarity.isNull()) {
          const existSheetColumnDataModelOptionSimilarities =
            sheetColumnDataModelOptionSimilaritiesHashMap[
              sheetColumnDataModelOptionSimilarity.hash()
            ];

          if (existSheetColumnDataModelOptionSimilarities) {
            hasMatchedSimilarity = true;
            if (
              sheetColumnDataModelOptionSimilarity
                .getSimilarity()
                .getSimilarity() >
              existSheetColumnDataModelOptionSimilarities
                .getSimilarity()
                .getSimilarity()
            ) {
              sheetColumnDataModelOptionSimilaritiesHashMap[
                sheetColumnDataModelOptionSimilarity.hash()
              ] = sheetColumnDataModelOptionSimilarity;
            }
          }
        }

        if (!hasMatchedSimilarity) {
          sheetColumnDataModelOptionSimilaritiesHashMap[
            sheetColumnDataModelOptionSimilarity.hash()
          ] = sheetColumnDataModelOptionSimilarity;
        }
      }
    }
  };

  getSheetColumnDataModelOptionSimilarityList = (
    mapSheetColumn?: SheetColumn
  ) => {
    const sheetColumnDataModelOptionSimilaritiesHashMap: Record<
      string,
      SheetColumnDataModelOptionSimilarity
    > = {};

    const calculateSimilarityResult = this.calculateSimilarityResult;
    const mapSheetColumnDataModelOptions: {
      sheetColumn: SheetColumn;
      dataModel: DataModel;
    }[] = [];

    for (let j = 0; j < calculateSimilarityResult.result.length; ++j) {
      const resultMatching = calculateSimilarityResult.result[j];
      const dataModelWithSheetOptions = resultMatching.choice;

      let sheetColumn: SheetColumn | undefined = undefined;

      if (mapSheetColumn) {
        sheetColumn = mapSheetColumn;
      } else {
        for (let index = 0; index < this.sheets.length; index++) {
          const column = this.sheets[index].getColumn(
            resultMatching.label,
            this.options?.isAutoMapping
          );
          if (column) {
            sheetColumn = column;
            break;
          }
        }
      }

      const dataModelWithSheetOptionsKeys = Object.keys(
        dataModelWithSheetOptions
      );
      for (let k = 0; k < dataModelWithSheetOptionsKeys.length; ++k) {
        const dataModelValue = dataModelWithSheetOptionsKeys[k];
        const dataModelWithSheetOption =
          dataModelWithSheetOptions[dataModelValue];

        const dataModel = this.dataModels.find((dataModel) => {
          let dataModelKey = dataModel.getKey();
          let dataModelValueTarget = dataModelValue;
          if (this.options?.isAutoMapping === true) {
            dataModelKey = cleanSpecialCharAndToLowercase(dataModelKey);
            dataModelValueTarget =
              cleanSpecialCharAndToLowercase(dataModelValueTarget);
          }
          return dataModelKey === dataModelValueTarget;
        });

        if (dataModel && sheetColumn) {
          mapSheetColumnDataModelOptions.push({
            sheetColumn,
            dataModel,
          });
        }

        const dataModelWithSheetOptionKeys = Object.keys(
          dataModelWithSheetOption
        );

        for (let l = 0; l < dataModelWithSheetOptionKeys.length; ++l) {
          const sheetOption = dataModelWithSheetOptionKeys[l];
          const similarities = dataModelWithSheetOption[sheetOption];
          this.generateSheetColumnDataModelOptionSimilarity({
            sheetOption,
            similarities,
            dataModel,
            sheetColumnDataModelOptionSimilaritiesHashMap,
            sheetColumn,
          });
        }
      }
    }

    const sheetColumnDataModelOptionSimilarities: SheetColumnDataModelOptionSimilarity[] =
      [];
    const keys = Object.keys(sheetColumnDataModelOptionSimilaritiesHashMap);
    for (let i = 0; i < keys.length; ++i) {
      const key = keys[i];

      if (!sheetColumnDataModelOptionSimilaritiesHashMap[key].isNull()) {
        sheetColumnDataModelOptionSimilarities.push(
          sheetColumnDataModelOptionSimilaritiesHashMap[key]
        );
      }
    }

    return new SheetColumnDataModelOptionSimilarityList({
      sheetColumnDataModelOptionSimilarities,
      mapSheetColumnDataModelOptions,
    });
  };
}

export default CalculateSimilarityMapper;
