/* istanbul ignore file */
import {
  ICleaningAssistantContext,
  ICleaningAssistantProps,
} from './index.types';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DataCleaningAIRepository from './api/CleaningAssistant.repository';
import DataCleaningAIServices from './api/CleaningAssistant.services';
import {
  CleaningStatus,
  ICleaningAssistantGroup,
  ICleaningAssistantOnRemoveRow,
  ICleaningAssistantPayload,
  ICleaningAssistantRequestDTO,
  ICleaningAssistantResponse,
  ICleaningAssistantSuggestion,
} from './api/CleaningAssistant.dto';
import { useTheme } from '../../../../../theme';
import { CUSTOM_SOURCE_CHANGE, EditRowChange } from '../../type';
import { Error as ValidatorError } from '../../../../reviewEntries/validator';
import CategoryDataModel from '../../../../dataModel/model/CategoryDataModel';
import ValidateMessageUtil from '../../../../reviewEntries/validator/ValidateMessageUtil';
import { useTranslation } from 'react-i18next';
import { DataModel } from '../../../../dataModel/model/DataModel';
import { isIntersecting } from './utils/isIntersecting';
import { DEFAULT_COUNTDOWN_VALUE, MAX_ALLOWED_ROWS } from './constants';
import Handsontable from 'handsontable';
import { updateLogs } from './utils/updateLogs';

export const useViewModel = (
  props: ICleaningAssistantProps
): ICleaningAssistantContext => {
  const { t } = useTranslation();
  const theme = useTheme();
  const initialLoaded = useRef<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState<ICleaningAssistantGroup[]>([]);
  const groupsRef = useRef<ICleaningAssistantGroup[]>([]);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState<number>(-1);
  const [selectedSuggestions, setSelectedSuggestions] = useState<
    ICleaningAssistantSuggestion[]
  >([]);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const cleaningAssistantLogsRef = props.cleaningAssistantLogsRef;
  const setTotalCleanings = props.setTotalCleanings;
  const errorMessageMapRef = useRef<Record<string, string>>({});
  const dataModelsRef = useRef(props.dataModelRegistry.getDataModels());
  const deleteRowsBulkRef = useRef<ICleaningAssistantSuggestion[]>([]);
  const initialContentTransform = useRef<string>('');
  const [showRowLimitMessage, setShowRowLimitMessage] =
    useState<boolean>(false);
  const [isApplying, setIsApplying] = useState<boolean>(false);

  const disableRefreshTimerRef = useRef<
    ReturnType<typeof setInterval> | number | undefined
  >(undefined);
  const disableRefreshCountdownRef = useRef<number>(0);
  const [refreshDisabled, setRefreshDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (!refreshDisabled) {
      return;
    }

    if (disableRefreshCountdownRef.current > 0) {
      disableRefreshTimerRef.current = setInterval(() => {
        disableRefreshCountdownRef.current--;

        if (disableRefreshCountdownRef.current === 0) {
          setRefreshDisabled(false);
          clearInterval(disableRefreshCountdownRef.current);
        }
      }, 1000);
    } else {
      clearInterval(disableRefreshCountdownRef.current);
    }

    return () => {
      if (disableRefreshTimerRef.current !== undefined) {
        clearInterval(disableRefreshTimerRef.current);
      }
    };
  }, [refreshDisabled]);

  const preparePayload = useCallback((): ICleaningAssistantRequestDTO[] => {
    setShowRowLimitMessage(false);
    const errors: (ValidatorError | null)[][] = props.validator.getError();
    const error_data: ICleaningAssistantPayload = [];
    const clean_data = [];
    const CLEAN_ROWS_COUNT = 5;
    const hotInstance = props.hotInstance.current?.hotInstance;

    if (!hotInstance) {
      return [];
    }

    for (let row = 0; row < errors.length; row++) {
      const isEmptyErrorRow =
        errors[row] !== undefined && errors[row].every((c) => c === null);

      if (errors[row] === undefined || isEmptyErrorRow) {
        if (clean_data.length === CLEAN_ROWS_COUNT) {
          continue;
        }

        const rowData = hotInstance.getDataAtRow(row);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const parsedRowData: Record<string, any> = {};

        for (let i = 0; i < rowData.length; i++) {
          if (!dataModelsRef.current[i]) {
            continue;
          }

          const key: string = dataModelsRef.current[i].getKey();
          parsedRowData[key] = rowData[i];
        }

        clean_data.push(parsedRowData);
        continue;
      }

      const rowValues = hotInstance?.getSourceDataAtRow(row) ?? {};
      error_data.push({
        rowIndex: row,
        data: {},
      });

      const rowData = error_data[error_data.length - 1];

      for (const dm of dataModelsRef.current) {
        const key = dm.getKey();
        rowData.data[key] = {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value: rowValues[key],
        };
      }

      for (const colError of errors[row]) {
        if (!colError || colError.colIndex < 0) {
          continue;
        }

        const col = colError.colIndex;
        const key = dataModelsRef.current[col].getKey();

        const message = ValidateMessageUtil.getValidateMessage(
          t,
          colError,
          props.dataModelRegistry.getColumns(),
          props.baseColumns
        );

        const id = `${row}_${col}`;
        errorMessageMapRef.current[id] = message;

        rowData.data[key].info = [
          {
            message,
            level: 'error',
          },
        ];
      }
    }

    if (error_data.length === 0) {
      return [];
    }

    const data_model = dataModelsRef.current.map((dm) => {
      return {
        columnType: dm.getType(),
        key: dm.getKey(),
        validations: dm.getValidators().map((v) => v.serialize()),
        label: dm.getLabel(),
        description: dm.getDescription(),
        dropdownOptions: dm.isCategoryType()
          ? (dm as CategoryDataModel).getOptions().map((o) => {
              return {
                label: o.label,
                value: o.value,
                type: o.type,
              };
            })
          : [],
        outputFormat: dm.getOutputFormat(),
      };
    }) as Partial<DataModel>[];

    let processedRows = 0;

    let CHUNK_SIZE = Math.min(500, MAX_ALLOWED_ROWS);
    const chunks: ICleaningAssistantRequestDTO[] = [];

    while (error_data.length > 0) {
      const chunk = error_data.splice(0, CHUNK_SIZE);
      chunks.push({
        error_data: chunk,
        data_model,
        clean_data,
        session_id: props.sessionId,
        version: process.env.NX_SDK_VERSION_NUMBER || '',
      });
      processedRows += chunk.length;

      if (processedRows >= MAX_ALLOWED_ROWS) {
        setShowRowLimitMessage(true);
        break;
      }

      CHUNK_SIZE = Math.min(CHUNK_SIZE * 2, 2000);
    }

    return chunks;
  }, [props, t]);

  const onSelectAll = () => {
    setSelectedSuggestions(
      (selectedSuggestions: ICleaningAssistantSuggestion[]) => {
        return selectedSuggestions.length === 0
          ? groups[selectedGroupIndex]?.suggestions || []
          : [];
      }
    );
  };

  const onSelectSuggestion = (item: ICleaningAssistantSuggestion) => {
    setSelectedSuggestions(
      (selectedSuggestions: ICleaningAssistantSuggestion[]) => {
        const nextSelectedSuggestions: ICleaningAssistantSuggestion[] = [
          ...selectedSuggestions,
        ];
        const index = selectedSuggestions.findIndex(
          (suggestion: ICleaningAssistantSuggestion) =>
            item.suggestion === suggestion.suggestion
        );

        if (index >= 0) {
          nextSelectedSuggestions.splice(index, 1);
        } else {
          nextSelectedSuggestions.push(item);
        }

        return nextSelectedSuggestions;
      }
    );
  };

  const count: number = useMemo(() => {
    return groups.reduce(
      (a: number, g: ICleaningAssistantGroup) => (a += g.suggestions.length),
      0
    );
  }, [groups]);

  const onOpenPopover = useCallback(() => {
    setOpen(true);
  }, []);

  const onDismissPopover = useCallback(() => {
    setOpen(false);
  }, []);

  useLayoutEffect(() => {
    if (open && !initialContentTransform.current) {
      setTimeout(() => {
        const dropdownContent = document.querySelector<HTMLElement>(
          '.nuvo_cleaning-assistant-popover .nuvo-popover__content'
        );

        if (!dropdownContent) {
          return;
        }

        const style = window.getComputedStyle(dropdownContent);
        const matrix = new WebKitCSSMatrix(style.transform);
        initialContentTransform.current = `translate(${matrix.m41}px, ${matrix.m42}px)`;
      }, 100);
    }
  }, [open]);

  const repository = useMemo(() => {
    return new DataCleaningAIRepository(new DataCleaningAIServices());
  }, []);

  const getSuggestions = useCallback((): Promise<void> => {
    if (refreshDisabled) {
      return Promise.resolve();
    }

    const payloads: ICleaningAssistantRequestDTO[] = preparePayload();

    setShowErrorMessage(false);
    setLoading(true);

    if (!payloads || payloads.length === 0) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      return Promise.resolve();
    }

    setGroups([]);
    groupsRef.current = [];
    setSelectedGroupIndex(-1);
    setSelectedSuggestions([]);

    return new Promise((resolve) => {
      const MAX_REQUESTS = 8;
      const totalRequests = payloads.length;
      let completedRequests = 0;

      function batchedQueue(payload: ICleaningAssistantRequestDTO | undefined) {
        if (!payload) {
          return;
        }

        repository
          .getSuggestions(props.licenseKey, props.origin, payload)
          .then((response: ICleaningAssistantResponse) => {
            if (response.length > 0) {
              disableRefreshCountdownRef.current = DEFAULT_COUNTDOWN_VALUE;
              setRefreshDisabled(true);
            }

            groupsRef.current = repository.groupSuggestions(
              groupsRef.current,
              response,
              dataModelsRef.current,
              cleaningAssistantLogsRef,
              errorMessageMapRef.current
            );
            setGroups(groupsRef.current);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            completedRequests++;
            if (payloads.length > 0) {
              const payload = payloads.shift() as ICleaningAssistantRequestDTO;
              batchedQueue(payload);
            } else {
              if (completedRequests === totalRequests) {
                if (cleaningAssistantLogsRef.current) {
                  setTotalCleanings(cleaningAssistantLogsRef.current.length);
                }
                setLoading(false);
                resolve();
              }
            }
          });
      }

      for (let i = 0; i < MAX_REQUESTS; i++) {
        const payload = payloads.shift() as ICleaningAssistantRequestDTO;
        batchedQueue(payload);
      }
    });
  }, [
    cleaningAssistantLogsRef,
    preparePayload,
    props.licenseKey,
    props.origin,
    refreshDisabled,
    repository,
    setTotalCleanings,
  ]);

  const onBackClick = () => {
    setSelectedGroupIndex(-1);
    setSelectedSuggestions([]);
  };

  const onFind = useCallback(
    (suggestion: ICleaningAssistantSuggestion) => {
      const hotInstance = props.hotInstance.current?.hotInstance;

      if (!hotInstance) {
        return;
      }

      try {
        const row = hotInstance.toVisualRow(suggestion.rowIndex);
        const col = hotInstance.toVisualColumn(suggestion.colIndex);

        hotInstance?.selectCell(row, col, undefined, undefined, false, true);
        hotInstance?.scrollViewportTo({
          row,
          col,
          horizontalSnap: 'start',
          verticalSnap: 'top',
        });

        const cell = hotInstance.getCell(row, col);
        const dropdownContent = document.querySelector<HTMLElement>(
          '.nuvo_cleaning-assistant-popover .nuvo-popover__content'
        );

        if (cell && dropdownContent) {
          dropdownContent.style.transform = initialContentTransform.current;

          if (isIntersecting(dropdownContent, cell)) {
            const style = window.getComputedStyle(dropdownContent);
            const matrix = new WebKitCSSMatrix(style.transform);
            const cellRect = cell.getBoundingClientRect();
            const dropdownContentRect = dropdownContent.getBoundingClientRect();
            const translateX = Math.abs(
              matrix.m41 - (dropdownContentRect.right - cellRect.left)
            );
            dropdownContent.style.transform = `translate(${translateX}px, ${matrix.m42}px)`;
          }
        }
      } catch (err) {
        // NOTE: Do nothing.
      }
    },
    [props.hotInstance]
  );

  const onDismiss = useCallback(
    (suggestions: { id: string }[], callback?: () => void) => {
      setGroups((groups: ICleaningAssistantGroup[]) => {
        const deleteMap: Record<string, boolean> = {};

        for (let i = 0; i < suggestions.length; i++) {
          if (deleteMap[suggestions[i].id] === undefined) {
            deleteMap[suggestions[i].id] = true;
          }
        }

        setSelectedSuggestions(
          (selectedSuggestions: ICleaningAssistantSuggestion[]) => {
            return selectedSuggestions.filter(
              (s: ICleaningAssistantSuggestion) => !deleteMap[s.id]
            );
          }
        );

        const nextGroups: ICleaningAssistantGroup[] = [...groups];
        let closeGroup = false;

        for (let i = nextGroups.length - 1; i >= 0; i--) {
          const nextSuggestions = [];

          for (let j = 0; j < nextGroups[i].suggestions.length; j++) {
            const suggestion = nextGroups[i].suggestions[j];
            if (!deleteMap[suggestion.id]) {
              nextSuggestions.push(suggestion);
            }
          }

          nextGroups[i].suggestions = nextGroups[i].suggestions.filter(
            (s: ICleaningAssistantSuggestion) => !deleteMap[s.id]
          );

          if (nextGroups[i].suggestions.length === 0) {
            nextGroups.splice(i, 1);
            if (i === selectedGroupIndex) {
              closeGroup = true;
            }
          }
        }

        if (closeGroup) {
          setSelectedGroupIndex(-1);
        }

        groupsRef.current = nextGroups;
        callback && callback();
        return nextGroups;
      });
    },
    [selectedGroupIndex]
  );

  const onApply = useCallback(
    (suggestions: ICleaningAssistantSuggestion[]) => {
      const hotInstance = props.hotInstance.current?.hotInstance;

      if (!hotInstance) {
        return;
      }

      function updateInChunk(
        hotInstance: Handsontable,
        suggestion: ICleaningAssistantSuggestion
      ): Promise<void> {
        return new Promise((resolve) => {
          setTimeout(() => {
            const row = hotInstance.toVisualRow(suggestion.rowIndex);
            const col = hotInstance.toVisualColumn(suggestion.colIndex);

            if (row < 0 || col < 0) {
              return;
            }

            hotInstance?.setDataAtCell(
              row,
              col,
              suggestion.suggestion,
              CUSTOM_SOURCE_CHANGE.CLEANING_ASSISTANT
            );

            updateLogs(
              cleaningAssistantLogsRef.current,
              suggestion,
              CleaningStatus.Applied
            );

            hotInstance.render();
            resolve();
          });
        });
      }

      if (suggestions.length > 1) {
        setIsApplying(true);
      }

      const promises = suggestions.map((s) => updateInChunk(hotInstance, s));
      Promise.allSettled(promises).then(() => {
        hotInstance.render();
        setIsApplying(false);
      });
    },
    [cleaningAssistantLogsRef, props.hotInstance]
  );

  useEffect(() => {
    if (!props.cleaningAssistantEntryChangeObserverRef.current) {
      return;
    }

    const entryChangeSub =
      props.cleaningAssistantEntryChangeObserverRef.current.subscribe(
        (data: EditRowChange) => {
          if (data.actionType === 'create') {
            return;
          }

          data.sourceCols.forEach((column: string) => {
            const colIndex = dataModelsRef.current.findIndex(
              (dm) => dm.getKey() === column
            );
            const id = `${data.rowIndex}_${colIndex}`;

            for (let i = 0; i < groupsRef.current.length; i++) {
              const group: ICleaningAssistantGroup = groupsRef.current[i];
              const suggestion = group.suggestions.find(
                (s: ICleaningAssistantSuggestion) => s.id === id
              );
              const change_value = data.currentRowData[colIndex];

              updateLogs(
                cleaningAssistantLogsRef.current,
                suggestion,
                CleaningStatus.Changed,
                { change_value }
              );
            }

            const groupIndex = groupsRef.current.findIndex(
              (g: ICleaningAssistantGroup) => {
                const suggestion = g.suggestions.find(
                  (s: ICleaningAssistantSuggestion) => s.id === id
                );

                return suggestion !== undefined;
              }
            );

            if (groupIndex >= 0) {
              onDismiss([{ id }]);
            }
          });
        }
      );

    return () => {
      entryChangeSub.unsubscribe();
    };
  }, [
    cleaningAssistantLogsRef,
    onDismiss,
    props.cleaningAssistantEntryChangeObserverRef,
  ]);

  useEffect(() => {
    if (!props.cleaningAssistantRemoveRowObserverRef.current) {
      return;
    }

    const removeRowsSub =
      props.cleaningAssistantRemoveRowObserverRef.current.subscribe(
        ({ type, rows }: ICleaningAssistantOnRemoveRow) => {
          const hotInstance = props.hotInstance.current?.hotInstance;
          const groups = groupsRef.current;

          if (!hotInstance) {
            return;
          }

          // Shift `rowIndex` and `id` of every suggestion before the deletion and
          // delete suggestions on selected rows
          if (type === 'before') {
            const suggestionsToDelete: ICleaningAssistantSuggestion[] = [];

            for (let i = 0; i < groups.length; i++) {
              for (let j = 0; j < groups[i].suggestions.length; j++) {
                const s = groups[i].suggestions[j];
                let shift = 0;
                let deleteMark = '';

                for (let r = 0; r < rows.length; r++) {
                  const physicalRow = hotInstance.toPhysicalRow(rows[r]);
                  if (s.rowIndex > physicalRow) {
                    shift++;
                  } else if (s.rowIndex === physicalRow) {
                    deleteMark = 'd';
                    suggestionsToDelete.push(s);
                  }
                }

                s.rowIndex -= shift;
                s.id = `${s.rowIndex}_${s.colIndex}${deleteMark}`;
              }
            }

            deleteRowsBulkRef.current = suggestionsToDelete;
          }

          // Delete suggestions that are no longer relevant after deletion because rows shifted and
          // errors could be fixed
          if (type === 'after') {
            const suggestionsToDelete: ICleaningAssistantSuggestion[] = [];
            const errors: (ValidatorError | null)[][] =
              props.validator.getError();

            for (let i = 0; i < groups.length; i++) {
              for (let j = 0; j < groups[i].suggestions.length; j++) {
                const s = groups[i].suggestions[j];
                if (errors[s.rowIndex] && !errors[s.rowIndex][s.colIndex]) {
                  suggestionsToDelete.push(s);
                }
              }
            }

            deleteRowsBulkRef.current = [
              ...deleteRowsBulkRef.current,
              ...suggestionsToDelete,
            ];

            deleteRowsBulkRef.current.forEach(
              (suggestion: ICleaningAssistantSuggestion) => {
                updateLogs(
                  cleaningAssistantLogsRef.current,
                  suggestion,
                  CleaningStatus.Deleted
                );
              }
            );

            onDismiss(deleteRowsBulkRef.current, () => {
              deleteRowsBulkRef.current = [];
            });
          }
        }
      );

    return () => {
      removeRowsSub.unsubscribe();
    };
  }, [
    cleaningAssistantLogsRef,
    getSuggestions,
    onDismiss,
    preparePayload,
    props.cleaningAssistantRemoveRowObserverRef,
    props.hotInstance,
    props.validator,
  ]);

  return {
    ...props,
    open,
    setOpen,
    loading,
    setLoading,
    onOpenPopover,
    onDismissPopover,
    getSuggestions,
    groups,
    setGroups,
    selectedGroupIndex,
    setSelectedGroupIndex,
    initialLoaded,
    count,
    selectedSuggestions,
    setSelectedSuggestions,
    onSelectAll,
    onSelectSuggestion,
    onFind,
    onDismiss,
    onApply,
    onBackClick,
    theme,
    showErrorMessage,
    setShowErrorMessage,
    showRowLimitMessage,
    refreshDisabled,
    setRefreshDisabled,
    disableRefreshCountdownRef,
    isApplying,
  };
};
