import { ColumnAPI } from 'dataModel';
import { SheetData, Values } from '../../types';
import ContextualEngineService from './ContextualEngine.service';
import {
  IContextualEngineDeleteFileResponse,
  IContextualEngineGetFilePathResponse,
} from './ContextualEngine.dto';
import { Observable, switchMap } from 'rxjs';

export class ContextualEngineRepository {
  private contextualEngineService: ContextualEngineService;

  constructor(contextualEngineService: ContextualEngineService) {
    this.contextualEngineService = contextualEngineService;
  }

  processContextualEngine({
    data,
    licenseKey,
    columns,
    sessionId,
    abortController,
  }: {
    data: SheetData;
    licenseKey: string;
    columns: ColumnAPI[];
    sessionId: string;
    abortController: AbortController;
  }): Observable<{
    data: Values;
    fileName: string;
  }> {
    let fileName = '';
    return this.getFilePath(licenseKey, abortController)
      .pipe(
        switchMap((fileRes) => {
          fileName = fileRes.file_name;
          return this.uploadDataToFile(
            fileRes.signed_url,
            data,
            abortController
          );
        })
      )
      .pipe(
        switchMap(() =>
          this.contextualEngineService.processContextualEngine(
            licenseKey,
            {
              file_name: fileName,
              tdm: columns,
              session_id: sessionId,
            },
            abortController
          )
        )
      )
      .pipe(
        switchMap((response) => {
          return this.contextualEngineService.getProcessedData(
            response.signed_url,
            abortController
          );
        })
      );
  }

  deleteFile(
    licenseKey: string,
    fileName: string
  ): Observable<IContextualEngineDeleteFileResponse> {
    return this.contextualEngineService.deleteProcessedFile(
      licenseKey,
      fileName
    );
  }

  private getFilePath(
    licenseKey: string,
    abortController: AbortController
  ): Observable<IContextualEngineGetFilePathResponse> {
    return this.contextualEngineService.getFilePath(
      licenseKey,
      abortController
    );
  }

  private uploadDataToFile(
    presignedUrl: string,
    data: SheetData,
    abortController: AbortController
  ): Observable<unknown> {
    return this.contextualEngineService.uploadDataToFile(
      presignedUrl,
      data,
      abortController
    );
  }
}
