import { CSSInterpolation } from 'core/emotion';
import { Sheet } from 'sheetImporter';

export type SheetTableProps = {
  sheet: Sheet;
  dataSheet: { sheetName: string; filename: string; id: string };
  onSelected: (id: string) => void;
  selected: string[];
  configTheme?: {
    root?: CSSInterpolation;
    title?: CSSInterpolation;
    description?: CSSInterpolation;
    checkbox?: CSSInterpolation;
    illustration?: {
      borderColor?: string;
      headerColor?: string;
      cellColor?: string;
      outlineColor?: string;
    };
    checked?: {
      root?: CSSInterpolation;
      title?: CSSInterpolation;
      description?: CSSInterpolation;
      checkbox?: CSSInterpolation;
      illustration?: {
        borderColor?: string;
        headerColor?: string;
        cellColor?: string;
        outlineColor?: string;
      };
    };
  };
};

const useViewModel = ({ selected, id }: { selected: string[]; id: string }) => {
  const isSelected = selected.includes(id);

  return { isSelected };
};

export default useViewModel;
