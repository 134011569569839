import React from 'react';
import { ConfigureFormSection } from '../ConfigureFormSection';
import { Button, Divider, FormElement } from '@getnuvo/ui-kit';
import { TargetDataModel } from '../TargetDataModel';
import { RotateCcw } from 'lucide-react';
import { CheckboxControl } from '../controls/CheckboxControl';
import { InputControl } from '../controls/InputControl';
import { SelectControl } from '../controls/SelectControl';
import { SelectMultiControl } from '../controls/MultiSelectControl';
import {
  buttonModeOptions,
  completeImportActionOptions,
  contextualEngineModeOptions,
  inputTypeOptions,
  languageOptions,
  processingEngineOptions,
} from './options';
import { useViewModel } from './useViewModel';

export const Configuration = () => {
  const { clearMappingLoading, clearRememberedMapping } = useViewModel();

  return (
    <div className="nuvo-sdk__form-block">
      <ConfigureFormSection title="Configuration options">
        <div className="nuvo-sdk__form-row">
          <div className="nuvo-sdk__configuration-checkboxesCol">
            <CheckboxControl name="modal" label="Modal" />
            <CheckboxControl
              name="disableExcelTemplate"
              label="Disable Excel Templates"
            />
            <CheckboxControl label="Embed Upload Area" name="embedUploadArea" />
            <CheckboxControl
              label="Disable Success Modal"
              name="disableSuccessModal"
            />
            <CheckboxControl
              label="Allow Custom Columns"
              name="allowCustomColumns"
            />
            <CheckboxControl
              name="automaticHeaderDetection"
              label="Automatic Header Detection"
            />
          </div>

          <div className="nuvo-sdk__configuration-checkboxesCol">
            <CheckboxControl label="Enable Examples" name="enableExamples" />
            <CheckboxControl
              label="Allow Manual Input"
              name="allowManualInput"
            />
            <CheckboxControl
              label="Allow Custom Options"
              name="allowCustomOptions"
            />
            <CheckboxControl label="Smart Table" name="smartTable" />
            <CheckboxControl
              name="disableTemplates"
              label="Disable Templates"
            />
          </div>

          <div className="nuvo-sdk__configuration-checkboxesCol">
            <CheckboxControl label="Show probability" name="isShowSimilarity" />
            <CheckboxControl
              label="Multiple file upload"
              name="multipleFileUpload"
            />
            <CheckboxControl
              label="Only Mapped Columns"
              name="onlyMappedColumns"
            />
            <CheckboxControl
              label="Automatic Mapping"
              name="automaticMapping"
            />
            <CheckboxControl
              label="Cleaning Assistant"
              name="cleaningAssistant"
            />
          </div>
        </div>

        <div className="nuvo-sdk__form-clearPreviousMapping">
          <Button
            variant="plainPrimary"
            startIcon={<RotateCcw />}
            size="xs"
            loading={clearMappingLoading}
            spinnerPosition="start"
            onClick={clearRememberedMapping}
          >
            Clear Previous Mapping
          </Button>
        </div>
      </ConfigureFormSection>

      <Divider />

      <ConfigureFormSection title="Preferences">
        <div className="nuvo-sdk__form-limitWidth">
          <FormElement label="Title">
            <InputControl placeholder="Enter Title" name="title" />
          </FormElement>
        </div>
        <div className="nuvo-sdk__form-limitWidth">
          <FormElement label="Language">
            <SelectControl
              placeholder="Select Language"
              options={languageOptions}
              name="language"
              portal
              maxHeight="300px"
            />
          </FormElement>
        </div>
        <div className="nuvo-sdk__form-limitWidth">
          <FormElement label="Input Types">
            <SelectMultiControl
              placeholder="Select Input Types"
              options={inputTypeOptions}
              name="inputTypes"
              portal
              maxHeight="300px"
              tags={true}
            />
          </FormElement>
        </div>
        <div className="nuvo-sdk__form-limitWidth">
          <FormElement label="Complete Import Action">
            <SelectControl
              placeholder="Select Complete Import Action"
              options={completeImportActionOptions}
              name="completeImportAction"
            />
          </FormElement>
        </div>
        <div className="nuvo-sdk__form-limitWidth">
          <FormElement label="Button Mode">
            <SelectControl
              placeholder="Select Button Mode"
              options={buttonModeOptions}
              name="buttonMode"
            />
          </FormElement>
        </div>
        <div className="nuvo-sdk__form-limitWidth">
          <FormElement label="Contextual Engine">
            <SelectControl
              placeholder="Select Contextual Engine"
              options={contextualEngineModeOptions}
              name="contextualEngine"
            />
          </FormElement>
        </div>
      </ConfigureFormSection>

      <Divider />

      <ConfigureFormSection title="Others">
        <FormElement label="License Key">
          <div className="nuvo-sdk__form-row">
            <div className="nuvo-sdk__form-limitWidth">
              <InputControl placeholder="Enter License Key" name="licenseKey" />
            </div>
            <CheckboxControl label="Developer Mode" name="developerMode" />
          </div>
        </FormElement>

        <TargetDataModel />

        <FormElement label="Processor">
          <div className="nuvo-sdk__form-row">
            <div className="nuvo-sdk__form-limitWidth">
              <SelectControl
                placeholder="Select Processor"
                options={processingEngineOptions}
                name="processingEngine"
              />
            </div>
          </div>
        </FormElement>

        <FormElement label="Max Entries">
          <div className="nuvo-sdk__form-row">
            <div className="nuvo-sdk__form-limitWidth">
              <InputControl
                type="number"
                placeholder="Enter allowed number of entires"
                name="maxEntries"
              />
            </div>
          </div>
        </FormElement>

        <FormElement label="Enable Massive Error Alert">
          <div className="nuvo-sdk__form-row">
            <div className="nuvo-sdk__form-limitWidth">
              <InputControl
                type="number"
                placeholder="Enter allowed number of errors"
                name="enableMassiveErrorAlert"
              />
            </div>
          </div>
        </FormElement>
      </ConfigureFormSection>
    </div>
  );
};
