import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Message } from '../Message';
import { useTranslation } from 'react-i18next';
import { CleaningAssistantContext } from '../../context';

export const Timer = () => {
  const { t } = useTranslation();
  const { disableRefreshCountdownRef } = useContext(CleaningAssistantContext);

  const [counter, setCounter] = useState<number>(() => {
    return disableRefreshCountdownRef.current;
  });
  const timerRef = useRef<ReturnType<typeof setInterval> | number | undefined>(
    undefined
  );

  useEffect(() => {
    if (counter > 0) {
      timerRef.current = setInterval(() => {
        setCounter((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }

    return () => {
      if (timerRef.current !== undefined) {
        clearInterval(timerRef.current);
      }
    };
  }, [counter]);

  const formatTime = useCallback((time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`;
  }, []);

  return (
    <Message
      text={`${t('txt_cleanings_available_in')} ${formatTime(counter)}`}
    />
  );
};
