import { createContext, ReactNode, useContext } from 'react';
import useLocalStorage from '../Preview/useLocalStorage';
import {
  CompleteImportType,
  LanguageType,
  ProcessorType,
  InputType,
  ContextualEngineType,
} from 'lib-nuvo-react';
import { ITargetDataModel } from '../Preview/Configure/types';

export enum DataModelType {
  TEST_VERSION = 'test_version',
  CRM = 'crm',
}

export type ButtonModeType = 'import' | 'edit' | 'both';

export type PreviewConfig = {
  targetDataModel?: string;
  modal?: boolean;
  automaticHeaderDetection?: boolean;
  title?: string;
  disableTemplates?: boolean;
  allowInvalidEntries?: boolean;
  style?: string;
  maxEntries?: number;
  enableMassiveErrorAlert?: number;
  enableExamples?: boolean;
  isShowSimilarity?: boolean;
  ignoreReuseMappingColumns?: boolean;
  embedUploadArea?: boolean;
  licenseKey?: string;
  developerMode?: boolean;
  columnHook?: string;
  entryInitHook?: string;
  entryChangeHook?: string;
  resultHook?: string;
  cancelHook?: string;
  targetDataModelId?: string;
  multipleFileUpload?: boolean;
  disableSuccessModal?: boolean;
  allowManualInput?: boolean;
  onlyMappedColumns?: boolean;
  completeImportAction?: CompleteImportType;
  allowCustomColumns?: boolean;
  allowCustomOptions?: boolean;
  disableExcelTemplate?: boolean;
  automaticMapping?: boolean;
  preloadData?: string;
  buttonMode?: ButtonModeType;
  inputTypes?: InputType[];
  dataHandler?: string;
  language?: LanguageType;
  processingEngine?: ProcessorType;
  enableShowSimilarity?: boolean;
  smartTable?: boolean;
  cleaningAssistant?: boolean;
  contextualEngine?: ContextualEngineType;
};

type PreviewConfigProps = {
  configure: PreviewConfig;
  setConfigure: (configure: PreviewConfig) => void;
  removeConfigure: () => void;
  dataModels: ITargetDataModel[];
  setDataModels: (value: ITargetDataModel[]) => void;
};

const PreviewConfigContext = createContext<PreviewConfigProps>(
  {} as PreviewConfigProps
);

export const usePreviewConfig = () => {
  return useContext(PreviewConfigContext);
};

type PreviewConfigProviderProps = {
  children: ReactNode;
};

export const defaultValue: PreviewConfig = {
  allowCustomColumns: false,
  allowCustomOptions: false,
  allowManualInput: false,
  automaticHeaderDetection: false,
  automaticMapping: false,
  developerMode: false,
  disableExcelTemplate: false,
  disableSuccessModal: false,
  disableTemplates: false,
  embedUploadArea: false,
  enableExamples: false,
  enableShowSimilarity: false,
  modal: false,
  multipleFileUpload: false,
  onlyMappedColumns: false,
  title: 'Upload',
  language: 'en',
  completeImportAction: 'discard' as CompleteImportType,
  buttonMode: 'import' as ButtonModeType,
  processingEngine: 'default' as ProcessorType,
  maxEntries: 0,
  enableMassiveErrorAlert: 0,
  ignoreReuseMappingColumns: false,
  licenseKey: '',
  targetDataModel: DataModelType.TEST_VERSION,
  columnHook: '',
  entryInitHook: '',
  entryChangeHook: '',
  resultHook: '',
  cancelHook: '',
  preloadData: '',
  dataHandler: '',
  style: '',
  inputTypes: ['csv', 'tsv', 'xls', 'xlsx', 'json', 'xml'],
  smartTable: false,
  cleaningAssistant: false,
  contextualEngine: 'disabled',
};

export const PreviewConfigProvider = ({
  children,
}: PreviewConfigProviderProps) => {
  const [configure = {}, setConfigure, remove] = useLocalStorage<PreviewConfig>(
    'configure',
    defaultValue
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataModels, setDataModels] = useLocalStorage<any[]>(
    'target_data_models',
    []
  );

  return (
    <PreviewConfigContext.Provider
      value={{
        configure,
        dataModels: dataModels ?? [],
        setConfigure,
        removeConfigure: remove,
        setDataModels,
      }}
    >
      {children}
    </PreviewConfigContext.Provider>
  );
};
