import React, { useContext } from 'react';
import { ICleaningAssistantContext } from '../../index.types';
import { CleaningAssistantContext } from '../../context';
import styles from './index.style';
import { ICleaningAssistantSuggestion } from '../../api/CleaningAssistant.dto';
import Suggestion from '../Suggestion';
import Checkbox from '../../../../ContextMenu/components/Checkbox';

const SuggestionList = () => {
  const context = useContext<ICleaningAssistantContext>(
    CleaningAssistantContext
  );
  const s = styles(context);
  const { groups, selectedGroupIndex, selectedSuggestions, onSelectAll } =
    context;

  const selectedGroup = groups[selectedGroupIndex];
  const suggestions = selectedGroup?.suggestions || [];

  const suggestionsJSX = suggestions.map((s: ICleaningAssistantSuggestion) => {
    return <Suggestion key={s.id} suggestion={s} />;
  });

  return (
    <div className={s.rootClass}>
      <header className={s.headerClass}>
        <Checkbox
          indeterminate={
            selectedSuggestions.length > 0 &&
            selectedSuggestions.length !== suggestions.length
          }
          checked={
            selectedSuggestions.length > 0 &&
            selectedSuggestions.length === suggestions.length
          }
          onChecked={onSelectAll}
          className={s.headerCheckboxClass}
        />
        <span className={s.headerCheckboxLabelClass}>
          {selectedGroup?.columnLabel}
        </span>
      </header>
      {suggestionsJSX}
    </div>
  );
};

export default SuggestionList;
