import { css, cx } from '../../../../../../../core/emotion';
import { ICleaningAssistantContext } from '../../index.types';

export default (context: ICleaningAssistantContext) => {
  const cleaningAssistantTheme = context.theme.getCleaningAssistantTheme();

  const rootClass = cx([
    'bg-blue-light-50',
    css`
      display: flex;
      gap: 8px;
      padding: 8px 12px;
      max-width: 320px;
    `,
    css({
      '&&': css(cleaningAssistantTheme?.message?.root),
    }),
  ]);

  const iconClass = cx([
    'text-blue-light-500 text-xsmall',
    css`
      flex-shrink: 0;
    `,
    css({
      '&&': css(cleaningAssistantTheme.message?.icon),
    }),
  ]);

  const textClass = cx([
    'text-blue-light-500 text-small',
    css({
      '&&': css(cleaningAssistantTheme.message?.text),
    }),
    css({
      '&&': {
        textAlign: 'left',
      },
    }),
  ]);

  return {
    rootClass,
    iconClass,
    textClass,
  };
};
