import { isMobile, isTablet } from 'react-device-detect';
import Popover from '../../Popover';
import Message from '../../Popover/Message';
import { useState, useMemo, useRef, MouseEvent } from 'react';
import { ReactComponent as InfoBlackIcon } from '../../../assets/icon/info-icon-black.svg';
import { Boundary } from '@popperjs/core';
import { css, cx } from '../../../core/emotion';

const maxStyle = css`
  text-align: left;
  max-width: 320px;
`;

const overflowCheckOffset = {
  top: 10,
  left: 0,
  right: 0,
  bottom: 0,
};

const PopoverInfo = ({
  description,
  configTheme,
  boundary,
  flip,
}: {
  description: string;
  configTheme?: string;
  boundary?: Boundary;
  flip?: boolean;
}) => {
  const [isShow, setIsShow] = useState(false);
  const isContentHover = useRef(false);

  const getHandleHelpInfoEvent = (isIconHover: boolean) => {
    if (!isMobile && !isTablet) {
      return {
        onMouseEnter: () => {
          if (!isIconHover) isContentHover.current = true;
          setIsShow(true);
        },
        onMouseLeave: () => {
          if (isIconHover) {
            setTimeout(() => {
              if (isContentHover.current) return;
              setIsShow(false);
            }, 200);
          } else {
            isContentHover.current = false;
            setIsShow(false);
          }
        },
        onMouseDown: (event: MouseEvent) => {
          event?.stopPropagation();
        },
        onClick: (event: MouseEvent) => {
          event?.stopPropagation();
        },
      };
    } else {
      return {
        onClick: () => {
          setIsShow(true);
        },
        onMouseLeave: () => {
          setIsShow(false);
        },
      };
    }
  };

  const iconStyled = useMemo(() => {
    return configTheme;
  }, [configTheme]);

  return (
    <Popover
      flip={flip}
      isShow={isShow}
      offset={[0, 8]}
      direction="top-start"
      boundary={boundary}
      overflowCheckOffset={overflowCheckOffset}
      message={
        <Message
          className={maxStyle}
          variant="info"
          {...getHandleHelpInfoEvent(false)}
        >
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Message>
      }
      className={`${isShow ? 'visible' : 'invisible'} !pointer-events-auto`}
      render={({ referenceElement }) => {
        return (
          <span
            ref={referenceElement}
            className={cx(
              'text-gray-260 hover:text-gray-970 flex-shrink-0',
              iconStyled
            )}
            {...getHandleHelpInfoEvent(true)}
          >
            <InfoBlackIcon className={cx('h-4 w-4 rotate-180 transform')} />
          </span>
        );
      }}
    />
  );
};

export default PopoverInfo;
