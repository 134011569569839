import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DATATYPE } from '../../core/dataType';
import useValidationSchema from '../../core/form/useValidationSchema';

export const createNewColumnModalId = 'nuvo-create-new-column-modal';

export type FormValues = {
  columnName: string;
  columnType: DATATYPE;
  dateFormat?: string;
};

type UseViewModelProps = {
  isOpen: boolean;
  columnLabels: string[];
  onSubmit: (forValues: FormValues) => void;
  close: () => void;
  type: 'edit' | 'add';
  initialValues: FormValues;
};

const useViewModel = ({
  isOpen,
  columnLabels,
  onSubmit,
  close,
  type,
  initialValues,
}: UseViewModelProps) => {
  const { t } = useTranslation();
  const [hasSubmit, setHasSubmit] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
      setHasSubmit(false);
    }
  }, [isOpen]);

  const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      if (isOpen) {
        setModalElement(
          (document.getElementById(createNewColumnModalId) as HTMLDivElement) ??
            null
        );
      } else {
        setModalElement(null);
      }
    }, 0);
  }, [isOpen]);

  const dataModelOptions = useMemo(() => {
    return [
      {
        label: t('txt_data_type_string'),
        value: DATATYPE.STRING,
      },
      {
        label: t('txt_data_type_int'),
        value: DATATYPE.INTEGER,
      },
      {
        label: t('txt_data_type_float'),
        value: DATATYPE.FLOAT,
      },
      {
        label: t('txt_data_type_boolean'),
        value: DATATYPE.BOOLEAN,
      },
      {
        label: t('txt_data_type_bic'),
        value: DATATYPE.BIC,
      },
      {
        label: t('txt_data_type_currency_eur'),
        value: DATATYPE.CURRENCY_EUR,
      },
      {
        label: t('txt_data_type_currency_usd'),
        value: DATATYPE.CURRENCY_USD,
      },
      {
        label: t('txt_data_type_date'),
        value: DATATYPE.DATE,
      },
      {
        label: t('txt_data_type_email'),
        value: DATATYPE.EMAIL,
      },
      {
        label: t('txt_data_type_vat_eu'),
        value: DATATYPE.VAT_EU,
      },
      {
        label: t('txt_data_type_gtin'),
        value: DATATYPE.GTIN,
      },
      {
        label: t('txt_data_type_iban'),
        value: DATATYPE.IBAN,
      },
      {
        label: t('txt_data_type_percentage'),
        value: DATATYPE.PERCENTAGE,
      },
      {
        label: t('txt_data_type_phone'),
        value: DATATYPE.PHONE,
      },
      {
        label: t('txt_data_type_url'),
        value: DATATYPE.URL,
      },
      {
        label: t('txt_data_type_url_https'),
        value: DATATYPE.URL_HTTPS,
      },
      {
        label: t('txt_data_type_url_www'),
        value: DATATYPE.URL_WWW,
        info: 'txt_column_url_www_info',
      },
      {
        label: t('txt_data_type_url_zip_code'),
        value: DATATYPE.ZIP_CODE_DE,
      },
    ];
  }, [t]);

  const dateFormatOptions = useMemo(() => {
    return [
      {
        label: t('txt_date_format_dd_mm_yyyy'),
        value: 'DD.MM.YYYY',
      },
      {
        label: t('txt_date_format_mm_dd_yyyy'),
        value: 'MM.DD.YYYY',
      },
      {
        label: t('txt_date_format_mm_dd_yyyy_slash'),
        value: 'MM/DD/YYYY',
      },
      {
        label: t('txt_date_format_iso'),
        value: 'YYYY-MM-DD',
      },
      {
        label: t('txt_date_format_sql'),
        value: 'YYYY-MM-DD HH:mm:ss',
      },
      {
        label: t('txt_date_format_iso_8061_timestamp'),
        value: 'YYYY-MM-DDTHH:mm:ssZ',
      },
      {
        label: t('txt_date_format_unix_timestamp'),
        value: 'X',
      },
      {
        label: t('txt_date_format_time_12hr'),
        value: 'hh:mm',
      },
      {
        label: t('txt_date_format_time_24hr'),
        value: 'HH:mm',
      },
      {
        label: t('txt_date_format_time_12hr_ss'),
        value: 'hh:mm:ss',
      },
      {
        label: t('txt_date_format_time_24hr_ss'),
        value: 'HH:mm:ss',
      },
    ];
  }, [t]);

  const validateSchema = useMemo(() => {
    return yup.object({
      columnName: yup
        .string()
        .required(t('txt_require_field'))
        .test(
          'duplicate_label',
          t('txt_field_invalid_dup_column_label'),
          (value?: string) => {
            return columnLabels.every((item) => {
              if (
                type === 'edit' &&
                item.trim() === initialValues.columnName.trim()
              ) {
                return true;
              } else {
                return item.trim() !== value?.trim();
              }
            });
          }
        ),
      columnType: yup.string().required(t('txt_require_field')),
      dateFormat: yup.string().when('columnType', {
        is: DATATYPE.DATE,
        then: yup.string().required(t('txt_require_field')),
      }),
    });
  }, [t, columnLabels, type, initialValues]);

  const validate = useValidationSchema(validateSchema);

  const handleSubmit = (values: FormValues) => {
    onSubmit(values);
    setHasSubmit(true);
    close();
  };

  return {
    dataModelOptions,
    dateFormatOptions,
    modalElement,
    validate,
    hasSubmit,
    inputRef,
    handleSubmit,
  };
};

export default useViewModel;
