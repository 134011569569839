import { FC } from 'react';
import { Checkbox, ICheckboxProps } from '@getnuvo/ui-kit';
import { Controller, useFormContext } from 'react-hook-form';

interface IProps extends ICheckboxProps {
  name: string;
}

export const CheckboxControl: FC<IProps> = (props: IProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, value } }) => (
        <Checkbox {...props} checked={value} onChange={onChange} ref={null} />
      )}
    />
  );
};
