import { DataModelSheetMatch } from '../DataModelSheetMatching';

export const parseMatchingToJSON = (
  dataModelSheetMatch: DataModelSheetMatch[]
) => {
  const values = [];
  for (let i = 0; i < dataModelSheetMatch.length; ++i) {
    const item = dataModelSheetMatch[i];
    const sheetColumn = item.sheetColumn.toJSON();
    const matchedDataModel = item.matchedDataModel;
    values.push({
      sheetColumn: sheetColumn,
      matchedDataModel: matchedDataModel,
    });
  }
  return values;
};
