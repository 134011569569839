import { isNil, escapeRegExp } from 'lodash';
import { DATATYPE } from '../dataType';
import { NumberParser } from '../utils/NumberParser';
import { NumberFormat } from '../dataModel/columnsAPI';
import { ISearchParams } from './DataModelSheetForm/TopAction/SearchAndReplace';
import ValueParser from './valueParser/ValueParser';
import { FieldValue } from '../value';

export const formatCurrency = (type: DATATYPE, rowValue: string | number) => {
  const value = NumberParser.convertToUsWithDecimal(
    `${rowValue}`,
    type === DATATYPE.CURRENCY_USD ? NumberFormat.US : NumberFormat.EU
  );

  if (type === DATATYPE.CURRENCY_USD) {
    return `$ ${value}`;
  }

  return `${value} €`;
};

export const formatStringPercentage = (valueString: number) => {
  return `${valueString * 100} %`.replace('.', ',');
};

export const thousandFormat = (value: string | number) => {
  return Number(value).toLocaleString('de-DE');
};

export const isMatchSearchByValue = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  searchParams: ISearchParams
) => {
  const { value, isExact, columns } = searchParams;
  const parsedData = ValueParser.parseRawValueToDisplayValue(data, columns[0]);

  if (isExact && !isNil(data) && parsedData === value) {
    return true;
  } else if (!isExact) {
    if (
      !isNil(data) &&
      parsedData?.toLowerCase()?.includes(value?.toLowerCase())
    ) {
      return true;
    }
  }
  return false;
};

export const replaceWord = (from: string, replace: string, to: string) => {
  const regex = new RegExp(escapeRegExp(replace), 'gi');
  return `${from}`.replace(regex, to);
};

export const clearLineBreak = (value: FieldValue): FieldValue => {
  return typeof value === 'string'
    ? value.replace(/(\r\n|\n|\r)/gm, '')
    : value;
};
