import { css, cx } from '../../../../../../../core/emotion';
import { ICleaningAssistantContext } from '../../index.types';

export default (context: ICleaningAssistantContext) => {
  const cleaningAssistantTheme = context.theme.getCleaningAssistantTheme();

  const rootClass = cx([
    'bg-white border-gray-120 hover:border-blue-light-300 hover:bg-blue-light-50',
    css`
      height: 40px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      gap: 8px;
      border: 1px solid;
      border-radius: 4px;
      cursor: pointer;
    `,
    css({
      '&&': css(cleaningAssistantTheme.cleaningGroup?.root),
    }),
  ]);

  const nameClass = cx([
    'text-small neutral-gray font-semibold',
    css({
      '&&': css(cleaningAssistantTheme.cleaningGroup?.name),
    }),
  ]);

  const dividerClass = cx([
    'bg-gray-120',
    css`
      width: 1px;
      height: 16px;
    `,
    css({
      '&&': css(cleaningAssistantTheme.cleaningGroup?.divider),
    }),
  ]);

  const countClass = cx([
    'text-small text-blue-light-500',
    css({
      '&&': cx([
        css({
          color: context.theme.getGlobalTheme().getPrimaryColor(),
        }),
        css(cleaningAssistantTheme.cleaningGroup?.count),
      ]),
    }),
  ]);

  const iconClass = cx([
    'text-gray-560',
    css`
      margin-inline-start: auto;
      cursor: pointer;
    `,
    css({
      '&&': css(cleaningAssistantTheme.cleaningGroup?.icon),
    }),
  ]);

  return {
    rootClass,
    nameClass,
    dividerClass,
    countClass,
    iconClass,
  };
};
