import { validateEmptyObjectStyle } from '../../../../../../../core/style';
import { css, CSSObject, cx } from '../../../../../../../core/emotion';
import { ICleaningAssistantContext } from '../../index.types';

export default (context: ICleaningAssistantContext) => {
  const cleaningAssistantTheme = context.theme.getCleaningAssistantTheme();
  const cleaningAssistantThemeToggleRoot =
    cleaningAssistantTheme.toggle?.root ?? {};

  const cleaningAssistantThemeToggleIcon = cleaningAssistantTheme.toggle
    ?.icon as CSSObject;

  const isDisabledSparkle =
    cleaningAssistantThemeToggleIcon?.display === 'none' ||
    cleaningAssistantThemeToggleIcon?.opacity === '0' ||
    (
      cleaningAssistantThemeToggleIcon?.background ||
      cleaningAssistantThemeToggleIcon?.backgroundImage
    )
      ?.toString()
      .match('url');

  const rootClass = cx([
    validateEmptyObjectStyle(cleaningAssistantThemeToggleRoot)
      ? css({
          '&&': css(cleaningAssistantThemeToggleRoot),
        })
      : 'bg-blue-light-900 hover:bg-blue-light-800',
    css`
      && {
        margin-inline-end: 8px;
        box-sizing: border-box;
        height: 40px;
        padding: 0 20px 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        cursor: pointer;
        outline: none;
        user-select: none;
      }

      && > span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: static;
        gap: 8px;
      }
    `,
  ]);

  const sparkleClassA = cx(
    isDisabledSparkle && 'hidden',
    css`
      position: absolute;
      top: 13px;
      left: 31px;
      color: #ffc700;
    `,
    css({
      '&&': css(cleaningAssistantTheme.toggle?.sparkleA),
    })
  );

  const sparkleClassB = cx(
    isDisabledSparkle && 'hidden',
    css`
      position: absolute;
      top: 8px;
      right: 8px;
      color: #ffc700;
    `,
    css({
      '&&': css(cleaningAssistantTheme.toggle?.sparkleB),
    })
  );

  const iconClass = cx([
    css({
      '&&': css(cleaningAssistantTheme.toggle?.icon),
    }),
  ]);

  const badgeClass = cx([
    'bg-red-510 text-white text-small',
    css`
      position: absolute;
      top: -10px;
      right: -4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      min-width: 24px;
      height: 20px;
      border-radius: 16px;
    `,
    css({
      '&&': css(cleaningAssistantTheme.toggle?.badge),
    }),
  ]);

  return {
    rootClass,
    sparkleClassA,
    sparkleClassB,
    iconClass,
    badgeClass,
  };
};
