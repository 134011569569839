import { isEmpty, isNil } from 'lodash';
import {
  DynamicStepValues,
  InputType,
  IParseFile,
  IParseSheet,
  ParseFiles,
  UploadData,
} from '../../types';
import NuvoSessionListener from './NuvoSessionListener';
import {
  ParserWorker,
  parseSingleFileToSheets,
} from '../../sheetImporter/fileUtils';
import Tracking from '../../tracking/Tracking';
import { singleSheetTypes, supportedTypes } from 'core/constants/file';
import { getFileName } from 'core/file';
import {
  IDataFile,
  IMultipleSheetData,
  IParseOption,
  ISheetData,
} from '../../modules/nuvo.parser.worker';
import { wrap, releaseProxy } from 'comlink';
import { createWorker } from '../worker/createWorker';
import buildWorker from '../../worker/importWorkerBundle.txt';
import {
  ERROR_CODE_IMPORT_FILE,
  ERROR_CODE_PARSE_FILE,
  ERROR_CODE_SESSION_PARSE,
} from '../../errors/errorCode';

export const DATA_EMPTY = [[]];
const DEFAULT_IDENTIFIER = '';

export enum DynamicStep {
  HEADER = 'header',
  MAPPING = 'mapping',
  REVIEW = 'review',
  SHEET_SELECTION = 'sheetSelection',
}

export type UploadOptions = {
  step: DynamicStepValues;
  headerIndex?: number;
  data: UploadData;
};

class NuvoSession {
  private nuvoSessionListener: NuvoSessionListener;
  private dataStack: Record<string, UploadOptions> = {};

  constructor(nuvoSessionListener: NuvoSessionListener) {
    this.nuvoSessionListener = nuvoSessionListener;
    // NOTE: to solve stale data for hot-reload only development
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((module as any)?.hot && process.env.NODE_ENV !== 'production') {
        const callback = (status: string) => {
          if (status === 'prepare') {
            this.dataStack = {};
          }
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (module as any)?.hot?.addStatusHandler(callback);
      }
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn('nuvo: ', 'Not found module hot reload');
      }
    }
  }

  private parseRejectedData = (files: IDataFile[]) => {
    const parsedFiles: IParseFile[] = [];
    for (let i = 0; i < files.length; ++i) {
      const file = files[i];
      parsedFiles.push({
        fileName: file.filename,
        sheets: [],
      });
    }
    return parsedFiles;
  };

  private parseData = (files: IDataFile[]) => {
    const parsedFiles: IParseFile[] = [];
    for (let i = 0; i < files.length; ++i) {
      const file = files[i];
      const parsedSheets: IParseSheet[] = [];
      if (singleSheetTypes.includes(file.type)) {
        parsedSheets.push({
          sheetName: getFileName(file.filename),
          data: file.data as ISheetData,
        });
      } else {
        const sheets = file.data as IMultipleSheetData[];
        for (let j = 0; j < sheets.length; ++j) {
          const sheet = sheets[j];
          parsedSheets.push({
            sheetName: sheet?.sheetName ?? '',
            data: sheet?.data ?? [],
          });
        }
      }
      parsedFiles.push({
        fileName: file.filename,
        sheets: parsedSheets,
      });
    }
    return parsedFiles;
  };

  private throwError = (
    code:
      | ERROR_CODE_SESSION_PARSE
      | ERROR_CODE_IMPORT_FILE
      | ERROR_CODE_PARSE_FILE,
    sessionKey?: string
  ) => {
    const messages: Partial<
      Record<
        | ERROR_CODE_SESSION_PARSE
        | ERROR_CODE_IMPORT_FILE
        | ERROR_CODE_PARSE_FILE,
        string
      >
    > = {
      [ERROR_CODE_SESSION_PARSE.NOT_VERIFIED]: 'The component is not verified.',
      [ERROR_CODE_SESSION_PARSE.NO_COMPONENT_FOUND]: 'No component found.',
      [ERROR_CODE_IMPORT_FILE.NOT_SUPPORT]:
        'This feature or this file type are not included in your subscription.',
    };
    if (sessionKey) {
      Tracking.getInstance().setParseSession();
    }

    throw new Error(
      `code: ${
        messages[code] ? code : ERROR_CODE_PARSE_FILE.UNKNOWN
      }, message: ${messages[code] ?? 'Unexpected error'}`
    );
  };

  private parseFiles = async (inputFiles: File[], options: IParseOption) => {
    const worker = createWorker(buildWorker);
    const workerInterface = wrap<ParserWorker>(worker);
    const acceptedFiles: IDataFile[] = [];
    const rejectedFiles: IDataFile[] = [];

    await Promise.all(
      inputFiles.map(async (targetFile) => {
        return parseSingleFileToSheets(targetFile, workerInterface, options)
          .then((result) => {
            acceptedFiles.push(result);
          })
          .catch(() => {
            rejectedFiles.push({
              data: [],
              filename: targetFile.name,
              fileSize: targetFile.size,
              type: targetFile.type,
            });
          });
      })
    ).finally(() => {
      workerInterface[releaseProxy]();
      worker.terminate();
    });

    return { acceptedFiles, rejectedFiles };
  };

  upload = (options: UploadOptions, identifier?: string) => {
    this.dataStack[identifier ?? DEFAULT_IDENTIFIER] = options;
  };

  start = (identifier?: string) => {
    let sessionKey = DEFAULT_IDENTIFIER;

    if (
      identifier &&
      this.nuvoSessionListener.listenStartCallbackStack[identifier]
    ) {
      sessionKey = identifier;
    } else {
      const callbackStackKey = Object.keys(
        this.nuvoSessionListener.listenStartCallbackStack
      );
      sessionKey = callbackStackKey[callbackStackKey.length - 1];
    }

    let targetData = this.dataStack?.[sessionKey];

    if (isNil(identifier) || isEmpty(identifier)) {
      targetData = this.dataStack?.[DEFAULT_IDENTIFIER];
    }

    this.nuvoSessionListener.listenStartCallbackStack[sessionKey]?.({
      data: targetData?.data,
      headerIndex: targetData?.headerIndex,
      step: targetData?.step as DynamicStep,
    });
  };

  parse = async (files: ParseFiles, identifier?: string) => {
    let sessionKey = DEFAULT_IDENTIFIER;
    if (
      identifier &&
      this.nuvoSessionListener.listenStartCallbackStack[identifier]
    ) {
      sessionKey = identifier;
    } else {
      const callbackStackKey = Object.keys(
        this.nuvoSessionListener.listenStartCallbackStack
      );
      sessionKey = callbackStackKey[callbackStackKey.length - 1];
    }

    const componentData =
      this.nuvoSessionListener.getConfigCallbackStack[sessionKey]?.();
    const isDynamicImportSupport =
      componentData?.featureWhiteList.isDynamicImport();
    const inputFileTypes =
      componentData?.featureWhiteList.getInputTypes() ?? [];

    if (!componentData) {
      return this.throwError(
        ERROR_CODE_SESSION_PARSE.NO_COMPONENT_FOUND,
        sessionKey
      );
    }

    if (!componentData?.isAuth) {
      return this.throwError(ERROR_CODE_SESSION_PARSE.NOT_VERIFIED, sessionKey);
    }

    if (!isDynamicImportSupport) {
      return this.throwError(ERROR_CODE_IMPORT_FILE.NOT_SUPPORT, sessionKey);
    }

    const acceptedFiles = [];
    const rejectedFiles = [];
    const inputFiles = Array.from(files);
    for (let i = 0; i < inputFiles.length; ++i) {
      const file = inputFiles[i];
      const fileType = (
        Object.keys(supportedTypes).find((key) => {
          return (
            supportedTypes[key as keyof typeof supportedTypes] === file.type
          );
        }) ?? file.type
      )?.replace('_text', '');

      if (inputFileTypes.includes(fileType as InputType)) {
        acceptedFiles.push(file);
      } else {
        rejectedFiles.push(file);
      }
    }

    try {
      const result = await this.parseFiles(acceptedFiles, {
        licenseKey: componentData.licenseKey,
        framework: Tracking.getInstance().getFrameWork(),
        sdkVersion: Tracking.getInstance().getVersionNumber(),
        hasDateType: componentData.columnAPIMapper.hasDateType(),
        originRequest: Tracking.getInstance().getOrigin(),
      });
      Tracking.getInstance().setParseSession();
      return {
        accepted: this.parseData(result?.acceptedFiles ?? []),
        rejected: this.parseRejectedData([
          ...(result?.rejectedFiles ?? []),
          ...rejectedFiles.map((file) => {
            return {
              filename: file.name,
              fileSize: file.size,
              data: [],
              type: file.type,
            };
          }),
        ]),
      };
    } catch (e: unknown) {
      return this.throwError(
        (e as { code: ERROR_CODE_PARSE_FILE })?.code,
        sessionKey
      );
    }
  };
}

export default NuvoSession;
