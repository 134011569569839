import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ICleaningAssistantContext } from '../../index.types';
import { CleaningAssistantContext } from '../../context';
import styles from './index.style';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, RotateCcw, X, XCircle } from 'lucide-react';
import LoadingState from '../LoadingState';
import EmptyState from '../EmptyState';
import CleaningGroupList from '../CleaningGroupList';
import SuggestionList from '../SuggestionList';
import Button from '../../../../../../../components/Button';
import { Message } from '../Message';
import { MAX_ALLOWED_ROWS } from '../../constants';
import { Timer } from '../Timer';
import Popover from '../../../../../../../components/Popover';
import { cx } from '../../../../../../../core/emotion';
import { COLOR } from '../../../../../../../core/constants/colors';

const Dropdown = () => {
  const { t } = useTranslation();
  const context = useContext<ICleaningAssistantContext>(
    CleaningAssistantContext
  );
  const {
    loading,
    groups,
    getSuggestions,
    onDismissPopover,
    selectedGroupIndex,
    onBackClick,
    initialLoaded,
    count,
    onDismiss,
    onApply,
    selectedSuggestions,
    showRowLimitMessage,
    refreshDisabled,
    isApplying,
  } = context;
  const selectedGroup = groups[selectedGroupIndex];
  const s = styles(context);
  const [isShowPopover, setIsShowPopover] = useState(false);

  useEffect(() => {
    if (initialLoaded.current) {
      return;
    }
    initialLoaded.current = true;

    getSuggestions();
  }, [getSuggestions, initialLoaded]);

  const handleDismiss = useCallback(() => {
    onDismiss(selectedSuggestions);
  }, [onDismiss, selectedSuggestions]);

  const handleApply = useCallback(() => {
    onApply(selectedSuggestions);
  }, [onApply, selectedSuggestions]);

  const showMessageContainer =
    !loading &&
    groups.length > 0 &&
    !selectedGroup &&
    (showRowLimitMessage || refreshDisabled);

  const isSelectedDisabledColumns = useMemo(() => {
    return Boolean(selectedSuggestions.find((e) => e.isDisabled));
  }, [selectedSuggestions]);

  const getOnHover = () => {
    return {
      onMouseEnter: () => setIsShowPopover(true),
      onMouseLeave: () => setIsShowPopover(false),
    };
  };

  return (
    <div className={s.rootClass}>
      <header className={s.headerClass}>
        {selectedGroup ? (
          <button className={s.headerBackClass} onClick={onBackClick}>
            <ArrowLeft size={16} />
            {t('txt_back')}
          </button>
        ) : (
          <>
            <p className={s.headerTextClass}>{t('txt_cleaning_assistant')}</p>
            {groups.length > 0 && (
              <div className={s.headerTagClass}>
                {t('txt_cleanings', { count })}
              </div>
            )}
          </>
        )}
        <div className={s.headerActionsClass}>
          {loading ? (
            <div className={s.headerActionRepeatSpinnerClass}>
              <div className={s.headerActionRepeatSpinnerInnerClass} />
            </div>
          ) : (
            <RotateCcw
              className={s.headerActionRepeatClass}
              onClick={getSuggestions}
              data-testid="refresh-button"
            />
          )}
          <X
            className={s.headerActionCloseClass}
            onClick={onDismissPopover}
            data-testid="dismiss-button"
          />
        </div>
      </header>
      {showMessageContainer && (
        <div className={s.messageContainerClass}>
          {showRowLimitMessage && (
            <Message
              text={t('txt_cleanings_row_limit', { n: MAX_ALLOWED_ROWS })}
            />
          )}
          {refreshDisabled && <Timer />}
        </div>
      )}

      <div className={s.bodyClass}>
        {groups.length === 0 ? (
          loading ? (
            <LoadingState />
          ) : (
            <EmptyState />
          )
        ) : context.showErrorMessage ? (
          <EmptyState
            icon={<XCircle className="text-salmon-500" />}
            title={t('txt_get_cleanings_fail_title')}
            message={t('txt_get_cleanings_fail_message')}
          />
        ) : selectedGroup ? (
          <SuggestionList />
        ) : (
          <CleaningGroupList />
        )}
      </div>

      {selectedGroup && (
        <footer className={s.footerClass}>
          <span className={s.footerSelectedClass}>
            {t('txt_count_selected', { count: selectedSuggestions.length })}
          </span>
          <div className={s.footerActionsClass}>
            <button
              className={s.footerDismissClass}
              disabled={selectedSuggestions.length === 0 || isApplying}
              onClick={handleDismiss}
            >
              {t('txt_dismiss')}
            </button>
            <Popover
              message={
                <div
                  className={cx(
                    'text-small rounded-medium max-w-251 -ml-px whitespace-pre-line bg-gray-700 p-3 text-center text-white'
                  )}
                >
                  {t('txt_unable_replace_disabled_cell_value')}
                </div>
              }
              offset={[16, 8]}
              arrowClassName={COLOR.DARK_GRAY}
              flip
              isShowArrow
              render={({ referenceElement }) => (
                <span ref={referenceElement} {...getOnHover()}>
                  <Button
                    className={s.footerApplyClass}
                    disabled={
                      selectedSuggestions.length === 0 ||
                      isSelectedDisabledColumns
                    }
                    onClick={handleApply}
                  >
                    {isApplying ? (
                      <div className={s.footerApplySpinnerClass}>
                        <div className={s.footerApplySpinnerInnerClass} />
                      </div>
                    ) : (
                      t('txt_apply')
                    )}
                  </Button>
                </span>
              )}
              isShow={isShowPopover && isSelectedDisabledColumns}
            />
          </div>
        </footer>
      )}
    </div>
  );
};

export default Dropdown;
