import { MutableRefObject, RefObject, useEffect, useState } from 'react';
import { VIEW_ACTION } from './ToggleViewButton';
import CheckboxController from '../checkbox/CheckboxController';
import { ConfirmModalProps } from '../confirmModal';
import { useTranslation } from 'react-i18next';
import AllColumnSetting from '../columns/AllColumnSetting';
import ModeViewTable, {
  ModeViewTableState,
} from '../ModeViewTable/ModeViewTable';
import DataModelRegistry from '../DataModelRegistry';
import { DataModel } from '../../../dataModel/model/DataModel';
import { Subject } from 'rxjs';
import { ICleaningAssistantOnRemoveRow } from './CleaningAssistant/api/CleaningAssistant.dto';
import { HotTableClass } from '@handsontable/react';

const useViewModel = ({
  errorCount,
  disableExportHasMoreDataRef,
  changeModeView,
  checkboxController,
  onRemove,
  onDuplicate,
  showConfirmModal,
  allColumnSetting,
  modeViewTable,
  dataModelRegistry,
  cleaningAssistantRemoveRowObserverRef,
  hotInstance,
}: {
  errorCount: MutableRefObject<number>;
  disableExportHasMoreDataRef: MutableRefObject<boolean>;
  changeModeView: (showOnlyError: boolean) => void;
  checkboxController?: CheckboxController;
  onRemove: (rows: number[]) => void;
  onDuplicate: (rows: number[]) => void;
  showConfirmModal: (props: ConfirmModalProps) => void;
  allColumnSetting: AllColumnSetting;
  modeViewTable: ModeViewTable;
  dataModelRegistry: DataModelRegistry;
  cleaningAssistantRemoveRowObserverRef: RefObject<
    Subject<ICleaningAssistantOnRemoveRow>
  >;
  hotInstance: RefObject<HotTableClass>;
}) => {
  const [errorCountNumber, setErrorCountNumber] = useState(errorCount.current);
  const [isShowOnlyError, setIsShowOnlyError] = useState(false);
  const [disableExportHasMoreData, setDisableExportHasMoreData] =
    useState(false);
  const isErrorCount = errorCountNumber === 0;
  const [selectedCount, setSelectedCount] = useState(0);
  const { t } = useTranslation();
  const [isLoadingDuplicate, setIsLoadingDuplicate] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [dataModels, setDataModels] = useState<DataModel[]>(
    dataModelRegistry.getDataModels()
  );

  useEffect(() => {
    const subscription = dataModelRegistry
      .dataModelObservable()
      .subscribe(() => {
        setDataModels([...dataModelRegistry.getDataModels()]);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [dataModelRegistry]);

  useEffect(() => {
    const interval = setInterval(() => {
      setErrorCountNumber(errorCount.current);
      setDisableExportHasMoreData(disableExportHasMoreDataRef.current);
    }, 150);

    return () => {
      clearTimeout(interval);
    };
  }, [errorCount, disableExportHasMoreDataRef]);

  useEffect(() => {
    const subscription = allColumnSetting
      .getFilterStrategy()
      .filterObservable()
      .subscribe(() => {
        setFilterCount(allColumnSetting.filterCount());
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [allColumnSetting]);

  useEffect(() => {
    const subscription = modeViewTable
      .modeViewTableObservable()
      .subscribe(() => {
        setIsShowOnlyError(
          modeViewTable.getViewState() === ModeViewTableState.ERROR
        );
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [modeViewTable]);

  useEffect(() => {
    if (checkboxController) {
      const subscription = checkboxController
        .checkBoxObservable()
        .subscribe(() => {
          const checkedCount = checkboxController.getCheckedCount();
          setSelectedCount(checkedCount);
        });

      return () => {
        subscription.unsubscribe();
      };
    } else {
      return () => {};
    }
  }, [checkboxController]);

  const onToggleViewChange = (action: VIEW_ACTION) => {
    if (action === VIEW_ACTION.SHOW_ALL) {
      changeModeView(false);
      setIsShowOnlyError(false);
    } else {
      changeModeView(true);
      setIsShowOnlyError(true);
    }
    checkboxController?.setCheckAll(false);
  };

  const onDeleteClick = () => {
    if (checkboxController) {
      const removeRows = checkboxController
        .getCheckedState()
        .getCheckedRows(true);
      if (removeRows.length > 1) {
        showConfirmModal({
          title: t('txt_confirm_title'),
          description: t('txt_confirm_delete_rows', {
            selectedRow: removeRows.length,
          }),
          onClickNegativeButtonPromise: () => {
            return new Promise<void>((resolve) => {
              setTimeout(() => {
                cleaningAssistantRemoveRowObserverRef.current?.next({
                  type: 'before',
                  rows: removeRows,
                });
                onRemove(removeRows);
                cleaningAssistantRemoveRowObserverRef.current?.next({
                  type: 'after',
                  rows: removeRows,
                });
                resolve();
              }, 100);
            });
          },
          onClickNegativeButton: () => {},
          onClickPositiveButton: () => {},
          isShowIcon: true,
          isShowCloseIcon: false,
          disabledClickOutside: true,
          textNegativeButton: t('txt_delete_all'),
          textPositiveButton: t('txt_cancel'),
        });
      } else {
        cleaningAssistantRemoveRowObserverRef.current?.next({
          type: 'before',
          rows: removeRows,
        });
        onRemove(removeRows);
        cleaningAssistantRemoveRowObserverRef.current?.next({
          type: 'after',
          rows: removeRows,
        });
      }
    }
  };

  const onDuplicateClick = () => {
    setIsLoadingDuplicate(true);
    if (checkboxController) {
      setTimeout(() => {
        onDuplicate(checkboxController.getCheckedState().getCheckedRows(true));
        setIsLoadingDuplicate(false);
      }, 0);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      hotInstance.current?.hotInstance?.scrollViewportTo(0);
    }, 0);
  }, [isShowOnlyError, hotInstance]);

  return {
    isErrorCount,
    errorCountNumber,
    setIsShowOnlyError,
    isShowOnlyError,
    disableExportHasMoreData,
    onToggleViewChange,
    selectedCount,
    onDeleteClick,
    onDuplicateClick,
    isLoadingDuplicate,
    filterCount,
    dataModels,
  };
};

export default useViewModel;
