import {
  JoinSheetColumn,
  SHEET_COLUMN_TYPE,
  SheetColumn,
} from '@nuvo-importer/common/sdk';
import JoinSheetBadge from './JoinSheetBadge';
import SheetInfo from './SheetInfo';
import { useTheme } from '@nuvo-importer/common';

type ISheetColumnInfoProps = {
  sheetColumn: SheetColumn;
};

const SheetColumnInfo = ({ sheetColumn }: ISheetColumnInfoProps) => {
  const theme = useTheme();
  return sheetColumn.getType() === SHEET_COLUMN_TYPE.JOIN ? (
    <JoinSheetBadge
      joinPopoverTheme={theme.getColumnMatchTheme().columnPopover}
      sheetColumn={sheetColumn as JoinSheetColumn}
      customTheme={theme.getColumnMatchTheme().joinBadge}
    />
  ) : sheetColumn.getSheet().getSheetFileName() ||
    sheetColumn.getSheet().getName() ? (
    <SheetInfo
      joinPopoverTheme={theme.getColumnMatchTheme().columnPopover}
      sheetColumn={sheetColumn}
      customTheme={theme.getColumnMatchTheme().sheetInfo}
    />
  ) : null;
};

export default SheetColumnInfo;
