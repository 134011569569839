import { css, cx, useTheme } from '@nuvo-importer/common';
import { Sheet } from '@nuvo-importer/common/sdk';

const RemoveSheetContent = ({
  removedDepSheets,
}: {
  removedDepSheets: Sheet[];
}) => {
  const theme = useTheme();

  return (
    <div
      className={cx(
        'mb-4 space-y-3 bg-gray-50 px-3 py-4 text-left',
        css({
          '&&': theme.getJoinSheetTheme().removedSheet?.root,
        })
      )}
    >
      {removedDepSheets.map((removedDepSheet) => {
        return (
          <div>
            <span
              className={cx(
                'text-neutral-gray text-sm',
                css({
                  '&&': theme.getJoinSheetTheme().removedSheet?.sheetName,
                })
              )}
            >
              {removedDepSheet.getName()}
            </span>{' '}
          </div>
        );
      })}
    </div>
  );
};

export default RemoveSheetContent;
