import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ICleaningAssistantContext } from '../../index.types';
import { CleaningAssistantContext } from '../../context';
import styles from './index.style';
import { ICleaningAssistantSuggestion } from '../../api/CleaningAssistant.dto';
import Checkbox from '../../../../ContextMenu/components/Checkbox';
import { Check, Locate, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../../components/Button';
import Popover from '../../../../../../../components/Popover';
import { cx } from '../../../../../../../core/emotion';
import { COLOR } from '../../../../../../../core/constants/colors';

interface IProps {
  suggestion: ICleaningAssistantSuggestion;
}

const Suggestion: React.FC<IProps> = ({ suggestion }: IProps) => {
  const { t } = useTranslation();
  const context = useContext<ICleaningAssistantContext>(
    CleaningAssistantContext
  );
  const {
    selectedSuggestions,
    onSelectSuggestion,
    onFind,
    onDismiss,
    onApply,
    isApplying,
  } = context;
  const [isShowPopover, setIsShowPopover] = useState(false);

  const selected: boolean = useMemo(() => {
    return !!selectedSuggestions.find(
      (s: ICleaningAssistantSuggestion) =>
        s.suggestion === suggestion.suggestion
    );
  }, [selectedSuggestions, suggestion.suggestion]);

  const s = styles(context);

  const onSelect = useCallback(() => {
    onSelectSuggestion(suggestion);
  }, [onSelectSuggestion, suggestion]);

  const handleFind = useCallback(() => {
    onFind(suggestion);
  }, [onFind, suggestion]);

  const handleDismiss = useCallback(() => {
    if (isApplying) {
      return;
    }
    onDismiss([suggestion]);
  }, [onDismiss, suggestion, isApplying]);

  const handleApply = useCallback(() => {
    if (isApplying) {
      return;
    }
    onApply([suggestion]);
  }, [onApply, suggestion, isApplying]);

  const suggestionValue = suggestion.suggestion
    ? suggestion.suggestion.toString().trim()
    : '';

  const getOnHover = () => {
    return {
      onMouseEnter: () => setIsShowPopover(true),
      onMouseLeave: () => setIsShowPopover(false),
    };
  };

  return (
    <div className={s.rootClass}>
      <Checkbox
        checked={selected}
        onChecked={onSelect}
        className={s.checkboxClass}
      />
      <div className={s.detailsClass}>
        <div className={s.newValue}>{suggestionValue}</div>
        <div className={s.oldValue}>
          {suggestion.value || (
            <span className={s.oldValueEmpty}>{t('txt_empty')}</span>
          )}
        </div>
      </div>

      <div className={s.actionsClass}>
        <button
          type="button"
          className={s.findButtonClass}
          onClick={handleFind}
        >
          <Locate size={16} />
        </button>
        <button
          type="button"
          className={s.dismissButtonClass}
          onClick={handleDismiss}
        >
          <X size={16} />
        </button>
        {suggestion.isDisabled ? (
          <Popover
            message={
              <div
                className={cx(
                  'text-small rounded-medium max-w-251 -ml-px whitespace-pre-line bg-gray-700 p-3 text-center text-white'
                )}
              >
                {t('txt_unable_replace_disabled_cell_value')}
              </div>
            }
            offset={[16, 8]}
            arrowClassName={COLOR.DARK_GRAY}
            flip
            isShowArrow
            render={({ referenceElement }) => (
              <span ref={referenceElement} {...getOnHover()}>
                <Button
                  type="button"
                  className={s.applyButtonDisabledClass}
                  onClick={handleApply}
                  disabled
                  aria-label={t('txt_unable_replace_disabled_cell_value')}
                >
                  <Check size={16} />
                </Button>
              </span>
            )}
            isShow={isShowPopover}
          />
        ) : (
          <Button
            type="button"
            className={s.applyButtonClass}
            onClick={handleApply}
          >
            <Check size={16} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Suggestion;
