import { useCallback } from 'react';
import Sheet from './../../../sheetImporter/Sheet';

const useViewModel = ({
  sheet,
  hasMoreAllSheet,
  goToSheet,
  isCurrentOrAfter,
}: {
  sheet: Sheet;
  hasMoreAllSheet: boolean;
  goToSheet: (sheet: Sheet) => void;
  isCurrentOrAfter: boolean;
}) => {
  const sheetName = sheet.getName();
  const fileName = sheet.getSpreadSheet().getFilename();

  const isClickAble = hasMoreAllSheet && !isCurrentOrAfter;

  const onClick = useCallback(() => {
    if (isClickAble) {
      goToSheet(sheet);
    }
  }, [isClickAble, sheet, goToSheet]);

  return {
    sheetName,
    fileName,
    onClick,
    isClickAble,
  };
};

export default useViewModel;
