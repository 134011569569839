import { DataModel } from '../../dataModel/model/DataModel';
import { isBoolean, isEmpty, isNumber, isUndefined } from 'lodash';
import {
  CurrencyValue,
  ErrorValue,
  ErrorValues,
  FieldValue,
  Values,
} from '../../value';
import ValueResultParser from '../valueResultParser/valueResultParser';
import { RecordInfo } from '../../reviewEntries/type';
import { Error } from '../../reviewEntries/validator';
import CellError from '../CellError';
import { ColumnAPI } from '../../dataModel/columnsAPI';
import { TFunction } from 'i18next';
import ValidateMessageUtil from '../../reviewEntries/validator/ValidateMessageUtil';
import { DataModelSheetMatch } from '../../matching/DataModelSheetMatching';
import { CleaningLogsRecord } from '../DataModelSheetForm/TopAction/CleaningAssistant/api/CleaningAssistant.dto';

class DataModelSheet {
  private values: Values;
  private dataModels: DataModel[];
  private baseColumns: ColumnAPI[];
  private errors: (Error | null)[][] = [];
  private dataInfos: Record<string, RecordInfo[]>;
  private removeRows?: number[];
  private identifier: string;
  private translate: TFunction;
  private columns: ColumnAPI[];
  private matchedColumns: DataModelSheetMatch[];
  private cleaningAssistantLogs: CleaningLogsRecord[] | null;

  constructor({
    values,
    dataModels,
    baseColumns,
    errors,
    dataInfos,
    removeRows,
    identifier,
    translate,
    columns,
    matchedColumns,
    cleaningAssistantLogs,
  }: {
    values: Values;
    dataModels: DataModel[];
    baseColumns: ColumnAPI[];
    errors: (Error | null)[][];
    dataInfos: Record<string, RecordInfo[]>;
    removeRows?: number[];
    identifier: string;
    translate: TFunction;
    columns: ColumnAPI[];
    matchedColumns: DataModelSheetMatch[];
    cleaningAssistantLogs: CleaningLogsRecord[] | null;
  }) {
    this.values = values;
    this.errors = errors;
    this.dataInfos = dataInfos;
    this.removeRows = removeRows;
    this.identifier = identifier;
    this.baseColumns = baseColumns;
    this.translate = translate;
    this.dataModels = dataModels;
    this.columns = columns;
    this.matchedColumns = matchedColumns;
    this.cleaningAssistantLogs = cleaningAssistantLogs;
  }

  setValues = (values: Values) => {
    this.values = values;
  };

  getColumnByKey = (key: string) => {
    return this.dataModels.find((column) => column.getKey() === key);
  };

  getDataModels = () => {
    return this.dataModels;
  };

  getMatchedColumns = (): DataModelSheetMatch[] => {
    return this.matchedColumns;
  };

  private isRowValid = (row: number) => {
    const hasErrorCol = (this.errors[row] ?? [])?.filter((item) => !!item);
    const hasErrorInfo = this.dataInfos[row]?.filter(
      (item) => item.popover.level === 'error' && item.colIndex > -1
    );
    const hasError =
      (hasErrorCol?.length ?? 0) > 0 || (hasErrorInfo?.length ?? 0) > 0;

    return !hasError;
  };

  private getRow = (index: number, nullDefault: boolean) => {
    const parsedValues: Record<string, FieldValue | CurrencyValue> = {};

    this.getDataModels().forEach((value) => {
      const columnKey = value.getKey();
      const column = this.getColumnByKey(columnKey);

      if (column) {
        const value = this.values[index][column.getKey()];

        if (!isUndefined(value)) {
          parsedValues[columnKey] = ValueResultParser.parse(column, value);
        } else if (nullDefault) {
          parsedValues[columnKey] = null;
        }
      }
    });

    return parsedValues;
  };

  private isRowEmpty(data: Record<string, FieldValue | CurrencyValue>) {
    return Object.keys(data).every(
      (key) =>
        data?.[key] === null ||
        data?.[key] === undefined ||
        (isEmpty(data?.[key]) &&
          !isNumber(data?.[key]) &&
          !isBoolean(data?.[key]))
    );
  }

  private isRowRemove = (index: number) => {
    if (this.removeRows) {
      return this.removeRows.includes(index);
    } else {
      return false;
    }
  };

  getValidValues = () => {
    const parsedValues = [];
    for (let i = 0; i < this.values.length; i++) {
      const data = this.getRow(i, true);
      if (
        !this.isRowRemove(i) &&
        this.isRowValid(i) &&
        !this.isRowEmpty(data)
      ) {
        parsedValues.push(data);
      }
    }
    return parsedValues;
  };

  getAllValuesAsResults = () => {
    const parsedValues = [];
    for (let i = 0; i < this.values.length; i++) {
      const data = this.getRow(i, true);
      if (!this.isRowRemove(i) && !this.isRowEmpty(data)) {
        parsedValues.push(data);
      }
    }
    return parsedValues;
  };

  getAllErrorsAsResults = () => {
    const errors: ErrorValues = [];
    const visibleDataModels = this.dataModels.filter(
      (dataModel) => !dataModel.isHidden()
    );
    const visibleColumns = this.columns.filter((column) => !column.hidden);

    for (let rowIndex = 0; rowIndex < this.values.length; rowIndex++) {
      const data = this.getRow(rowIndex, true);

      if (!this.isRowEmpty(data) && !this.isRowValid(rowIndex)) {
        const errorRow: Record<string, ErrorValue> = {};

        for (
          let columnIndex = 0;
          columnIndex < visibleDataModels.length;
          columnIndex++
        ) {
          const columnKey = visibleDataModels[columnIndex].getKey();
          const cleaningFunctionsErrors = (this.dataInfos[rowIndex] ?? [])
            .filter(
              (item) =>
                item.colIndex === columnIndex && item.popover.level === 'error'
            )
            .map((item) => {
              return {
                message: item.popover.message,
                level: item.popover.level,
              };
            });

          const tdmValidationErrors = (
            (this.errors[rowIndex] ?? []).filter(
              (item) => item && item.colIndex === columnIndex
            ) as Error[]
          ).map((item) => {
            const errorMessage = ValidateMessageUtil.getValidateMessage(
              this.translate,
              {
                validate: item.validate,
                colIndex: columnIndex,
                validateMsg: item?.validateMsg,
              },
              visibleColumns,
              this.baseColumns
            );
            return {
              level: 'error',
              message: errorMessage,
            };
          });

          if (cleaningFunctionsErrors.length || tdmValidationErrors.length) {
            errorRow[columnKey] = {
              value: data[columnKey],
              info: [...cleaningFunctionsErrors, ...tdmValidationErrors],
            };
          } else {
            errorRow[columnKey] = {
              value: data[columnKey],
            };
          }
        }
        errors.push(errorRow);
      }
    }

    return errors;
  };

  getRawValues = () => {
    return this.values;
  };

  getError = () => {
    return this.errors;
  };

  getErrorAsChunkResult = () => {
    const errors = [];
    const visibleDataModels = this.dataModels.filter(
      (dataModel) => !dataModel.isHidden()
    );
    for (let i = 0; i < this.errors.length; ++i) {
      const data = this.getRow(i, true);
      if (!this.isRowRemove(i) && !this.isRowEmpty(data)) {
        const errorRow = [];
        for (let j = 0; j < visibleDataModels.length; j++) {
          errorRow.push(this.errors?.[i]?.[j] ?? null);
        }

        errors.push(errorRow);
      }
    }

    return errors;
  };

  getDataInfo() {
    return this.dataInfos;
  }

  getAllValuesAsChunkResult = () => {
    const dataInfos = this.getDataInfo();
    const visibleDataModels = this.getDataModels().filter(
      (dataModel) => !dataModel.isHidden()
    );
    const data: Record<string, FieldValue | CurrencyValue | CellError>[] = [
      ...this.getAllValuesAsResults(),
    ];
    for (let i = 0; i < data.length; ++i) {
      const element = dataInfos[i];
      for (let j = 0; j < element?.length ?? 0; ++j) {
        const formulaError = element[j];
        if (formulaError) {
          const key = visibleDataModels[formulaError.colIndex].getKey();
          data[i][key] = new CellError({
            rowIndex: i,
            errorMessage: formulaError.popover.message,
          });
        }
      }
    }
    return data;
  };

  getIdentifier = () => {
    return this.identifier;
  };

  getBaseColumns = (): ColumnAPI[] => {
    return this.baseColumns;
  };

  getCleaningAssistantLogs = (): CleaningLogsRecord[] | null => {
    return this.cleaningAssistantLogs;
  };
}

export default DataModelSheet;
