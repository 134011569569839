import { DataModel } from '../../../../dataModel/model/DataModel';
import CategoryDataModel from '../../../../dataModel/model/CategoryDataModel';
import { booleanDropdownOptions } from 'core/constants/boolean';
import { useMemo } from 'react';
import { DATATYPE } from 'core/dataType';
import DataModelSheetMatcher from '../../../../matching/DataModelSheetMatcher';
import { SheetColumn } from '../../../../sheetImporter';
import { SheetColumnDataModelOptionSimilarity } from '@nuvo-importer/common/sdk';

type UseViewModelProps = {
  prefixName: string;
  dataModel: DataModel;
  dataModelSheetMatcher: DataModelSheetMatcher;
  sheetColumn: SheetColumn;
};

const useViewModel = ({
  prefixName,
  dataModel,
  dataModelSheetMatcher,
  sheetColumn,
}: UseViewModelProps) => {
  const matchOptionSelectorName = `${prefixName}.matchedDataModel.matchedOptions`;

  const baseMatcherOptions = useMemo(() => {
    const options =
      dataModel.getType() === DATATYPE.BOOLEAN
        ? booleanDropdownOptions()
        : (dataModel as CategoryDataModel).getOptions();

    const sheetColumnDataModelBySheetColumnSimilarities = dataModelSheetMatcher
      .getSheetColumnDataModelOptionSimilarityList()
      .getSimilaritiesDataModelBySheetColumn(sheetColumn, dataModel);

    const hashMap: Record<string, SheetColumnDataModelOptionSimilarity[]> = {};

    for (
      let i = 0;
      i < sheetColumnDataModelBySheetColumnSimilarities.length;
      ++i
    ) {
      if (
        hashMap[
          sheetColumnDataModelBySheetColumnSimilarities[i].getDataModelOption()
            .option
        ]
      ) {
        hashMap[
          sheetColumnDataModelBySheetColumnSimilarities[i].getDataModelOption()
            .option
        ].push(sheetColumnDataModelBySheetColumnSimilarities[i]);
      } else {
        hashMap[
          sheetColumnDataModelBySheetColumnSimilarities[
            i
          ].getDataModelOption().option
        ] = [sheetColumnDataModelBySheetColumnSimilarities[i]];
      }
    }

    return options.map((option) => {
      const similarityOptions = hashMap[option.value] ?? [];
      const lookupSimilarityOptions: Record<
        string,
        SheetColumnDataModelOptionSimilarity
      > = {};
      for (let i = 0; i < similarityOptions.length; ++i) {
        const item = similarityOptions[i];
        lookupSimilarityOptions[`${item.getSheetColumnOption().option}`] = item;
      }

      return {
        option,
        lookupSimilarityOptions,
      };
    });
  }, [dataModel, sheetColumn, dataModelSheetMatcher]);

  return { matchOptionSelectorName, baseMatcherOptions };
};

export default useViewModel;
