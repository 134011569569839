import { Sheet } from '@nuvo-importer/common/core';
import SimpleBar from 'simplebar-react';
import { Button, css, cx, useTheme } from '@nuvo-importer/common';
import useViewModel from './viewModel';
import Label from '../../components/Label';
import { FieldArray } from 'react-final-form-arrays';
import Row from '../../components/Row';
import ColumnDropdownField from 'baseUI/ColumnDropdownField';
import AppendColumn from './AppendColumn';
import { useTranslation } from 'react-i18next';

type FormProps = {
  handleSubmit: () => void;
  appendTargetSheet: Sheet;
  sourceSheet: Sheet;
  loading: boolean;
};

const Form = ({
  handleSubmit,
  loading,
  appendTargetSheet,
  sourceSheet,
}: FormProps) => {
  const { scrollbarRef, sheetName, modalElement, getBaseColumnOptions } =
    useViewModel({
      appendTargetSheet,
      sourceSheet,
    });
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <form
      onSubmit={handleSubmit}
      className={cx(
        'nuvo-append-sheet-list-modal-scroll flex min-h-0 flex-grow flex-col',
        css`
          .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            background-color: ${theme.getJoinSheetTheme().joinColumnsDialog
              ?.scrollbar?.navigatorColor};
          }
          .simplebar-track {
            background-color: ${theme.getJoinSheetTheme().joinColumnsDialog
              ?.scrollbar?.backgroundColor};
          }
        `
      )}
    >
      <SimpleBar
        ref={scrollbarRef}
        forceVisible={false}
        autoHide={false}
        className="min-h-0 flex-shrink flex-grow p-3 pb-0"
      >
        <FieldArray name="columns">
          {({ fields }) => {
            return (
              <div className="flex min-h-0 flex-shrink flex-grow flex-col">
                <div className="flex min-h-0 flex-col">
                  <Row showDeleteButton={false} className="mb-2">
                    <Label
                      className={css({
                        '&&': theme.getJoinSheetTheme().appendColumnsDialog
                          ?.label,
                      })}
                      asteriskClassName={css({
                        '&&': theme.getJoinSheetTheme().appendColumnsDialog
                          ?.asterisk,
                      })}
                      required={false}
                    >
                      {sheetName}
                    </Label>
                    <Label
                      className={css({
                        '&&': theme.getJoinSheetTheme().appendColumnsDialog
                          ?.label,
                      })}
                      asteriskClassName={css({
                        '&&': theme.getJoinSheetTheme().appendColumnsDialog
                          ?.asterisk,
                      })}
                    >
                      {t('txt_append_to_join_dataset')}
                    </Label>
                  </Row>
                  {fields.map((name, index) => {
                    return (
                      <Row
                        className={
                          index < (fields.length ?? 0) - 1 ? 'mb-2' : ''
                        }
                        showDeleteButton={false}
                        key={name}
                      >
                        <AppendColumn
                          name={`${name}.target`}
                          className={css({
                            '&&': theme.getJoinSheetTheme().appendColumnsDialog
                              ?.appendColumn,
                          })}
                        />
                        <ColumnDropdownField
                          name={`${name}.source`}
                          rootDropdownPortal={modalElement ?? undefined}
                          options={getBaseColumnOptions(index)}
                          menuTitle={t('txt_joined_dataset')}
                          placeholder={t('txt_select_output_col')}
                          isFieldShowError={false}
                          theme={
                            theme.getJoinSheetTheme().appendColumnsDialog
                              ?.dropdown
                          }
                          errorMessageClassName={css({
                            '&&': theme.getJoinSheetTheme().joinColumnsDialog
                              ?.errorMessage,
                          })}
                        />
                      </Row>
                    );
                  })}
                </div>
              </div>
            );
          }}
        </FieldArray>
      </SimpleBar>
      <div className="flex justify-end pt-4">
        <Button type="submit" loading={loading}>
          {t('txt_append')}
        </Button>
      </div>
    </form>
  );
};

export default Form;
