import Handsontable from 'handsontable';
import { RecordInfo } from '../../../reviewEntries/type';
import { Validator } from '../../../reviewEntries/validator';
import FilterStrategy from '../columns/FilterStrategy';
import { BehaviorSubject } from 'rxjs';
import AllColumnSetting from '../columns/AllColumnSetting';
import DataModelRegistry from '../DataModelRegistry';

export enum ModeViewTableState {
  ALL,
  ERROR,
}

class ModeViewTable {
  private validator?: Validator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private dataSet: Record<string, any>[] = [];
  private dataInfos: Record<string, RecordInfo[]> = {};
  private hotInstance?: Handsontable;
  private hiddenRows: number[] = [];
  private filtering: FilterStrategy;
  private viewState = ModeViewTableState.ALL;
  private readOnly: boolean;
  private _modeViewTableObservable: BehaviorSubject<ModeViewTable>;
  private allowCustomColumns?: boolean;
  private allColumnSetting: AllColumnSetting;
  private dataModelRegistry: DataModelRegistry;

  constructor(
    filtering: FilterStrategy,
    readOnly: boolean,
    allColumnSetting: AllColumnSetting,
    dataModelRegistry: DataModelRegistry,
    allowCustomColumns?: boolean
  ) {
    this.filtering = filtering;
    this.readOnly = readOnly;
    this._modeViewTableObservable = new BehaviorSubject<ModeViewTable>(this);
    this.allowCustomColumns = allowCustomColumns;
    this.allColumnSetting = allColumnSetting;
    this.dataModelRegistry = dataModelRegistry;
  }

  modeViewTableObservable = () => {
    return this._modeViewTableObservable;
  };

  private calculateHideRows = () => {
    const hotInstance = this.hotInstance;
    if (!hotInstance || !this.validator) {
      return [];
    }
    const validRows = this.validator.getCorrectRows(
      this.dataSet.length - 1,
      this.dataInfos,
      hotInstance
    );

    if (!this.readOnly) {
      const lastRow = hotInstance.toVisualRow(this.dataSet.length - 1);
      if (lastRow !== null) {
        validRows.push(lastRow);
      }
    }

    const hiddenByFilterRowIndexes =
      this.filtering.getFilteredHideRowIndexMaps();

    const hiddenRows = validRows.filter((rowIndex) => {
      if (hiddenByFilterRowIndexes[rowIndex]) {
        return false;
      } else {
        return true;
      }
    });

    return hiddenRows;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDataSet = (dataSet: Record<string, any>[]) => {
    this.dataSet = dataSet;
  };

  setValidator = (validator: Validator) => {
    this.validator = validator;
  };

  setDataInfos = (dataInfos: Record<string, RecordInfo[]>) => {
    this.dataInfos = dataInfos;
  };

  getDataInfo = (row: number) => {
    return this.dataInfos[row];
  };

  setHotInstance = (hotInstance: Handsontable) => {
    this.hotInstance = hotInstance;
  };

  recalculate = () => {
    if (this.viewState === ModeViewTableState.ERROR && this.hotInstance) {
      const hiddenRows = this.calculateHideRows();
      this.hiddenRows = hiddenRows;
      const hiddenPlugin = this.hotInstance.getPlugin('hiddenRows');

      if (!hiddenPlugin) {
        return;
      }

      const allRows = [];
      for (let i = 0; i < this.dataSet.length; ++i) {
        allRows[i] = i;
      }
      hiddenPlugin.showRows(allRows);
      hiddenPlugin.hideRows(hiddenRows);

      // NOTE: We unable to use  this.hotInstance.updateSettings({hiddenRows: []}) because it breaks with React 16
    }

    this.handleCustomAddColumn();
  };

  hideRows = (isEmit = false) => {
    if (!this.hotInstance || !this.validator) {
      return;
    }

    const hiddenPlugin = this.hotInstance.getPlugin('hiddenRows');

    if (!hiddenPlugin) {
      return;
    }

    const hiddenRows = this.calculateHideRows();

    hiddenPlugin.hideRows(hiddenRows);
    this.hiddenRows = hiddenRows;
    this.viewState = ModeViewTableState.ERROR;
    if (isEmit) {
      this._modeViewTableObservable.next(this);
    }

    this.handleCustomAddColumn();
  };

  showRows = (isEmit = false) => {
    if (!this.hotInstance) {
      return;
    }

    const hiddenPlugin = this.hotInstance.getPlugin('hiddenRows');

    if (!hiddenPlugin) {
      return;
    }

    hiddenPlugin.showRows(this.hiddenRows);
    this.hiddenRows = [];
    this.viewState = ModeViewTableState.ALL;
    if (isEmit) {
      this._modeViewTableObservable.next(this);
    }

    this.handleCustomAddColumn();
  };

  getHiddenRows = (recalculate = false) => {
    if (recalculate) {
      if (this.viewState === ModeViewTableState.ERROR && this.hotInstance) {
        this.hiddenRows = this.calculateHideRows();
      } else {
        this.hiddenRows = [];
      }
    }
    return this.hiddenRows;
  };

  getViewState = () => {
    return this.viewState;
  };

  handleCustomAddColumn = () => {
    if (!this.allowCustomColumns) {
      return;
    }
    if (
      this.viewState === ModeViewTableState.ERROR ||
      this.allColumnSetting.hasFilter()
    ) {
      this.hideCustomAddColumn();
    } else {
      this.showCustomAddColumn();
    }
  };

  private showCustomAddColumn = () => {
    const hideColumnsPlugin = this.hotInstance?.getPlugin('hiddenColumns');
    if (!this.allowCustomColumns || !hideColumnsPlugin || !this.hotInstance) {
      return;
    }

    const lastColumn = this.dataModelRegistry.getColumns().length;
    if (hideColumnsPlugin.isHidden(lastColumn)) {
      hideColumnsPlugin.showColumn(lastColumn);
    }
  };

  private hideCustomAddColumn = () => {
    const hideColumnsPlugin = this.hotInstance?.getPlugin('hiddenColumns');
    if (!this.allowCustomColumns || !hideColumnsPlugin || !this.hotInstance) {
      return;
    }

    const lastColumn = this.dataModelRegistry.getColumns().length;
    if (!hideColumnsPlugin.isHidden(lastColumn)) {
      hideColumnsPlugin.hideColumn(lastColumn);
    }
  };
}

export default ModeViewTable;
