import Button from '../Button';
import { css, cx } from '../../core/emotion';
import { DataModel } from '../../core/dataModel/model/DataModel';
import BackButton from '../BackButton';
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import useCollapse from './collapse';
import Column from './Column';
import ShowMoreButton from './ShowMoreButton';
import useViewModel from './viewModel';
import { createPortal } from 'react-dom';

type MatchFooterProps = {
  className?: string;
  wrapperClassName?: string;
  dataModels: DataModel[];
  matchedDataModels: DataModel[];
  isShowFieldRequired: boolean;
  rootElement?: HTMLElement | null;
  disabledAction?: boolean;
  buttonWrapperClassName?: string;
  onIsAvailableCollapseChange: (isAvailableCollapse: boolean) => void;
  onIsShowChange?: (isShow: boolean) => void;
  onBackClick?: () => void;
  onSubmitClick?: () => void;
  isDisabledSubmitButton?: boolean;
  isDisabledBackButton?: boolean;
  nextLabel?: string;
  backLabel?: string;
  hideIcon?: boolean;
  modal?: boolean;
};

export type DataModelFooterImperativeHandle = {
  setIsShowBadge: (isShow: boolean) => void;
  getIsAvailableCollapse: () => boolean;
};

const MatchFooter = forwardRef<
  DataModelFooterImperativeHandle,
  MatchFooterProps
>(
  (
    {
      className,
      wrapperClassName,
      dataModels,
      matchedDataModels,
      isShowFieldRequired,
      onIsAvailableCollapseChange,
      buttonWrapperClassName,
      onBackClick,
      onIsShowChange,
      onSubmitClick,
      isDisabledSubmitButton,
      isDisabledBackButton,
      nextLabel,
      backLabel,
      hideIcon,
      rootElement,
      modal,
    },
    forwardRef
  ) => {
    const { refWrapper, refContent, isAvailableCollapse, setIsShow, isShow } =
      useCollapse();
    const { columns, requiredCount } = useViewModel({
      dataModels,
      matchedDataModels,
    });
    const theme = useTheme();
    const { t } = useTranslation();

    useImperativeHandle(forwardRef, () => {
      return {
        setIsShowBadge: (isShow: boolean) => {
          setIsShow(isShow);
        },
        getIsAvailableCollapse: () => {
          return isAvailableCollapse;
        },
      };
    });

    useEffect(() => {
      onIsShowChange?.(isShow);
    }, [onIsShowChange, isShow]);

    useEffect(() => {
      onIsAvailableCollapseChange(isAvailableCollapse);
    }, [isAvailableCollapse, onIsAvailableCollapseChange]);

    const matchFooterElement = useMemo(
      () => (
        <div className={className}>
          <div className={cx(wrapperClassName)}>
            <div className={cx('flex w-full justify-center')}>
              {isAvailableCollapse ? (
                <ShowMoreButton
                  isShow={isShow}
                  requiredCount={requiredCount}
                  onClick={() => {
                    setIsShow((isShow) => !isShow);
                  }}
                />
              ) : null}
            </div>
            <div>
              <div
                className={cx(
                  'flex items-center justify-between py-5 px-3 lg:px-8',
                  modal ? 'rounded-b-lg' : '',
                  css({ '&&': { paddingTop: 19, minHeight: 90 } }),
                  css({ '&&': theme.getFooterTheme().root }),
                  css({
                    backgroundColor: theme
                      .getGlobalTheme()
                      .getBackgroundColor(),
                  }),
                  css`
                    box-shadow: 0px -13px 50px rgba(17, 48, 68, 0.08);
                  `
                )}
              >
                <div className="flex-grow">
                  {columns.length > 0 ? (
                    <p
                      className={cx(
                        'text-color-primary text-left text-sm font-semibold',
                        css({ '&&': theme.getRequiredColumnsTheme().title })
                      )}
                    >
                      {t('txt_require_columns')}:
                    </p>
                  ) : null}
                  <div ref={refWrapper} className="overflow-hidden">
                    <div
                      className="flex flex-wrap items-center"
                      ref={refContent}
                    >
                      {columns.map((column, index) => (
                        <Column
                          key={`${column.label}${index}`}
                          label={column.label}
                          matched={column.matched}
                          isError={isShowFieldRequired}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div
                  className={cx(
                    'flex h-full items-center justify-end space-x-4',
                    columns.length > 0 && isAvailableCollapse ? 'mt-2' : '',
                    buttonWrapperClassName
                  )}
                >
                  <BackButton
                    onClick={onBackClick}
                    disabled={isDisabledBackButton}
                    className="h-10"
                    label={backLabel}
                    hideIcon={hideIcon}
                  />
                  <Button
                    className="h-10"
                    type="submit"
                    onClick={onSubmitClick}
                    disabled={isDisabledSubmitButton}
                  >
                    {nextLabel ?? t('txt_confirm')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      [
        backLabel,
        buttonWrapperClassName,
        className,
        columns,
        hideIcon,
        isAvailableCollapse,
        isDisabledBackButton,
        isDisabledSubmitButton,
        isShow,
        isShowFieldRequired,
        modal,
        nextLabel,
        onBackClick,
        onSubmitClick,
        refContent,
        refWrapper,
        requiredCount,
        setIsShow,
        t,
        theme,
        wrapperClassName,
      ]
    );

    return rootElement && !modal
      ? createPortal(matchFooterElement, rootElement)
      : matchFooterElement;
  }
);

export default MatchFooter;
