import { ResultValue } from '../../../../../value';
import { DataModel } from '../../../../../dataModel/model/DataModel';

export interface ICleaningAssistantRequestDTO {
  error_data: ICleaningAssistantPayload;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clean_data: Record<string, any>;
  data_model: Partial<DataModel>[];

  session_id: string;
  version: string;
}

export interface ICleaningAssistantRequest {
  value: ResultValue;
  info?: ICleaningAssistantRecordInfo[];
}

export interface ICleaningAssistantRecordInfo {
  message: string;
  level: string;
}

export interface ICleaningAssistantPayloadRow {
  rowIndex: number;
  data: Record<string, ICleaningAssistantRequest>;
}

export type ICleaningAssistantPayload = ICleaningAssistantPayloadRow[];

export interface ICleaningAssistantBaseSuggestion {
  value: ResultValue;
  suggestion: string | number;
}

export interface ICleaningAssistantRowSuggestion {
  rowIndex: number;
  data: Record<string, ICleaningAssistantBaseSuggestion>;
}

export type ICleaningAssistantResponse = ICleaningAssistantRowSuggestion[];

export interface ICleaningAssistantGroup {
  column: string;
  columnLabel: string;
  suggestions: ICleaningAssistantSuggestion[];
}

export interface ICleaningAssistantSuggestion {
  id: string;
  rowIndex: number;
  colIndex: number;
  initRowIndex: number;
  initColIndex: number;
  column: string;
  value: ResultValue;
  suggestion: number | string;
  isDisabled?: boolean;
}

export type CleaningLogsRecord = {
  // id is a UI-only field
  id?: string;
  key: string;
  value: ResultValue;
  error_message: string;
  suggested_value: string | number;
  status: CleaningStatus;
};

export enum CleaningStatus {
  Applied = 'applied',
  Ignored = 'ignored',
  Deleted = 'deleted',
  Changed = 'changed',
}

export type ICleaningAssistantOnRemoveRow = {
  type: 'before' | 'after';
  rows: number[];
};
