import { CSSObject } from '@emotion/css';
import { isEmpty } from 'lodash';
import { ButtonThemeCssObjectAPI } from '../theme/themeAPI';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertHexColorToUrlSVGColor = (color?: any) => {
  if (color && typeof color === 'string') {
    return color.replace('#', '%23');
  } else {
    return color;
  }
};

export const validateBackgroundImageInCss = (css: CSSObject) => {
  if (css?.background?.toString()?.match?.('url') || css?.backgroundImage) {
    return true;
  } else {
    return false;
  }
};

export const validateEmptyObjectStyle = (css?: ButtonThemeCssObjectAPI) => {
  const tempCss = Object.assign({}, css);
  delete tempCss['icon'];

  if (isEmpty(tempCss)) {
    return undefined;
  } else {
    return css;
  }
};
