import { DATATYPE } from 'core/dataType';
import { booleanDropdownOptions } from 'core/constants/boolean';
import CategoryDataModel from '../../dataModel/model/CategoryDataModel';
import { DataModel } from '../../dataModel/model/DataModel';

export const breathing = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const allDropdownOptions = (dataModels: DataModel[]) => {
  const items: {
    label: string;
    value: string;
  }[][] = [];
  for (let i = 0; i < dataModels.length; ++i) {
    let options: { label: string; value: string }[] = [];
    if (dataModels[i].isCategoryType()) {
      for (
        let j = 0;
        j < (dataModels[i] as CategoryDataModel).getOptions().length;
        j++
      ) {
        options.push({
          label: (dataModels[i] as CategoryDataModel).getOptions()[j].label,
          value: (dataModels[i] as CategoryDataModel).getOptions()[j].value,
        });
      }
    } else if (dataModels[i].getType() === DATATYPE.BOOLEAN) {
      options = booleanDropdownOptions();
    }

    items.push(options);
  }
  return items;
};

export const findDropdownOption = (
  columnIndex: number,
  value: string,
  allDropdownOptions: {
    label: string;
    value: string;
  }[][],
  optionValueType: 'label' | 'value' | 'both'
) => {
  const options = allDropdownOptions[columnIndex];
  if (!options || options.length <= 0) return value;
  const findValue = `${value}`?.toLowerCase()?.trim();
  // NOTE: if options match both label and value, we use option that match value instead of label.
  if (optionValueType === 'both') {
    const matchedOptions = [];
    for (let i = 0; i < options.length; ++i) {
      if (options[i].value?.toLowerCase()?.trim() === findValue) {
        return options[i]['value'];
      } else if (options[i]?.label?.toLowerCase()?.trim() === findValue) {
        matchedOptions.push(options[i]['value']);
      }
    }
    return matchedOptions[0] ?? value;
  }

  for (let i = 0; i < options.length; ++i) {
    if (
      optionValueType === 'label'
        ? options[i].value?.toLowerCase()?.trim() === findValue
        : options[i].label?.toLowerCase()?.trim() === findValue
    ) {
      return options[i][optionValueType];
    }
  }
  return value;
};

export function shouldParseDateFormat(
  colType: DATATYPE | undefined,
  hasParsedDate: boolean
) {
  if (
    [
      DATATYPE.DATE_DMY,
      DATATYPE.DATE_MDY,
      DATATYPE.DATE_ISO,
      DATATYPE.DATETIME,
      DATATYPE.DATE,
    ].includes(colType || DATATYPE.STRING) &&
    !hasParsedDate
  ) {
    return true;
  }

  return false;
}
