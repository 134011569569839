import {
  CleaningLogsRecord,
  CleaningStatus,
  ICleaningAssistantSuggestion,
} from '../api/CleaningAssistant.dto';

export function updateLogs(
  logs: CleaningLogsRecord[] | null,
  suggestion: ICleaningAssistantSuggestion | undefined,
  status: CleaningStatus,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appendObject?: Record<string, any>
): void {
  if (!logs || !suggestion) {
    return;
  }

  const id = `${suggestion.initRowIndex}_${suggestion.initColIndex}`;
  const index = logs.findIndex((l: CleaningLogsRecord) => l.id === id);
  if (index >= 0) {
    logs[index].status = status;

    if (appendObject) {
      logs[index] = {
        ...logs[index],
        ...appendObject,
      };
    }
  }
}
