export const STAGE = 'dev';

export type {
  IDataFile,
  IMultipleSheetDataFile,
  IParseOption,
  ISheetDataFile,
  IMultipleSheetData,
  ISheetData,
} from '@getnuvo/parser-sdk-dev';

export {
  convertCsv2Sheet,
  convertExcel2Sheet,
  convertJson2Sheet,
  convertXLS2Sheet,
  convertXML2Sheet,
  convertPdf2Sheets,
  jsonParser,
  parseValue,
} from '@getnuvo/parser-sdk-dev';
