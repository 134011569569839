import { useLayoutEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import ColHeaderController from '../colHeaders/Controller';
import { SheetColumn, JoinSheetColumn } from '@nuvo-importer/common/core';

type IViewModelProps = {
  colHeaderController: ColHeaderController;
  sheetColumns: SheetColumn[];
};

const useViewModel = ({
  colHeaderController,
  sheetColumns,
}: IViewModelProps) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [isOpen, setIsOpen] = useState(false);
  const [joinSheetColumnPopover, setJoinSheetColumnPopover] =
    useState<JoinSheetColumn | null>(null);

  const {
    styles: currentStyles,
    attributes,
    state,
    update,
  } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 12],
        },
      },
      {
        name: 'flip',
        enabled: true,
      },
      {
        name: 'preventOverflow',
        options: {
          tether: false,
        },
      },
    ],
  });

  useLayoutEffect(() => {
    const popoverObservable = colHeaderController.getPopoverObservable();
    const subscription = popoverObservable.subscribe((event) => {
      setIsOpen(event.popoverPos !== null);
      setReferenceElement(event.referenceElement as HTMLElement);
      if (event.popoverPos !== null) {
        setJoinSheetColumnPopover(
          sheetColumns[event.popoverPos] as JoinSheetColumn
        );
      } else {
        setJoinSheetColumnPopover(null);
      }
      update?.();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [colHeaderController, update, sheetColumns]);

  const popper = {
    styles: currentStyles,
    attributes,
    state,
    setPopperElement,
  };

  return { popper, isOpen, joinSheetColumnPopover };
};

export default useViewModel;
