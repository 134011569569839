import {
  CategoryDataModelType,
  DataModel,
  DataModelCommonConstructor,
} from './DataModel';
import {
  DropdownOptionType,
  DropdownOptionValidation,
  OptionMappingMode,
} from '../columnsAPI';

export type Option = {
  label: string;
  value: string;
  type: DropdownOptionType;
  alternativeMatches: string[];
  creator?: string;
  baseKey?: string;
  baseKeyCounter?: number;
  validations?: DropdownOptionValidation[];
  optionMappingMode?: OptionMappingMode;
};

type CategoryDataModelConstructor = DataModelCommonConstructor & {
  options: Option[];
  isMultiSelection: boolean;
  allowCustomOptions?: boolean;
};

class CategoryDataModel extends DataModel {
  private options: Option[];
  private isMultiSelection: boolean;
  private allowCustomOptions: boolean;

  constructor({
    description,
    key,
    example,
    label,
    type,
    options,
    validators,
    alternativeMatches,
    columnSize,
    isMultiSelection,
    allowCustomOptions,
    creator,
    baseKey,
    baseKeyCounter,
    optionMappingMode,
    hidden,
  }: CategoryDataModelConstructor) {
    super({
      description,
      key,
      example,
      label,
      type,
      validators,
      alternativeMatches,
      columnSize,
      creator,
      baseKey,
      baseKeyCounter,
      optionMappingMode,
      hidden,
    });
    this.options = options?.map((entry) => ({
      ...entry,
      baseKey: entry?.baseKey || entry.value,
      baseKeyCounter: entry.baseKeyCounter || 0,
      alternativeMatches: entry.alternativeMatches.filter(
        (alternativeMatch) => {
          return alternativeMatch !== entry.label;
        }
      ),
      validations: entry.validations || [],
    }));
    this.isMultiSelection = isMultiSelection;
    this.allowCustomOptions = allowCustomOptions || false;
  }

  getOptions = () => {
    return this.options;
  };

  setOptions = (options: Option[]) => {
    this.options = options;
  };

  serialize = () => {
    const dataModelJSON = {
      description: this.getDescription(),
      example: this.getExample(),
      isRequired: this.getIsRequired(),
      key: this.getKey(),
      label: this.getLabel(),
      type: this.getType(),
      validators: this.getValidators().map((validator) => {
        return validator.serialize();
      }),
      alternativeMatches: this.getAlternativeMatches(),
      columnSize: this.getColumnSize(),
      outputFormat: this.getOutputFormat(),
      numberFormat: this.getNumberFormat(),
      optionMappingMode: this.getOptionMappingMode(),
      baseKey: this.getBaseKey(),
      baseKeyCounter: this.getBaseKeyCounter(),
      creator: this.getCreator(),
      hidden: this.isHidden(),
      disabled: this.isDisabled(),
    };

    const options: Option[] = this.getOptions();

    return {
      ...dataModelJSON,
      options,
      isMultiSelection: this.isMultiSelection,
      allowCustomOptions: this.allowCustomOptions,
    };
  };

  clone() {
    const dataModelJSON = this.serialize();
    return new CategoryDataModel({
      description: dataModelJSON.description,
      example: dataModelJSON.example,
      key: dataModelJSON.key,
      label: dataModelJSON.label,
      type: dataModelJSON.type as CategoryDataModelType,
      options: dataModelJSON.options!,
      validators: this.getValidators(),
      alternativeMatches: dataModelJSON.alternativeMatches,
      columnSize: dataModelJSON.columnSize,
      isMultiSelection: dataModelJSON.isMultiSelection,
      allowCustomOptions: dataModelJSON.allowCustomOptions,
      optionMappingMode: dataModelJSON.optionMappingMode,
      hidden: dataModelJSON.hidden,
      disabled: dataModelJSON.disabled,
    });
  }

  getIsMultiSelection = () => {
    return this.isMultiSelection;
  };

  getAllowCustomOptions = (): boolean => {
    return this.allowCustomOptions;
  };
}

export default CategoryDataModel;
