import {
  HEADER_SELECTION_PATH,
  JOIN_COLUMN_PATH,
  MATCH_COLUMN_PATH,
  SHEET_SELECTION_PATH,
} from './../../core/constants/route';
import { flatten } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SpreadSheetNavigate from '../../uploadData/SelectHeaderPage/SpreadSheetNavigate';
import Sheet from './../../sheetImporter/Sheet';
import SpreadSheet, {
  SpreadSheetType,
} from './../../sheetImporter/SpreadSheet';
import useUploadFilePolicy from './../../sheetImporter/uploadFilePolicy';
import SpreadSheetList from './../../sheetImporter/SpreadSheetList';
import { useSheetImporter } from './../../sheetImporter/SheetImporterProvider';
import { useDataModelSheetFormConfig } from 'dataModelSheet';
import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { useTranslation } from 'react-i18next';
import { useMainView, useSettings } from 'settings';
import { usePage } from 'main/MainView';
import { ViewTemplate } from 'core/constants/storage';
import { UPLOAD_PAGE_PATH } from 'core/constants/route';
import { useNavigatePolicy } from 'navigation';
import { MAX_SELECTED_SHEET } from 'core/constants/sheet';
import {
  getSelectingSpreadSheetIndex,
  setSelectingSpreadSheetIndex,
  setSelectingSpreadSheetPageIndex,
} from './common/utils';
import useInAppNavigate from 'core/navigate';
import { useHooks } from '../../hooks/HooksProvider';
import { useFeatureWhiteList } from '../../configure/ConfigureProvider';
import SpreadSheetSelecting from './common/SpreadSheetSelectingStorage';

const getId = (spreadSheet: SpreadSheet, sheet: Sheet, fileIndex: number) => {
  const id = `${spreadSheet.getFilename()}-${sheet.getName()}-${fileIndex}`;
  return id;
};

const mapSpreadSheetListToSheetSelector = (
  spreadSheetList: SpreadSheetList,
  isIncludeOnlySelected: boolean
) => {
  const spreadSheets = spreadSheetList.getSpreadSheets();
  const allSheets = flatten(
    spreadSheets.map((spreadSheet, fileIndex) => {
      const sheets = spreadSheet.getSheets();

      return sheets
        .filter((sheet) => {
          if (isIncludeOnlySelected) {
            return sheet.isSelected();
          } else {
            return true;
          }
        })
        .map((sheet) => {
          const id = getId(spreadSheet, sheet, fileIndex);
          return {
            id,
            sheets,
            sheet: sheet,
            filename: spreadSheet.getFilename(),
            type: spreadSheet.getType(),
            sheetName: sheet.getName(),
          };
        });
    })
  );

  return allSheets;
};

const useViewModel = () => {
  const { state: locationState } = useLocation();
  const { gridHeight, setGridHeight } = useSheetImporter();
  const { dataHandlerMapper } = useHooks();
  let oldSelectedSingleSheetWithoutModified: Sheet;

  const state = locationState as {
    spreadSheetList: SpreadSheetList;
    hasBackStep?: boolean;
    dynamicUploadStart?: string;
    oldSelectedSingleSheetWithoutModified?: Sheet;
  };

  for (let i = 0; i < state.spreadSheetList.getAllSheets().length; i++) {
    const sheet = state.spreadSheetList.getAllSheets()[i];
    if (
      sheet.getId() === state.oldSelectedSingleSheetWithoutModified?.getId()
    ) {
      state.spreadSheetList.setSheet(
        state.oldSelectedSingleSheetWithoutModified
      );
    }
  }

  const [spreadSheetListState, setSpreadSheetList] = useState({
    spreadSheetList: state.spreadSheetList,
  });

  const defaultSelectedSheet = useMemo(() => {
    if (state?.spreadSheetList) {
      return mapSpreadSheetListToSheetSelector(
        state?.spreadSheetList,
        true
      ).map((sheet) => sheet.id);
    } else {
      return [''];
    }
  }, [state]);

  const [selected, setSelected] = useState<string[]>(defaultSelectedSheet);
  const [selectedSpreadSheet, setSelectedSpreadSheet] = useState<number>(
    getSelectingSpreadSheetIndex() || 0
  );
  const navigate = useInAppNavigate();
  const { maxRecords, warnExceedMaxRecords, warnMaxSelectedSheet } =
    useDataModelSheetFormConfig();
  const { showConfirmModal } = useContextConfirmModalManager();
  const { t } = useTranslation();
  const {
    automaticHeaderDetection,
    embedUploadArea = false,
    multipleFileUpload,
  } = useSettings();
  const [viewTemplate, setSelectViewTemplate] = useState<ViewTemplate>(
    SpreadSheetSelecting.getInstance().getSheetSelectionViewMode()
  );
  const { cancel } = usePage();
  const { modal } = useMainView();
  const { checkPolicy } = useUploadFilePolicy();
  const { getHasJoinColumnPage } = useNavigatePolicy();
  const { featureWhiteList } = useFeatureWhiteList();

  const [isHasScroll, setIsHasScroll] = useState(false);
  const tabMenuMultipleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    SpreadSheetSelecting.getInstance().setSheetSelectionViewMode(viewTemplate);
  }, [viewTemplate]);

  const spreadSheets = useMemo(() => {
    return spreadSheetListState.spreadSheetList.getSpreadSheets();
  }, [spreadSheetListState]);

  const sheets = useMemo(() => {
    return mapSpreadSheetListToSheetSelector(
      spreadSheetListState.spreadSheetList,
      false
    );
  }, [spreadSheetListState]);

  const displaySheets = useMemo(() => {
    if (
      !multipleFileUpload &&
      state?.dynamicUploadStart !== SHEET_SELECTION_PATH
    ) {
      return sheets;
    }

    if (selectedSpreadSheet < 0) {
      return sheets.filter((sheet) => {
        if (selected.includes(sheet.id)) {
          return true;
        }
        return false;
      });
    }

    const maxSelectedSheetRange = spreadSheets.reduce(
      (previousVal, _currentVal, currentIndex, arr) => {
        if (currentIndex <= selectedSpreadSheet) {
          return previousVal + arr[currentIndex].getSheets().length;
        } else {
          return previousVal;
        }
      },
      0
    );

    const minSelectedSheetRange = spreadSheets.reduce(
      (previousVal, _currentVal, currentIndex, arr) => {
        if (currentIndex < selectedSpreadSheet) {
          return previousVal + arr[currentIndex].getSheets().length;
        } else {
          return previousVal;
        }
      },
      0
    );

    return sheets.filter(
      (_entry, index) =>
        index >= minSelectedSheetRange && index < maxSelectedSheetRange
    );
  }, [
    multipleFileUpload,
    state?.dynamicUploadStart,
    selectedSpreadSheet,
    spreadSheets,
    sheets,
    selected,
  ]);

  const onBackClick = () => {
    if (state?.dynamicUploadStart === SHEET_SELECTION_PATH) {
      showConfirmModal({
        isShowIcon: true,
        title: t('txt_confirm_title'),
        description: t('txt_go_back_dynamic_import_title'),
        textNegativeButton: t('txt_confirm_leave_confirm_btn'),
        textPositiveButton: t('txt_confirm_leave_cancel_btn'),
        onClickNegativeButton: () => {
          cancel();
        },
      });
    } else {
      showConfirmModal({
        isShowIcon: true,
        title: t('txt_confirm_title'),
        description: t('txt_back_page_dialog'),
        textNegativeButton: t('txt_go_back'),
        textPositiveButton: t('txt_cancel'),
        onClickNegativeButton: () => {
          if (embedUploadArea || !modal) {
            cancel();
          } else {
            cancel();
            navigate({ pathname: UPLOAD_PAGE_PATH });
          }
        },
      });
    }
  };

  const doDataHandlerTask = (
    pathname: string,
    spreadSheetNavigate: SpreadSheetNavigate
  ) => {
    dataHandlerMapper.headerStep
      .getDataModifier()({
        spreadSheets: spreadSheetNavigate
          .getSpreadSheetList()
          .getSpreadSheets(),
      })
      .then((spreadSheets) => {
        let newSpreadSheetNavigate = spreadSheetNavigate;
        if (spreadSheets?.length) {
          newSpreadSheetNavigate = new SpreadSheetNavigate({
            spreadSheetList: new SpreadSheetList({ spreadSheets }),
          });
        }
        if (automaticHeaderDetection) {
          newSpreadSheetNavigate?.getCurrentSheet()?.setHeaderByAutoDetection();
        }
        navigate(
          {
            pathname:
              pathname === JOIN_COLUMN_PATH &&
              newSpreadSheetNavigate?.getSpreadSheetList()?.getSelectedSheets()
                ?.length === 1
                ? MATCH_COLUMN_PATH
                : pathname,
          },
          {
            state: {
              spreadSheetNavigate: newSpreadSheetNavigate,
              hasBackStep: state?.hasBackStep,
              dynamicUploadStart: state?.dynamicUploadStart,
              oldSelectedSingleSheetWithoutModified,
            },
          }
        );
      })
      .catch(() => {
        showConfirmModal({
          title: t('txt_default_title_error'),
          description: `<div>
                <p className="font-bold">
               ${t('txt_matching_error_description')}
                </p>
              </div>`,
          textNegativeButton: t('txt_close'),
          isShowIcon: true,
          isShowPositiveButton: false,
          onClickNegativeButton: () => {
            cancel();
            navigate({ pathname: UPLOAD_PAGE_PATH });
          },
        });
      });
  };

  const onContinueClick = () => {
    sheets.forEach((sheet) => {
      if (selected.includes(sheet.id)) {
        sheet.sheet.setSelected(true);
      } else {
        sheet.sheet.setSelected(false);
      }
    });

    if (maxRecords) {
      const isExceedMaxRecords = spreadSheetListState.spreadSheetList
        .getAllSheets()
        .filter((sheet) => {
          return sheet.isSelected();
        })
        .find((sheet) => sheet.getValues().length > maxRecords);
      if (isExceedMaxRecords) {
        warnExceedMaxRecords();
        return;
      }
    }

    const spreadSheetNavigate = new SpreadSheetNavigate({
      spreadSheetList: spreadSheetListState.spreadSheetList,
    });

    const hasJoinColumnPage = getHasJoinColumnPage(
      spreadSheetListState.spreadSheetList
    );

    const pathname = (() => {
      if (automaticHeaderDetection) {
        if (hasJoinColumnPage) {
          return JOIN_COLUMN_PATH;
        }
        for (let i = 0; i < sheets.length; i++) {
          const sheet = sheets[i];
          if (sheet.id === selected[0]) {
            oldSelectedSingleSheetWithoutModified = new Sheet({
              data: JSON.parse(JSON.stringify(sheet.sheet.getData())),
              name: sheet.sheet.getName(),
              id: sheet.sheet.getId(),
              isNotSetColumn: true,
            });
          }
        }

        return MATCH_COLUMN_PATH;
      } else {
        return HEADER_SELECTION_PATH;
      }
    })();

    if (featureWhiteList.getDataHandler()) {
      doDataHandlerTask(pathname, spreadSheetNavigate);
    } else {
      navigate(
        {
          pathname,
        },
        {
          state: {
            spreadSheetNavigate: spreadSheetNavigate,
            hasBackStep: state?.hasBackStep,
            dynamicUploadStart: state?.dynamicUploadStart,
          },
        }
      );
    }
  };

  const onSelected = (id: string, pageIndex: number) => {
    if (selected.includes(id)) {
      const filtered = selected.filter((selectedId) => selectedId !== id);
      setSelected(filtered);
      if (!filtered.length && selectedSpreadSheet < 0) {
        setSelectedSpreadSheet(0);
      }
      if (!filtered.length) {
        setSelectingSpreadSheetIndex(selectedSpreadSheet);
        setSelectingSpreadSheetPageIndex(pageIndex);
      }
    } else {
      if (multipleFileUpload) {
        if (selected.length >= MAX_SELECTED_SHEET) {
          warnMaxSelectedSheet();
          return;
        }
        if (!selected.length) {
          setSelectingSpreadSheetIndex(selectedSpreadSheet);
          setSelectingSpreadSheetPageIndex(pageIndex);
        }
        setSelected([...selected, id]);
      } else {
        setSelected([id]);
      }
    }
  };

  const disabledContinue = selected.length <= 0;

  const onSelectViewTemplate = useCallback((type: ViewTemplate) => {
    setSelectViewTemplate(
      type === ViewTemplate.LIST ? ViewTemplate.LIST : ViewTemplate.GRID
    );
  }, []);

  const parseDataSheet = (
    sheet: {
      id: string;
      sheets: Sheet[];
      sheet: Sheet;
      filename: string;
      type: SpreadSheetType;
      sheetName: string;
    },
    index: number
  ) => {
    return {
      key: `${sheet.id}-${index}`,
      id: sheet.id,
      sheetName: sheet.sheetName,
      filename: sheet.filename,
    };
  };

  const onAddFile = (spreadSheetList: SpreadSheetList) => {
    if (checkPolicy(spreadSheetList)) {
      spreadSheetListState.spreadSheetList.addList(spreadSheetList);

      setSpreadSheetList({
        spreadSheetList: spreadSheetListState.spreadSheetList,
      });
    }
  };

  const onSelectedSpreadSheet = (spreadSheetIndex: number) => {
    setSelectedSpreadSheet(spreadSheetIndex);
  };

  function onHasShowScroll() {
    const scrollHeight = tabMenuMultipleRef.current?.scrollHeight ?? 0;
    const offsetHeight = tabMenuMultipleRef.current?.offsetHeight ?? 0;

    if (scrollHeight > offsetHeight) {
      setIsHasScroll(true);
    } else {
      setIsHasScroll(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onHasShowScroll);

    return () => {
      window.removeEventListener('resize', onHasShowScroll);
    };
  }, [tabMenuMultipleRef, spreadSheetListState]);

  return {
    sheets,
    onBackClick,
    onContinueClick,
    onSelected,
    selected,
    disabledContinue,
    onSelectViewTemplate,
    viewTemplate,
    parseDataSheet,
    onAddFile,
    spreadSheets,
    onSelectedSpreadSheet,
    selectedSpreadSheet,
    displaySheets,
    isHasScroll,
    tabMenuMultipleRef,
    setGridHeight,
    gridHeight,
  };
};

export default useViewModel;
