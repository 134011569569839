import { css, cx } from '../../../../../../../core/emotion';
import { ICleaningAssistantContext } from '../../index.types';

export default (context: ICleaningAssistantContext) => {
  const cleaningAssistantTheme = context.theme.getCleaningAssistantTheme();

  const rootClass = cx([
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    `,
    css({
      '&&': css(cleaningAssistantTheme.loadingState?.root),
    }),
  ]);

  const skeletonClass = cx([
    css`
      width: 100%;
      height: 40px;
      border-radius: 4px;
      background-color: var(--nuvo-neutral-100);
      animation: skeleton-pulse 1.8s ease-in-out 0.5s infinite;

      @keyframes skeleton-pulse {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.4;
        }
        100% {
          opacity: 1;
        }
      }
    `,
    css({
      '&&': css(cleaningAssistantTheme.loadingState?.root),
    }),
  ]);

  return {
    rootClass,
    skeletonClass,
  };
};
