import React, { useContext } from 'react';
import { ICleaningAssistantContext } from '../../index.types';
import { CleaningAssistantContext } from '../../context';
import styles from './index.style';

const LoadingState = () => {
  const context = useContext<ICleaningAssistantContext>(
    CleaningAssistantContext
  );
  const s = styles(context);

  return (
    <div className={s.rootClass}>
      <div className={s.skeletonClass} />
      <div className={s.skeletonClass} />
    </div>
  );
};

export default LoadingState;
