import { ReactComponent as ContextualEngineLoader } from '../assets/animated/contextual-engine-loader.svg';
import { css, CSSObject, cx } from 'core/emotion';
import { NUVO_TAILWIND_CLASS } from 'core/constants/style';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'theme';
import { validateBackgroundImageInCss } from '@nuvo-importer/common/sdk';
import { createPortal } from 'react-dom';
import { useEffect, useMemo, useRef } from 'react';
import { NUVO_ELEMENT_ID } from 'core/constants/element';
import { useMainView } from 'settings';

export const ContextualEngineLoading = () => {
  const { t } = useTranslation();
  const { modal } = useMainView();
  const theme = useTheme();
  const container = useRef<HTMLElement | null>(null);

  useEffect(() => {
    container.current = document.getElementById(NUVO_ELEMENT_ID.APP_ROOT);
    let tempOverflow = '';
    let tempPosition = '';
    if (
      (theme?.getContextualEngineTheme().loading?.root as CSSObject)
        ?.position !== 'absolute' &&
      container.current?.style
    ) {
      tempOverflow = container.current.style.overflow;
      tempPosition = container.current.style.position;
      container.current.style.overflow = 'hidden';
      container.current.style.position = 'relative';
      container.current.style.height = '100%';
    }

    return () => {
      if (container.current?.style) {
        container.current.style.overflow = tempOverflow;
        container.current.style.position = tempPosition;
        container.current.style.height = 'auto';
      }
    };
  }, [theme]);

  const loadingElement = useMemo(
    () => (
      <div
        className={cx(
          NUVO_TAILWIND_CLASS,
          'absolute top-0 left-0 z-50 h-full w-full'
        )}
      >
        <div
          role="dialog"
          aria-modal="true"
          aria-labelledby="loading-title"
          className={cx(
            'inset-0 flex h-full flex-col items-center justify-center overflow-x-hidden bg-white bg-opacity-60 p-10 !opacity-100 transition-opacity duration-300 ease-out',
            css`
              z-index: 10000;
              backdrop-filter: blur(20px);
              -webkit-backdrop-filter: blur(20px);
              transform: translate3d(0, 0, 0);
            `,
            css({ '&&': theme?.getContextualEngineTheme().loading?.root })
          )}
        >
          {validateBackgroundImageInCss(
            theme?.getContextualEngineTheme().loading?.icon as CSSObject
          ) ? (
            <svg
              className={cx(
                'w-73 bg-contain bg-center bg-no-repeat',
                css({
                  '&&': theme?.getContextualEngineTheme().loading?.icon,
                })
              )}
            />
          ) : (
            <ContextualEngineLoader
              className={cx(
                'w-73',
                css({ '&&': theme?.getContextualEngineTheme().loading?.icon })
              )}
            />
          )}
          <p
            id="loading-title"
            className="text-blue-dark-900 z-10 font-semibold"
          >
            {t('txt_processing_data')}
          </p>
        </div>
      </div>
    ),
    [t, theme]
  );

  return !modal && container.current
    ? createPortal(loadingElement, container.current)
    : loadingElement;
};
