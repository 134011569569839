import { cx, css } from '../../core/emotion';
import BaseModal from './Modal';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import FieldTextInput from '../form/FieldTextInput';
import Label from '../form/Label';
import useViewModel, { FormValues, createNewColumnModalId } from './viewModel';
import FieldDropdown from '../form/FieldDropdown';
import { DialogCreateCustomColumn } from '../../theme/themeAPI';
import { DATATYPE } from '../../core/dataType';
import Button from '../Button';

type CreateNewColumnModalProps = {
  onSubmit: (forValues: FormValues) => void;
  configureStyled?: DialogCreateCustomColumn;
  isOpen: boolean;
  close: () => void;
  columnLabels: string[];
  initialValues: FormValues;
  type?: 'edit' | 'add';
};

const CreateNewColumnModal = ({
  configureStyled,
  isOpen,
  close,
  onSubmit,
  columnLabels,
  initialValues,
  type = 'add',
}: CreateNewColumnModalProps) => {
  const { t } = useTranslation();
  const {
    dataModelOptions,
    dateFormatOptions,
    modalElement,
    validate,
    hasSubmit,
    inputRef,
    handleSubmit,
  } = useViewModel({
    isOpen,
    columnLabels,
    onSubmit,
    close,
    type,
    initialValues,
  });

  return (
    <BaseModal
      gravity="middle"
      className="w-full !p-0 sm:max-w-xl"
      isOpen={isOpen}
      onCloseModal={() => {
        close();
      }}
      wrapperClassName={cx(css`
        z-index: 10000 !important;
      `)}
      isShowCloseIcon={true}
      id={createNewColumnModalId}
      overlayStyled={configureStyled?.overlay}
      bodyStyled={configureStyled?.root}
      closeIconStyled={configureStyled?.closeIcon}
      contentClassName="!items-stretch"
    >
      <Form<FormValues>
        onSubmit={handleSubmit}
        validate={(values) => {
          if (hasSubmit) {
            return true;
          }
          return validate({
            ...values,
            columnName: values.columnName?.trim() ?? '',
          });
        }}
        initialValues={initialValues}
        render={({ handleSubmit, values }) => {
          return (
            <form className="p-6 pt-0 text-left" onSubmit={handleSubmit}>
              <div>
                <p
                  className={cx(
                    'text-neutral-gray text-2xl font-semibold',
                    css({ '&&': configureStyled?.title })
                  )}
                >
                  {type === 'add'
                    ? t('txt_create_new_column')
                    : t('txt_change_custom_column')}
                </p>
                <p
                  className={cx(
                    'text-gray-970 mt-2 text-sm leading-5',
                    css({ '&&': configureStyled?.description })
                  )}
                >
                  {type === 'add'
                    ? t('txt_create_new_column_description')
                    : t('txt_change_custom_column_description')}
                </p>
              </div>
              <div className="mt-6">
                <Label
                  description={t(
                    'txt_create_new_column_column_name_field_description'
                  )}
                  className="mb-1"
                  textClassName={css({
                    '&&': configureStyled?.columnName?.label,
                  })}
                  iconClassName={css({
                    '&&': configureStyled?.columnName?.icon,
                  })}
                >
                  {t('txt_create_new_column_column_name_field')}
                </Label>
                <FieldTextInput
                  ref={inputRef}
                  name="columnName"
                  placeholder={t(
                    'txt_create_new_column_column_name_field_placeholder'
                  )}
                  className={css({
                    '&&': configureStyled?.columnName?.input,
                  })}
                  errorMessageClassName={css({
                    '&&': configureStyled?.columnName?.errorMessage,
                  })}
                />
              </div>
              <div className="mt-4">
                <Label
                  description={t(
                    'txt_create_new_column_column_type_field_description'
                  )}
                  className="mb-1"
                  textClassName={css({
                    '&&': configureStyled?.columnType?.label,
                  })}
                  iconClassName={css({
                    '&&': configureStyled?.columnType?.icon,
                  })}
                >
                  {t('txt_create_new_column_column_type_field')}
                </Label>
                <FieldDropdown
                  name="columnType"
                  placeholder={t('txt_select_column_type')}
                  options={dataModelOptions}
                  rootDropdownPortal={modalElement ?? undefined}
                  disabled={type === 'edit'}
                  showInfo={type === 'edit'}
                  infoMessage={t('txt_change_custom_column_type_description')}
                  dropdownStyled={configureStyled?.columnType?.dropdown}
                  popoverStyled={configureStyled?.popover}
                />
              </div>
              {values.columnType === DATATYPE.DATE ? (
                <div className="mt-4">
                  <Label
                    description={t('txt_date_format_field_description')}
                    className="mb-1"
                    textClassName={css({
                      '&&': configureStyled?.dateFormat?.label,
                    })}
                    iconClassName={css({
                      '&&': configureStyled?.dateFormat?.icon,
                    })}
                  >
                    {t('txt_date_format')}
                  </Label>
                  <FieldDropdown
                    name="dateFormat"
                    placeholder={t('txt_select_date_format')}
                    options={dateFormatOptions}
                    disabled={type === 'edit'}
                    showInfo={type === 'edit'}
                    infoMessage={t(
                      'txt_change_custom_column_date_format_description'
                    )}
                    rootDropdownPortal={modalElement ?? undefined}
                    dropdownStyled={configureStyled?.dateFormat?.dropdown}
                    popoverStyled={configureStyled?.popover}
                  />
                </div>
              ) : null}
              <div className="flex justify-end pt-10">
                <Button
                  variant="secondary"
                  className="mr-4"
                  onClick={() => {
                    close();
                  }}
                >
                  {t('txt_cancel')}
                </Button>
                <Button type="submit">
                  {type === 'add' ? t('txt_create') : t('txt_save')}
                </Button>
              </div>
            </form>
          );
        }}
      />
    </BaseModal>
  );
};

export default CreateNewColumnModal;
