import { useForm, useFormState } from 'react-final-form';
import { CategoryDataModel, DataModel, Option } from 'dataModel';
import { DATATYPE } from 'core/dataType';
import { useCallback, useMemo } from 'react';
import { MatchedOption } from '../../../../../../matching/DataModelSheetMatching';
import { useContextCreateNewOptionModal } from '../../../../../CreateCustomModal/CreateNewOptionModal/CreateNewOptionModalContext';
import { Value } from './../../../../../../sheetImporter/Sheet';
import { get, isArray, isNil } from 'lodash';
import { BaseMatcherOptions } from '../../types';
import { SheetColumnDataModelOptionSimilarity } from '@nuvo-importer/common/sdk';
import {
  checkIsMultipleValues,
  separateMultipleValues,
} from '@nuvo-importer/common/core';

const findSimilarity = (
  lookupSimilarityOptions: Record<string, SheetColumnDataModelOptionSimilarity>,
  sheetColumnOption: Value,
  isMultiSelection: boolean
) => {
  if (!isMultiSelection) {
    return (
      lookupSimilarityOptions[`${sheetColumnOption}`]
        ?.getSimilarity()
        .getSimilarity() ?? 0
    );
  } else {
    const optionValuesByTdm: string[] = checkIsMultipleValues(sheetColumnOption)
      ? separateMultipleValues(sheetColumnOption)
      : [`${sheetColumnOption}`];

    let maxItem;
    for (let i = 0; i < optionValuesByTdm.length; i++) {
      const option = optionValuesByTdm[i]?.trim();
      const sheetColumnDataModelOptionSimilarity =
        lookupSimilarityOptions[option];
      if (!maxItem) {
        maxItem = sheetColumnDataModelOptionSimilarity;
      } else if (
        (sheetColumnDataModelOptionSimilarity
          ?.getSimilarity()
          ?.getSimilarity() ?? 0) > maxItem.getSimilarity().getSimilarity()
      ) {
        maxItem = sheetColumnDataModelOptionSimilarity;
      }
    }

    return maxItem?.getSimilarity()?.getSimilarity() ?? 0;
  }
};

const useViewModel = ({
  baseName,
  dataModel,
  sheetColumnOption,
  prefixName,
  index,
  baseMatcherOptions,
}: {
  baseName: string;
  dataModel: DataModel;
  sheetColumnOption: Value;
  prefixName: string;
  index: number;
  baseMatcherOptions: BaseMatcherOptions;
}) => {
  const isMultiSelection = dataModel.getIsMultiSelection();
  const { open } = useContextCreateNewOptionModal();

  const name = useMemo(
    () =>
      !isMultiSelection
        ? `${baseName}.dataModelOption`
        : `${baseName}.dataModelOptions`,
    [baseName, isMultiSelection]
  );

  const { change } = useForm();
  const { values } = useFormState();

  const value = useMemo(() => get(values, name), [values, name]);
  const matchOptionsName = `${prefixName}.matchedDataModel.matchedOptions`;
  const categoryDataModelName = `${prefixName}.matchedDataModel.dataModel`;

  const sortedOptions = useMemo(() => {
    const items = baseMatcherOptions.map((baseMatcherOption) => {
      const similarity = (() => {
        return (baseMatcherOption.option as Option)?.creator
          ? 0
          : findSimilarity(
              baseMatcherOption.lookupSimilarityOptions,
              sheetColumnOption,
              isMultiSelection
            );
      })();

      return {
        ...baseMatcherOption.option,
        similarity: similarity,
        customOption: !!(baseMatcherOption.option as Option)?.creator,
      };
    });

    return items.sort((itemA, itemB) => {
      if (!itemA.customOption && itemB.customOption) {
        return -1;
      }
      if (itemA.customOption && !itemB.customOption) {
        return 1;
      }
      return itemB.similarity - itemA.similarity;
    });
  }, [baseMatcherOptions, sheetColumnOption, isMultiSelection]);

  const autoMatchDataModelOptionForBoolean = useCallback(
    (value: string) => {
      const matchOptions = get(values, matchOptionsName) as MatchedOption[];
      const hasTwoMatchedOptions = matchOptions.length === 2;
      const noDataModelOption = matchOptions.every((matchOption) =>
        isNil(matchOption.dataModelOption)
      );

      if (hasTwoMatchedOptions && noDataModelOption && value) {
        const booleanValue = JSON.parse(value);
        let updatedIndex: number;
        if (index === 0) {
          updatedIndex = 1;
        } else {
          updatedIndex = 0;
        }
        change(
          `${matchOptionsName}.${updatedIndex}.dataModelOption`,
          `${!booleanValue}`
        );
      }
    },
    [change, index, matchOptionsName, values]
  );

  const onChange = useCallback(
    (value: string | string[]) => {
      const isBooleanDataModel = dataModel.getType() === DATATYPE.BOOLEAN;

      if (isBooleanDataModel) {
        autoMatchDataModelOptionForBoolean(value as string);
      }
      change(name, value);
    },
    [autoMatchDataModelOptionForBoolean, change, name, dataModel]
  );

  const onCreateOptions = () => {
    if (dataModel.isCategoryType()) {
      open(
        dataModel as CategoryDataModel,
        sheetColumnOption,
        (updateCategoryDataModel, updatedValue) => {
          if (updateCategoryDataModel) {
            change(categoryDataModelName, updateCategoryDataModel);
          }
          if (dataModel.getIsMultiSelection()) {
            if (isArray(value)) {
              onChange([...value, updatedValue]);
            } else {
              onChange([updatedValue]);
            }
          } else {
            onChange(updatedValue);
          }
        }
      );
    }
  };

  return {
    input: { value },
    onChange,
    options: sortedOptions,
    onCreateOptions,
    isMultiSelection,
  };
};

export default useViewModel;
