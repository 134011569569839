import { RefObject, useEffect, useMemo, useState } from 'react';
import DataModelRegistry from '../DataModelRegistry';
import { DataModel } from '../../../dataModel/model/DataModel';
import CreateNewColumnModal from '../../../../components/CreateNewColumnModal';
import CreateNewOptionModal from '../../../../components/CreateNewOptionModal';
import { DATATYPE } from '../../../dataType';
import { Subject } from 'rxjs';
import { HotTableClass } from '@handsontable/react';
import { ColumnAPI, DropdownOptionType } from '../../../dataModel/columnsAPI';
import useColumnViewModel from './columnViewModel';
import useOptionViewModel from './optionViewModel';
import { CustomOptionUIObservable } from '../customOption';
import CategoryDataModel from '../../../dataModel/model/CategoryDataModel';
import { CustomColumnUIObservable } from '../customColumns';
import { useTheme } from '../../../../theme';
import { uniq } from 'lodash';

type CustomColumnAndOptionProps = {
  dataModelRegistry: DataModelRegistry;
  onAddColumn: (
    columnName: string,
    columnType: DATATYPE,
    dateFormat?: string
  ) => void;
  onAddOption: (
    dataModel: CategoryDataModel,
    optionName: string,
    optionType: DropdownOptionType
  ) => void;
  onEditColumn: (dataModel: DataModel, optionName: string) => void;
  customColumnUIObservable: Subject<CustomColumnUIObservable>;
  customOptionUIObservable: Subject<CustomOptionUIObservable>;
  hotInstance: RefObject<HotTableClass>;
  baseColumns: ColumnAPI[];
};

const getAllColumnLabels = (
  dataModelRegistry: DataModelRegistry,
  baseColumns: ColumnAPI[]
) => {
  return uniq([
    ...dataModelRegistry
      .getDataModelsWithHidden()
      .map((item) => item.getLabel()),
    ...baseColumns.map((column) => column.label),
  ]);
};

const CustomColumnAndOption = ({
  dataModelRegistry,
  onAddColumn,
  onAddOption,
  customColumnUIObservable,
  customOptionUIObservable,
  hotInstance,
  onEditColumn,
  baseColumns,
}: CustomColumnAndOptionProps) => {
  const [columnLabels, setColumnLabels] = useState<string[]>(
    getAllColumnLabels(dataModelRegistry, baseColumns)
  );
  const {
    isCustomColumnOpen,
    onCustomColumnClose,
    columnInitialValues,
    typeCustomColumn,
    editingDataModel,
  } = useColumnViewModel({
    customColumnUIObservable,
    hotInstance,
  });
  const {
    isAddOptionOpen,
    onAddOptionClose,
    addingOptionDataModel,
    optionInitialValues,
  } = useOptionViewModel({
    customOptionUIObservable,
    hotInstance,
  });

  useEffect(() => {
    const subscription = dataModelRegistry
      .dataModelObservable()
      .subscribe(() => {
        setColumnLabels(getAllColumnLabels(dataModelRegistry, baseColumns));
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [dataModelRegistry, baseColumns]);

  const theme = useTheme();
  const configureCustomColumnStyled = useMemo(() => {
    return theme.getReviewEntriesTheme().dialogCreateCustomColumn;
  }, [theme]);

  const configureCustomOptionStyled = useMemo(() => {
    return theme.getReviewEntriesTheme().dialogCreateCustomOption;
  }, [theme]);

  return (
    <>
      <CreateNewColumnModal
        close={onCustomColumnClose}
        columnLabels={columnLabels}
        isOpen={isCustomColumnOpen}
        initialValues={columnInitialValues}
        type={typeCustomColumn ?? 'add'}
        configureStyled={configureCustomColumnStyled}
        onSubmit={(formValues) => {
          if (typeCustomColumn === 'add') {
            onAddColumn(
              formValues.columnName,
              formValues.columnType,
              formValues.dateFormat
            );
          } else if (typeCustomColumn === 'edit' && editingDataModel) {
            onEditColumn(editingDataModel, formValues.columnName);
          }
        }}
      />
      <CreateNewOptionModal
        close={onAddOptionClose}
        isOpen={isAddOptionOpen}
        dataModel={addingOptionDataModel}
        initialValues={optionInitialValues}
        configureStyled={configureCustomOptionStyled}
        onSubmit={(formValues) => {
          if (addingOptionDataModel) {
            onAddOption(
              addingOptionDataModel,
              formValues.optionName,
              formValues.optionType
            );
          }
        }}
      />
    </>
  );
};

export default CustomColumnAndOption;
