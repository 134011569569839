import { DataModel } from 'dataModel';
import DataModelSheetMatching from './../../../../../matching/DataModelSheetMatching';
import SheetColumn from './../../../../../sheetImporter/SheetColumn';
import AutoMatchedText from './AutoMatchedText';
import NotMatchedText from './NotMatchedText';
import ManualMatchedText from './ManualMatchedText';
import NotAutoMatchedText from './NotAutoMatchedText';
import { ColumnMatchThemeAPI } from '../../PreviewData/types';
import { useMemo } from 'react';
import { css, CSSObject } from 'core/emotion';

const MatchingText = ({
  matchedDataModel,
  dataModelSheetMatching,
  sheetColumn,
  configTheme,
}: {
  matchedDataModel?: DataModel;
  dataModelSheetMatching: DataModelSheetMatching;
  sheetColumn: SheetColumn;
  configTheme?: ColumnMatchThemeAPI;
}) => {
  const autoMatchedDataModel =
    dataModelSheetMatching.getMatchingBySheetColumn(sheetColumn)
      ?.matchedDataModel?.dataModel;

  const styled = useMemo(() => {
    return {
      popoverInfoIcon: css({
        '&&': {
          color:
            (configTheme?.matchingTitle?.icon as CSSObject)?.color ?? '#C5C5C5',
          ':hover': {
            color:
              (configTheme?.matchingTitle?.icon as CSSObject)?.[':hover']
                ?.color ?? '#717171',
          },
        },
      }),
    };
  }, [configTheme]);

  if (matchedDataModel && autoMatchedDataModel === matchedDataModel) {
    return (
      <AutoMatchedText
        matchedDataModel={matchedDataModel}
        configThemePopoverInfoIcon={styled.popoverInfoIcon}
      />
    );
  } else if (matchedDataModel) {
    return (
      <ManualMatchedText
        matchedDataModel={matchedDataModel}
        configThemePopoverInfoIcon={styled.popoverInfoIcon}
      />
    );
  } else if (!autoMatchedDataModel) {
    return <NotAutoMatchedText />;
  } else {
    return <NotMatchedText />;
  }
};

export default MatchingText;
