import { css, cx } from 'core/emotion';
import { ReactNode, useMemo } from 'react';

type IRowProps = {
  children: ReactNode;
  showDeleteButton: boolean;
  className?: string;
};

const Row = ({ children, showDeleteButton, className }: IRowProps) => {
  const gridStyle = useMemo(
    () =>
      showDeleteButton
        ? css`
            grid-template-columns: minmax(0, 3fr) minmax(0, 4fr) auto;
          `
        : css`
            grid-template-columns: minmax(0, 3fr) minmax(0, 4fr);
          `,
    [showDeleteButton]
  );

  return (
    <div className={cx('grid min-w-0 items-start gap-2', className, gridStyle)}>
      {children}
    </div>
  );
};

export default Row;
