import { CSSProperties, ReactNode, useCallback, useMemo } from 'react';
import { ConfigTheme } from '../../../DataModelSheetForm/type';
import { isFirefox } from 'react-device-detect';
import { css, CSSObject, cx } from '../../../../emotion';
import {
  COLOR,
  COLOR_HOVER,
  COLOR_TABLE,
  COLOR_SCROLL_BAR,
} from '../../../../constants/colors';
import { useTheme } from '../../../../../theme';
import { convertHexColorToUrlSVGColor } from '../../../../style';
import chroma from 'chroma-js';

type WrapperStyledTableProps = {
  children: ReactNode;
  configTheme?: ConfigTheme;
  mediaSize?: boolean;
  modal?: boolean;
  enableExamples?: boolean;
  readOnly?: boolean;
  showRemove?: boolean;
};

type BodyCellType = 'error' | 'warning' | 'info' | 'default' | 'disabled';
type BodyCellTypeTd = 'error' | 'warning' | 'info' | 'normal' | 'disabled';

const WrapperStyledTable = ({
  children,
  configTheme,
  mediaSize,
  modal,
  enableExamples,
  readOnly,
  showRemove,
}: WrapperStyledTableProps) => {
  const cellStatus: Record<
    string,
    'ERROR' | 'WARNING' | 'INFO' | 'DEFAULT' | 'DISABLED'
  > = {
    error: 'ERROR',
    warning: 'WARNING',
    info: 'INFO',
    default: 'DEFAULT',
    disabled: 'DISABLED',
  };
  const theme = useTheme();

  const bodyCellGenerator = useCallback(
    (type: BodyCellType) => {
      const typeClass = type;
      const typeEnum = cellStatus[type];

      const typeObjValue: BodyCellTypeTd =
        typeEnum === 'DEFAULT'
          ? 'normal'
          : (typeEnum.toLowerCase() as BodyCellTypeTd);

      const tdCellColor =
        typeEnum === 'INFO'
          ? (
              configTheme?.reviewEntriesTheme?.table?.td?.[
                typeObjValue
              ] as CSSObject
            )?.color ?? theme.getGlobalTheme().getPrimaryColor()
          : (
              configTheme?.reviewEntriesTheme?.table?.td?.[
                typeObjValue
              ] as CSSObject
            )?.color ?? COLOR[typeEnum];

      const categoryTextColor =
        typeEnum === 'INFO'
          ? (
              configTheme?.reviewEntriesTheme?.table?.td?.[
                typeObjValue
              ] as CSSObject
            )?.color ?? theme.getGlobalTheme().getPrimaryColor()
          : (
              configTheme?.reviewEntriesTheme?.table?.td?.[
                typeObjValue
              ] as CSSObject
            )?.color ?? COLOR[typeEnum];

      const booleanTextColor =
        typeEnum === 'INFO'
          ? (
              configTheme?.reviewEntriesTheme?.table?.td?.[
                typeObjValue
              ] as CSSObject
            )?.color ?? theme.getGlobalTheme().getPrimaryColor()
          : (
              configTheme?.reviewEntriesTheme?.table?.td?.[
                typeObjValue
              ] as CSSObject
            )?.color ?? COLOR[typeEnum];

      const tdCellHoverColor =
        typeEnum === 'INFO'
          ? theme.getGlobalTheme().getLight200Color()
          : COLOR_HOVER[typeEnum];

      return css`
        .data-review .handsontable td.${typeClass}-cell {
          ${configTheme?.reviewEntriesTheme?.table?.td?.[typeObjValue]}
        }
        .data-review .handsontable td.${typeClass}-cell {
          color: ${tdCellColor};
        }
        .data-review .handsontable td.default-cell.htDimmed,
        .data-review .handsontable td.default-cell.htDimmed.current.highlight,
        .data-review .handsontable td.default-cell.htDimmed div,
        .data-review .handsontable td.default-cell.htDimmed span,
        .data-review .handsontable td.default-cell.htDimmed svg {
          color: #8d8d8d !important;
        }

        .data-review
          .handsontable
          td.default-cell.htDimmed
          .arrow-down-icon
          svg
          path {
          fill: #8d8d8d !important;
        }

        .data-review
          .handsontable
          td.${typeClass}-cell.current.highlight
          > div {
          border-color: ${configTheme?.reviewEntriesTheme?.table
            ?.selectedBackground?.[typeObjValue]};
        }
        .data-review .handsontable td.${typeClass}-cell:hover {
          background-color: ${tdCellHoverColor};
          ${(
            configTheme?.reviewEntriesTheme?.table?.td?.[
              typeObjValue
            ] as CSSObject
          )?.[':hover']}
        }
        .data-review
          .handsontable
          td.${typeClass}-cell.current.highlight:hover {
          background-color: ${tdCellHoverColor};
          ${(
            configTheme?.reviewEntriesTheme?.table?.td?.[
              typeObjValue
            ] as CSSObject
          )?.[':hover']}
        }
        .data-review
          .handsontable
          td.${typeClass}-cell
          div.custom-dropdown-renderer
          .custom-dropdown-renderer-text-element {
          color: ${categoryTextColor};
        }
        .data-review
          .handsontable
          td.${typeClass}-cell
          div.custom-dropdown-boolean-renderer
          .custom-dropdown-boolean-renderer-text-element {
          color: ${booleanTextColor};
        }
        .data-review
          .handsontable
          td.${typeClass}-cell.current.highlight:hover
          div.custom-dropdown-renderer
          .custom-dropdown-renderer-text-element {
          ${(
            configTheme?.reviewEntriesTheme?.table?.td?.[
              typeObjValue
            ] as CSSObject
          )?.[':hover']}
        }
        .data-review
          .handsontable
          td.${typeClass}-cell:hover
          div.custom-dropdown-renderer
          .custom-dropdown-renderer-text-element {
          ${(
            configTheme?.reviewEntriesTheme?.table?.td?.[
              typeObjValue
            ] as CSSObject
          )?.[':hover']}
        }
        .data-review
          .handsontable
          td.${typeClass}-cell.current.highlight:hover
          div.custom-dropdown-boolean-renderer
          .custom-dropdown-boolean-renderer-text-element {
          ${(
            configTheme?.reviewEntriesTheme?.table?.td?.[
              typeObjValue
            ] as CSSObject
          )?.[':hover']}
        }
        .data-review
          .handsontable
          td.${typeClass}-cell:hover
          div.custom-dropdown-boolean-renderer
          .custom-dropdown-boolean-renderer-text-element {
          ${(
            configTheme?.reviewEntriesTheme?.table?.td?.[
              typeObjValue
            ] as CSSObject
          )?.[':hover']}
        }
      `;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      configTheme?.reviewEntriesTheme?.table?.selectedBackground,
      configTheme?.reviewEntriesTheme?.table?.td,
    ]
  );

  const style = useMemo(() => {
    const editCategoryStyle = Object.assign(
      {},
      (configTheme?.reviewEntriesTheme?.edit?.category?.item?.option ??
        {}) as CSSObject
    );
    delete editCategoryStyle[':hover'];

    const editBooleanStyle = Object.assign(
      {},
      (configTheme?.reviewEntriesTheme?.edit?.boolean?.item?.option ??
        {}) as CSSObject
    );
    delete editBooleanStyle[':hover'];

    const header = css`
      .data-review .handsontable thead th .colHeader .title-header,
      #review-entries-table .ht_clone_top.handsontable {
        background-color: #fbfbfb;
        ${configTheme?.reviewEntriesTheme?.table?.th}
      }

      .data-review .handsontable thead th .colHeader .title-header #text-title {
        ${configTheme?.reviewEntriesTheme?.table?.th}
      }

      .data-review .handsontable thead th .colHeader .title-header #icon-info {
        ${configTheme?.reviewEntriesTheme?.infoIcon}
      }

      .data-review .handsontable .ht_clone_top .htCore tr th {
        border-color: ${(
          configTheme?.reviewEntriesTheme?.table?.th as CSSObject
        )?.borderColor || COLOR_TABLE.BORDER_COLOR};
      }
    `;

    const body = css`
      .data-review .handsontable td {
        border-color: ${(
          configTheme?.reviewEntriesTheme?.table?.td?.root as CSSObject
        )?.borderColor || COLOR_TABLE.BORDER_COLOR};
      }

      .data-review .handsontable.ht_master tr th:last-child {
        border-color: ${(
          configTheme?.reviewEntriesTheme?.table?.td?.root as CSSObject
        )?.borderColor || COLOR_TABLE.BORDER_COLOR};
      }

      .data-review .handsontable.ht_master tr td:last-child {
        border-color: ${(
          configTheme?.reviewEntriesTheme?.table?.td?.root as CSSObject
        )?.borderColor || COLOR_TABLE.BORDER_COLOR};
      }

      .data-review .handsontable .htCore tr:first-child td {
        border-top-color: ${(
          configTheme?.reviewEntriesTheme?.table?.th as CSSObject
        )?.borderColor || COLOR_TABLE.BORDER_COLOR};
      }

      .data-review .handsontable .htCore tr:first-child th {
        border-top-color: ${(
          configTheme?.reviewEntriesTheme?.table?.th as CSSObject
        )?.borderColor || COLOR_TABLE.BORDER_COLOR} !important;
      }

      .data-review .handsontable .ht_clone_top_left_corner .htCore tr th {
        background-color: ${(
          configTheme?.reviewEntriesTheme?.table?.th as CSSObject
        )?.backgroundColor || '#fbfbfb'} !important;
        border-right-color: ${(
          configTheme?.reviewEntriesTheme?.table?.th as CSSObject
        )?.borderColor || COLOR_TABLE.BORDER_COLOR} !important;
      }

      .data-review .handsontable .ht_clone_left .wtHolder .wtHider .htCore th {
        ${configTheme?.reviewEntriesTheme?.table?.td?.root}
        ${configTheme?.reviewEntriesTheme?.table?.td?.normal}
      }

      .data-review
        .handsontable
        .ht_clone_left
        .wtHolder
        .wtHider
        .htCore
        th:hover,
      .data-review
        .handsontable
        .ht_clone_top
        .wtHolder
        .wtHider
        .htCore
        th:hover {
        ${(configTheme?.reviewEntriesTheme?.table?.td?.root as CSSObject)?.[
          ':hover'
        ]}
      }

      .bg-info-cell {
        background-color: ${theme.getGlobalTheme().getLight50Color()};
      }

      .data-review .handsontable td.info-cell #dropdown-arrow-icon svg {
        color: ${theme.getGlobalTheme().getPrimaryColor()};
      }

      .data-review .handsontable .ht_clone_left .wtHolder {
        padding-right: 24px;
        pointer-events: none;
      }

      .data-review .handsontable .ht_clone_left .wtHolder > div {
        pointer-events: auto;
      }
      .data-review .has-freeze .ht_clone_left .wtHolder .htCore {
        box-shadow: ${configTheme?.reviewEntriesTheme?.smartTable
          ?.freezeBoxShadow ||
        '0px 0px 8px -4px rgba(16, 24, 40, 0.06), 0px 0px 24px -4px rgba(16, 24, 40, 0.16)'};
      }
    `;

    const bodyErrorCell = bodyCellGenerator('error');
    const bodyWarningCell = bodyCellGenerator('warning');
    const bodyInfoCell = bodyCellGenerator('info');
    const bodyDefaultCell = bodyCellGenerator('default');

    const scrollbar = css`
      #review-entries-table
        .ht_master.handsontable
        .wtHolder::-webkit-scrollbar-thumb {
        background-color: ${configTheme?.reviewEntriesTheme?.table?.scrollbar
          ?.navigatorColor};
      }

      #review-entries-table
        .ht_master.handsontable
        .wtHolder::-webkit-scrollbar-track {
        background-color: ${configTheme?.reviewEntriesTheme?.table?.scrollbar
          ?.backgroundColor};
      }

      #dropdown-scroll-element-id::-webkit-scrollbar-thumb {
        background-color: ${configTheme?.reviewEntriesTheme?.table?.scrollbar
          ?.navigatorColor};
      }

      #dropdown-scroll-element-id::-webkit-scrollbar-track {
        background-color: ${configTheme?.reviewEntriesTheme?.table?.scrollbar
          ?.backgroundColor};
      }

      scrollbar-color: ${configTheme?.reviewEntriesTheme?.table?.scrollbar
          ?.navigatorColor
          ? configTheme?.reviewEntriesTheme?.table?.scrollbar?.navigatorColor
          : COLOR_SCROLL_BAR.NAVIGATOR}
        ${configTheme?.reviewEntriesTheme?.table?.scrollbar?.backgroundColor
          ? configTheme?.reviewEntriesTheme?.table?.scrollbar?.backgroundColor
          : COLOR_SCROLL_BAR.BACKGROUND};

      .context-menu-scroll > div > .simplebar-track {
        background-color: ${configTheme?.reviewEntriesTheme?.table?.scrollbar
          ?.backgroundColor};
      }

      .context-menu-scroll
        > div
        > .simplebar-track
        > .simplebar-scrollbar::before {
        background-color: ${configTheme?.reviewEntriesTheme?.table?.scrollbar
          ?.navigatorColor};
      }

      .filter-value-list-scroll .simplebar-track {
        background-color: ${configTheme?.reviewEntriesTheme?.table?.scrollbar
          ?.backgroundColor};
      }

      .filter-value-list-scroll
        .simplebar-track
        > .simplebar-scrollbar::before {
        background-color: ${configTheme?.reviewEntriesTheme?.table?.scrollbar
          ?.navigatorColor};
      }

      .nuvo-checkbox-dropdown-menu-pane::-webkit-scrollbar-thumb {
        background-color: ${configTheme?.reviewEntriesTheme?.table?.scrollbar
          ?.navigatorColor};
      }

      .nuvo-checkbox-dropdown-menu-pane::-webkit-scrollbar-track {
        background-color: ${configTheme?.reviewEntriesTheme?.table?.scrollbar
          ?.backgroundColor};
      }

      .nuvo-dropdown-menu-pane::-webkit-scrollbar-thumb {
        background-color: ${configTheme?.reviewEntriesTheme?.table?.scrollbar
          ?.navigatorColor};
      }

      .nuvo-dropdown-menu-pane::-webkit-scrollbar-track {
        background-color: ${configTheme?.reviewEntriesTheme?.table?.scrollbar
          ?.backgroundColor};
      }
    `;

    const selectRowColor =
      configTheme?.reviewEntriesTheme?.selectRowColor &&
      css`
        .default-cell.current.highlight,
        .default-cell.area.highlight {
          background-color: ${configTheme?.reviewEntriesTheme
            ?.selectRowColor} !important;
        }

        .default-cell.currentRow {
          background-color: ${configTheme?.reviewEntriesTheme
            ?.selectRowColor} !important;
        }

        .ht_clone_left .wtHolder .wtHider .htCore th.ht__highlight.currentRow,
        .ht_clone_left .wtHolder .wtHider .htCore th.currentRow {
          background-color: ${configTheme?.reviewEntriesTheme
            ?.selectRowColor} !important;
        }
      `;

    const editorStyled = css`
      .data-review .handsontable .handsontableInputHolder .custom-text-editor {
        ${configTheme?.reviewEntriesTheme?.edit?.string}
      }

      .data-review
        .handsontable
        .handsontableInputHolder
        .custom-number-editor {
        ${configTheme?.reviewEntriesTheme?.edit?.number}
      }

      .data-review
        .handsontable
        .handsontableInputHolder
        .custom-percentage-editor {
        ${configTheme?.reviewEntriesTheme?.edit?.percentage}
      }

      .data-review
        .handsontable
        .handsontableInputHolder
        .custom-currency-editor {
        ${configTheme?.reviewEntriesTheme?.edit?.currency}
      }

      .data-review .handsontable .handsontableInputHolder .custom-time-editor {
        ${configTheme?.reviewEntriesTheme?.edit?.time}
      }

      .data-review .handsontable .handsontableInputHolder .custom-date-editor {
        ${configTheme?.reviewEntriesTheme?.edit?.date}
      }

      .data-review
        .handsontable
        td
        > div.custom-dropdown-renderer
        > .arrow-down-icon
        * {
        ${configTheme?.reviewEntriesTheme?.edit?.category?.button?.arrowIcon}
      }

      .data-review
        .handsontable
        td
        > div.custom-dropdown-renderer
        > .close-icon
        * {
        ${configTheme?.reviewEntriesTheme?.edit?.category?.button?.closeIcon}
      }

      .data-review
        .handsontable
        #dropdown-parent-element-id.menu-single-select {
        ${configTheme?.reviewEntriesTheme?.edit?.category?.item?.root}
      }

      .data-review
        .handsontable
        #dropdown-parent-element-id.menu-single-select
        #dropdown-scroll-element-id
        span.options.selected {
        ${configTheme?.reviewEntriesTheme?.edit?.category?.item?.selectedOption}
      }

      .data-review
        .handsontable
        #dropdown-parent-element-id.menu-single-select
        #dropdown-scroll-element-id
        span.options {
        ${editCategoryStyle}
      }

      .data-review
        .handsontable
        #dropdown-parent-element-id.menu-single-select
        #dropdown-scroll-element-id
        span.options-hover:hover {
        ${(
          configTheme?.reviewEntriesTheme?.edit?.category?.item
            ?.option as CSSObject
        )?.[':hover']}
      }

      .data-review
        .handsontable
        td
        > div.custom-dropdown-boolean-renderer
        > .arrow-down-icon
        * {
        ${configTheme?.reviewEntriesTheme?.edit?.boolean?.button?.arrowIcon}
      }

      .data-review
        .handsontable
        td
        > div.custom-dropdown-boolean-renderer
        > .close-icon
        * {
        ${configTheme?.reviewEntriesTheme?.edit?.boolean?.button?.closeIcon}
      }

      .data-review
        .handsontable
        #dropdown-parent-element-id.menu-boolean-select {
        ${configTheme?.reviewEntriesTheme?.edit?.boolean?.item?.root}
      }

      .data-review
        .handsontable
        #dropdown-parent-element-id.menu-boolean-select
        #dropdown-scroll-element-id
        span.options.selected {
        ${configTheme?.reviewEntriesTheme?.edit?.boolean?.item?.selectedOption}
      }

      .data-review
        .handsontable
        #dropdown-parent-element-id.menu-boolean-select
        #dropdown-scroll-element-id
        span.options {
        ${editBooleanStyle}
      }

      .data-review
        .handsontable
        #dropdown-parent-element-id.menu-boolean-select
        #dropdown-scroll-element-id
        span.options-hover:hover {
        ${(
          configTheme?.reviewEntriesTheme?.edit?.boolean?.item
            ?.option as CSSObject
        )?.[':hover']}
      }
    `;

    const selectTableColor = theme.getGlobalTheme().getDark50Color();

    const borderConner = css`
      .data-review .handsontable .ht_clone_left .htBorders .wtBorder.current,
      .data-review .handsontable .ht_master .htBorders .wtBorder.current {
        background-color: ${configTheme?.reviewEntriesTheme?.selectedBackground
          ?.normal ?? COLOR.DEFAULT} !important;
      }
      .data-review
        .handsontable
        .ht_clone_left
        .htBorders.error
        .wtBorder.current,
      .data-review .handsontable .ht_master .htBorders.error .wtBorder.current {
        background-color: ${configTheme?.reviewEntriesTheme?.selectedBackground
          ?.error ?? COLOR.ERROR} !important;
      }

      .data-review
        .handsontable
        .ht_clone_left
        .htBorders.warning
        .wtBorder.current,
      .data-review
        .handsontable
        .ht_master
        .htBorders.warning
        .wtBorder.current {
        background-color: ${configTheme?.reviewEntriesTheme?.selectedBackground
          ?.warning ?? COLOR.WARNING} !important;
      }

      .data-review
        .handsontable
        .ht_clone_left
        .htBorders.info
        .wtBorder.current,
      .data-review .handsontable .ht_master .htBorders.info .wtBorder.current {
        background-color: ${configTheme?.reviewEntriesTheme?.selectedBackground
          ?.info ?? theme.getGlobalTheme().getPrimaryColor()} !important;
      }

      .data-review
        .handsontable
        .ht_clone_left
        .htBorders.disabled
        .wtBorder.current,
      .data-review
        .handsontable
        .ht_master
        .htBorders.disabled
        .wtBorder.current {
        background-color: ${configTheme?.reviewEntriesTheme?.selectedBackground
          ?.disabled ?? COLOR.DISABLED} !important;
      }

      .data-review
        .handsontable
        .ht_clone_left
        .htBorders.hide-corner
        .wtBorder.current.corner,
      .data-review
        .handsontable
        .ht_master
        .htBorders.hide-corner
        .wtBorder.current.corner {
        display: none !important;
      }
    `;

    const reviewEntriesTheme = theme.getReviewEntriesTheme();
    const headerLoadingIconColor =
      (reviewEntriesTheme.smartTable?.th?.loadingIcon as CSSProperties)?.[
        'color'
      ] ?? '#8d8d8d';

    const rowHeaderStyled = css`
      .data-review .check-all-checkbox {
        ${reviewEntriesTheme.rowHeader?.allCheckbox}
        &:checked {
          background-image: url("data:image/svg+xml;charset=utf-8,<svg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'><path fill='${convertHexColorToUrlSVGColor(
            (reviewEntriesTheme.rowHeader?.checkbox as CSSProperties)?.['color']
          ) ||
          '%23ffffff'}' d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/></svg>");
        }
      }

      .data-review .check-all-checkbox:indeterminate {
        background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'><path stroke='${convertHexColorToUrlSVGColor(
          (reviewEntriesTheme.rowHeader?.allCheckbox as CSSProperties)?.[
            'color'
          ]
        ) ||
        '%23c5c5c5'}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/></svg>");
      }

      .data-review .row-header-checkbox {
        ${reviewEntriesTheme.rowHeader?.checkbox}
        &:checked {
          background-image: url("data:image/svg+xml;charset=utf-8,<svg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'><path fill='${convertHexColorToUrlSVGColor(
            (reviewEntriesTheme.rowHeader?.checkbox as CSSProperties)?.['color']
          ) ||
          '%23ffffff'}' d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/></svg>");
        }
      }

      .data-review .handsontable .header-circle-loader {
        ${reviewEntriesTheme.smartTable?.th?.loadingIcon}
        border-color: ${chroma(headerLoadingIconColor).alpha(0.3).hex()};
        border-top-color: ${headerLoadingIconColor};
      }
    `;

    return cx(
      modal ? 'h-full flex flex-col min-h-0' : '',
      isFirefox &&
        css`
          .data-review .handsontable thead th .colHeader {
            height: ${(configTheme?.reviewEntriesTheme?.table?.th as CSSObject)
              ?.height
              ? (configTheme?.reviewEntriesTheme?.table?.th as CSSObject)
                  ?.height
              : enableExamples
              ? mediaSize
                ? 46
                : 66
              : mediaSize
              ? 24
              : 34}px !important;
          }
        `,
      isFirefox && !mediaSize
        ? css`
            .custom-dropdown-renderer,
            .custom-dropdown-boolean-renderer {
              height: 33px !important;
            }
          `
        : '',
      header,
      selectRowColor,
      body,
      bodyDefaultCell,
      bodyInfoCell,
      bodyWarningCell,
      bodyErrorCell,
      scrollbar,
      editorStyled,
      borderConner,
      rowHeaderStyled,
      css`
        .data-review {
          ${configTheme?.reviewEntriesTheme?.root}
        }

        .data-review .handsontableInputHolder textarea {
          padding: ${mediaSize ? 0 : 2.3}px 12px 0px !important;
          border-width: 0px !important;
          box-shadow: inset 0px 0px 0px 1px ${COLOR.DEFAULT};
          cursor: auto;

          ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }
          ::-webkit-scrollbar-track {
            background-color: ${configTheme?.reviewEntriesTheme?.table
              ?.scrollbar?.backgroundColor || '#dfe2e4'};
          }
          ::-webkit-scrollbar-thumb {
            background-color: ${configTheme?.reviewEntriesTheme?.table
              ?.scrollbar?.navigatorColor || '#B3BCC0'};
            opacity: 1;
          }
        }

        .data-review
          .handsontable
          .ht_clone_left
          .wtHolder
          .wtHider
          .htCore
          th.currentRow {
          background-color: ${selectTableColor};
        }
        .data-review .handsontable td.default-cell.currentRow {
          background-color: ${selectTableColor};
        }
        .data-review .handsontable th.currentRow {
          background-color: ${selectTableColor};
        }
        .data-review .handsontable td.default-cell.current.highlight {
          background-color: ${readOnly ? '#FFFFFF' : selectTableColor};
        }

        .data-review
          .handsontable
          td.default-cell.nuvo-custom-add-column-cell.htDimmed {
          ${configTheme?.reviewEntriesTheme?.table?.td?.root}
          ${configTheme?.reviewEntriesTheme?.table?.td?.addColumn}
        }

        .data-review
          .handsontable
          td.default-cell.nuvo-custom-add-column-cell.htDimmed.currentRow {
          background-color: ${selectTableColor};
        }

        .data-review .handsontable th.currentRow.default-cell.htDimmed {
          background-color: ${selectTableColor};
        }

        .data-review .handsontable td.default-cell.htDimmed,
        .data-review .handsontable td.default-cell.htDimmed.current.highlight {
          background-color: ${showRemove
            ? COLOR_TABLE.REMOVE
            : COLOR_TABLE.READ_ONLY};
        }

        .data-review .handsontable .ht_clone_master,
        .data-review .handsontable .ht_clone_top {
          z-index: 13 !important;
        }

        .data-review .handsontable .ht_clone_inline_start {
          z-index: 12 !important;
        }

        .data-review .handsontable .handsontableInputHolder.ht_clone_master {
          z-index: 11 !important;
        }

        .data-review .nuvo-add-row-button {
          color: #c5c5c5;
        }
        .data-review
          .handsontable
          .nuvo-add-row-button:hover:not(.nuvo-add-row-button-disabled) {
          color: #717171;
        }

        .nuvo-custom-add-column-button {
          color: #c5c5c5;
        }
        .nuvo-custom-add-column-button:hover {
          color: #717171;
        }

        .data-review .column-menu-icon {
          ${reviewEntriesTheme.smartTable?.th?.menuIcon}
        }

        .data-review .hide-arrow-btn {
          ${reviewEntriesTheme.smartTable?.th?.expandIcon}
        }

        .data-review .hide-arrow-btn.hide-arrow-btn-hover {
          background-color: #eff3f9;
          ${(reviewEntriesTheme.smartTable?.th?.expandIcon as CSSObject)?.[
            ':hover'
          ]}
        }

        .data-review .handsontable .nuvo-add-row-button {
          ${reviewEntriesTheme.addRowButton}
        }

        .data-review .handsontable .nuvo-custom-add-column-button {
          ${reviewEntriesTheme.addColumnButton}
        }
      `,
      readOnly
        ? css`
            .data-review
              .handsontable
              .ht_clone_left
              .wtHolder
              .wtHider
              .htCore
              th {
              color: #8d8d8d;
            }

            .handsontable th:last-child {
              color: #222 !important;
            }
          `
        : ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configTheme, enableExamples, mediaSize, modal]);

  return (
    <div id="wrapper-styled" className={style}>
      {children}
    </div>
  );
};

export default WrapperStyledTable;
