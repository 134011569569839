import { SheetColumn } from '@nuvo-importer/common/sdk';
import ColHeaderController from '../colHeaders/Controller';
import useViewModel from './viewModel';
import { useMemo } from 'react';
import { css } from '@emotion/css';
import { cx, useTheme } from '@nuvo-importer/common';
import Message from 'baseUI/SheetInfoMessage';
import { useTranslation } from 'react-i18next';

type JoinPopoverProps = {
  colHeaderController: ColHeaderController;
  sheetColumns: SheetColumn[];
};

const JoinPopover = ({
  colHeaderController,
  sheetColumns,
}: JoinPopoverProps) => {
  const { popper, isOpen, joinSheetColumnPopover } = useViewModel({
    colHeaderController,
    sheetColumns,
  });
  const { t } = useTranslation();
  const theme = useTheme();

  const popoverStyle = useMemo(() => {
    return css`
      &[data-popper-reference-hidden='true'] {
        visibility: hidden;
        pointer-events: none;
      }
    `;
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <Message
      sheetColumns={joinSheetColumnPopover?.getJoinedSheetColumns() ?? []}
      theme={theme.getJoinSheetTheme().joinPopover}
      className={cx(
        popoverStyle,
        css({
          '&&': theme.getJoinSheetTheme().joinPopover,
        })
      )}
      style={popper.styles['popper']}
      ref={popper.setPopperElement}
      popperAttrs={popper.attributes['popper']}
      title={t('txt_joined_data_from')}
    />
  );
};

export default JoinPopover;
