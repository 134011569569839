/* istanbul ignore file */

import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import { useContextConfirmModalManager } from './context';
import BaseModal from '../Modal/BaseModal';
import { css, CSSObject, cx } from 'core/emotion';
import { ReactComponent as WarningIcon } from '../../assets/icon/warning-icon.svg';
import { ReactComponent as WarningCircleIcon } from '../../assets/icon/warning-circle-icon.svg';
import { useSettings } from 'settings';
import { useTheme } from 'theme';
import Popover from 'baseUI/Popover';
import Message from 'baseUI/Popover/Message';
import { isMobile, isTablet } from 'react-device-detect';
import isPromise from 'is-promise';

export type ConfirmModalProps = {
  isShowIcon?: boolean;
  title?: string | ReactNode;
  description: string | ReactNode;
  content?: ReactNode;
  textPositiveButton?: string;
  textNegativeButton?: string;
  onClickPositiveButton?: () => void;
  onClickNegativeButton: () => void;
  onClickNegativeButtonPromise?: () => Promise<void>;
  isShowPositiveButton?: boolean;
  disabledClickOutside?: boolean;
  isShowCloseIcon?: boolean;
  type?: 'warn' | 'confirm';
  disabledPositiveButton?: boolean;
  isPopper?: boolean;
  textPopper?: string;
  descriptionClassName?: string;
  footerClassName?: string;
  negativeButtonClassName?: string;
  positiveButtonClassName?: string;
  positiveButtonWrapperClassName?: string;
  roundedIcon?: boolean;
  baseModalClassName?: string;
  customIcon?: (className: string) => ReactNode;
  titleClassName?: string;
};

const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  description,
  textPositiveButton,
  textNegativeButton,
  onClickPositiveButton,
  onClickNegativeButton,
  onClickNegativeButtonPromise,
  isShowPositiveButton = true,
  isShowIcon = false,
  disabledClickOutside = false,
  isShowCloseIcon = true,
  type = 'confirm',
  disabledPositiveButton = false,
  isPopper = false,
  textPopper,
  content,
  descriptionClassName,
  footerClassName,
  negativeButtonClassName,
  positiveButtonClassName,
  positiveButtonWrapperClassName,
  roundedIcon,
  baseModalClassName,
  customIcon,
  titleClassName,
}) => {
  const { isOpen, setOpenModal } = useContextConfirmModalManager();
  const { t } = useTranslation();
  const { modal } = useSettings();
  const theme = useTheme();
  const [isPopperShow, setIsPopoverShow] = useState(false);
  const [loadingNegativeButton, setLoadingNegativeButton] = useState(false);

  const getOnExportFileHoverProps = () => {
    if (!isMobile && !isTablet) {
      return {
        onMouseEnter: () => setIsPopoverShow(true),
        onMouseLeave: () => setIsPopoverShow(false),
      };
    } else {
      return {
        onClick: () => setIsPopoverShow((isShow) => !isShow),
        onMouseLeave: () => setIsPopoverShow(false),
      };
    }
  };

  const disabledClassName = cx(
    `cursor-not-allowed bg-opacity-75`,
    css`
      background-color: ${(theme.getButtonTheme().secondary as CSSObject)
        ?.backgroundColor ?? '#DFE2E4'} !important;
      color: #88959c;
      border-color: #dfe2e4;
    `
  );

  const popperClassName = css`
    z-index: 1000 !important;
  `;

  const onHandleNegativeButtonClick = () => {
    if (onClickNegativeButtonPromise) {
      const promise = onClickNegativeButtonPromise();
      if (isPromise(promise)) {
        setLoadingNegativeButton(true);
        promise
          .then(() => {
            setLoadingNegativeButton(false);
            setOpenModal(false);
          })
          .catch(() => {
            setLoadingNegativeButton(false);
            setOpenModal(false);
          });
      } else {
        setOpenModal(false);
      }
    } else {
      setOpenModal(false);
      onClickNegativeButton();
    }
  };

  const iconBaseClassName = cx(
    'w-20',
    css({
      '&&': theme.getDialogConfirmTheme().icon,
      '&& path': {
        fill: (theme.getDialogConfirmTheme().icon as CSSObject)?.['fill'],
        stroke: (theme.getDialogConfirmTheme().icon as CSSObject)?.['stroke'],
      },
    })
  );

  return (
    <BaseModal
      gravity="middle"
      className={cx('w-full !p-0 sm:max-w-xl', baseModalClassName)}
      isOpen={isOpen}
      onCloseModal={() => {
        setOpenModal(false);
      }}
      wrapperClassName={cx(css`
        z-index: 10000 !important;
      `)}
      disabledClickOutside={disabledClickOutside}
      isShowCloseIcon={isShowCloseIcon}
      overlayStyled={theme.getDialogConfirmTheme().overlay}
      bodyStyled={theme.getDialogConfirmTheme().root}
      closeIconStyled={theme.getDialogConfirmTheme().closeIcon}
    >
      <div className="w-full px-7 pb-8 pt-3">
        {customIcon ? (
          <div className="flex justify-center pb-4">
            {customIcon(iconBaseClassName)}
          </div>
        ) : isShowIcon && roundedIcon ? (
          <div className="flex justify-center pb-4">
            <WarningCircleIcon className={iconBaseClassName} />
          </div>
        ) : (
          <div className="flex justify-center pb-4">
            <WarningIcon className={iconBaseClassName} />
          </div>
        )}

        {title && (
          <p
            className={cx(
              'xss:pb-2 text-color-primary text-neutral-gray pb-1 text-center text-xl font-semibold',
              css({ '&&': theme.getDialogConfirmTheme().title }),
              titleClassName
            )}
            dangerouslySetInnerHTML={{ __html: title as string }}
          ></p>
        )}
        {description && (
          <p
            className={cx(
              'xss:pb-7 text-color-secondary pb-2 text-center text-sm font-normal',
              css({ '&&': theme.getDialogConfirmTheme().description }),
              descriptionClassName
            )}
            dangerouslySetInnerHTML={{ __html: description as string }}
          ></p>
        )}
        {content ? content : null}
        <div
          className={cx(
            'xss:flex mt-2 flex-row justify-center sm:mt-0',
            footerClassName
          )}
        >
          <Popover
            isShow={isPopper && isPopperShow}
            message={
              <Message className="max-w-355 h-full w-full" variant="info">
                {textPopper}
              </Message>
            }
            flip
            direction="top"
            className={popperClassName}
            render={({ referenceElement }) => {
              return (
                <>
                  <span
                    ref={referenceElement}
                    className={cx(positiveButtonWrapperClassName)}
                  >
                    {isShowPositiveButton && (
                      <Button
                        {...getOnExportFileHoverProps()}
                        className={cx(
                          `xss:mt-0 xss:mr-4 mt-3 mr-0 ${
                            disabledPositiveButton && disabledClassName
                          }`,
                          positiveButtonClassName
                        )}
                        type="button"
                        variant="secondary"
                        onClick={() => {
                          if (!disabledPositiveButton) {
                            setOpenModal(false);
                            onClickPositiveButton && onClickPositiveButton();
                          }
                        }}
                      >
                        <div
                          className={cx(
                            'leading-6',
                            disabledPositiveButton && 'text-gray-260'
                          )}
                        >
                          {textPositiveButton
                            ? textPositiveButton
                            : t('txt_cancel')}
                        </div>
                      </Button>
                    )}
                  </span>
                  {type !== 'warn' ? (
                    <Button
                      className={cx(
                        'xss:mt-0 mt-3',
                        {
                          'w-full': !isShowPositiveButton,
                        },
                        negativeButtonClassName
                      )}
                      type="submit"
                      loading={loadingNegativeButton}
                      onClick={() => {
                        onHandleNegativeButtonClick();
                      }}
                    >
                      {textNegativeButton ? textNegativeButton : 'Ok'}
                    </Button>
                  ) : modal ? (
                    <Button
                      className={cx(
                        'xss:mt-0 mt-3',
                        {
                          'w-full': !isShowPositiveButton,
                        },
                        negativeButtonClassName
                      )}
                      type="submit"
                      loading={loadingNegativeButton}
                      onClick={() => {
                        onHandleNegativeButtonClick();
                      }}
                    >
                      {textNegativeButton ? textNegativeButton : 'Ok'}
                    </Button>
                  ) : null}
                </>
              );
            }}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default ConfirmModal;
