import { DataModelSerializer } from '@nuvo-importer/common/worker/matching-value';
import {
  MatchingJSON,
  MatchingSheetJSON,
} from '../../reviewEntries/DataModelSheetMatchingValues';
import { DetectedNumberFormats } from '@nuvo-importer/common/core';

export class MatchingSerializer {
  static serialize = (instance: {
    matching: MatchingSheetJSON[];
    detectedNumberFormats: DetectedNumberFormats;
  }) => {
    const result: {
      matching: MatchingJSON[];
      detectedNumberFormats: DetectedNumberFormats;
    } = {
      matching: [],
      detectedNumberFormats: instance.detectedNumberFormats,
    };

    result.matching = instance.matching.map((match) => {
      return {
        sheetColumn: match.sheetColumn,
        matchedDataModel: match.matchedDataModel
          ? {
              dataModel: match.matchedDataModel!.dataModel
                ? DataModelSerializer.serialize(
                    match.matchedDataModel!.dataModel
                  )
                : match.matchedDataModel!.dataModel,
              matchedOptions: match.matchedDataModel.matchedOptions,
            }
          : match.matchedDataModel,
      };
    });

    return [result, []];
  };

  static deserialize = (obj: {
    matching: MatchingJSON[];
    detectedNumberFormats: DetectedNumberFormats;
  }): {
    matching: MatchingSheetJSON[];
    detectedNumberFormats: DetectedNumberFormats;
  } => {
    return {
      matching: obj.matching.map((match) => {
        return {
          sheetColumn: match.sheetColumn,
          matchedDataModel: match.matchedDataModel
            ? {
                dataModel: match.matchedDataModel!.dataModel
                  ? DataModelSerializer.deSerialize(
                      match.matchedDataModel!.dataModel
                    )
                  : match.matchedDataModel!.dataModel,
                matchedOptions: match.matchedDataModel.matchedOptions,
              }
            : match.matchedDataModel,
        };
      }),
      detectedNumberFormats: obj.detectedNumberFormats,
    };
  };
}
