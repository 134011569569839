import {
  IContextEngineUploadFileDTO,
  IContextualEngineDeleteFileResponse,
  IContextualEngineGetFilePathResponse,
  IContextualEngineProcessingDTO,
  IContextualEngineProcessingResponse,
} from './ContextualEngine.dto';
import { Values } from '../../types';
import { Rxios } from '@nuvo-importer/common/sdk';
import { map, Observable } from 'rxjs';
import { JSONValue } from '@nuvo-importer/common/core';
import { API_GATEWAY_TOKEN } from 'core/constants/service';

class ContextualEngineService extends Rxios {
  getFilePath(
    licenseKey: string,
    abortController: AbortController
  ): Observable<IContextualEngineGetFilePathResponse> {
    const url = `${process.env.NX_CONTEXTUAL_ENGINE_URL}/v1/file/directory`;
    return this.post(
      url,
      {},
      {
        headers: {
          apikey: API_GATEWAY_TOKEN,
          Authorization: licenseKey,
        },
        signal: abortController.signal,
      }
    );
  }

  uploadDataToFile(
    presignedUrl: string,
    body: IContextEngineUploadFileDTO,
    abortController: AbortController
  ): Observable<unknown> {
    return this.put(presignedUrl, body, {
      signal: abortController.signal,
    });
  }

  processContextualEngine(
    licenseKey: string,
    body: IContextualEngineProcessingDTO,
    abortController: AbortController
  ) {
    const headers = {
      apikey: API_GATEWAY_TOKEN,
      'x-license-key': licenseKey,
    };
    const url = `${process.env.NX_CONTEXTUAL_ENGINE_URL}/service/v1/contextual-engine`;
    return this.post<IContextualEngineProcessingResponse>(
      url,
      {
        file_name: body.file_name,
        tdm: body.tdm as JSONValue,
        session_id: body.session_id,
      },
      {
        headers,
        signal: abortController.signal,
      }
    );
  }

  getProcessedData(
    url: string,
    abortController: AbortController
  ): Observable<{ data: Values; fileName: string }> {
    return this.get<Values>(url, {}, { signal: abortController.signal }).pipe(
      map((data) => ({
        data,
        fileName: url,
      }))
    );
  }

  deleteProcessedFile(licenseKey: string, fileName: string) {
    const url = `${
      process.env.NX_CONTEXTUAL_ENGINE_URL
    }/v1/file/?file_name=${encodeURIComponent(fileName)}`;

    const headers = {
      apikey: API_GATEWAY_TOKEN,
      Authorization: licenseKey,
    };

    return this.delete<IContextualEngineDeleteFileResponse>(url, { headers });
  }
}

export default ContextualEngineService;
