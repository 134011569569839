import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { useModal } from '../../main/Modal';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SpreadSheetNavigate from './SpreadSheetNavigate';
import { usePage } from 'main/MainView';
import { useSettings } from 'settings';
import {
  HEADER_SELECTION_PATH,
  JOIN_COLUMN_PATH,
  MATCH_COLUMN_PATH,
  SHEET_SELECTION_PATH,
  UPLOAD_PAGE_PATH,
} from 'core/constants/route';
import { useNavigatePolicy } from 'navigation';
import { Sheet } from 'sheetImporter';
import useInAppNavigate from 'core/navigate';
import { useHooks } from '../../hooks/HooksProvider';
import { isDynamicImport } from 'core/dynamicImport/utils';
import { useDataModels } from '../../dataModel/dataModelConfig';
import { useContextualEngine } from '../../contextualEngine/ContextualEngine';

const useViewModel = () => {
  const initialized = useRef(false);
  const navigate = useInAppNavigate();
  const { state: locationState } = useLocation();
  const state = locationState as {
    spreadSheetNavigate: SpreadSheetNavigate;
    hasBackStep?: boolean;
    dynamicUploadStart?: string;
    processingContextualBefore?: boolean;
  };
  const headerRowIndexRef = useRef<number>(
    state.spreadSheetNavigate.getCurrentSheet().getHeaderRowIndex()
  );
  const { showConfirmModal } = useContextConfirmModalManager();
  const { t } = useTranslation();
  const { cancel } = usePage();
  const { embedUploadArea = false, modal, columns } = useSettings();
  const { getHasSheetSelectionPage, getHasJoinColumnPage } =
    useNavigatePolicy();
  const { hasPreHeaderModifier } = useHooks();
  const [isLoadingContextualEngine, setLoadingContextualEngine] =
    useState(false);
  const [hasProcessContextualEngine, setHasProcessContextualEngine] =
    useState(false);
  const { closeFullScreen } = useModal();
  const dataModel = useDataModels();
  const {
    processContextualEngine,
    getContextualEngineType,
    promptContextualEngine,
  } = useContextualEngine();

  const setHeaderRowIndex = (rowIndex: number) => {
    headerRowIndexRef.current = rowIndex;
  };

  const onHeaderRowIndexChange = (rowIndex: number) => {
    setHeaderRowIndex(rowIndex);
  };

  const sheet = useMemo(() => {
    return state.spreadSheetNavigate.getCurrentSheet();
  }, [state]);

  const allSheets = useMemo(() => {
    return state.spreadSheetNavigate.getSpreadSheetList().getSelectedSheets();
  }, [state]);

  const isHideSheetName = useMemo(() => {
    return (
      isDynamicImport(state.spreadSheetNavigate.getSpreadSheetList()) &&
      state.dynamicUploadStart === HEADER_SELECTION_PATH
    );
  }, [state]);

  const currentSheetSelected = useMemo(() => {
    const current = allSheets.findIndex((item) => item === sheet);
    return current;
  }, [sheet, allSheets]);

  const currentProgress = useMemo(() => {
    let sheetHasSelectedHeader = 0;

    allSheets.forEach((sheet) => {
      if (sheet.getIsManualSelectHeader()) {
        sheetHasSelectedHeader += 1;
      }
    });

    return sheetHasSelectedHeader;

    // sheet need to be dependency for update when change current sheet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSheets, sheet]);

  useEffect(() => {
    return () => {
      closeFullScreen();
    };
  }, [closeFullScreen]);

  const handleGoBack = () => {
    if (
      !getHasSheetSelectionPage(state.spreadSheetNavigate.getSpreadSheetList())
    ) {
      if (embedUploadArea) {
        cancel();
      } else {
        cancel();
        navigate({
          pathname: modal === true && !embedUploadArea ? UPLOAD_PAGE_PATH : '/',
        });
      }
    } else {
      state.spreadSheetNavigate
        .getSpreadSheetList()
        .getSelectedSheets()
        .forEach((sheet) => {
          sheet.clearIsManualSelectHeader();
        });

      navigate(
        {
          pathname: SHEET_SELECTION_PATH,
        },
        {
          state: {
            spreadSheetList: state.spreadSheetNavigate.getSpreadSheetList(),
            hasBackStep: true,
            dynamicUploadStart: state?.dynamicUploadStart,
          },
        }
      );
    }
  };

  const onBackClick = () => {
    if (state?.dynamicUploadStart === HEADER_SELECTION_PATH) {
      showConfirmModal({
        isShowIcon: true,
        title: t('txt_confirm_title'),
        description: t('txt_go_back_dynamic_import_title'),
        textNegativeButton: t('txt_confirm_leave_confirm_btn'),
        textPositiveButton: t('txt_confirm_leave_cancel_btn'),
        onClickNegativeButton: () => {
          cancel();
        },
      });
    } else if (hasPreHeaderModifier) {
      showConfirmModal({
        isShowIcon: true,
        title: t('txt_confirm_title'),
        description: t('txt_has_per_mapping_modal'),
        textNegativeButton: t('txt_confirm_leave_confirm_btn'),
        textPositiveButton: t('txt_confirm_leave_cancel_btn'),
        onClickNegativeButton: () => {
          if (embedUploadArea) {
            cancel();
          } else {
            cancel();
            navigate({
              pathname:
                modal === true && !embedUploadArea ? UPLOAD_PAGE_PATH : '/',
            });
          }
        },
      });
    } else {
      showConfirmModal({
        isShowIcon: true,
        title: t('txt_confirm_title'),
        description: t('txt_back_page_dialog'),
        textNegativeButton: t('txt_go_back'),
        textPositiveButton: t('txt_cancel'),
        onClickNegativeButton: () => {
          handleGoBack();
        },
      });
    }
  };

  const wrapperSheetNameRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sheetNameHeader =
      wrapperSheetNameRef.current?.querySelector('.current');

    sheetNameHeader?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });

    return () => {};
  }, [currentProgress]);

  const goToSheet = (goSheet: Sheet) => {
    const foundSheet = state.spreadSheetNavigate.goToBySheet(goSheet);
    sheet.setHeaderRowIndex(headerRowIndexRef.current);

    if (foundSheet) {
      setHeaderRowIndex(foundSheet.getHeaderRowIndex());

      navigate(
        {
          pathname: HEADER_SELECTION_PATH,
        },
        {
          state: {
            spreadSheetNavigate: state.spreadSheetNavigate,
            hasBackStep: state?.hasBackStep,
            dynamicUploadStart: state?.dynamicUploadStart,
          },
        }
      );
    }
  };

  const onContinueClick = () => {
    const currentSheet = state.spreadSheetNavigate.getCurrentSheet();
    currentSheet.setHeaderRowIndex(headerRowIndexRef.current);
    currentSheet.setIsManualSelectHeader();

    const hasNext = state.spreadSheetNavigate.next();
    if (hasNext) {
      const nextSheet = state.spreadSheetNavigate.getCurrentSheet();
      setHeaderRowIndex(nextSheet.getHeaderRowIndex());

      navigate(
        {
          pathname: HEADER_SELECTION_PATH,
        },
        {
          state: {
            spreadSheetNavigate: state.spreadSheetNavigate,
            hasBackStep: state?.hasBackStep,
            dynamicUploadStart: state?.dynamicUploadStart,
          },
        }
      );
    } else {
      const isShowJoinColumn = getHasJoinColumnPage(
        state.spreadSheetNavigate.getSpreadSheetList()
      );

      navigate(
        {
          pathname: isShowJoinColumn ? JOIN_COLUMN_PATH : MATCH_COLUMN_PATH,
        },
        {
          state: {
            spreadSheetNavigate: state.spreadSheetNavigate,
            hasBackStep: state?.hasBackStep,
            dynamicUploadStart: state?.dynamicUploadStart,
          },
        }
      );
    }
  };

  const textSubmitButton = useMemo(() => {
    const isAllConfirm = state.spreadSheetNavigate
      .getSpreadSheetList()
      .getIsAllSheetsManualSelectHeader();

    const isLastSheetNotConfirm =
      currentProgress === allSheets.length - 1 &&
      !sheet.getIsManualSelectHeader();

    if (isAllConfirm || isLastSheetNotConfirm) {
      return t('txt_continue');
    } else {
      return t('txt_confirm');
    }
  }, [t, state, allSheets.length, currentProgress, sheet]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (
        getContextualEngineType() !== 'disabled' &&
        allSheets.length === 1 &&
        !isLoadingContextualEngine &&
        !hasProcessContextualEngine &&
        !state.processingContextualBefore
      ) {
        if (getContextualEngineType() === 'enabled') {
          setLoadingContextualEngine(true);
          processContextualEngine({
            sheetData: sheet.getData(),
            columns,
            dataModels: dataModel.getDataModels(),
            spreadSheetNavigate: state.spreadSheetNavigate,
          })
            .catch(() => {
              setLoadingContextualEngine(false);
              setHasProcessContextualEngine(true);
            })
            .finally(() => {
              setLoadingContextualEngine(false);
              setHasProcessContextualEngine(true);
            });
        } else if (getContextualEngineType() === 'prompt') {
          promptContextualEngine({
            sheetData: sheet.getData(),
            columns,
            dataModels: dataModel.getDataModels(),
            spreadSheetNavigate: state.spreadSheetNavigate,
            onStartProcess: () => setLoadingContextualEngine(true),
            onFinishProcess: () => {
              setLoadingContextualEngine(false);
              setHasProcessContextualEngine(true);
            },
            onFailProcess: () => {
              setLoadingContextualEngine(false);
              setHasProcessContextualEngine(true);
            },
            onCancel: () => {
              navigate(
                {
                  pathname: MATCH_COLUMN_PATH,
                },
                {
                  state: {
                    spreadSheetNavigate: state.spreadSheetNavigate,
                    hasBackStep: state?.hasBackStep,
                    dynamicUploadStart: state?.dynamicUploadStart,
                  },
                }
              );
            },
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onBackClick,
    sheet,
    onContinueClick,
    onHeaderRowIndexChange,
    headerRowIndexRef,
    currentProgress,
    currentSheetSelected,
    allSheets,
    wrapperSheetNameRef,
    goToSheet,
    textSubmitButton,
    isHideSheetName,
    isLoadingContextualEngine,
  };
};

export default useViewModel;
